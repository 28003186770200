/// <reference types="@types/google.maps" />
import { useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { LocationFragment } from '@assured/shared-types/Claim/LocationType';
import { Coordinate } from '@assured/step-renderer/types/step-components/additional';

const queryPredictions: {
  [query: string]: google.maps.places.AutocompletePrediction[];
} = {};

const placeDetails: {
  [placeId: string]: google.maps.places.PlaceResult;
} = {};

export const usePlacesQuery = (
  query?: string,
  options: {
    searchBias?: Coordinate | LocationFragment;
    types?: string[];
  } = {},
) => {
  const {
    placePredictions,
    placesService,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const resolvePlaceDetails = (placeId: string) => {
    if (placeDetails[placeId]) {
      return Promise.resolve(placeDetails[placeId]);
    }

    return new Promise<google.maps.places.PlaceResult>((resolve, reject) =>
      placesService?.getDetails(
        { placeId },
        (
          place: google.maps.places.PlaceResult | null,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (status === 'OK' && place) {
            resolve(place);
          } else {
            reject(status);
          }
        },
      ),
    );
  };

  const resolvePlaceIdFromText = (text: string) => {
    return new Promise<string>((resolve, reject) =>
      placesService?.findPlaceFromQuery(
        {
          query: text,
          fields: ['place_id'],
        },
        results => {
          const placeId = results?.[0]?.place_id;
          if (!placeId) {
            return reject('Not found');
          }
          resolve(placeId);
        },
      ),
    );
  };

  // Whenever the query changes, we want to get the place predictions
  useEffect(() => {
    if (!query) {
      setPredictions([]);
      return;
    }

    if (queryPredictions[query]) {
      setPredictions(queryPredictions[query]);
      return;
    }

    getPlacePredictions({
      input: query,
      ...(options.searchBias?.latitude && options.searchBias?.longitude
        ? {
            // Use specific lat/lng to bias search results.
            locationBias: {
              radius: 250,
              center: {
                lat: options.searchBias.latitude,
                lng: options.searchBias.longitude,
              },
            },
          }
        : (options.searchBias as LocationFragment)?.country === 'US'
        ? {
            // Bias to USA-wide, rather than using user specific location.
            locationBias: {
              radius: 2000,
              center: {
                lat: 39.8283,
                lng: -98.5795,
              },
            },
          }
        : {}),
      ...(options.types ? { types: options.types } : {}),
    });
  }, [query]);

  return {
    predictions: predictions.length ? predictions : placePredictions,
    isPlacePredictionsLoading,
    resolvePlaceDetails,
    resolvePlaceIdFromText,
  };
};
