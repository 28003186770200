import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { ActiveLogEventItem } from './types';

export const useActiveLogEvents = (
  currentTime: number,
  items: ActiveLogEventItem[] | undefined,
  earliestStartTime: string | undefined,
  duration: number | undefined,
): ActiveLogEventItem[] => {
  const [seenItems, setSeenItems] = useState<ActiveLogEventItem[]>([]);

  useEffect(() => {
    const earliestStartDateTime = earliestStartTime
      ? DateTime.fromISO(earliestStartTime)
      : null;

    if (!items || !earliestStartDateTime) {
      setSeenItems([]);
      return;
    }

    if (currentTime === duration) {
      // If currentTime equals duration, set all items as seen
      setSeenItems(items);
      return;
    }

    const updatedSeenItems = items.filter(item => {
      // Treat the `createdAt` of `CREATE` events as `earliestStartDateTime`
      const relativeStart =
        item.type === 'CREATE'
          ? 0 // Adjust `CREATE` event to be at the very beginning
          : DateTime.fromISO(item.createdAt)
              .diff(earliestStartDateTime)
              .toMillis();

      const isActive = currentTime >= relativeStart;
      return isActive;
    });

    setSeenItems(updatedSeenItems);
  }, [currentTime, items, earliestStartTime, duration]);

  return seenItems;
};
