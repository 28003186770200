import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type SegmentedControlNavProps = HTMLAttributes<HTMLDivElement>;

export const SegmentedControlNav = forwardRef<
  HTMLDivElement,
  SegmentedControlNavProps
>(({ className, ...rest }, ref) => {
  const classes = classNames(
    'bg-white inline-flex items-center py-[4px] px-[2px] border border-gray-200 rounded-lg',
    className,
  );

  return <nav className={classes} ref={ref} {...rest} />;
});

export type SegmentedControlNavLinkProps = NavLinkProps;

export const SegmentedControlNavLink = forwardRef<
  HTMLAnchorElement,
  SegmentedControlNavLinkProps
>(({ className, activeClassName, ...rest }, ref) => {
  const classes = classNames(
    `relative text-sm text-gray-600 h-[40px] flex items-center justify-center px-4 rounded-lg font-medium hover:bg-gray-50 outline-none mx-[2px] focus-visible:ring-1`,
    className,
  );
  const activeClasses = classNames(
    'text-gray-700 bg-indigo-50 text-indigo-700 hover:bg-indigo-50 shadow-[0_0_0_1px_rgb(67,56,202),0_1px_2px_0_rgba(0,0,0,0.6)] before:hidden focus-visible:shadow-none focus-visible:ring-1',
    activeClassName,
  );

  return (
    <NavLink
      className={classes}
      activeClassName={activeClasses}
      ref={ref}
      {...rest}
    />
  );
});
