export interface PlayHeadProps {
  playheadPosition: number;
  handleMouseDown: () => void;
  handleKeyDown: (e: React.KeyboardEvent) => void;
  ariaMin: number;
  ariaMax: number;
  ariaNow: number;
}

export function PlayHead(props: PlayHeadProps) {
  const {
    playheadPosition,
    handleMouseDown,
    handleKeyDown,
    ariaMin,
    ariaMax,
    ariaNow,
  } = props;

  return (
    <>
      {/* The playhead ball marker */}
      <div
        className="absolute w-[10px] h-[10px] rounded-[5px] bg-indigo-600 -top-[0.2rem] cursor-pointer z-10"
        style={{ left: `${playheadPosition}%` }}
        onMouseDown={handleMouseDown}
        onKeyDown={handleKeyDown}
        role="slider"
        tabIndex={0}
        aria-valuemin={ariaMin}
        aria-valuemax={ariaMax}
        aria-valuenow={ariaNow}
        aria-label="Playback position"
      />
      {/* The playhead played bar */}
      <div
        className="absolute h-full bg-blue-500"
        style={{ width: `${playheadPosition}%` }}
      />
    </>
  );
}
