import classNames from 'classnames';
import { ReactNode } from 'react';

export interface ShortcutKeyIconProps {
  /** Custom className */
  className?: string;

  /** Child node */
  children?: ReactNode;

  /** If `true` will be checked */
  isActive?: boolean;

  /** If `true` will appear disabled */
  isDisabled?: boolean;

  /** Different variants of styling */
  variant?: 'light';
}

export const ShortcutKeyIcon = ({
  children,
  className,
  isActive,
  isDisabled,
  variant,
}: ShortcutKeyIconProps) => {
  const isLight = variant === 'light';
  return (
    <span
      className={classNames(
        'rounded-md text-sm font-medium px-2.5 min-w-[30px] min-h-[28px] tabular-nums leading-7 inline-block transition-opacity',
        {
          'bg-indigo-bright-600 text-white': isActive,
          'bg-cool-gray-200 text-cool-gray-800': !isActive && !isLight,
          'bg-cool-gray-300 text-white': isLight,
          'opacity-50': isDisabled,
        },
        className,
      )}
    >
      {children}
    </span>
  );
};
