import classNames from 'classnames';
import { ReactNode } from 'react';

import { ConcatenatedChips } from '../Chip';
import { Spinner } from '../Spinner';

const PolicySearchTrailingContentIcon = ({
  children,
  className,
}: {
  /** Inner content */
  children?: ReactNode;

  /** Custom className */
  className?: string;
}) => (
  <div
    className={classNames(
      'px-2 py-1 rounded-[6px] bg-cool-gray-100 text-cool-gray-800 text-xs leading-5 font-medium absolute',
      className,
    )}
  >
    {children}
  </div>
);

/**
 * A component to render hint text, styled accordingly
 */
const HintText = ({ children }: { children: ReactNode }) => (
  <span className="text-xs leading-5 font-medium text-cool-gray-500">
    {children}
  </span>
);

export const PolicySearchTrailingContent = ({
  className,
  hintText,
  isLoading,
  shouldPromptToSearch,
}: {
  /** Custom className */
  className?: string;

  /** Custom hint text */
  hintText?: string;

  /** Set to `true` when the input is loading */
  isLoading?: boolean;

  /**
   * Set to `true` if the user should be prompted to search.
   */
  shouldPromptToSearch?: boolean;
}) => {
  /**
   * If `true` shows a hint to the user to search when a higher
   * priority hint is non-existent.
   */
  const shouldShowPromptToSearchHint = !hintText && shouldPromptToSearch;

  return (
    <div className={className}>
      {isLoading ? (
        <Spinner className="text-cool-gray-400" />
      ) : shouldShowPromptToSearchHint ? (
        <>
          <HintText>Press</HintText>
          <ConcatenatedChips chips={[{ children: 'Enter ↵' }]} />
          <HintText>to search</HintText>
        </>
      ) : (
        <>
          {!hintText && (
            <div className="flex items-center w-[62px] relative">
              <PolicySearchTrailingContentIcon className="left-0">
                &uarr;
              </PolicySearchTrailingContentIcon>
              <PolicySearchTrailingContentIcon className="right-0">
                &darr;
              </PolicySearchTrailingContentIcon>
            </div>
          )}
          <HintText>{hintText ?? 'to navigate'}</HintText>
        </>
      )}
    </div>
  );
};
