import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';

export type PillVariant =
  | 'blue'
  | 'dark'
  | 'disabled'
  | 'green-light'
  | 'neutral'
  | 'neutral-active'
  | 'red-light'
  | 'indigo-light'
  | 'blue-light'
  | 'orange-light';

export interface PillProps {
  /** Custom className */
  className?: string;

  /** Text content of the pill */
  children: ReactNode;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** An icon (or any React Node really) to render as a text prefix */
  icon?: ReactNode;

  /** A click handler */
  onClick?: () => void;

  /** Size variation of the pill to include font-size and padding */
  size?: 'sm' | 'sm-md' | 'md' | 'lg';

  /** True if pill should have outline (note: some "themes" won't have a border either way - like "dark") */
  isOutlined?: boolean;

  /** Custom styles for the container element, typically border and background styles */
  style?: CSSProperties;

  /** Custom styles for the text element, typically font styles */
  styleText?: CSSProperties;

  /** Variations to cover common cases. Feel free to extend here as needed or override with `style` and `styleText` props */
  variant?: PillVariant;
}

export const Pill = ({
  children,
  className,
  dataTestId = 'pill',
  icon,
  isOutlined = true,
  onClick,
  size = 'sm',
  style,
  styleText,
  variant = 'neutral',
}: PillProps) => {
  return (
    <span
      className={classNames(
        'rounded-full inline-flex',
        {
          // background
          'bg-[#e4ecff]': variant === 'blue',
          'bg-white': variant === 'neutral' || variant === 'disabled',
          'hover:bg-[#eef2ff]': variant === 'neutral' && onClick,
          'bg-[#eef2ff]': variant === 'neutral-active',
          'bg-cool-gray-600': variant === 'dark',
          'bg-green-warm-50': variant === 'green-light',
          'bg-[#fff9f9]': variant === 'red-light',
          'bg-indigo-50': variant === 'indigo-light',
          'bg-blue-light-100': variant === 'blue-light',
          'bg-orange-warm-50': variant === 'orange-light',

          // borders
          border: isOutlined && variant !== 'dark' && variant !== 'blue',
          'border-cool-gray-300': variant === 'neutral',
          'hover:border-[#4F46E5]': variant === 'neutral' && onClick,
          'border-[#4F46E5]': variant === 'neutral-active',
          'border-green-warm-200': variant === 'green-light',
          'border-red-600': variant === 'red-light',
          'border-indigo-200': variant === 'indigo-light',
          'border-blue-light-200': variant === 'blue-light',
          'border-orange-warm-200': variant === 'orange-light',

          // padding
          'px-2 py-0.5': size === 'sm',
          'px-2 py-1': size === 'sm-md',
          'px-2.5 py-1': size === 'md',
          'px-3 py-1.5': size === 'lg',

          // miscellaneous
          'hover:cursor-pointer': onClick,
        },
        className,
      )}
      data-testid={dataTestId}
      onClick={onClick}
      style={style}
    >
      <span
        className={classNames('flex items-center', {
          // font-size
          'text-xxs': size === 'sm' || size === 'sm-md',
          'text-sm': size === 'md',
          'text-base': size === 'lg',

          // color
          'text-blue-600': variant === 'blue',
          'text-cool-gray-50': variant === 'dark',
          'text-cool-gray-400': variant === 'disabled',
          'text-cool-gray-700': variant.includes('neutral'),
          'text-cool-gray-900':
            variant === 'green-light' || variant === 'red-light',
          'text-green-warm-900': variant === 'green-light',
          'text-orange-warm-900': variant === 'orange-light',
          'text-indigo-900': variant === 'indigo-light',
          'text-blue-light-900': variant === 'blue-light',
        })}
        style={styleText}
      >
        {icon && <span className="inline-flex items-center mr-1">{icon}</span>}
        {children}
      </span>
    </span>
  );
};
