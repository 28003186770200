import { ComponentProps, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';

export type TaskCardHeaderInfoProps = ComponentProps<'div'> & {
  /** Main header text */
  header: ReactNode;

  /**
   * Set `true` if actionable (in other words contained in a button,
   * for example)
   */
  isActionable?: boolean;

  /** Text to display under the main header */
  subHeader?: ReactNode;
};

/**
 * A React component to render task prominent info like title
 */
export const TaskCardHeaderInfo = ({
  className,
  header,
  isActionable,
  subHeader,
}: TaskCardHeaderInfoProps) => {
  return (
    <div className={twMerge('flex flex-col', className)}>
      <div className="flex justify-between items-center gap-2">
        <h4 className="font-semibold text-base leading-6">{header}</h4>
        {isActionable && (
          <IconFlatRenderer
            iconKey="ICON_FLAT_CHEVRON_OUTLINE_RIGHT"
            className="text-cool-gray-500"
          />
        )}
      </div>
      {subHeader && (
        <span className="text-xs leading-4 font-normal">{subHeader}</span>
      )}
    </div>
  );
};
