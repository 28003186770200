import { PlayHead } from './PlayHead';
import { MemoizedEventTick } from './Tick';
import TimeLabels from './TimeLabels';
import { MainEvent } from './types';

interface TrackProps {
  event: MainEvent;
  playheadPosition: number;
  setCurrentTime: (time: number) => void;
  trackRef: React.RefObject<HTMLDivElement>;
  currentTime: number;
  earliestStartTime: string;
}

export function Track(props: TrackProps): JSX.Element {
  const {
    event,
    playheadPosition,
    setCurrentTime,
    trackRef,
    currentTime,
    earliestStartTime,
  } = props;

  const handleScrub = (position: number) => {
    const trackRect = trackRef.current?.getBoundingClientRect();
    if (!trackRect) {
      return;
    }

    const ratio = position / trackRect.width;
    const newTime = Math.min(
      Math.max(ratio * event.duration, 0),
      event.duration,
    );
    setCurrentTime(newTime);
  };

  const handleTrackClick = (e: React.MouseEvent) =>
    handleScrub(
      e.clientX - (trackRef.current?.getBoundingClientRect().left || 0),
    );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      // These values probably need to be updated to be dynamic off of whatever the
      // incrementAmount is at instantiation
      setCurrentTime(Math.min(currentTime + 10, event.duration));
    } else if (e.key === 'ArrowLeft') {
      // These values probably need to be updated to be dynamic off of whatever the
      // incrementAmount is at instantiation
      setCurrentTime(Math.max(currentTime - 10, 0));
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const touchX = e.touches[0].clientX;
    handleScrub(touchX - (trackRef.current?.getBoundingClientRect().left || 0));
  };

  const handleMouseDown = () => {
    const handleMouseMove = (e: MouseEvent) =>
      handleScrub(
        e.clientX - (trackRef.current?.getBoundingClientRect().left || 0),
      );
    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="relative mt-4">
      {/* Track background */}
      <div
        className="h-1 bg-gray-200 w-full relative cursor-pointer"
        ref={trackRef}
        onClick={handleTrackClick}
        onKeyDown={handleKeyDown}
        onTouchMove={handleTouchMove}
        role="slider"
        tabIndex={0}
        aria-valuemin={0}
        aria-valuemax={event.duration}
        aria-valuenow={currentTime}
        aria-label="Playback track"
      >
        <PlayHead
          playheadPosition={playheadPosition}
          handleMouseDown={handleMouseDown}
          handleKeyDown={handleKeyDown}
          ariaMin={0}
          ariaMax={event.duration}
          ariaNow={currentTime}
        />
        {event &&
          event.subEvents &&
          event.subEvents.length > 0 &&
          event.subEvents.map(subEvent => (
            <MemoizedEventTick
              id={subEvent.id}
              phase={subEvent.phase}
              start={subEvent.start}
              eventDuration={event.duration}
              earliestStartTime={earliestStartTime}
            />
          ))}
      </div>

      {/* Time labels */}
      {event && event.labels && event.labels.length > 0 && (
        <TimeLabels labels={event.labels} />
      )}
    </div>
  );
}
