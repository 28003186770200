export interface StatusIconProps {
  status: 'invalid' | '0/4' | '1/4' | '2/4' | '3/4' | 'done';
  color?: string;
}

export const StatusIcon = ({ status, color = '#D97706' }: StatusIconProps) => {
  switch (status) {
    case 'done':
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C5.51088 15 3.60322 14.2098 2.1967 12.8033C0.790177 11.3968 0 9.48912 0 7.5C0 5.51088 0.790177 3.60322 2.1967 2.1967C3.60322 0.790177 5.51088 0 7.5 0C9.48912 0 11.3968 0.790177 12.8033 2.1967C14.2098 3.60322 15 5.51088 15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033ZM11.0918 5.46563C11.3254 5.13864 11.2497 4.68421 10.9227 4.45064C10.5957 4.21707 10.1412 4.29281 9.90766 4.6198L6.76884 9.01415L5.19336 7.43866C4.90921 7.15451 4.44851 7.15451 4.16436 7.43866C3.88021 7.72281 3.88021 8.18351 4.16436 8.46766L6.34719 10.6505C6.49843 10.8017 6.70855 10.8788 6.9217 10.8611C7.13486 10.8435 7.32945 10.733 7.45377 10.5589L11.0918 5.46563Z"
            fill={color}
          />
        </svg>
      );
    case '0/4':
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7.5" cy="7.5" r="6.75" stroke={color} strokeWidth="1.5" />
        </svg>
      );
    case '1/4':
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7.5" cy="7.5" r="6.75" stroke={color} strokeWidth="1.5" />
          <path
            d="M7.5 3.24992C7.5 3.11185 7.61202 2.9992 7.74988 3.00686C8.44612 3.04558 9.12508 3.24568 9.73251 3.59276C10.4124 3.98125 10.9792 4.54045 11.3768 5.21507C11.7744 5.8897 11.9891 6.6564 11.9996 7.43941C12.009 8.13894 11.8552 8.82985 11.5518 9.45772C11.4917 9.58203 11.3389 9.62547 11.2181 9.55859L7.5 7.49992L7.5 3.24992Z"
            fill={color}
          />
        </svg>
      );
    case '2/4':
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7.5" cy="7.5" r="6.75" stroke={color} strokeWidth="1.5" />
          <path
            d="M7.5 3.25C7.5 3.11193 7.61202 2.99928 7.74988 3.00694C8.25541 3.03506 8.7532 3.14833 9.22208 3.34254C9.76804 3.56869 10.2641 3.90016 10.682 4.31802C11.0998 4.73588 11.4313 5.23196 11.6575 5.77792C11.8836 6.32389 12 6.90905 12 7.5C12 8.09095 11.8836 8.67611 11.6575 9.22208C11.4313 9.76804 11.0998 10.2641 10.682 10.682C10.2641 11.0998 9.76804 11.4313 9.22208 11.6575C8.7532 11.8517 8.25541 11.9649 7.74988 11.9931C7.61202 12.0007 7.5 11.8881 7.5 11.75L7.5 7.5L7.5 3.25Z"
            fill={color}
          />
        </svg>
      );
    case '3/4':
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7.5" cy="7.5" r="6.75" stroke={color} strokeWidth="1.5" />
          <path
            d="M7.50065 3.25C7.50065 3.11193 7.61267 2.99928 7.75053 3.00694C8.44563 3.0456 9.12352 3.24511 9.73021 3.59116C10.4094 3.97857 10.976 4.53628 11.374 5.20931C11.772 5.88235 11.9878 6.64749 12.0001 7.42932C12.0124 8.21115 11.8207 8.98268 11.444 9.66789C11.0673 10.3531 10.5186 10.9283 9.85189 11.3369C9.18519 11.7454 8.42354 11.9732 7.642 11.9978C6.86046 12.0223 6.086 11.8428 5.39496 11.4769C4.7777 11.1501 4.24602 10.6847 3.84093 10.1185C3.76059 10.0062 3.79568 9.85125 3.91225 9.77726L7.50065 7.5L7.50065 3.25Z"
            fill={color}
          />
        </svg>
      );
    default:
      return (
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.822266 7.5C0.822266 3.35786 4.18013 0 8.32227 0C12.4644 0 15.8223 3.35786 15.8223 7.5C15.8223 11.6421 12.4644 15 8.32227 15C4.18013 15 0.822266 11.6421 0.822266 7.5Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.09469 5.27243C6.17908 5.18804 6.29354 5.14063 6.41289 5.14063C6.53224 5.14063 6.6467 5.18804 6.73109 5.27243L8.32208 6.86342L9.91307 5.27243C9.99746 5.18804 10.1119 5.14062 10.2313 5.14062C10.3506 5.14062 10.4651 5.18804 10.5495 5.27243C10.6339 5.35682 10.6813 5.47128 10.6813 5.59062C10.6813 5.70997 10.6339 5.82443 10.5495 5.90882L8.95847 7.49981L10.5495 9.0908C10.6339 9.17519 10.6813 9.28965 10.6813 9.409C10.6813 9.52835 10.6339 9.64281 10.5495 9.7272C10.4651 9.81159 10.3506 9.859 10.2313 9.859C10.1119 9.859 9.99746 9.81159 9.91307 9.7272L8.32208 8.13621L6.73109 9.7272C6.6467 9.81159 6.53224 9.859 6.41289 9.859C6.29354 9.859 6.17908 9.81159 6.09469 9.7272C6.0103 9.64281 5.96289 9.52835 5.96289 9.409C5.96289 9.28965 6.0103 9.17519 6.09469 9.0908L7.68568 7.49981L6.09469 5.90882C6.0103 5.82443 5.96289 5.70997 5.96289 5.59063C5.96289 5.47128 6.0103 5.35682 6.09469 5.27243Z"
            fill="white"
          />
        </svg>
      );
  }
};
