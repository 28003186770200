import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { SubEvent } from './types';

/**
 * Calculates and returns the earliest start time among a list of sub-events.
 */
export const useEarliestStartTime = (subEvents: SubEvent[]) => {
  return useMemo(() => {
    if (!subEvents || subEvents.length === 0) {
      return null;
    }

    return subEvents.reduce((earliest, subEvent) => {
      const subEventStartDate = DateTime.fromISO(subEvent.start);
      return subEventStartDate < earliest ? subEventStartDate : earliest;
    }, DateTime.fromISO(subEvents[0].start)).toISO();
  }, [subEvents]);
};

