import classNames from 'classnames';
import { ReactNode, useMemo, useRef, useState } from 'react';

import { useResizeObserver } from '@assured/ui/hooks';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';

type ToggleColor = 'indigo' | 'blue';
type Size = 'base' | 'small' | 'large';

export interface ToggleProps {
  /** ID for aria label / form label */
  id?: string;

  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** A default value */
  defaultValue?: boolean;

  /** A controlled value */
  value?: boolean;

  /** Check change handler */
  onChange: (isToggled: boolean) => void;

  /** Which type of toggle to display */
  variant?: 'default' | 'yesno' | 'yesno-slider' | 'checkbox-circle';

  color?: ToggleColor;
  size?: Size;
  /** Optional label */
  label?: ReactNode;
  labelLeft?: ReactNode;
  labelRight?: ReactNode;
  /** Label to replace default of "Yes" in yesno-slider or yesno */
  yesLabel?: string;
  /** Label to replace default of "No" in yesno-slider or yesno */
  noLabel?: string;
}

export const Toggle = ({
  className,
  dataTestId = 'toggle',
  defaultValue = false,
  value,
  onChange,
  id = '',
  variant = 'default',
  label = null,
  labelLeft = '',
  labelRight = '',
  color = 'indigo',
  size = 'base',
  yesLabel = 'Yes',
  noLabel = 'No',
}: ToggleProps) => {
  // Used if component is uncontrolled, ignored if `value` is defined.
  const [isToggled_, setIsToggled] = useState(defaultValue);
  const isToggled = typeof value !== 'undefined' ? value : isToggled_;
  const yesLabelRef = useRef<HTMLDivElement | null>(null);
  const noLabelRef = useRef<HTMLDivElement | null>(null);
  const { width: yesWidth = 0 } = useResizeObserver({
    ref: yesLabelRef,
    box: 'border-box',
  });
  const { width: noWidth = 0 } = useResizeObserver({
    ref: noLabelRef,
    box: 'border-box',
  });
  const labelWidth = Math.max(yesWidth, noWidth);
  const width = useMemo(() => {
    if (size === 'base') {
      return `calc(${labelWidth}px + 2.25rem + 2px)`;
    } else if (size === 'large') {
      return `calc(${labelWidth}px + 3.5rem + 2px)`;
    } else {
      `calc(${labelWidth}px + 1.875rem + 2px)`;
    }
  }, [size, labelWidth]);

  return (
    <label
      className={classNames(
        'relative inline-flex items-center cursor-pointer w-fit',
        {
          'opacity-0': variant === 'yesno-slider' && !labelWidth,
          'opacity-100': variant === 'yesno-slider' && !!labelWidth,
        },
        className,
      )}
      data-testid={dataTestId}
      id={id}
    >
      {label}
      {labelLeft !== '' && <span className="pr-2">{labelLeft}</span>}
      <input
        checked={isToggled}
        className="sr-only peer"
        onChange={() => {
          setIsToggled(!isToggled);
          onChange(!isToggled);
        }}
        type="checkbox"
        value=""
        tabIndex={-1}
        id={id}
      />
      {variant === 'default' || variant === 'yesno-slider' ? (
        <div
          style={{ width: variant === 'yesno-slider' ? width : undefined }}
          className={classNames(
            "bg-cool-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:border-white after:content-[''] after:absolute after:bg-white after:border-white after:border after:rounded-full after:transition-all relative",
            {
              'h-6 after:top-[3px] after:left-[2px] after:h-5 after:w-5':
                size === 'large',
              'h-6 after:top-[2px] after:left-[2px] after:h-5 after:w-5':
                size === 'base',
              'h-5 after:top-[2px] after:left-[2px] after:h-4 after:w-4':
                size === 'small',
              'w-10 peer-checked:after:translate-x-[calc(100%-4px)]':
                variant === 'default' && size === 'base',
              'w-8 peer-checked:after:translate-x-[calc(100%-4px)]':
                variant === 'default' && size === 'small',

              'w-11 peer-checked:after:translate-x-[calc(100%-4px)]':
                variant === 'default' && size === 'large',

              'peer-checked:bg-indigo-bright-600': color === 'indigo',
              'peer-checked:bg-blue-500': color === 'blue',

              'relative peer-checked:after:left-[calc(100%-1.35rem-2px)] peer-checked:[&_:first-child]:opacity-100 peer-checked:[&_:last-child]:opacity-0':
                variant === 'yesno-slider' && size === 'large',
              'relative peer-checked:after:left-[calc(100%-1.25rem-2px)] peer-checked:[&_:first-child]:opacity-100 peer-checked:[&_:last-child]:opacity-0':
                variant === 'yesno-slider' && size === 'base',
              'relative peer-checked:after:left-[calc(100%-1rem-2px)] peer-checked:[&_:first-child]:opacity-100 peer-checked:[&_:last-child]:opacity-0':
                variant === 'yesno-slider' && size === 'small',
            },
          )}
        >
          {variant === 'yesno-slider' ? (
            <>
              <div
                ref={yesLabelRef}
                className={classNames(
                  'text-nowrap absolute opacity-0 select-none text-white font-medium  transition-opacity',
                  {
                    'left-2 h-6 leading-6 text-xs': size === 'base',
                    'left-2 h-6 leading-6 text-base': size === 'large',
                    'left-2 h-5 leading-5 text-xxs': size === 'small',
                  },
                )}
              >
                {yesLabel}
              </div>
              <div
                ref={noLabelRef}
                className={classNames(
                  'text-nowrap absolute opacity-100 select-none text-cool-gray-500 font-medium text-xs transition-opacity',
                  {
                    'right-3 h-6 leading-6 text-xs': size === 'base',
                    'right-3 h-6 leading-6 text-base': size === 'large',
                    'right-3 h-5 leading-5 text-xxs': size === 'small',
                  },
                )}
              >
                {noLabel}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {variant === 'yesno' ? (
        <div className="border border-cool-gray-200 rounded-md flex flex-nowrap">
          <button
            type="button"
            className={classNames('rounded-l-md', {
              'leading-7 p-1 w-14 text-sm': size === 'large',
              'leading-6 p-1 w-10 text-sm': size === 'base',
              'leading-5 p-1 w-9 text-xs': size === 'small',
              'bg-indigo-bright-600 text-white':
                !isToggled && color === 'indigo',
              'bg-blue-500 text-white': !isToggled && color === 'blue',
              'bg-white text-cool-gray-600': isToggled,
            })}
            onClick={() => {
              setIsToggled(false);
              onChange(false);
            }}
          >
            {noLabel}
          </button>
          <button
            type="button"
            className={classNames('rounded-r-md', {
              'leading-7 p-1 w-14 text-sm': size === 'large',
              'leading-6 p-1 w-10 text-sm': size === 'base',
              'leading-5 p-1 w-9 text-xs': size === 'small',
              'bg-white text-cool-gray-600': !isToggled,
              'bg-indigo-bright-600 text-white':
                isToggled && color === 'indigo',
              'bg-blue-500 text-white': isToggled && color === 'blue',
            })}
            onClick={e => {
              setIsToggled(true);
              onChange(true);
            }}
          >
            {yesLabel}
          </button>
        </div>
      ) : null}
      {variant === 'checkbox-circle' ? (
        <span className="ml-4 w-[33px] h-[33px] bg-cool-gray-50 block rounded-full border-gray-100 border-[3px]">
          <IconFlatRenderer
            className={classNames('transition-opacity -ml-[3px] -mt-[3px]', {
              'opacity-0': !isToggled,
              'opacity-100': isToggled,
              ' [&_path]:fill-blue-500': color === 'blue',
            })}
            iconKey="ICON_FLAT_CHECK_CIRCLE"
          />
        </span>
      ) : null}
      {labelRight && <span className="pl-2">{labelRight}</span>}
    </label>
  );
};
