import { Route, RouteProps } from 'react-router-dom';
import { useAuth } from '~/src/hooks/useAuth';

import { AdjusterConfig } from '@assured/shared-types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import config from '../../../config';
import { Placeholder } from '../../shared/Placeholder';
import { AssuredUser } from '../AssuredAuthOProvider';

export const ProtectedRoute = ({
  component,
  condition,
  ...args
}: RouteProps & {
  condition?: (config: AdjusterConfig, user?: AssuredUser) => boolean;
}) => {
  const { user, logout } = useAuth();

  if (!component) {
    return null;
  }

  if (!config.authentication) {
    return <Route component={component} {...args} />;
  }

  // If user has been loaded, and the condition is not met, show error
  if (condition && user?.sub && !condition(config, user)) {
    return (
      <Route
        component={() => (
          <Placeholder
            message={
              <div>
                <div>You do not have permission to access this page.</div>
                <div className="mt-1">
                  <button
                    className="cursor-pointer text-blue-700 hover:text-blue-600 text-xs font-bold"
                    onClick={() => logout()}
                  >
                    Log out
                  </button>
                </div>
              </div>
            }
          />
        )}
        {...args}
      />
    );
  }

  return (
    <Route
      component={withAuthenticationRequired(
        component as any, // due to auth0 typings not being compatible with react-router-dom
        {
          onRedirecting: () => (
            <Placeholder
              message={`Authorizing access to Assured ${
                args.path?.indexOf('sidekick') !== -1 ? 'Sidekick' : 'ClaimView'
              }...`}
            />
          ),
        },
      )}
      {...args}
    />
  );
};
