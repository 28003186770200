import classNames from 'classnames';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export type TaskCardDetailsProps = ComponentProps<'div'> & {
  isFullWidth?: boolean;
};

/**
 * A React component to render details about the task
 */
export const TaskCardDetails = ({
  children,
  className,
  isFullWidth,
}: TaskCardDetailsProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-col gap-2 font-normal text-sm leading-5',
        className,
      )}
    >
      <strong className="font-medium text-cool-gray-700">Details</strong>
      <div
        className={classNames('grid gap-4', {
          'grid-cols-1': isFullWidth,
          'grid-cols-2': !isFullWidth,
        })}
      >
        {children}
      </div>
    </div>
  );
};
