import classNames from 'classnames';
import { createContext, HTMLAttributes, useContext, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export default function getInitials(name: string, fallback = '?') {
  if (!name || typeof name !== 'string') return fallback;
  return name
    .trim()
    .replace(/\s+/, ' ')
    .split(' ')
    .filter((_, index, array) => index === 0 || index === array.length - 1)
    .map(v => v && v[0].toUpperCase())
    .join('');
}

export type AvatarSize = 'sm' | 'md' | 'lg' | 'xl';

const AvatarContext = createContext<{
  backgroundColor?: string;
  size: AvatarSize;
}>({
  backgroundColor: undefined,
  size: 'md',
});

export const useAvatarContext = () => {
  const context = useContext(AvatarContext);

  if (!context) {
    throw new Error(
      'Avatar compound components cannot be rendered outside the Avatar component',
    );
  }

  return context;
};

const AvatarSizeClassesMap: Record<AvatarSize, string> = {
  sm: 'h-[24px] w-[24px]',
  md: 'h-[32px] w-[32px]',
  lg: 'h-[48px] w-[48px]',
  xl: 'h-[64px] w-[64px]',
};

const AvatarInitialsClassesMap: Record<AvatarSize, string> = {
  sm: 'text-xs',
  md: 'text-xs',
  lg: 'text-md',
  xl: 'text-xl',
};

export const AvatarInitials = ({
  className,
  name,
  ...rest
}: {
  name: string;
} & Omit<HTMLAttributes<HTMLSpanElement>, 'children'>) => {
  const { size } = useAvatarContext();

  // support overriding color or font with `twMerge` and `className`
  const classes = twMerge(
    classNames('text-gray-700 font-medium', AvatarInitialsClassesMap[size]),
    className,
  );

  return (
    <span className={classes} {...rest}>
      {getInitials(name)}
    </span>
  );
};

export type AvatarProps = {
  backgroundColor?: string;
  size?: AvatarSize;
} & HTMLAttributes<HTMLSpanElement>;

export const Avatar = ({
  backgroundColor,
  className,
  children,
  size = 'md',
  style = {},
  ...rest
}: AvatarProps) => {
  const sizeClasses = AvatarSizeClassesMap[size];
  const classes = classNames(
    'rounded-full flex flex-shrink-0 items-center justify-center border border-gray-200',
    sizeClasses,
    className,
  );

  const contextValue = useMemo(
    () => ({ backgroundColor, size }),
    [backgroundColor, size],
  );

  return (
    <AvatarContext.Provider value={contextValue}>
      <span
        className={classes}
        style={{
          ...style,
          ...(backgroundColor && {
            backgroundColor,
          }),
        }}
        {...rest}
      >
        {children}
      </span>
    </AvatarContext.Provider>
  );
};
