import classNames from 'classnames';
import { ReactNode, RefObject, useEffect, useRef } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import { twMerge } from 'tailwind-merge';

import {
  FloatingFocusManager,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';
import { useModal } from './useModal';

import type { IconKey } from '../Icon/IconFlatRenderer';

export interface ModalProps {
  /** The `aria-label` of the modal, giving it a name/title for screen readers (required) */
  ariaLabel: string;

  /** The `role` of the modal, giving it a level of urgency (alertdialog = more urgent) for screen readers */
  ariaRole?: 'dialog' | 'alertdialog';

  /** The ID of an element that describes the modal */
  ariaDescribedBy?: string;

  /** The content of the modal */
  children: ReactNode | ReactNode[];

  /** Custom className */
  className?: string;

  /** Custom className for close icon */
  classNameIconClose?: string;

  /**
   * A list of control elements that correspond to the modal. These are
   * only needed if you want to ensure they don't trigger a modal close.
   */
  controlRefs?: RefObject<HTMLElement>[];

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** If `true` modal will have a close icon */
  hasCloseIcon?: boolean;

  /** The `IconFlatRenderer` `iconKey` for the close icon */
  iconKeyClose?: IconKey;

  /** If `true` modal will be open */
  isOpen?: boolean;

  /** A handler for when the modal close icon is clicked or escape key */
  onClose?: (event?: MouseEvent | React.MouseEvent | null) => void;

  /** Variant display mode for the modal */
  variant?: 'default' | 'wide' | 'extra-wide';
}

// make sure these have the same value
const ANIMATION_DURATION_MS = 150;
const ANIMATION_DURATION_CLASS_NAME = 'duration-150';

export const Modal = ({
  ariaLabel,
  ariaDescribedBy,
  ariaRole,
  children,
  className,
  classNameIconClose,
  controlRefs,
  hasCloseIcon,
  iconKeyClose = 'ICON_FLAT_CLOSE',
  isOpen: shouldOpen,
  dataTestId = 'modal',
  variant = 'default',
  onClose,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { context } = useFloating(
    modalRef.current
      ? {
          elements: {
            floating: modalRef.current,
            reference: modalRef.current,
          },
        }
      : undefined,
  );
  const dismiss = useDismiss(context);
  const { getFloatingProps } = useInteractions([dismiss]);
  const { isDisplayNone, isOpen, isTransformEnabled } = useModal({
    animationDurationMs: ANIMATION_DURATION_MS,
    isOpen: shouldOpen,
    modalControlRefs: controlRefs,
    modalRef,
    onClose,
  });

  // establish if the modal overflows the window vertically
  const { height: windowHeight } = useWindowSize();
  const isLongModalMode = !modalRef.current
    ? false
    : modalRef.current.offsetHeight > windowHeight;

  useEffect(() => {
    if (modalRef.current && isOpen) {
      const firstFocusableElement = modalRef.current.querySelector(
        'select:not([disabled]), input:not([disabled]), textarea:not([disabled])',
      ) as HTMLElement | null;
      const elementToFocus = firstFocusableElement ?? modalRef.current;
      elementToFocus.focus();
    }
  }, [isOpen]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      id="modal-overlay"
      onKeyDown={e => {
        if (e.key === 'Escape' && isOpen) {
          onClose?.();
        }
      }}
      className={classNames(
        `fixed overflow-y-scroll z-[2000] flex w-full h-full top-0 left-0 p-3 justify-center bg-cool-gray-900/50 transition-opacity ease-in-out ${ANIMATION_DURATION_CLASS_NAME}`,
        {
          'opacity-0': !isOpen,
          'opacity-100': isOpen,
          hidden: isDisplayNone,
          'items-center': !isLongModalMode,
          'items-start': isLongModalMode,
        },
      )}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      {isOpen ? (
        <FloatingFocusManager
          context={context}
          initialFocus={modalRef}
          order={['floating', 'content']}
          modal
        >
          <div
            className={twMerge(
              classNames(
                `bg-white shadow-lightgray rounded-[20px] border border-cool-gray-200 px-10 py-12`,
                `relative mt-auto mb-auto`,
                {
                  'w-full max-w-[560px]': variant === 'default',
                  'w-[640px] max-w-[90vw]': variant === 'wide',
                  'w-[820px] max-w-[90vw]': variant === 'extra-wide',
                  [`transition-transform transform ease-in-out ${ANIMATION_DURATION_CLASS_NAME}`]:
                    isTransformEnabled,
                  'scale-90': isTransformEnabled && !isOpen,
                  'scale-100': isTransformEnabled && isOpen,
                },
              ),
              className,
            )}
            data-testid={dataTestId}
            ref={modalRef}
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedBy}
            role={ariaRole || 'dialog'}
            aria-modal="true"
            {...getFloatingProps()}
          >
            {children}
            {hasCloseIcon && (
              <button
                className={twMerge(
                  'absolute top-6 right-6 z-10 modal-close-button',
                  classNameIconClose,
                )}
                onClick={e => {
                  if (onClose) {
                    onClose(e);
                  }
                }}
                type="button"
                tabIndex={0}
                aria-label="Close"
              >
                <IconFlatRenderer iconKey={iconKeyClose} />
              </button>
            )}
          </div>
        </FloatingFocusManager>
      ) : null}
    </div>
  );
};
