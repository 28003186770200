import classNames from 'classnames';
import { DetailedHTMLProps, ReactNode } from 'react';

interface ChipPropsBase {
  /** Child node */
  children: ReactNode;

  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** If `true` will display in a faded / opaque-like style */
  isFaded?: boolean;
}

type HtmlSpanProps = DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

export type ChipProps = HtmlSpanProps & ChipPropsBase;

export const Chip = ({
  children,
  className,
  dataTestId = 'chip',
  isFaded,
  ...rest
}: ChipProps) => {
  return (
    <span
      className={classNames(
        'bg-cool-gray-200 font-medium text-sm px-2 py-1 rounded-md whitespace-nowrap transition-colors',
        {
          'text-cool-gray-800': !isFaded,
          'text-cool-gray-400': isFaded,
        },
        className,
      )}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </span>
  );
};
