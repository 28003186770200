import { twMerge } from 'tailwind-merge';

interface InboxItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}

/**
 * A React component to render an inbox item button
 */
export const InboxItemButton = ({
  children,
  className,
  ...rest
}: InboxItemProps) => {
  return (
    <button
      className={twMerge(
        'w-full text-left rounded-lg hover:bg-cool-gray-50 active:bg-indigo-bright-50 focus:outline-0 focus:shadow-outline-indigo-bright-inset disabled:bg-transparent',
        className,
      )}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
};
