export type units = 'feet' | 'yards';

export function booleanToFeetOrYards(v: boolean): units {
  // feet = false, yards = true
  return v ? 'yards' : 'feet';
}

export function extractFeetYardsValue(str: string) {
  const regex = /^(\d+(\.\d+)?)\s*(yard(s)?|feet)$/i;
  const match = str.match(regex);
  if (match) {
    return parseFloat(match[1]);
  } else {
    return NaN; // or any other indication of an invalid input
  }
}

export function extractUnit(str: string): units | null {
  const match = str.match(/\b(feet|yards)\b/i);
  if (match) {
    return match[0] as units; // matched unit
  } else {
    return null;
  }
}

export function convertFeetYards(
  previousValue: string,
  previousUnits: units,
  newUnits: units,
) {
  const previousValueAsNumber = extractFeetYardsValue(previousValue);
  if (previousUnits === newUnits) {
    return previousValueAsNumber;
  }

  let valueInNewUnits =
    newUnits === 'feet' ? previousValueAsNumber * 3 : previousValueAsNumber / 3;

  return Math?.trunc
    ? Math?.trunc(valueInNewUnits)
    : parseInt(valueInNewUnits.toString().split('.')[0], 10);
}
