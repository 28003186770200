import classNames from 'classnames';

import { HighlightedText } from '../HighlightedText';

export interface InfoListItemSectionProps {
  /** General color style of the chip element */
  chipColorStyle?: 'fill' | 'stroke';

  /** The conent within the chip element */
  chipContent: string;

  /** The placement of the chip element */
  chipPosition?: 'bottom' | 'top';

  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** Text that should be highlighted within the subtitle */
  highlightedSubtitle?: string;

  /** Text that should be highlighted within the title */
  highlightedTitle?: string;

  /** If `true` will have a fixed width */
  isFixedWidth?: boolean;

  /* A subtitle */
  subtitle: string | null;

  /* A  title */
  title: string | null;
}

export const InfoListItemSection = ({
  chipColorStyle = 'stroke',
  chipContent,
  chipPosition = 'bottom',
  className,
  dataTestId = 'info-list-item-section',
  highlightedSubtitle,
  highlightedTitle,
  subtitle,
  title,
}: InfoListItemSectionProps) => {
  const chipElement = (
    <span
      className={classNames(
        'text-cool-gray-700 text-[10px] font-medium leading-5 px-2 rounded-full',
        {
          'bg-cool-gray-100': chipColorStyle === 'fill',
          'bg-white border border-cool-gray-300': chipColorStyle === 'stroke',
        },
      )}
    >
      {chipContent}
    </span>
  );
  return (
    <div
      className={classNames(className, 'flex flex-col gap-1 truncate')}
      data-testid={dataTestId}
    >
      <div className="flex flex-start gap-1 truncate">
        {chipPosition === 'top' && chipElement}
        <span className="text-cool-gray-800 text-sm font-medium truncate">
          {!title ? (
            <div className="animate-pulse w-[250px] h-4 bg-cool-gray-400" />
          ) : !highlightedTitle ? (
            title
          ) : (
            <HighlightedText
              classNameHighlighted="font-bold text-indigo-bright-600"
              input={title}
              term={highlightedTitle}
            />
          )}
        </span>
      </div>
      <div className="flex flex-start gap-1 items-center truncate">
        {chipPosition === 'bottom' && chipElement}
        <span className="text-cool-gray-500 text-xs font-normal truncate">
          {!subtitle ? (
            <div className="animate-pulse w-[250px] h-4 bg-cool-gray-300" />
          ) : !highlightedSubtitle ? (
            subtitle
          ) : (
            <HighlightedText
              classNameHighlighted="font-bold text-indigo-bright-600"
              input={subtitle}
              term={highlightedSubtitle}
            />
          )}
        </span>
      </div>
    </div>
  );
};
