import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export type TaskCardHeaderContainerProps = ComponentProps<'div'>;

/**
 * A React component to render a task header container element,
 * styled accordingly
 */
export const TaskCardHeaderContainer = ({
  children,
  className,
}: TaskCardHeaderContainerProps) => {
  return (
    <div className={twMerge('flex flex-col gap-3', className)}>{children}</div>
  );
};
