import {
  createContext,
  forwardRef,
  HTMLProps,
  Ref,
  useContext,
  useMemo,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';

interface TooltipOptions {
  disabled?: boolean;
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  hoverDelay?: number;
}

export const useTooltip = ({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  disabled = false,
  hoverDelay = 0,
}: TooltipOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const { context } = data;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
    handleClose: safePolygon(),
    delay: { open: hoverDelay, close: 0 },
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(() => {
    return {
      disabled,
      open,
      setOpen,
      ...interactions,
      ...data,
    };
  }, [open, disabled, setOpen, interactions, data]);
};

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export const Tooltip = ({
  children,
  ...options
}: { children: React.ReactNode } & TooltipOptions) => {
  const tooltip = useTooltip(options);

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(
  ({ children, ...otherProps }, propRef) => {
    const context = useTooltipContext();
    const childrenRef = children
      ? (children as { ref: Ref<HTMLElement> }).ref
      : undefined;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    return (
      <span {...context.getReferenceProps({ ref, ...otherProps })}>
        {children}
      </span>
    );
  },
);

export const TooltipContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(({ style, className, ...props }, propRef) => {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  const classes = twMerge(
    'flex items-center justify-center gap-2 px-[10px] py-2 rounded-lg bg-black text-white text-xs font-normal leading-[16px]',
    className,
  );

  if (!context.open || context.disabled) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        className={classes}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      />
    </FloatingPortal>
  );
});
