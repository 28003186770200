import moment from 'moment';

/**
 * A function to return a display date using logic that has become
 * conventional across our products. If the date is less than or
 * equal to 48 hours ago, then we display the `moment.fromNow()`
 * date, otherwise `MM/DD/YYYY [at] LT z`.
 */
export const getDisplayDate = (date: moment.MomentInput) => {
  if (!date) {
    return;
  }
  const momentDate = moment(date);
  const shouldUseFromNowDateFormat = moment()
    .subtract(48, 'hours')
    .isSameOrBefore(momentDate);
  return !shouldUseFromNowDateFormat
    ? momentDate.format('MM/DD/YYYY [at] LT z')
    : momentDate.fromNow();
};
