import { useState } from 'react';

import { DonutChart as TremorDonutChart, Legend } from '@tremor/react';

import { ChartContainer } from './ChartContainer';

import type { ChartContainerProps } from './ChartContainer';

import type { DonutChartProps } from '@tremor/react';
/**
 * A component to render a donut chart and its container layout.
 */
export const DonutChart = ({
  categories,
  chartHeight = 224,
  chartLabelTheme,
  chartTitle,
  isLoading,
  labels,
  ...props
}: DonutChartProps &
  ChartContainerProps & {
    categories: string[];
    index: string;
  }) => {
  const [activeLegend, setActiveLegend] = useState<string>();
  return (
    <ChartContainer
      chartHeight={chartHeight}
      chartLabelTheme={chartLabelTheme}
      chartTitle={chartTitle}
      isCentered
      isEmpty={!isLoading && (!props?.data || props?.data.length <= 0)}
      isLoading={isLoading}
      labels={labels}
    >
      <div className="flex flex-col gap-3 justify-center items-center w-full">
        <TremorDonutChart
          {...props}
          onValueChange={(payload?: Record<string, string>) => {
            if (!payload) {
              setActiveLegend(undefined);
            } else if (typeof payload[props.index] === 'string') {
              setActiveLegend(payload[props.index]);
            }
            if (props.onValueChange) {
              props.onValueChange(payload);
            }
          }}
        />
        <Legend
          categories={categories}
          colors={props.colors}
          activeLegend={activeLegend}
        />
      </div>
    </ChartContainer>
  );
};
