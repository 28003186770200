import {
  Context,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export type KeyboardShortcutCtx = {
  /**
   * Request a keyboard shortcut using the first of the key combinations in
   * `acceptableKeys` that is available. If a shortcut is available, it will be
   * reserved and returned. If none of the requested shortcuts are available,
   * this function returns null.
   */
  requestShortcut: (
    acceptableKeys: string[],
    callback: () => void,
  ) => string | null;

  /**
   * Release a keyboard shortcut. This will make the shortcut available again.
   */
  releaseShortcut: (shortcut: string) => void;

  /**
   * The current shortcut being pressed, if any.
   */
  currentShortcut: string | null;

  /**
   * A map of all currently reserved shortcuts.
   */
  shortcuts: ShortcutMap;

  /**
   * Whether or not keyboard shortcuts are disabled.
   */
  disabled: boolean;

  /**
   * A list of shortcuts that are disabled because the user is focused on a text
   * entry element whose `data-enable-shortcuts` attribute doesn't include them,
   * or which are captured by a different KeyboardShortcutProvider before / instead
   * of this one.
   */
  disabledShortcuts: string[];
};

export const KeyboardShortcutContext: Context<KeyboardShortcutCtx> =
  createContext<KeyboardShortcutCtx>({
    requestShortcut: () => null,
    releaseShortcut: () => {},
    currentShortcut: '',
    shortcuts: new Map(),
    disabled: false,
    disabledShortcuts: [],
  });

type ShortcutHandler = () => void;
type ShortcutMap = Map<string, ShortcutHandler>;

export const KeySets: { [name: string]: string[] } = {
  numeric: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
};

const eventMatchesShortcut = (e: KeyboardEvent, shortcut: string) => {
  const parts = shortcut.split('+');
  const modifiers = parts.slice(0, parts.length - 1);
  const keyName = parts[parts.length - 1];

  const givenModifiers = {
    shift: e.shiftKey,
    alt: e.altKey,
    ctrl: e.ctrlKey,
    meta: e.metaKey,
  };

  const hasAllAndOnlyRequestedModifiers = Object.entries(givenModifiers).every(
    ([name, applied]) => {
      const requested = modifiers.includes(name);
      return requested === applied;
    },
  );

  const keyMatch = keyName?.toLowerCase() === e.key?.toLowerCase();

  return hasAllAndOnlyRequestedModifiers && keyMatch;
};

/**
 * Parse a comma-separated list of shortcuts into an array, accounting for
 * platform differences.
 */
const parseDataShortcuts = (list?: string | null) => {
  return (
    (list || '')
      .replace(/meta/g, navigator.userAgent.includes('Mac') ? 'meta' : 'ctrl')
      .split(',')
      .map((s: string) => s.trim().toLowerCase()) || []
  );
};

/**
 * Walk up the DOM from `element` to `rootElement` (not including `rootElement` itself)
 * and collect all of the shortcuts specified in `data-shortcuts-provided` attributes
 * along the way.
 */
const parentShortcutsProvided = ({
  element,
  rootElement,
}: {
  element: HTMLElement | null;
  rootElement: HTMLElement;
}) => {
  const shortcuts = [];
  let parent = element?.parentElement;
  while (parent && parent !== rootElement && parent !== document.body) {
    if (parent.getAttribute('data-shortcuts-provided')) {
      shortcuts.push(
        ...parseDataShortcuts(parent.getAttribute('data-shortcuts-provided')),
      );
    }
    parent = parent.parentElement;
  }

  return shortcuts;
};

/**
 * Convert a ShortcutMap to a JSON string for equality comparison
 */
const serializeShortcuts = (map: ShortcutMap) => {
  const newMap = new Map();
  for (const [key, value] of map.entries()) {
    newMap.set(key, (value as any).id);
  }
  return JSON.stringify(newMap);
};

/**
 * If the user is focused on one of these element types,
 * we don't want to trigger most shortcuts.
 *
 * Array in the format [HTMLElement, optional: string[]] where the optional string
 * array is an array of possible `type` attributes of the element.
 */
const shortcutsInactiveElementTypes = [
  [HTMLInputElement, ['text', 'number', 'email', 'password']],
  [HTMLTextAreaElement],
  [HTMLSelectElement],
] as const;

function elementIsShortcutsInactiveElement(
  element: EventTarget | HTMLElement | null,
) {
  if (!element) {
    return false;
  }

  return shortcutsInactiveElementTypes.some(
    t =>
      element instanceof t[0] &&
      (!t[1] || (t[1] as readonly string[]).includes(element.type)),
  );
}

/**
 * Provider that manages keyboard shortcut registration and execution. Can be nested
 * to allow for different sets of shortcuts in different contexts.
 */
export const KeyboardShortcutProvider = ({
  children,
  rootElement = document.body,
  disabled = false,
}: {
  children: ReactNode;
  rootElement?: HTMLElement | null;
  disabled?: boolean;
}) => {
  const shortcuts = useRef<ShortcutMap>(new Map());
  const [currentShortcut, setCurrentShortcut] = useState<string | null>(null);
  const [disabledShortcuts, setDisabledShortcuts] = useState<string[]>([]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (disabled) {
        return;
      }

      for (const [shortcut, handler] of shortcuts.current) {
        if (eventMatchesShortcut(e, shortcut)) {
          const fromInactiveElement = elementIsShortcutsInactiveElement(
            e.target,
          );
          const elementAllowsShortcut = parseDataShortcuts(
            (e.target as HTMLElement).getAttribute('data-enable-shortcuts'),
          ).includes(shortcut.toLowerCase());

          if (fromInactiveElement && !elementAllowsShortcut) {
            continue;
          }

          e.preventDefault();
          e.stopPropagation();
          handler();
          setCurrentShortcut(shortcut);
          break;
        }
      }
    },
    [rootElement, disabled],
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (currentShortcut) {
        setCurrentShortcut(null);
      }
    },
    [currentShortcut],
  );

  const handleFocus = useCallback(
    (e: FocusEvent) => {
      const inactiveElementFocused = elementIsShortcutsInactiveElement(
        e.target,
      );

      const enabledShortcuts = parseDataShortcuts(
        (e.target as HTMLElement).getAttribute('data-enable-shortcuts'),
      );

      // Besides those not explicitly enabled by the `data-enable-shortcuts`
      // attribute, we want to mark-as-disabled any shortcuts that will be
      // captured by a parent of the focused element before (or instead of)
      // this provider's rootElement.
      const targetParentShortcuts = parentShortcutsProvided({
        element: e.target as HTMLElement,
        rootElement: rootElement as HTMLElement,
      });

      const newDisabledShortcuts = Array.from(shortcuts.current.keys()).filter(
        k =>
          (inactiveElementFocused &&
            !enabledShortcuts.includes(k.toLowerCase())) ||
          targetParentShortcuts.includes(k.toLowerCase()),
      );

      setDisabledShortcuts(newDisabledShortcuts);
    },
    [rootElement],
  );

  const handleBlur = useCallback(() => {
    setDisabledShortcuts([]);
  }, []);

  // Continuously update the `data-shortcuts-provided` attribute on the root element
  // to reflect the current set of shortcuts. This lets other providers selectively
  // disable shortcuts that are already in use.
  useEffect(() => {
    if (rootElement === null) {
      return () => {};
    }

    rootElement.setAttribute(
      'data-shortcuts-provided',
      disabled
        ? ''
        : Array.from(shortcuts.current.keys())
            .filter(k => !disabledShortcuts.includes(k.toLowerCase()))
            .join(','),
    );

    return () => {
      rootElement?.setAttribute('data-shortcuts-provided', '');
    };
  }, [
    rootElement,
    serializeShortcuts(shortcuts.current),
    disabledShortcuts,
    disabled,
  ]);

  useEffect(() => {
    if (rootElement === null) {
      return () => {};
    }

    rootElement.addEventListener('keydown', handleKeyDown);
    rootElement.addEventListener('keyup', handleKeyUp);

    rootElement.addEventListener('focus', handleFocus, true);
    rootElement.addEventListener('blur', handleBlur, true);

    return () => {
      rootElement.removeEventListener('keydown', handleKeyDown);
      rootElement.removeEventListener('keyup', handleKeyUp);

      rootElement.removeEventListener('focus', handleFocus, true);
      rootElement.removeEventListener('blur', handleBlur, true);
    };
  }, [handleKeyDown, handleKeyUp, handleFocus, handleBlur, rootElement]);

  const requestShortcut = (
    acceptableKeys: string[],
    callback: ShortcutHandler,
  ) => {
    for (const key of acceptableKeys) {
      if (!shortcuts.current.has(key)) {
        shortcuts.current.set(key, callback);
        return key;
      }
    }
    return null;
  };

  const releaseShortcut = (shortcut: string) => {
    shortcuts.current.delete(shortcut);
    return shortcuts;
  };

  return (
    <KeyboardShortcutContext.Provider
      value={{
        requestShortcut,
        releaseShortcut,
        currentShortcut,
        shortcuts: shortcuts.current,
        disabled,
        disabledShortcuts,
      }}
    >
      {children}
    </KeyboardShortcutContext.Provider>
  );
};

/**
 * Hook to register the first keyboard shortcut available from a list of
 * acceptable keys.
 */
export const useKeyboardShortcut = (
  /**
   * An array of keys that are acceptable for the shortcut.
   */
  givenAcceptableKeys: string[],

  /**
   * The callback to run when the shortcut is triggered.
   */
  callback: () => void,
) => {
  const id = useRef(Math.random().toString(36).slice(2));
  const acceptableKeys = givenAcceptableKeys.map(k =>
    navigator.userAgent.includes('Mac') ? k : k.replace('meta', 'ctrl'),
  );
  const {
    requestShortcut,
    releaseShortcut,
    currentShortcut,
    shortcuts,
    disabled,
    disabledShortcuts,
  } = useContext(KeyboardShortcutContext);

  const shortcut = useRef<string | null>(null);
  (callback as any).id = id.current;
  const [reportedShortcut, setReportedShortcut] = useState<string | null>(null);

  useEffect(() => {
    if (shortcut.current && shortcuts.has(shortcut.current)) {
      if ((shortcuts.get(shortcut.current) as any)?.id !== id.current) {
        shortcut.current = null;
        return () => {};
      }

      return () => {
        if (shortcut.current) {
          releaseShortcut(shortcut.current);
          shortcut.current = null;
        }
      };
    }

    shortcut.current = requestShortcut(acceptableKeys, callback);
    setReportedShortcut(shortcut.current);
    let disposed = false;

    if (!shortcut.current && acceptableKeys.length) {
      // try again after a tick in case we were waiting on another
      // component to release the desired shortcut
      setTimeout(() => {
        if (!disposed) {
          shortcut.current = requestShortcut(acceptableKeys, callback);
          setReportedShortcut(shortcut.current);
        }
      }, 0);
    }

    return () => {
      disposed = true;
      if (shortcut.current) {
        releaseShortcut(shortcut.current);
        shortcut.current = null;
      }
    };
  }, [acceptableKeys.join(','), callback, serializeShortcuts(shortcuts)]);

  return {
    shortcut: reportedShortcut,
    currentShortcut,
    disabled:
      disabled ||
      (!!reportedShortcut && disabledShortcuts.includes(reportedShortcut)),
  };
};
