import { DateTime } from 'luxon';
import React, { createContext, ReactNode, useContext } from 'react';

import { Phase } from './constants';
import { PhasedEvents } from './types';
import { usePhaseManager } from './usePhaseManager';
import { usePlaybackSpeed } from './usePlaybackSpeed';
import { usePlayhead } from './usePlayhead';

export interface PlayerContextProps {
  currentTime: number;
  isPlaying: boolean;
  selectedPhases: Phase[];
  play: () => void;
  pause: () => void;
  setCurrentTime: (time: number) => void;
  nextPhase: () => void;
  previousPhase: () => void;
  onHandlePhaseChange: (phase: Phase) => void;
  earliestStartTime: DateTime<true> | DateTime<false> | undefined;
  phasedEvents: PhasedEvents;
  playbackSpeed: number;
  setPlaybackSpeed: (playbackSpeed: number) => void;
  moveForward: () => void;
  moveBackward: () => void;
  moveToStart: () => void;
  moveToEnd: () => void;
  duration?: number;
}

const PlayerContext = createContext<PlayerContextProps | undefined>(undefined);

export const usePlayerContext = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error('usePlayerContext must be used within a CATPlayerProvider');
  }
  return context;
};

export const CATPlayerProvider: React.FC<{
  children: ReactNode;
  phasedEvents: PhasedEvents;
  earliestStartTime?: DateTime<true> | DateTime<false> | undefined;
  duration?: number;
}> = ({ children, phasedEvents, earliestStartTime, duration }) => {
  const [playbackSpeed, setPlaybackSpeed] = usePlaybackSpeed();

  const {
    currentTime,
    isPlaying,
    play,
    pause,
    setCurrentTime,
    moveForward,
    moveBackward,
    moveToStart,
    moveToEnd,
  } = usePlayhead({
    duration,
    playbackSpeed,
  });

  const { selectedPhases, nextPhase, previousPhase, onHandlePhaseChange } =
    usePhaseManager({
      initialPhase: ['BEFORE', 'DURING', 'AFTER'],
    });

  const value = React.useMemo(
    () => ({
      currentTime,
      isPlaying,
      selectedPhases,
      play,
      pause,
      setCurrentTime,
      nextPhase,
      previousPhase,
      onHandlePhaseChange,
      earliestStartTime,
      phasedEvents,
      playbackSpeed,
      setPlaybackSpeed,
      moveForward,
      moveBackward,
      duration,
      moveToStart,
      moveToEnd,
    }),
    [
      currentTime,
      isPlaying,
      selectedPhases,
      play,
      pause,
      setCurrentTime,
      nextPhase,
      previousPhase,
      onHandlePhaseChange,
      earliestStartTime,
      phasedEvents,
      playbackSpeed,
      setPlaybackSpeed,
      moveForward,
      moveBackward,
      duration,
      moveToStart,
      moveToEnd,
    ],
  );

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
};
