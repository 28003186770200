import classNames from 'classnames';
import { DetailedHTMLProps } from 'react';

import { InfoListItemSection } from './InfoListItemSection';

export interface InfoListItemPropsBase {
  /** The conent within the primary chip */
  chipContentPrimary: string;

  /** The conent within the secondary chip */
  chipContentSecondary: string;

  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** If `true` chip elements will have an alternate position */
  hasAlternateChipPosition?: boolean;

  /** Text that should be highlighted */
  highlightedText?: string;

  /** If highlighting text this prop dictates which field */
  highlightedTextMode?:
    | 'SUBTITLE_PRIMARY'
    | 'SUBTITLE_SECONDARY'
    | 'TITLE_PRIMARY'
    | 'TITLE_SECONDARY';

  /** If `true` will display a selected state. Useful when a list is toggled via keyboard */
  isSelected?: boolean;

  /* A primary subtitle */
  subtitlePrimary: string;

  /* A secondary subtitle */
  subtitleSecondary: string | null;

  /* A primary title */
  titlePrimary: string;

  /* A secondary title */
  titleSecondary: string | null;
}

type HtmlButtonProps = DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type InfoListItemProps = HtmlButtonProps & InfoListItemPropsBase;

export const InfoListItem = ({
  chipContentPrimary,
  chipContentSecondary,
  className,
  dataTestId = 'info-list-item',
  hasAlternateChipPosition,
  highlightedText,
  highlightedTextMode,
  isSelected,
  subtitlePrimary,
  subtitleSecondary,
  titlePrimary,
  titleSecondary,
  ...rest
}: InfoListItemProps) => {
  return (
    <button
      className={classNames(
        'p-4 flex justify-start gap-6 cursor-pointer focus:outline-none focus-visible:outline-none',
        {
          'bg-white hover:bg-cool-gray-50': !isSelected,
          'bg-indigo-100': isSelected,
        },
        className,
      )}
      data-testid={dataTestId}
      type="button"
      {...rest}
    >
      <InfoListItemSection
        chipColorStyle="fill"
        chipContent={chipContentPrimary}
        className="basis-[166px]"
        highlightedSubtitle={
          highlightedTextMode !== 'SUBTITLE_PRIMARY'
            ? undefined
            : highlightedText
        }
        highlightedTitle={
          highlightedTextMode !== 'TITLE_PRIMARY' ? undefined : highlightedText
        }
        subtitle={subtitlePrimary}
        title={titlePrimary}
      />
      <InfoListItemSection
        chipContent={chipContentSecondary}
        chipPosition={!hasAlternateChipPosition ? 'bottom' : 'top'}
        className="basis-[calc(100%-190px)]"
        highlightedSubtitle={
          highlightedTextMode !== 'SUBTITLE_SECONDARY'
            ? undefined
            : highlightedText
        }
        highlightedTitle={
          highlightedTextMode !== 'TITLE_SECONDARY'
            ? undefined
            : highlightedText
        }
        subtitle={subtitleSecondary}
        title={titleSecondary}
      />
    </button>
  );
};
