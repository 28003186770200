import { ReactNode, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '~/src/hooks/useAuth';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

import { useOutOfOfficeSettingsModalQuery } from '../../../../generatedX/graphql';
import useTimelinePrefetch from '../useTimelinePrefetch';
import { OOO_SETTINGS_MODAL_QUERY_PARAM_KEY } from './constants';
import { outOfOfficeConsideredActive } from './OutOfOfficeConsideredActive';
import { OutOfOfficeSettingsModalContext } from './OutOfOfficeSettingsContext';
import OutOfOfficeSettingsModalInner from './OutOfOfficeSettingsModalInner';

const OutOfOfficeSettingsModal = ({ children }: { children: ReactNode }) => {
  useTimelinePrefetch();
  const location = useLocation();
  const history = useHistory();
  const user = useAuth();
  const { data } = useOutOfOfficeSettingsModalQuery({
    skip: !user.isAuthenticated,
  });

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const isOpen = query.get(OOO_SETTINGS_MODAL_QUERY_PARAM_KEY) !== null;

  const setIsOpen = useCallback(
    (shouldOpen: boolean) => {
      const newParams = new URLSearchParams(location.search);
      if (shouldOpen) {
        newParams.set(OOO_SETTINGS_MODAL_QUERY_PARAM_KEY, 'open');
      } else {
        newParams.delete(OOO_SETTINGS_MODAL_QUERY_PARAM_KEY);
      }

      history.push({ search: newParams.toString() });
    },
    [history, location.search],
  );

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);

  const contextValue = useMemo(() => {
    const outOfOfficeActive = outOfOfficeConsideredActive(
      data?.adjusterOutOfOfficeSettings?.startAt,
    );

    return {
      getReferenceProps,
      getFloatingProps,
      floatingStyles: context.floatingStyles,
      refs: context.refs,
      isOpen,
      setIsOpen,
      adjusterOutOfOfficeSettings: data?.adjusterOutOfOfficeSettings,
      outOfOfficeActive,
    };
  }, [
    getReferenceProps,
    getFloatingProps,
    context.floatingStyles,
    context.refs,
    isOpen,
    setIsOpen,
    data?.adjusterOutOfOfficeSettings,
  ]);

  return (
    <OutOfOfficeSettingsModalContext.Provider value={contextValue}>
      {children}
      <FloatingPortal>
        {isOpen && (
          <FloatingOverlay
            className="bg-[rgba(30,41,59,0.7)] flex flex-col h-dvh w-full"
            style={{
              zIndex: 1000,
            }}
            lockScroll
          >
            <FloatingFocusManager context={context}>
              <OutOfOfficeSettingsModalInner
                ref={refs.setFloating}
                {...getFloatingProps()}
              />
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </OutOfOfficeSettingsModalContext.Provider>
  );
};

export default OutOfOfficeSettingsModal;
