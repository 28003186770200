import { Dispatch, SetStateAction, useState } from 'react';

export const usePlaybackSpeed = (): [
  number,
  Dispatch<SetStateAction<number>>,
] => {
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);

  return [playbackSpeed, setPlaybackSpeed];
};
