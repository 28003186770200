import { twMerge } from 'tailwind-merge';

import { IconFlatArchiveLarge } from '../Icon/IconFlatArchiveLarge';
import { IconFlatEnvelopeOpen } from '../Icon/IconFlatEnvelopeOpen';
import { IconFlatEnvelopeWithDot } from '../Icon/IconFlatEnvelopeWithDot';
import { InboxIconButton } from './InboxIconButton';

const buttonClassName =
  'border border-cool-gray-200 bg-white focus:border-white';

/**
 * A React component to render buttons for various actions to be taken
 * within an inbox item (ie: "archive", "mark as read", etc)
 */
export const InboxItemActions = ({
  className,
  isArchived,
  isRead,
  onArchived,
  onMarkedAsRead,
  onMarkedAsUnread,
  setShouldHideActions,
  setShouldShowActions,
}: {
  className?: string;
  isArchived?: boolean;
  isRead?: boolean;
  onArchived?: () => void;
  onMarkedAsRead?: () => void;
  onMarkedAsUnread?: () => void;
  setShouldHideActions: () => void;
  setShouldShowActions: () => void;
}) => {
  return (
    <div className={twMerge('flex gap-2 pr-2 absolute z-10', className)}>
      {!isArchived && (
        <InboxIconButton
          accessibilityText="Archive"
          className={buttonClassName}
          icon={<IconFlatArchiveLarge />}
          onBlur={setShouldHideActions}
          onClick={onArchived}
          onFocus={setShouldShowActions}
          onMouseEnter={setShouldShowActions}
        />
      )}
      <InboxIconButton
        accessibilityText="Mark as read"
        className={buttonClassName}
        icon={!isRead ? <IconFlatEnvelopeOpen /> : <IconFlatEnvelopeWithDot />}
        onBlur={setShouldHideActions}
        onClick={() => {
          if (isRead && onMarkedAsUnread) {
            onMarkedAsUnread();
          } else if (onMarkedAsRead) {
            onMarkedAsRead();
          }
        }}
        onFocus={setShouldShowActions}
        onMouseEnter={setShouldShowActions}
      />
    </div>
  );
};
