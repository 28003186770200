import debounce from 'lodash.debounce';
import { ReactNode, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { NotificationItem } from '../Notifications/NotificationItem';
import { InboxItemActions } from './InboxItemActions';
import { InboxItemButton } from './InboxItemButton';

/**
 * A React component to render an inbox item
 */
export const InboxItem = ({
  avatarIconClassName,
  children,
  className,
  classNameButton,
  classNameNotificationItem,
  icon,
  isArchived,
  isImportant,
  isLast,
  isRead,
  isReadOnly,
  onArchived,
  onClick,
  onMarkedAsRead,
  onMarkedAsUnread,
  timestamp,
  title,
  trailingContent,
}: {
  avatarIconClassName?: string;
  children: ReactNode;
  className?: string;
  classNameButton?: string;
  classNameNotificationItem?: string;
  icon: ReactNode;
  isArchived?: boolean;
  isImportant?: boolean;
  isLast?: boolean;
  isRead?: boolean;
  isReadOnly?: boolean;
  onArchived?: () => void;
  onClick?: () => void;
  onMarkedAsRead?: () => void;
  onMarkedAsUnread?: () => void;
  timestamp: string;
  title?: ReactNode;
  trailingContent?: ReactNode;
}) => {
  const [shouldShowActions, setShouldShowActions] = useState(false);

  // debounce hiding of action buttons because these buttons overlay the
  // button that triggers the showing and hiding of them. this way we can
  // cancel hiding, if a newer event has triggered to "show". this accounts
  // for mousing over the button that shows / hides actions and then mousing
  // over those buttons. it also provides the same solution for focus and blur.
  const setShouldHideActions = useCallback(
    debounce(() => {
      setShouldShowActions(false);
    }, 50),
    [],
  );

  // show the actions and cancel a previous debounced event to hide
  const setShouldShowActionsAndCancelHide = () => {
    setShouldHideActions.cancel();
    setShouldShowActions(true);
  };

  return (
    <div
      className={twMerge('relative flex items-center justify-end', className)}
    >
      <InboxItemButton
        className={classNameButton}
        disabled={!onClick}
        onBlur={setShouldHideActions}
        onClick={onClick}
        onFocus={setShouldShowActionsAndCancelHide}
        onMouseEnter={setShouldShowActionsAndCancelHide}
        onMouseLeave={setShouldHideActions}
      >
        <NotificationItem
          avatarIconClassName={avatarIconClassName}
          className={classNameNotificationItem}
          icon={icon}
          isImportant={isImportant}
          isLast={isLast}
          timestamp={timestamp}
          title={title}
          trailingContent={trailingContent}
        >
          {children}
        </NotificationItem>
      </InboxItemButton>
      {!isReadOnly && shouldShowActions && (
        <InboxItemActions
          isArchived={isArchived}
          isRead={isRead}
          onArchived={onArchived}
          onMarkedAsRead={onMarkedAsRead}
          onMarkedAsUnread={onMarkedAsUnread}
          setShouldHideActions={setShouldHideActions}
          setShouldShowActions={setShouldShowActionsAndCancelHide}
        />
      )}
    </div>
  );
};
