import { useCallback, useEffect, useRef, useState } from 'react';

import { Phase, PHASES } from './constants';

interface UsePhaseManagerProps {
  initialPhase?: Phase[];
}

interface UsePhaseManagerReturn {
  selectedPhases: Phase[];
  onHandlePhaseChange: (phase: Phase) => void;
  nextPhase: () => void;
  previousPhase: () => void;
  setSelectedPhases: React.Dispatch<React.SetStateAction<Phase[]>>;
}

/**
 * Manages the current phase of the CAT event, with phase transitions and playhead synchronization.
 */
export const usePhaseManager = ({
  initialPhase = ['BEFORE', 'DURING', 'AFTER'],
}: UsePhaseManagerProps): UsePhaseManagerReturn => {
  const [selectedPhases, setSelectedPhases] = useState<Phase[]>(
    Array.isArray(initialPhase) ? initialPhase : [initialPhase],
  );
  const phaseRef = useRef<Phase[]>(selectedPhases);

  useEffect(() => {
    phaseRef.current = selectedPhases;
  }, [selectedPhases]);

  const onHandlePhaseChange = useCallback((phase: Phase) => {
    setSelectedPhases(prevSelectedPhases => {
      if (phase === 'ALL') {
        // If "ALL" is selected, clear all other phases and keep only "ALL"
        return ['ALL'];
      }

      if (prevSelectedPhases.includes(phase)) {
        // If the phase is already selected, toggle it off
        return prevSelectedPhases.filter(p => p !== phase);
      }

      // If "ALL" is in the current selection, remove it before adding a new phase
      if (prevSelectedPhases.includes('ALL')) {
        return [phase];
      }

      // Add the new phase to the selection
      return [...prevSelectedPhases, phase];
    });
  }, []);

  const nextPhase = useCallback(() => {
    const currentIndex = PHASES.indexOf(phaseRef.current[0] || 'ALL');
    const nextIndex = (currentIndex + 1) % PHASES.length;
    const nextPhaseItem = PHASES[nextIndex];
    setSelectedPhases([nextPhaseItem]);
  }, []);

  const previousPhase = useCallback(() => {
    const currentIndex = PHASES.indexOf(phaseRef.current[0] || 'ALL');
    const previousIndex = (currentIndex - 1 + PHASES.length) % PHASES.length;
    const previousPhaseItem = PHASES[previousIndex];
    setSelectedPhases([previousPhaseItem]);
  }, []);

  return {
    selectedPhases,
    onHandlePhaseChange,
    nextPhase,
    previousPhase,
    setSelectedPhases,
  };
};
