import { ReactNode, Suspense } from 'react';
import { twMerge } from 'tailwind-merge';

import { AnimatedExpandCollapse } from '../AnimatedExpandCollapse';
import { IconFlatRendererLazy } from '../Icon/IconFlatRendererLazy';
import { InboxItemButton } from '../Inbox/InboxItemButton';

import type { IconKey } from '../Icon/IconFlatRenderer';

export interface NotificationsTaskProps {
  /** React node to render as the main content */
  children: ReactNode;

  /** `className` that will override via `tailwind-merge` */
  className?: string;

  /** `className` for the icon */
  classNameIcon?: string;

  /** An icon key to match an icon with to render as starting content */
  iconKey: IconKey;

  /** A button click handler */
  onClick?: () => void;

  /** A timestamp to be displayed */
  timestamp?: string;
}

/**
 * A React component to render a task item
 */
export const NotificationsTask = ({
  children,
  className,
  classNameIcon,
  iconKey,
  onClick,
  timestamp,
}: NotificationsTaskProps) => {
  return (
    <AnimatedExpandCollapse isExpanded shouldExpandOnEnter>
      <InboxItemButton
        className={twMerge('flex p-2 gap-3', className)}
        onClick={onClick}
      >
        <span className="flex w-5 items-start">
          <Suspense fallback="">
            <IconFlatRendererLazy className={classNameIcon} iconKey={iconKey} />
          </Suspense>
        </span>
        <span className="flex flex-col gap-[7px]">
          <span className="text-cool-gray-700 leading-[16.8px] font-medium text-sm">
            {children}
          </span>
          {timestamp && (
            <span className="text-cool-gray-500 leading-4 font-normal text-xs">
              {timestamp}
            </span>
          )}
        </span>
      </InboxItemButton>
    </AnimatedExpandCollapse>
  );
};
