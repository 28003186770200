import classNames from 'classnames';

interface ChartBackgroundProps {
  /** Height of each y-axis range in pixels */
  chartYAxisUnitHeight: number;

  /**
   * The y-axis values representing the possible range of data points
   */
  chartYAxisValues: number[];

  /**
   * A className for the main containing element
   */
  className?: string;
}

/**
 * A component to render a y-axis column
 */
export const ChartBackground = ({
  chartYAxisUnitHeight,
  chartYAxisValues,
  className,
}: ChartBackgroundProps) => {
  return (
    <div className={className}>
      {chartYAxisValues.slice(0, -1).map((value, index, array) => (
        <div
          className={classNames('border-t border-t-cool-gray-200', {
            'border-b border-b-cool-gray-200': index === array.length - 1,
          })}
          key={`y-axis-row-${value}`}
          style={{ height: `${chartYAxisUnitHeight}px` }}
        />
      ))}
    </div>
  );
};
