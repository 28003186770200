import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useEndOutOfOfficeMutation } from '~/src/generatedX/graphql';
import { useFeatureFlag } from '~/src/hooks/useFeatureFlag';

import { Spinner } from '@assured/design-system';

import {
  OOO_SETTING_FOR_USER,
  OOO_SETTINGS_MODAL_QUERY_PARAM_KEY,
} from './constants';
import { getDateString } from './getDateString';
import { useOutOfOfficeSettingsModalContext } from './OutOfOfficeSettingsContext';

export const OUT_OF_OFFICE_GLOBAL_BANNER_HEIGHT = 52;

export const OutOfOfficeGlobalBanner = () => {
  const { adjusterOutOfOfficeSettings, outOfOfficeActive } =
    useOutOfOfficeSettingsModalContext();
  const showOOOSettings =
    useFeatureFlag<boolean>('eng-5733-inbox', false) && outOfOfficeActive;
  const [endOutOfOffice, { loading }] = useEndOutOfOfficeMutation();
  const { search } = useLocation();

  const outOfOfficeSearchTo = useMemo(() => {
    const params = new URLSearchParams(search);
    params.set(OOO_SETTINGS_MODAL_QUERY_PARAM_KEY, OOO_SETTING_FOR_USER);

    return params.toString();
  }, [search]);

  const until = adjusterOutOfOfficeSettings?.endAt
    ? ` until ${getDateString(adjusterOutOfOfficeSettings.endAt)}`
    : '';

  return showOOOSettings ? (
    <div className="h-[52px] w-full flex items-center justify-center bg-yellow-100">
      <div className="text-yellow-900 font-medium pr-2">
        You are currently OOO{until}. Automated replies are being sent.
      </div>
      <div className="flex items-center">
        <button
          type="button"
          className="text-indigo-600 inline-flex items-center rounded outline-none underline px-2 font-medium hover:text-indigo-800 focus-visible:ring-1"
          disabled={loading}
          onClick={() => {
            endOutOfOffice();
          }}
        >
          {loading && (
            <span className="pr-2">
              <Spinner className="h-4 w-4" />
            </span>
          )}
          End now
        </button>
        <Link
          className="text-indigo-600 inline-flex items-center rounded outline-none underline px-2 font-medium hover:text-indigo-800 focus-visible:ring-1"
          to={{
            search: outOfOfficeSearchTo,
          }}
        >
          Update settings
        </Link>
      </div>
    </div>
  ) : null;
};
