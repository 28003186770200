import React from 'react';

import {
  IconKey,
  IconLicensePlateRenderer,
} from '../Icon/IconLicensePlateRenderer';
import { Text } from './Text';

const ICON_DIMENSION = 60;

export type LicensePlateInputProps = {
  disabled?: boolean;
  hasError?: boolean;
  hint?: string;
  iconKey?: IconKey | null;
  label?: string;
  onChange?: (v: string) => void;
  onSubmit?: () => void;
  placeholder?: string;
  required?: boolean;
  value?: string;
  ref?: React.RefObject<HTMLInputElement>;
};

export const LicensePlate = ({
  disabled = false,
  hasError,
  hint,
  iconKey = null,
  label,
  onChange,
  onSubmit,
  placeholder,
  required,
  value,
  ref,
}: LicensePlateInputProps) => {
  return (
    <Text
      disabled={disabled}
      hasError={hasError}
      header={<InputHeaderBlock iconKey={iconKey} />}
      hint={hint}
      label={label}
      onChange={onChange}
      onSubmit={onSubmit}
      placeholder={placeholder}
      required={required}
      value={value}
      ref={ref}
    />
  );
};

function InputHeaderBlock({ iconKey }: { iconKey: IconKey | null }) {
  return (
    <div className="overflow-hidden -ml-[18px] -mt-4 -mb-4 py-5 before:bg-[#94A2B8] px-3 w-[50px] inline-flex relative bg-[#94A2B8] self-stretch">
      {iconKey ? (
        <IconLicensePlateRenderer
          width={ICON_DIMENSION}
          height={ICON_DIMENSION}
          iconKey={iconKey}
          color="rgb(224 36 36)"
          className="absolute top-0 bottom-0 right-0 m-auto -translate-x-1/2 left-1/2 "
        />
      ) : null}
    </div>
  );
}
