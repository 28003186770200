import { ReactNode, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { FlyoutMenu } from '../Menu';
import { InboxIconButton } from './InboxIconButton';
import { inboxOpenMenuState } from './state/inboxOpenMenuState';

/**
 * A React component to render an inbox menu
 */
export const InboxMenu = ({
  accessibilityText,
  children,
  className,
  icon,
  id,
}: {
  accessibilityText?: string;
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  id: string;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [inboxOpenMenu, setInboxOpenMenu] = useRecoilState(inboxOpenMenuState);
  const setShouldOpen = (shouldOpen: boolean) => {
    if (shouldOpen) {
      setInboxOpenMenu(id);
    } else if (id === inboxOpenMenu) {
      setInboxOpenMenu(undefined);
    }
  };
  const isOpen = id === inboxOpenMenu;
  return (
    <FlyoutMenu
      button={
        <InboxIconButton
          accessibilityText={accessibilityText}
          aria-controls={id}
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          icon={icon}
          isActive={isOpen}
          onClick={() => setShouldOpen(true)}
          ref={buttonRef}
          title={accessibilityText}
        />
      }
      className={className}
      id={id}
      onEscapeClose={() => buttonRef.current?.focus()}
      role="listbox"
      setShouldOpen={setShouldOpen}
      shouldOpen={isOpen}
      tabIndex={0}
    >
      {children}
    </FlyoutMenu>
  );
};
