import type { ReactNode } from 'react';

/**
 * Hides text that should still be available to screen readers
 * @see - {@link https://webaim.org/techniques/css/invisiblecontent/#techniques | Invisible Content Just for Screen Reader Users | WebAIM}
 */
export const AccessibleHiddenText = ({ children }: { children: ReactNode }) => (
  <span className="absolute left-[-10000px] top-auto w-[1px] h-[1px] overflow-hidden">
    {children}
  </span>
);
