import './DatePicker.css';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker';

import { Checkbox } from '../Checkbox';
import { IconFlatRenderer } from '../Icon/IconFlatRenderer';

export type DatePickerProps = {
  errorMessage?: string;
  value?: { date: Date | null; discovered?: boolean };
  onChange: (date: Date | null, discovered?: boolean) => void;
  minDate?: Date;
  maxDate?: Date;
  maxYears?: number;
  showTimeSelect?: boolean;
  placeholderText?: string;
  inline?: boolean;
  wide?: boolean;
  showFieldAndPopperInline?: boolean;
  allowUnknownTime?: boolean;
  allowDiscovered?: boolean;
  id?: string;
  format?: string;
} & ReactDatePickerProps;

// https://stackoverflow.com/a/10050831
function range(size: number, startAt: number = 0): ReadonlyArray<number> {
  return [...Array(size).keys()].map(i => startAt - i);
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * We treat "Unknown time" as the special time value of 12:01 AM.
 */
const unknownDateTime = [0, 1, 0, 0] as const;
const unsetUnknownDateTime = [0, 0, 0, 0] as const;
const isUnknownDateTime = (date?: Date | null) => {
  if (!date) return false;
  return [
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  ].every((v, i) => v === unknownDateTime[i]);
};

export const DatePicker = ({
  errorMessage,
  value,
  onChange,
  minDate,
  maxDate,
  maxYears = 20,
  showTimeSelect,
  placeholderText,
  inline,
  wide,
  showFieldAndPopperInline,
  allowUnknownTime,
  allowDiscovered,
  id,
  format,
  ...rest
}: DatePickerProps) => {
  const [unknownTimeChecked, setUnknownTimeChecked] = useState(false);
  const [discoveredChecked, setDiscoveredChecked] = useState(false);

  useEffect(() => {
    setUnknownTimeChecked(
      allowUnknownTime ? isUnknownDateTime(value?.date) : false,
    );
  }, [value?.date]);

  useEffect(() => {
    setDiscoveredChecked(value?.discovered || false);
  }, [value?.discovered]);

  const years = range(maxYears + 1, new Date().getFullYear());
  return (
    <div className="react-datepicker--design-system">
      <ReactDatepicker
        id={id}
        popperClassName={classNames({
          'react-datepicker-popper--wide': showTimeSelect,
          'react-datepicker-popper--showFieldAndPopperInline':
            showFieldAndPopperInline,
        })}
        wrapperClassName={classNames({
          'react-datepicker-wrapper--error': !!errorMessage,
          'react-datepicker-wrapper--wide': wide,
          'react-datepicker-wrapper--narrow': !wide,
        })}
        selected={value?.date}
        placeholderText={placeholderText || 'Enter a date...'}
        onChange={date => {
          if (unknownTimeChecked) {
            date?.setHours(...unknownDateTime);
          }
          onChange(date, discoveredChecked);
        }}
        showMonthDropdown
        showYearDropdown
        popperPlacement="bottom-start"
        dropdownMode="select"
        dateFormat={
          format ??
          `MMMM d, yyyy${
            showTimeSelect
              ? unknownTimeChecked || isUnknownDateTime(value?.date)
                ? " 'at unknown time'"
                : ' h:mm a'
              : ''
          }`
        }
        showTimeSelect={showTimeSelect && !unknownTimeChecked}
        minDate={minDate}
        maxDate={maxDate}
        inline={inline}
        open={showFieldAndPopperInline}
        // We uncheck the button on close to workaround a bug in react-datepicker
        // where mounting without the time list and then opening the time list results
        // in the UI reflowing incorrectly.
        onCalendarClose={() => {
          setUnknownTimeChecked(false);
        }}
        onCalendarOpen={() => {
          setUnknownTimeChecked(
            allowUnknownTime ? isUnknownDateTime(value?.date) : false,
          );
        }}
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
              tether: true,
              altAxis: true,
            },
          },
        ]}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            className={classNames('react-datepicker__header-controls', {
              'react-datepicker__header-controls--has-time-select':
                showTimeSelect,
            })}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
            >
              <IconFlatRenderer
                iconKey="ICON_FLAT_CHEVRON_OUTLINE_LEFT"
                className="text-cool-gray-400"
              />
            </button>
            <div className="react-datepicker__header-select-controls">
              <select
                value={months[date.getMonth()]}
                onChange={({ target }) =>
                  changeMonth(months.indexOf(target.value))
                }
              >
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                value={date.getFullYear()}
                onChange={({ target }) => changeYear(Number(target.value))}
              >
                {years.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
            >
              <IconFlatRenderer
                iconKey="ICON_FLAT_CHEVRON_OUTLINE_RIGHT"
                className="text-cool-gray-400"
              />
            </button>
          </div>
        )}
        {...rest}
      >
        {allowUnknownTime || allowDiscovered ? (
          <div className="-mt-[2px] w-full border-t px-6 py-2 flex justify-end">
            {allowDiscovered ? (
              <label className="inline-flex items-center gap-2 cursor-pointer pr-3">
                <input
                  type="checkbox"
                  className="hidden"
                  checked={discoveredChecked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.target;
                    setDiscoveredChecked(checked);
                    onChange(value?.date || null, checked);
                  }}
                />
                <Checkbox size="sm" isActive={discoveredChecked} />
                <span className="text-cool-gray-600 text-sm">Discovered</span>
              </label>
            ) : null}
            {allowUnknownTime ? (
              <label className="inline-flex items-center gap-2 cursor-pointer">
                <input
                  type="checkbox"
                  className="hidden"
                  checked={unknownTimeChecked}
                  onChange={e => {
                    const { checked } = e.target;
                    const date = value?.date
                      ? new Date(value?.date)
                      : new Date();
                    if (checked) {
                      date.setHours(...unknownDateTime);
                    } else {
                      date.setHours(...unsetUnknownDateTime);
                    }
                    setUnknownTimeChecked(checked);
                    onChange(date, discoveredChecked);
                  }}
                />
                <Checkbox size="sm" isActive={unknownTimeChecked} />
                <span className="text-cool-gray-600 text-sm">Unknown time</span>
              </label>
            ) : null}
          </div>
        ) : null}
      </ReactDatepicker>
      {errorMessage && (
        <span className="react-datepicker__error">{errorMessage}</span>
      )}
    </div>
  );
};
