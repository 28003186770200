import './actionButtons.css';

import React from 'react';

import { IconFlatBackward } from '../Icon/IconFlatBackward';
import { IconFlatBackwardStep } from '../Icon/IconFlatBackwardStep';
import { IconFlatForward } from '../Icon/IconFlatForward';
import { IconFlatForwardStep } from '../Icon/IconFlatForwardStep';
import { IconFlatPause } from '../Icon/IconFlatPause';
import { IconFlatPlay } from '../Icon/IconFlatPlay';

export interface ActionButtonsProps {
  isPlaying: boolean;
  play: () => void;
  pause: () => void;
  previous: () => void;
  next: () => void;
  moveToStart: () => void;
  moveToEnd: () => void;
}

const buttonStyles =
  'rounded-full flex items-center justify-center bg-white hover:bg-gray-200';

/**
 * A set of playback control buttons, including play/pause, previous phase, and next phase.
 */

function BaseActionButtons(props: ActionButtonsProps): JSX.Element {
  const { isPlaying, play, pause, previous, next, moveToEnd, moveToStart } =
    props;

  return (
    <div className="flex gap-[10px] items-center justify-self-center">
      <button
        className={`w-8 h-8 ${buttonStyles}`}
        type="button"
        onClick={moveToStart}
      >
        <IconFlatBackwardStep />
      </button>
      <button
        className={`w-8 h-8 ${buttonStyles}`}
        type="button"
        onClick={previous}
      >
        <IconFlatBackward />
      </button>
      <button
        className={`w-10 h-10 ${buttonStyles} fill-rect-grey !border !border-gray-300`}
        type="button"
        onClick={isPlaying ? pause : play}
      >
        {!isPlaying ? (
          <IconFlatPlay className="relative left-[1px]" />
        ) : (
          <IconFlatPause className="relative left[-1px]" />
        )}
      </button>
      <button
        className={`w-8 h-8 ${buttonStyles}`}
        type="button"
        onClick={next}
      >
        <IconFlatForward />
      </button>
      <button
        className={`w-8 h-8 ${buttonStyles}`}
        type="button"
        onClick={moveToEnd}
      >
        <IconFlatForwardStep />
      </button>
    </div>
  );
}

/**
 * Memoized ActionButton that only updates when isPlaying changes or when playbackSpeed changes
 */
export default React.memo(BaseActionButtons, (prevProps, nextProps) => {
  return prevProps.isPlaying === nextProps.isPlaying;
});
