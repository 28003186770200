import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { AccessibleHiddenText } from '../Accessibility/AccessibleHiddenText';

interface InboxIconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  accessibilityText?: string;
  icon: ReactNode;
  isActive?: boolean;
}

/**
 * A React component to render a an inbox specific icon button
 */
export const InboxIconButton = forwardRef<
  HTMLButtonElement,
  InboxIconButtonProps
>(({ accessibilityText, className, icon, isActive, ...rest }, ref) => {
  return (
    <button
      className={twMerge(
        'w-10 h-10 flex justify-center items-center rounded-md hover:bg-cool-gray-50 active:bg-indigo-bright-50 focus:outline-0 focus:shadow-outline-indigo-bright-inset',
        classNames({
          'shadow-outline-indigo-bright-inset': isActive,
        }),
        className,
      )}
      type="button"
      title={accessibilityText}
      ref={ref}
      {...rest}
    >
      {accessibilityText && (
        <AccessibleHiddenText>{accessibilityText}</AccessibleHiddenText>
      )}
      {icon}
    </button>
  );
});
