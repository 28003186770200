import { FC, useState } from 'react';

import { useKeyboardShortcut } from '@assured/ui/hooks';

import { ButtonSelectSingleLong } from './ButtonSelectSingleLong';
import { ButtonSelectSingleSquare } from './ButtonSelectSingleSquare';

import type { ButtonSelectSingleProps } from './ButtonSelectSingleProps';

export const ButtonSelectSingle: FC<
  Omit<ButtonSelectSingleProps, 'shortcutKey'> & {
    /** Acceptable characters to be used as the shortcut key */
    shortcutKeys: string[];

    /** Style to inject on-hover */
    hoverStyle?: React.CSSProperties;

    /**
     * Optional dataTestId, if not provided a default is used
     */
    dataTestId?: string;
    minWidthMode?: boolean;
  }
> = ({
  isActive,
  size,
  shortcutKeys,
  style,
  hoverStyle,
  dataTestId,
  minWidthMode = true,
  ...props
}) => {
  const { shortcut, disabled } = useKeyboardShortcut(shortcutKeys, () => {
    props.onSelection(true);
  });
  const [isHovered, setIsHovered] = useState(false);
  const setIsActive = (active: boolean) => {}; // no-op, onSelection already fired by passed onSelection()

  if (size === 'square' || size === 'square-large') {
    return (
      <ButtonSelectSingleSquare
        {...props}
        shortcutKey={shortcut || (shortcutKeys?.length ? ' ' : '')} // Separator: prevent reflow
        shortcutDisabled={disabled}
        isActive={!!isActive}
        isHovered={isHovered}
        setIsActive={setIsActive}
        setIsHovered={setIsHovered}
        size={size}
        style={isHovered && hoverStyle ? { ...style, ...hoverStyle } : style}
        type="button"
        dataTestId={dataTestId}
      />
    );
  }

  return (
    <ButtonSelectSingleLong
      {...props}
      shortcutKey={shortcut || (shortcutKeys?.length ? ' ' : '')} // Separator: prevent reflow
      shortcutDisabled={disabled}
      isActive={!!isActive}
      isHovered={isHovered}
      setIsActive={setIsActive}
      setIsHovered={setIsHovered}
      dataTestId={dataTestId}
      minWidthMode={minWidthMode}
    />
  );
};
