import { useMemo, useState } from 'react';

import { TIMESLOT_DURATION_MINUTES } from './constants';
import { getDefaultTimeslotState, getTimeslotSelectOptions } from './helpers';

import type { TimeslotGroup } from './types';

/**
 * A React hook to return memoized, default timeslot select options
 */
export const useTimeslotSelectOptions = () =>
  useMemo(
    () =>
      getTimeslotSelectOptions({
        timeslotDurationMinutes: TIMESLOT_DURATION_MINUTES,
      }),
    [],
  );

/**
 * A React hook to provide timeslot state and state setter
 */
export const useTimeslotState = ({
  timeslotGroups,
}: {
  /** Groups of timeslots, typically days of the week (ie: 'Monday', 'Tuesday', etc) */
  timeslotGroups: TimeslotGroup[];
}) => {
  const state = useMemo(
    () => getDefaultTimeslotState(timeslotGroups),
    [timeslotGroups],
  );

  /** Local state of timelots, storing timeslot adds, removals, etc */
  return useState(state);
};
