import React, { ReactNode } from 'react';

import { AdjusterConfig } from '@assured/shared-types';

import config from '../../config';
import { useAuth } from '../../hooks/useAuth';
import { AssuredUser } from '../Providers/AssuredAuthOProvider';

type ShowIfPermittedProps = {
  condition: (
    config: AdjusterConfig,
    user?: AssuredUser,
  ) => boolean | undefined;
  fallback?: JSX.Element;
  children: ReactNode;
};

export const ShowIfPermitted = ({
  condition,
  fallback,
  children,
}: ShowIfPermittedProps): JSX.Element | null => {
  const { user } = useAuth();
  if (condition(config, user)) {
    return <>{children}</>;
  }
  return fallback || null;
};
