import debounce from 'lodash.debounce';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import { usAutocompletePro } from 'smartystreets-javascript-sdk';

import { useApolloClient } from '@apollo/client';
import { locationFragmentTypeSchema } from '@assured/shared-types/Location/Location';

import { HighlightedText } from '../../HighlightedText';
import { SelectDropdown } from '../../SelectDropdown';
import { smartyLocationsRequest } from './smartyLocationsRequest';
import { LoadingMessage, StartTypingMessage } from './SupportingComponents';
import { LocationOption, LocationProps, Suggestion } from './types';

const autocompleteResults: Record<string, Suggestion[]> = {};
const entriesAutocompleteResults: Record<string, Suggestion[]> = {};

const parseAutocompleteResult = (
  result: usAutocompletePro.Suggestion,
  parent?: LocationOption,
): Suggestion => {
  const addressText = result.secondary
    ? `${result.streetLine}, ${result.secondary}, ${result.city}, ${result.state} ${result.zipcode}`
    : `${result.streetLine}, ${result.city}, ${result.state} ${result.zipcode}`;

  return {
    ...result,
    label: addressText,
    value: addressText,
    addressText,
    line1: result.streetLine,
    line2: result.secondary,
    apartmentNumber:
      result.secondary.split(' ').slice(1).join(' ').replace('#', '') ||
      undefined,
    postalCode: result.zipcode,
    parent,
    children: [],
  };
};

export const LocationSmarty = ({
  disabled,
  error,
  field,
  label,
  placeholder = 'Search for a location',
  required,
  updateValue,
  primaryValue,
  autoFocus,
  addressBookEntries,
  searchBias,
  setQuery,
  query,
  locations,
  setLocations,
  setSwitchToGoogle,
}: LocationProps & {
  setQuery: (query?: string) => void;
  setLocations: (locations: LocationOption[]) => void;
  setSwitchToGoogle: (s: boolean) => void;
  query?: string;
  locations: LocationOption[];
}) => {
  const apolloClient = useApolloClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectDropdownRef = useRef<any | null>(null); // FIXME: Any type
  const [defaultOptions, setDefaultOptions] = useState<
    LocationOption[] | undefined
  >();
  // setting and clearing selectedParent indicates that we are looking
  // at a collection of sublocations with a parent
  const [selectedParent, setSelectedParent] = useState<
    LocationOption | undefined
  >();

  const retrieveLocations = async (
    prefix?: string,
    currentParent?: LocationOption,
  ) => {
    if (!prefix) {
      setLocations([]);
      return;
    }

    if (currentParent?.addressText) {
      const children = entriesAutocompleteResults[currentParent.addressText];
      if (children?.length) {
        setLocations(children || []);
        return;
      }
    } else {
      const cachedResults = autocompleteResults[prefix];
      if (cachedResults) {
        setLocations(cachedResults);
        return;
      }
    }

    try {
      setIsLoading(true);
      const response = await smartyLocationsRequest({
        gqlClient: apolloClient,
        prefix,
        selectedParent: currentParent,
        searchBias,
      });
      const results = response.map(r =>
        parseAutocompleteResult(r, currentParent),
      );

      if (currentParent?.addressText) {
        entriesAutocompleteResults[currentParent.addressText] = results;
      }

      setLocations(results);
      if (results.length === 0 && (prefix.length || 0) >= 5) {
        setSwitchToGoogle(true);
      }
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  const onRetrieveLocations = useMemo(
    // debounce and memoize the call to get new results
    () => debounce(retrieveLocations, 500),
    // this is how you are supposed to setup the debounce
    // I"m not sure the debounce is working correctly
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    // when query changes get new results
    onRetrieveLocations(query, selectedParent);
    if (query) {
      selectDropdownRef?.current?.focus();
    }
    // we don't want to update when the selectedParent get's set or cleared
    // it's already updating
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <SelectDropdown
      ref={selectDropdownRef}
      value={primaryValue?.addressText}
      options={locations || defaultOptions || []}
      filterOption={(o, inputValue) => {
        if (selectedParent) {
          return o.label.indexOf(inputValue) > -1;
        }
        return true;
      }}
      ClearIndicatorCallback={() => {
        setQuery(undefined);
        setLocations([]);
        setDefaultOptions([]);
        updateValue(field, null);
      }}
      onChange={async option => {
        if (option === null) {
          // The input has been edited such that the previous selection has been cleared.
          setLocations([]);
          setDefaultOptions([]);
          updateValue(field, null);
          return;
        }
        const o = option as LocationOption;

        if (o.entries && !o.parent) {
          // This is a parent address, so we don't select it directly
          setSelectedParent(o);
          onRetrieveLocations(query, o);
          selectDropdownRef.current?.onInputChange(
            `${o.streetLine}, ${o.secondary}`,
            '',
          );
          selectDropdownRef.current?.onMenuOpen();
          return;
        }
        const { parent, children, ...value } = o;
        setSelectedParent(undefined);
        setLocations([]);
        setDefaultOptions([]);
        updateValue(field, locationFragmentTypeSchema.parse(value));
        selectDropdownRef.current?.blur();
      }}
      onInputChange={val => {
        const parentQuery = selectedParent
          ? `${selectedParent.streetLine}, ${selectedParent.secondary}`
          : undefined;

        if (
          !val ||
          (parentQuery &&
            !val.toLowerCase().includes(parentQuery.toLowerCase()))
        ) {
          setSelectedParent(undefined);
        }
        setQuery(val);
      }}
      components={{
        Option: ((props: OptionProps<LocationOption>) => {
          const { inputValue } = props?.selectProps || '';

          return (
            <>
              <components.Option
                {...props}
                selectOption={option => {
                  props.selectOption(option);
                }}
              >
                <div className="flex flex-row justify-between items-center cursor-pointer">
                  <HighlightedText
                    classNameHighlighted="font-bold text-indigo-bright-600"
                    input={props?.data?.label}
                    term={inputValue}
                  />
                  {props.data.entries && !props.data.parent ? (
                    <span className="text-sm font-bold text-indigo-bright-600">
                      + {props.data.entries} address
                      {props.data.entries > 1 ? 'es' : ''} &#8250;
                    </span>
                  ) : null}
                </div>
              </components.Option>
              <div className="bg-cool-gray-200 h-[1px] w-full my-1 last:hidden" />
            </>
          );
        }) as FC<OptionProps<unknown, boolean, GroupBase<unknown>>>,
        ...(isLoading
          ? { NoOptionsMessage: LoadingMessage }
          : {
              NoOptionsMessage: props => (
                <StartTypingMessage
                  {...props}
                  addressBookEntries={addressBookEntries}
                  onAddressBookSelect={async v => {
                    updateValue(field, v);
                    selectDropdownRef.current?.blur();
                  }}
                />
              ),
            }),
      }}
      error={error}
      disabled={disabled}
      required={required}
      labelProps={{ labelStr: label }}
      placeholder={placeholder}
      openMenuOnFocus={
        // auto-reveal address book initially if there are entries
        (autoFocus && !primaryValue && !!addressBookEntries?.length) || !!query
      }
      editableInput
      editableInputIncompleteErrorText="Please select a location to continue."
    />
  );
};
