import { useEffect } from 'react';

/**
 * A React hook to trigger a callback upon click outside of the specified `ref`
 * or outside all `refs`
 */
export const useOutsideClickRef = <T extends HTMLElement>({
  callback,
  refs = [],
}: {
  callback: () => void;
  refs?: React.RefObject<T>[];
}) => {
  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      for (const refItem of refs) {
        if (!(event.target instanceof HTMLElement)) {
          return;
        }
        if (
          refItem.current &&
          (refItem.current === event.target ||
            refItem.current.contains(event.target))
        ) {
          return;
        }
      }
      callback();
    }
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [refs]);
};
