import { useId } from 'react-aria';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

const DiscardConfirmationDialog = ({
  isOpen,
  setIsOpen,
  onCancel,
  onConfirm,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  const headingId = useId();
  const descriptionId = useId();

  return (
    <FloatingPortal>
      {isOpen && (
        <FloatingOverlay
          className="bg-[rgba(30,41,59,0.7)] flex flex-col h-dvh w-full"
          style={{
            zIndex: 1000,
          }}
          lockScroll
        >
          <FloatingFocusManager context={context}>
            <div
              className="flex flex-col items-center justify-center h-dvh w-full"
              ref={refs.setFloating}
              aria-labelledby={headingId}
              aria-describedby={descriptionId}
              {...getFloatingProps()}
            >
              <div className="flex flex-col bg-white rounded-xl w-[400px] max-h-[80dvh] shadow-lg overflow-hidden">
                <header className="min-h-[72px] flex items-center px-6">
                  <h1
                    id={headingId}
                    className="font-medium text-lg text-gray-700"
                  >
                    Discard unsaved changes?
                  </h1>
                </header>
                <main className="px-6 text-gray-700 text-sm">
                  <p id={descriptionId}>
                    You have unsaved changes. Are you sure you want to discard
                    them and leave without saving?
                  </p>
                </main>
                <footer className="min-h-[88px] flex items-center px-6">
                  <button
                    type="button"
                    className="h-[40px] flex-grow mr-2 inline-flex items-center justify-center w-full text-sm text-gray-600 border border-gray-300 rounded-lg hover:bg-gray-50 hover:cursor-pointer outline-none focus-visible:ring-1"
                    onClick={onCancel}
                  >
                    Continue editing
                  </button>
                  <button
                    type="button"
                    className="h-[40px] flex-grow ml-2 inline-flex items-center justify-center w-full text-sm bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 hover:cursor-pointer outline-none focus-visible:ring-1"
                    onClick={onConfirm}
                  >
                    Discard
                  </button>
                </footer>
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
};

export default DiscardConfirmationDialog;
