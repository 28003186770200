import { useCallback, useEffect, useMemo } from 'react';

import { SelectInput } from '@assured/shared-types/Services';
import { PolicySubSection } from '@assured/shared-types/Services/PolicySearch';

import { Toggle } from '../Toggle';

interface PolicySearchSectionControlProps {
  policyNumber: string;
  contact: PolicySubSection;
  contactIndex: number;
  onCallerChange: (payload?: {
    policyNumber: string;
    contact: SelectInput;
    contactIndex: number;
  }) => void;
  callerSelection?: SelectInput;
  onItemSelect: (payload: { id: string; isSelected: boolean }) => void;
}

export const PolicySearchCallerToggle = ({
  policyNumber,
  contact,
  contactIndex,
  onCallerChange,
  callerSelection,
  onItemSelect,
}: PolicySearchSectionControlProps) => {
  const itemValue = useMemo<SelectInput>(
    () => ({
      action: 'set',
      name: contact.title,
      id: contact.dataId,
    }),
    [contact],
  );

  const isSelected = areValuesEqual(itemValue, callerSelection);

  const contactNameItemId = useMemo(
    () => contact.items.find(i => i.id.startsWith('contact_name'))?.id ?? '',
    [contact],
  );

  // Set initial value so that we can capture that the caller is on the policy
  useEffect(() => {
    if (isSelected) {
      onCallerChange({
        policyNumber,
        contact: itemValue,
        contactIndex,
      });
      onItemSelect({
        id: contactNameItemId,
        isSelected: true,
      });
    }
    // This is an "onmount" effect, so we don't want to run it again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback(
    (isToggled: boolean) => {
      if (isToggled) {
        onCallerChange({
          policyNumber,
          contact: itemValue,
          contactIndex,
        });
        onItemSelect({
          id: contactNameItemId,
          isSelected: true,
        });
      } else {
        onCallerChange();
      }
    },
    [
      contactIndex,
      contactNameItemId,
      itemValue,
      onCallerChange,
      onItemSelect,
      policyNumber,
    ],
  );

  return (
    <Toggle
      className="basis-auto"
      dataTestId={JSON.stringify(itemValue)}
      variant="yesno-slider"
      value={isSelected}
      onChange={onChange}
      yesLabel="Is Caller"
      noLabel="Is Caller"
    />
  );
};

const areValuesEqual = (a?: SelectInput, b?: SelectInput) => {
  return (
    (a && 'id' in a && b && 'id' in b && a.id && b.id && a.id === b.id) ||
    JSON.stringify(a) === JSON.stringify(b)
  );
};
