import classNames from 'classnames';
import { ReactNode } from 'react';

export enum ChartLabelTheme {
  'PURPLE' = 'PURPLE',
  'TEAL' = 'TEAL',
}

export const ChartLabel = ({
  children,
  theme = ChartLabelTheme.TEAL,
}: {
  /** Content to render */
  children: ReactNode;

  /** A theme option */
  theme?: ChartLabelTheme;
}) => (
  <span
    className={classNames('rounded-full px-3 leading-7 text-xs font-medium', {
      'bg-purple-bright-200 text-purple-bright-600':
        theme === ChartLabelTheme.PURPLE,
      'bg-teal-bright-200 text-teal-bright-600': theme === ChartLabelTheme.TEAL,
    })}
  >
    {children}
  </span>
);
