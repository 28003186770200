import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { KeyboardShortcutProvider } from '@assured/ui/hooks';

export interface MenuProps extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * A React component to render a menu
 */
export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ className, ...rest }, ref) => {
    return (
      <KeyboardShortcutProvider>
        <div
          className={twMerge(
            'w-[240px] flex flex-col gap-2 p-2 rounded-lg border border-cool-gray-200 bg-white shadow-lifted-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-bright-200',
            className,
          )}
          ref={ref}
          {...rest}
        />
      </KeyboardShortcutProvider>
    );
  },
);
