import { DateTime } from 'luxon';
import React from 'react';

export interface TimeLabelsProps {
  labels: { label: string; timeStamp: DateTime }[];
}

function BaseTimeLabels(props: TimeLabelsProps) {
  const { labels } = props;

  return (
    <div className="flex justify-between text-xs text-gray-500 mt-2 w-full">
      {labels.map(label => (
        <div key={label.timeStamp.toISO()}>{label.label}</div>
      ))}
    </div>
  );
}

export default React.memo(BaseTimeLabels);
