import classNames from 'classnames';
import { lazy, Suspense } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { TabLink } from '@assured/design-system';
import {
  ChevronRightIcon,
  CogIcon,
  CollectionIcon,
  HomeIcon,
  LightningBoltIcon,
} from '@heroicons/react/outline';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import useLocalSettings from '../../hooks/useLocalSettings';
import { SHOW_REAL_CAT } from '../Cat/constants';
import ClaimLookup from '../shared/ClaimLookup';
import { ShowIfPermitted } from '../shared/ShowIfPermitted';
import { LayoutProps } from './LayoutProps';
import { Notifications } from './Notifications';
import { ProfileMenu } from './ProfileMenu';

// only load bundle if it's used
const Inbox = lazy(() => import('../Inbox'));

export const Header = ({ navigation, subNavigation }: LayoutProps) => {
  const location = useLocation();
  const { settings, updateSettings } = useLocalSettings();
  const catEnabled = useFeatureFlag<boolean>(SHOW_REAL_CAT, false);
  const isInboxEnabled = useFeatureFlag<boolean | undefined>(
    'eng-5733-inbox',
    false,
    {
      shouldWaitForMount: true,
    },
  );

  return (
    <header className="w-full border-b border-gray-200 shadow-sm">
      <div
        className={classNames(
          'relative z-[60] flex-shrink-0 h-16  flex',
          settings.godMode ? '' : 'bg-white',
        )}
        style={
          settings.godMode
            ? {
                background:
                  'repeating-linear-gradient(-45deg, #fff, #fff 10px, #fdf6b2 10px, #fdf6b2 20px)',
              }
            : {}
        }
      >
        <div className="flex-1 flex justify-between px-4 sm:px-6">
          <div className="flex-1 flex">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div>
                    <Link to="/" className="text-gray-400 hover:text-gray-500">
                      {settings.godMode ? (
                        <LightningBoltIcon
                          className="flex-shrink-0 h-5 w-5"
                          aria-hidden="true"
                        />
                      ) : (
                        <HomeIcon
                          className="flex-shrink-0 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      <span className="sr-only">Home</span>
                    </Link>
                  </div>
                </li>
                {navigation.map(page => (
                  <li key={page.name}>
                    <div className="flex items-center">
                      <ChevronRightIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Link
                        to={page.href || '#'}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current={page.current ? 'page' : undefined}
                      >
                        {page.name}
                      </Link>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            <div className="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
              {location.pathname.includes('/cat') && !catEnabled ? (
                <span className="flex space-x-6">
                  <Link
                    to="/cat/configurator"
                    className="-relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                  >
                    <CogIcon className="mr-2 h-4 w-4" />
                    Configurator
                  </Link>
                  <Link
                    to="/cat/management"
                    className="-relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                  >
                    <CollectionIcon className="mr-2 h-4 w-4" />
                    Management
                  </Link>
                </span>
              ) : (
                <ClaimLookup />
              )}
              <div className="flex items-center space-x-8">
                {typeof isInboxEnabled === 'undefined' ? (
                  <div className="w-8 h-8" />
                ) : !isInboxEnabled ? (
                  <ShowIfPermitted condition={c => !!c.notifications}>
                    <Notifications />
                  </ShowIfPermitted>
                ) : (
                  <Suspense fallback={<div className="w-8 h-8" />}>
                    <Inbox />
                  </Suspense>
                )}
                <ProfileMenu
                  settings={settings}
                  updateSettings={updateSettings}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {subNavigation && (
        <div className="flex pl-20 items-end w-full border-b border-gray-200 h-14 bg-white border-t">
          {subNavigation.map(page => (
            <TabLink key={page.name} to={page.href || ''}>
              {page.name}
            </TabLink>
          ))}
        </div>
      )}
    </header>
  );
};
