import { SparkLineChart } from '@tremor/react';

import { SparkChartFlexContainer } from './SparkChartFlexContainer';
import { SparkChartBaseType } from './sparkChartTypes';

import type { ChartContainerProps } from './ChartContainer';

import type { SparkAreaChartProps } from '@tremor/react';

/**
 * A component to render a bar chart and its container layout.
 */
export const SimpleLineSparkChart = ({
  chartHeight = 32,
  chartTitle,
  children,
  isLoading,
  chartSubTitle,
  maxWidth,
  className,
  categories,
  ...props
}: SparkAreaChartProps & ChartContainerProps & SparkChartBaseType) => {
  return (
    <SparkChartFlexContainer
      chartHeight={chartHeight}
      isEmpty={!isLoading && (!props?.data || props?.data.length <= 0)}
      isLoading={isLoading}
      chartTitle={chartTitle}
      chartSubTitle={chartSubTitle}
      maxWidth={maxWidth}
      className={className}
    >
      {children}
      <SparkLineChart
        {...props}
        style={{ width: '64px', height: `${chartHeight}px` }}
        categories={categories}
      />
    </SparkChartFlexContainer>
  );
};
