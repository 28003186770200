import { ProgressCircle as TremorProgressCircle } from '@tremor/react';

import { ChartContainer } from './ChartContainer';

import type { ChartContainerProps } from './ChartContainer';

import type { ProgressCircleProps } from '@tremor/react';
/**
 * A component to render a progress circle and its container layout.
 */
export const ProgressCircle = ({
  chartHeight = 224,
  chartLabelTheme,
  chartTitle,
  isLoading,
  value,
  valueFormatter,
  ...props
}: ProgressCircleProps &
  ChartContainerProps & {
    valueFormatter?: (value: number) => string;
  }) => {
  return (
    <ChartContainer
      chartHeight={chartHeight}
      chartLabelTheme={chartLabelTheme}
      chartTitle={chartTitle}
      isCentered
      isSimple
      isEmpty={!isLoading && typeof value !== 'number'}
      isLoading={isLoading}
    >
      {typeof value !== 'number' ? null : (
        <div className="relative">
          <TremorProgressCircle
            radius={72}
            value={value}
            strokeWidth={12}
            {...props}
          >
            <div className="font-bold text-cool-gray-900 text-[28px]">
              {!valueFormatter ? value : valueFormatter(value)}
            </div>
          </TremorProgressCircle>
        </div>
      )}
    </ChartContainer>
  );
};
