import { useMemo } from 'react';
import type { CommercialVehicleOption } from '@assured/step-renderer/types/step-components/SelectOrCreate';
import Fuse from 'fuse.js';
import { CommercialAutoVehicleTypeDisplay } from '@assured/shared-types/Claim/Vehicle/InvolvedParty';

type OptionType = CommercialVehicleOption & { vehicleId: string | number };

interface UseVehicleSearchParams {
  options: OptionType[];
  searchTerm: string;
}

type ExtendedOption = OptionType & {
  commercialAutoVehicleTypeDisplay: string;
  ymm: string;
  unmaskedPortionOfVIN: string;
};

export const useVehicleSearch = ({
  options,
  searchTerm,
}: UseVehicleSearchParams) => {
  const fuse = useMemo(() => {
    return new Fuse<OptionType>(
      options.map(option => {
        const extendedOption: ExtendedOption = {
          ...option,
          // Additional fields for searching
          unmaskedPortionOfVIN: (option.vin || '').replace(/\*/g, ''),
          commercialAutoVehicleTypeDisplay:
            CommercialAutoVehicleTypeDisplay[
              option.commercialAutoVehicleType as keyof typeof CommercialAutoVehicleTypeDisplay
            ] ?? option.commercialAutoVehicleType,
          ymm: [option.year, option.make, option.model]
            .filter(Boolean)
            .join(' '),
        };

        return extendedOption;
      }),
      {
        keys: [
          'make',
          'model',
          'year',
          'unmaskedPortionOfVIN',
          'licensePlateStateCode',
          'licensePlate',
          'commercialAutoVehicleTypeDisplay', // Added in the map function above
          'ymm', // Added in the map function above
        ],
        includeScore: true,
        minMatchCharLength: 1,
        threshold: 0.4,
      },
    );
  }, [options]);

  // noinspection UnnecessaryLocalVariableJS
  const searchResults = useMemo(() => {
    const query = searchTerm.trim();

    if (!query) return options;

    const isLikelyVINSearch = query.length > 11 && !query.trim().includes(' ');

    const matches = fuse.search(
      isLikelyVINSearch
        ? // VINs are masked on the server and only include the last 5 characters
          // As a result we only query the last 5 characters for VIN searches
          query.slice(-5)
        : query,
    );

    // noinspection UnnecessaryLocalVariableJS
    const matchedOptions = matches.map(match => match.item);

    return matchedOptions;
  }, [fuse, options, searchTerm]);

  return searchResults;
};
