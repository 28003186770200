import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import PhoneNumberInput from 'react-phone-number-input/input';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { twMerge } from 'tailwind-merge';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useAbTestingVariant } from '../../hooks/useAbTestingVariant';
import {
  booleanToFeetOrYards,
  convertFeetYards,
  extractFeetYardsValue,
  extractUnit,
  units,
} from '../../lib/unitHelpers';
import { IconFlatRenderer } from '../Icon/IconFlatRenderer';
import { Toggle } from '../Toggle';
import {
  CustomTokenComponent,
  TextTokenExtractor,
  TokenExtractorConfig,
} from './TextTokenExtractor';

import type { StringMode } from '@assured/step-renderer';
type HTMLInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type HTMLTextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export type HTMLProps =
  | HTMLInputProps
  | HTMLTextAreaProps
  | (MaskedInputProps & { ref: undefined });

export type InputProps<T extends HTMLProps> = Omit<T, 'onChange'> & {
  /**
   * An optional overriding `className` for the `input` element
   */
  classNameInput?: string;

  /**
   * An optional overriding `className` for the `label` text element
   */
  classNameLabelText?: string;

  /**
   * The type of input to render. Defaults to "text"
   */
  type?: HTMLInputProps['type'] | StringMode;

  /**
   * A string displayed above the main component
   */
  label?: React.ReactNode;

  /**
   * A string displayed in small text beneath the main component
   */
  hint?: React.ReactNode;

  /**
   * Content displayed inside the main component, before the user-provided input
   */
  header?: React.ReactNode;

  /**
   * Content displayed inside the main component, after the user-provided input
   */
  trailer?: React.ReactNode;

  /**
   * Whether or not the component is in an error state
   */
  hasError?: boolean;

  /** The units to use for distance inputs */
  preferredUnits?: units | null;

  /**
   * The index of the item in a list of inputs
   */
  listIndex?: number;

  /**
   * Optional dataTestId, if not provided the id of the component is used
   */
  dataTestId?: string;

  /**
   * A callback function invoked when the user changes the value of the input
   */
  onChange?(v: string): void;

  /**
   * A callback function invoked when the user submits (usually by pressing enter)
   */
  onSubmit?(): void;

  /**
   * When type="token_extractor", configuration for the token extractor to use
   * when extracting tokens.
   */
  tokenExtractorConfig?: TokenExtractorConfig<string>;

  /**
   * When type="token_extractor", a custom TokenComponent to use.
   */
  TokenExtractorCustomTokenComponent?: CustomTokenComponent;
};

const DEFAULT_COUNTRY = 'US';

const disableUpAndDownArrows = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault();
  }
};

const InputTypeDispatch = (props: InputProps<HTMLProps>) => {
  const { dataTestId, preferredUnits, ...inputProps } = props;

  const enableInvalidVinWithWarnings = useAbTestingVariant(
    'eng-9475-enable-invalid-vin-with-warnings',
    false,
  );

  switch (props.type) {
    case 'paragraph':
      return (
        <textarea
          data-testid={props.dataTestId}
          {...(inputProps as InputProps<HTMLTextAreaProps>)}
          onChange={e => props.onChange?.(e.target.value)}
        />
      );
    case 'currency':
      return (
        <MaskedInput
          data-testid={props.dataTestId}
          mask={createNumberMask({
            prefix: '$',
            suffix: '',
            allowLeadingZeroes: true,
          })}
          onKeyDown={disableUpAndDownArrows}
          placeholder="$0"
          {...(inputProps as InputProps<HTMLInputProps>)}
          type="tel"
          ref={undefined}
          className={classNames(props.className, 'text-center')}
          onChange={e =>
            props.onChange?.(e.target.value.replace(/[^0-9.]/g, ''))
          }
        />
      );
    case 'mph_number': {
      return (
        <div>
          <input
            {...(inputProps as InputProps<HTMLInputProps>)}
            placeholder="0"
            data-enable-shortcuts="enter,meta+enter,meta+k"
            type="number"
            className={classNames(
              'py-4 pl-[18px] border-none text-left text-lg font-normal text-gray-600 bg-white focus:outline-none disabled:bg-cool-gray-100 [&:has(:disabled)]:bg-cool-gray-100 disabled:cursor-not-allowed [&:has(:disabled)]:cursor-not-allowed [&::-webkit-inner-spin-button]:[-webkit-appearance:none] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-outer-spin-button]:[-webkit-appearance:none] [&::-webkit-outer-spin-button]:m-0',
              parseInt(inputProps.value?.toString() ?? '', 10) > 99
                ? 'w-[52px]'
                : 'w-[44px]',
            )}
            onChange={e => {
              const { value } = e.target;
              const num = parseInt(value, 10);
              if (num > 999) {
                return;
              }

              props.onChange?.(value);
            }}
          />
          <span className="pr-[18px] pl-[8px]">MPH</span>
        </div>
      );
    }
    case 'distance_number': {
      const numberValue =
        !!props?.value && ['string', 'number'].includes(typeof props.value)
          ? extractFeetYardsValue(props?.value.toString())
          : undefined;

      return (
        <div>
          <input
            {...(inputProps as InputProps<HTMLInputProps>)}
            placeholder="0"
            data-enable-shortcuts="enter,meta+enter,meta+k"
            type="number"
            value={numberValue}
            className="py-4 pl-[18px] border-none text-left text-lg font-normal text-gray-600 bg-white focus:outline-none disabled:bg-cool-gray-100 [&:has(:disabled)]:bg-cool-gray-100 disabled:cursor-not-allowed [&:has(:disabled)]:cursor-not-allowed [&::-webkit-inner-spin-button]:[-webkit-appearance:none] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-outer-spin-button]:[-webkit-appearance:none] [&::-webkit-outer-spin-button]:m-0"
            onChange={e => {
              const { value } = e.target;

              props.onChange?.(`${value} ${preferredUnits}`);
            }}
          />
        </div>
      );
    }
    case 'number':
    case 'small_number':
    case 'percentage':
      return (
        <MaskedInput
          data-testid={props.dataTestId}
          mask={createNumberMask({
            prefix: '',
            suffix: props.type === 'percentage' ? '%' : undefined,
          })}
          onKeyDown={disableUpAndDownArrows}
          placeholder={props.type === 'percentage' ? '0%' : '0'}
          {...(inputProps as InputProps<HTMLInputProps>)}
          type="tel"
          ref={undefined}
          className={classNames(props.className, 'text-center')}
          onChange={e => {
            const strValue = e.target.value.replace(/[^0-9]/g, '');
            if (props.type === 'small_number') {
              if (parseInt(e.currentTarget.value) > 99) {
                e.preventDefault();
                e.currentTarget.value = '99';
              }
            }
            props.onChange?.(strValue);
          }}
        />
      );
    case 'postal_code':
      return (
        <input
          type="number"
          {...(inputProps as InputProps<HTMLInputProps>)}
          onChange={e =>
            props.onChange?.(e.target.value.replace(/\D/g, '').slice(0, 5))
          }
          data-testid={props.dataTestId}
        />
      );
    case 'tel':
    case 'phone_number':
      const { ref, value, onChange, dataTestId, ...rest } = props;
      const val = (value as string) || '';
      return (
        <PhoneNumberInput
          onChange={v => onChange?.(v || '')}
          value={val}
          defaultCountry={DEFAULT_COUNTRY}
          displayInitialValueAsLocalNumber
          data-testid={dataTestId}
          // Because this component seems to either maintain it's own state or
          // is not a 'connected' component, intercepting the onchange or the value
          // prop does not stop you from entering more that a valid number of chars.
          // The component formats the phone number so entering 512 shows (512) which is 5
          // chars. So we must use this technique in the maxlength prop. This will probably not
          // work for international phone numbers.  Most are 10 didgets long, but country
          // prefix's differ. For US its +1 so (345)678-9012 = +13456789012 (12 didgets)
          // where as GB is +44 so (456)789-0123 = +444567890123 (13 didgets)
          maxLength={val.length >= 12 ? 12 : undefined}
          {...rest}
        />
      );
    case 'insurance_claim_number':
    case 'insurance_policy_number':
    case 'driver_license_number':
      return (
        <input
          {...(inputProps as InputProps<HTMLInputProps>)}
          data-testid={props.dataTestId}
          onChange={e => props.onChange?.(e.target.value.toUpperCase())}
        />
      );
    case 'token_extractor':
      const { tokenExtractorConfig, TokenExtractorCustomTokenComponent } =
        props;
      if (!tokenExtractorConfig) {
        throw new Error(
          'Text component with type="token_extractor" requires tokenExtractorConfig',
        );
      }
      return (
        <TextTokenExtractor
          data-testid={props.dataTestId}
          className={props.className}
          placeholder={props.placeholder}
          data-enable-shortcuts={
            'data-enable-shortcuts' in props
              ? (props['data-enable-shortcuts'] as string)
              : undefined
          }
          config={tokenExtractorConfig}
          value={
            typeof props.value === 'string'
              ? JSON.parse(props.value)
              : { text: '', matches: [] }
          }
          onChange={value => props?.onChange?.(JSON.stringify(value))}
          {...(TokenExtractorCustomTokenComponent
            ? {
                components: {
                  CustomTokenComponent: TokenExtractorCustomTokenComponent,
                },
              }
            : {})}
        />
      );
    case 'vin':
      return (
        <input
          {...(inputProps as InputProps<HTMLInputProps>)}
          data-testid={props.dataTestId}
          onChange={e => {
            const candidate = e.target.value.toUpperCase();

            if (enableInvalidVinWithWarnings) {
              props.onChange?.(candidate);
            } else {
              // Allow only A-Z (excluding I, O, Q) and 0-9
              if (/^[A-HJ-NPR-Z0-9]*$/.test(candidate)) {
                props.onChange?.(candidate);
              }
            }
          }}
          // VIN must be exactly 17 characters
          maxLength={17}
          placeholder={props.placeholder ?? 'Enter VIN'}
        />
      );
    default:
      return (
        <input
          {...(inputProps as InputProps<HTMLInputProps>)}
          onChange={e => {
            let { value } = e.target;

            // Auto-capitalize name/full_name
            if (
              (props.type === 'name' || props.type === 'full_name') &&
              value.length
            ) {
              value = `${value.charAt(0)?.toUpperCase()}${value.slice(1)}`;
            }

            props.onChange?.(value);
          }}
          data-testid={props.dataTestId}
        />
      );
  }
};

/**
 * A general-purpose text input component. It handles formatting for numbers
 * (including percentages and US dollars) but only deals in strings.
 *
 * For fields which are actual numbers (number, small_number, currency, percentage)
 * you'll want a component that deals in actual numbers: see [`Number`](./Number.tsx)
 * which wraps this component.
 */
export const Text = <T extends HTMLProps>({
  classNameInput,
  classNameLabelText,
  hasError,
  label,
  hint,
  listIndex,
  header: givenHeader,
  trailer: givenTrailer,
  onSubmit,
  required,
  value,
  ...props
}: InputProps<T>) => {
  const trailer = hasError ? (
    <>
      {givenTrailer}
      <div className="error-icon-wrapper -ml-2">
        <IconFlatRenderer
          width={18}
          height={18}
          iconKey="ICON_FLAT_EXCLAMATION_CIRCLE"
          color="rgb(224 36 36)"
        />
      </div>
    </>
  ) : (
    givenTrailer
  );

  const header =
    typeof listIndex === 'number' ? (
      <>
        {listIndex + 1}. {givenHeader}
      </>
    ) : (
      givenHeader
    );

  const [preferredUnits, setPreferredUnits] = useState<units | null>('feet');

  useEffect(() => {
    if (
      !(value && ['string', 'number'].includes(typeof value)) ||
      props?.type !== 'distance_number'
    ) {
      return;
    }

    setPreferredUnits(extractUnit(value as units));
  }, [value]);

  const onUnitToggleChange = useCallback(
    (v: boolean) => {
      if (!preferredUnits) return;

      const previousUnits = preferredUnits;
      const newUnits = booleanToFeetOrYards(v);
      setPreferredUnits(v ? 'yards' : 'feet');

      if (typeof value !== 'string') {
        return;
      }

      const adjustedValue = convertFeetYards(value, previousUnits, newUnits);
      const externalValue = `${adjustedValue} ${newUnits}`;

      props.onChange?.(externalValue);
    },
    [preferredUnits, value, props.onChange],
  );

  return (
    <label htmlFor={props.id} className="flex flex-col gap-1">
      {label ? (
        <span
          className={twMerge(
            'mb-3 text-lg font-medium text-cool-gray-500',
            classNameLabelText,
          )}
        >
          {label}
          {required ? <span className="text-red-500">*</span> : null}
        </span>
      ) : null}
      <div
        {...(hasError ? { 'data-error': true } : {})} // used for selector specificity
        className={twMerge(
          classNames(
            'overflow-hidden m-[1px] shadow-lightgray rounded-lg border border-cool-gray-300 flex items-center text-gray-400 bg-white',
            '[&:has(input:disabled)]:cursor-not-allowed [&:has(input:disabled)]:bg-[#f1f5f9] [&:has(input:disabled)]:shadow-none [&:has(input:disabled):hover]:shadow-none [&:has(textarea:disabled)]:cursor-not-allowed [&:has(textarea:disabled)]:bg-[#f1f5f9] [&:has(textarea:disabled)]:shadow-none [&:has(textarea:disabled):hover]:shadow-none',
            '[&[data-error]]:border-red-600 [&[data-error]]:border-2 [&[data-error]]:m-0 [&[data-error]]:shadow-red [&[data-error]]:text-gray-600',
            '[&:has(:focus)]:text-gray-600 [&:has(:focus)]:shadow-lightindigo hover:shadow-lightindigo [&:has(:focus)]:border-indigo-600',
            {
              'gap-[10px] pl-4': !!header && props.type !== 'small_number',
              '[&:has(:focus)]:outline [&:has(:focus)]:outline-indigo-600':
                !hasError,
              'w-full pr-4': props.type !== 'small_number',
            },
          ),
          props.className,
        )}
      >
        {header}
        <InputTypeDispatch
          data-error={hasError}
          data-enable-shortcuts={
            typeof listIndex === 'number'
              ? 'enter,shift+enter,meta+enter,meta+k'
              : 'enter,meta+enter,meta+k'
          }
          {...(props as HTMLProps)}
          onChange={v => {
            props.onChange?.(v);
          }}
          value={value}
          onSubmit={onSubmit}
          preferredUnits={preferredUnits}
          className={twMerge(
            classNames(
              'py-4 pr-[18px]',
              header && props.type !== 'small_number' ? 'pl-0' : 'pl-[18px]',
              'border-none w-full text-lg font-normal text-gray-600 bg-white focus:outline-none disabled:bg-cool-gray-100 [&:has(:disabled)]:bg-cool-gray-100 disabled:cursor-not-allowed [&:has(:disabled)]:cursor-not-allowed [&::-webkit-inner-spin-button]:[-webkit-appearance:none] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-outer-spin-button]:[-webkit-appearance:none] [&::-webkit-outer-spin-button]:m-0',
              {
                'text-center':
                  props.type === 'number' || props.type === 'small_number',
              },
            ),
            classNameInput,
          )}
          aria-label={props['aria-label']}
        />
        {trailer}
      </div>
      {hint ? (
        <span
          className={classNames('text-xs text-gray-400', {
            'text-red-600': hasError,
          })}
        >
          {hint}
        </span>
      ) : null}

      {props?.type === 'distance_number' && (
        <Toggle
          variant="yesno"
          yesLabel="Yards"
          noLabel="Feet"
          value={preferredUnits === 'yards'}
          onChange={onUnitToggleChange}
          size="large"
          className="mt-2"
        />
      )}
    </label>
  );
};
