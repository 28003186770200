import classNames from 'classnames';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';

import type { IconKey } from '../Icon/IconFlatRenderer';

export type TaskCardProps = ComponentProps<'div'> & {
  /** A `className` that will override via `tailwind-merge` */
  classNameStatusIcon?: string;

  /** Set to `true` if content is compact and applies different styling */
  isCompact?: boolean;

  /** An `IconKey` to represent the task status */
  statusIconKey?: IconKey;
};

/**
 * A React component to render task status, data, and actions
 */
export const TaskCard = ({
  children,
  className,
  classNameStatusIcon,
  isCompact,
  statusIconKey,
}: TaskCardProps) => {
  return (
    <div
      className={twMerge(
        classNames(
          'flex flex-col gap-4 border border-gray-200 rounded-lg text-cool-gray-700 relative',
          !isCompact ? 'p-4' : 'p-1 pl-7',
        ),
        className,
      )}
    >
      {statusIconKey && (
        <IconFlatRenderer
          className={classNames(
            isCompact && 'absolute top-2 left-1',
            classNameStatusIcon,
          )}
          iconKey={statusIconKey}
          width={16}
          height={16}
        />
      )}
      {children}
    </div>
  );
};
