import { useEffect, useRef } from 'react';

/**
 * A React Hook to return the value of a variable from previous render.
 * This takes it a step further than solutions like
 * {@link https://github.com/uidotdev/usehooks/blob/main/index.js#L1017C1-L1027C2 | useHooks `usePrevious`}
 * in that a set state is not triggered, but instead leverages a combination
 * of `useEffect` from every render and a React `ref`.
 */
export function usePrevious<Type>(value?: Type) {
  const previous = useRef<Type | undefined>();
  useEffect(() => {
    previous.current = value;
  });
  return previous.current;
}
