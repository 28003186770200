import { useRef, useState } from 'react';

import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip/Tooltip';

export interface CopyTextProps {
  textToCopy: string;
}

export function CopyText({ textToCopy }: CopyTextProps) {
  const [copied, setCopied] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const mouseEnterTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const copyText = async (text: string | undefined) => {
    try {
      await navigator.clipboard.writeText(text || '');
      setCopied(true);
      setTooltipOpen(true);

      // Close tooltip and reset state after 5 seconds
      setTimeout(() => {
        setCopied(false);
        setTooltipOpen(false);
      }, 5000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleMouseEnter = () => {
    if (mouseEnterTimerRef.current) {
      clearTimeout(mouseEnterTimerRef.current);
      mouseEnterTimerRef.current = null;
    }
    mouseEnterTimerRef.current = setTimeout(() => {
      setTooltipOpen(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    // Cancel the tooltip-open timer if the mouse leaves quickly
    if (mouseEnterTimerRef.current) {
      clearTimeout(mouseEnterTimerRef.current);
      mouseEnterTimerRef.current = null;
    }
    setTooltipOpen(false);
    setCopied(false);
  };

  return (
    <Tooltip placement="top" open={tooltipOpen}>
      <TooltipTrigger>
        <button
          type="button"
          className="cursor-pointer hover:bg-gray-50 active:bg-blue-50 relative transition 
            before:absolute before:-top-1 before:-bottom-1 before:-left-2 before:-right-2 before:content-[''] 
            before:rounded-md before:bg-transparent 
            hover:before:bg-gray-50 active:before:bg-blue-50"
          onClick={() => copyText(textToCopy)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className="relative z-10">{textToCopy}</span>
        </button>
      </TooltipTrigger>
      <TooltipContent
        className={`z-[999999] text-sm font-medium rounded-md m-0 py-1 px-2 ${
          copied ? 'bg-blue-100 !text-blue-500' : 'bg-black text-white'
        }`}
      >
        {copied ? 'Copied to clipboard' : 'Copy to clipboard'}
      </TooltipContent>
    </Tooltip>
  );
}
