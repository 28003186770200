import { SparkChartContainer } from './SparkChartContainer';
import { SparkChartBaseType } from './sparkChartTypes';

import type { ChartContainerProps } from './ChartContainer';

/**
 * A component to render a bar chart and its container layout.
 */
export const SimpleStatCard = ({
  chartHeight = 69,
  chartTitle,
  children,
  isLoading,
  chartSubTitle,
  maxWidth,
}: ChartContainerProps & SparkChartBaseType) => {
  return (
    <SparkChartContainer
      chartHeight={chartHeight}
      isEmpty={!isLoading && !chartTitle}
      isLoading={isLoading}
      chartTitle={chartTitle}
      chartSubTitle={chartSubTitle}
      maxWidth={maxWidth}
    >
      {children}
    </SparkChartContainer>
  );
};
