import { usAutocompletePro } from 'smartystreets-javascript-sdk';

import { gql, useApolloClient } from '@apollo/client';
import { LocationFragment } from '@assured/shared-types/Location';

import { LocationOption } from './types';

export async function smartyLocationsRequest({
  gqlClient,
  prefix,
  selectedParent,
  searchBias,
}: {
  gqlClient: ReturnType<typeof useApolloClient>;
  prefix: string;
  selectedParent?: LocationOption;
  searchBias?: LocationFragment;
}): Promise<usAutocompletePro.Suggestion[]> {
  const { data: results } = await gqlClient.query({
    query: gql`
      query AutocompleteLocations(
        $prefix: String!
        $searchBias: LocationFragmentInput
        $selected: String
      ) {
        autocompleteLocation(
          prefix: $prefix
          searchBias: $searchBias
          selected: $selected
        ) {
          streetLine
          secondary
          city
          state
          zipcode
          entries
        }
      }
    `,
    variables: {
      prefix,
      searchBias,
      ...(selectedParent
        ? {
            // Using Smarty's secondary expansion feature to search for line2 options: https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#pro-secondary-expansion
            // Selected address text format: street_line secondary (entries) city state zipcode
            selected: `${selectedParent.line1} ${selectedParent.line2} (${selectedParent.entries}) ${selectedParent.city} ${selectedParent.state} ${selectedParent.postalCode}`,
          }
        : {}),
    },
  });

  return results.autocompleteLocation;
}
