import { ReactNode } from 'react';

import { BarChart as TremorBarChart } from '@tremor/react';

import { ChartContainer } from './ChartContainer';

import type { ChartContainerProps } from './ChartContainer';

import type { BarChartProps as TremorBarChartProps } from '@tremor/react';

interface BarChartProps {
  /** If set, will render a child node as the first child */
  children?: ReactNode;
}

/**
 * A component to render a bar chart and its container layout.
 */
export const BarChart = ({
  chartHeight = 224,
  chartLabelTheme,
  chartTitle,
  children,
  isLoading,
  labels,
  ...props
}: TremorBarChartProps & ChartContainerProps & BarChartProps) => (
  <ChartContainer
    chartHeight={chartHeight}
    chartLabelTheme={chartLabelTheme}
    chartTitle={chartTitle}
    isEmpty={!isLoading && (!props?.data || props?.data.length <= 0)}
    isLoading={isLoading}
    labels={labels}
  >
    {children}
    <TremorBarChart {...props} />
  </ChartContainer>
);
