import classNames from 'classnames';
import { Suspense } from 'react';

import { AccessibleHiddenText } from '../Accessibility/AccessibleHiddenText';
import { IconFlatRendererLazy } from '../Icon/IconFlatRendererLazy';

/**
 * A React component to render either a "next" or "previous" designed button
 */
export const NextPreviousButton = ({
  isDisabled,
  isNext,
  onClick,
}: {
  isDisabled?: boolean;
  isNext?: boolean;
  onClick?: () => void;
}) => (
  <button
    className="flex items-center justify-center rounded-full w-16 h-16 bg-cool-gray-800 border-[1.6px] border-cool-gray-600 shadow-[0_1.6px_3.2px_0_rgba(0,0,0,0.06),0_1.6px_4.8px_0_rgba(0,0,0,0.1)] hover:bg-cool-gray-600 hover:border-cool-gray-600 disabled:bg-cool-gray-700"
    disabled={isDisabled}
    onClick={() => {
      if (!isDisabled && onClick) {
        onClick();
      }
    }}
    type="button"
  >
    <AccessibleHiddenText>{!isNext ? 'previous' : 'next'}</AccessibleHiddenText>
    <Suspense fallback="">
      <IconFlatRendererLazy
        className={classNames({
          '-rotate-90': isNext,
          'rotate-90': !isNext,
          '[&_*]:fill-white': !isDisabled,
          '[&_*]:fill-cool-gray-500': isDisabled,
        })}
        iconKey="ICON_FLAT_DOWNWARDS_ARROW"
        width={36}
        height={36}
      />
    </Suspense>
  </button>
);
