/**
 * A function to conditionally format a number to a compact string.
 * For example the input `11200` would be converted to `11.2K`
 */
export const formatNumberCompact = (
  value: number,
  options?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat('us', {
    // below is a somewhat arbitrary / specific determination when
    // to use `compact`. it's really about number of characters
    notation: value < 9999 && value > -999 ? undefined : 'compact',
    maximumFractionDigits: 1,
    ...options,
  })
    .format(value)
    .toString();

/**
 * A function with opinionated formatting as "compact" and a
 * "%" suffix sub-string.
 */
export const formatNumberCompactPercentSuffix = (
  value: number,
  options?: Intl.NumberFormatOptions,
) => `${formatNumberCompact(value, options)}%`;
