import { LineChart as TremorLineChart } from '@tremor/react';

import { ChartContainer } from './ChartContainer';

import type { ChartContainerProps } from './ChartContainer';

import type { LineChartProps } from '@tremor/react';

/**
 * A component to render a line chart and its container layout.
 */
export const LineChart = ({
  chartHeight = 224,
  chartLabelTheme,
  chartTitle,
  isLoading,
  labels,
  ...props
}: LineChartProps & ChartContainerProps) => (
  <ChartContainer
    chartHeight={chartHeight}
    chartLabelTheme={chartLabelTheme}
    chartTitle={chartTitle}
    isEmpty={!isLoading && (!props?.data || props?.data.length <= 0)}
    isLoading={isLoading}
    labels={labels}
  >
    <TremorLineChart {...props} />
  </ChartContainer>
);
