import { InboxFilterMenu, InboxFilterTypeEnum } from './InboxFilterMenu';
import { InboxMainMenu } from './InboxMainMenu';

/**
 * A React component to render a an inbox header
 */
export const InboxHeader = ({
  archiveAll,
  isLoadingFilteredItems,
  setFilterType,
  markAllAsRead,
}: {
  archiveAll: () => void;
  isLoadingFilteredItems?: boolean;
  setFilterType: (filterType: InboxFilterTypeEnum) => void;
  markAllAsRead: () => void;
}) => {
  return (
    <header className="px-6 py-[10px] border-b border-b-cool-gray-200 flex items-center justify-between gap-2 w-full relative">
      <span className="flex items-center justify-start gap-2">
        <h3 className="font-medium text-cool-gray-700 text-xl leading-6">
          Inbox
        </h3>
        <InboxFilterMenu
          className="top-12"
          isLoadingFilteredItems={isLoadingFilteredItems}
          onFilter={setFilterType}
        />
      </span>
      <InboxMainMenu
        archiveAll={archiveAll}
        className="top-12 right-6 w-[265px]"
        markAllAsRead={markAllAsRead}
      />
    </header>
  );
};
