/// <reference types="@types/google.maps" />

import classNames from 'classnames';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import debounce from 'lodash.debounce';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import { usAutocompletePro } from 'smartystreets-javascript-sdk';

import { gql, useApolloClient } from '@apollo/client';
import {
  LocationFragment,
  LocationType,
} from '@assured/shared-types/Claim/LocationType';
import { locationFragmentTypeSchema } from '@assured/shared-types/Location/Location';
import { Coordinate } from '@assured/step-renderer/types/step-components/additional';
import { LocationCollectionMode } from '@assured/step-renderer/types/step-components/Location';
import { useLoadScript } from '@react-google-maps/api';
import { captureException } from '@sentry/browser';

import { HighlightedText } from '../../HighlightedText';
import { IconFlatAddressBook } from '../../Icon/IconFlatAddressBook';
import { SelectDropdown, SelectOption } from '../../SelectDropdown';
import { Spinner } from '../../Spinner';
import { Toggle } from '../../Toggle';
import { Text } from '../Text';
import { usePlacesQuery } from '../usePlacesQuery';

import type { LDClient } from 'launchdarkly-react-client-sdk';

interface LocationOnSubmitValue extends Partial<Coordinate> {
  businessGooglePlaceId?: string;
  addressText?: string | null;
  apartmentNumber?: string | null;
}

export interface LocationValue extends LocationOnSubmitValue {
  collectionMode?: Coordinate | string | null;
}

interface LocationProps {
  field: string;
  disabled?: boolean;
  label?: string;
  error?: string;
  updateValue: (field: string, data: any) => void;
  placeholder?: string;
  required?: boolean;
  primaryValue?: LocationValue | null;
  noApartmentEntry?: boolean;
  autoFocus?: boolean;
  addressBookEntries?: (LocationType & { label: string })[];
  disableUserLocationBias?: boolean;
  searchBias?: Coordinate | LocationFragment;
  placeTypes?: string[];
  mode?: LocationCollectionMode;
}

const libraries = ['places' as const];

export const LocationGoogleMaps = (
  props: LocationProps & {
    currentQuery?: string;
    setCurrentQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
  },
) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
  });

  if (!isLoaded) {
    return <Spinner />;
  }

  if (loadError) {
    console.error(
      'Error loading Google Maps API (will render anyway 🤞)',
      loadError,
    );
  }

  return <LocationGoogleMapsInner {...props} />;
};

export const LocationGoogleMapsInner = ({
  disabled,
  error,
  field,
  label,
  placeholder = 'Search for a location',
  required,
  updateValue,
  primaryValue,
  noApartmentEntry = true,
  autoFocus,
  addressBookEntries,
  searchBias,
  placeTypes,
  mode,
  currentQuery,
  setCurrentQuery,
}: LocationProps & {
  currentQuery?: string;
  setCurrentQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const [locations, setLocations] = useState<
    | { label: string; value: google.maps.places.AutocompletePrediction }[]
    | []
    | null
  >(null);

  const [query, setQuery] = useState<string>();
  const [isApartment, setIsApartment] = useState<boolean>(false);
  const [apartmentNumber, setApartmentNumber] = useState('');

  useEffect(() => {
    if (!query && currentQuery) {
      setQuery(currentQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only run on component load

  useEffect(() => {
    if (query && currentQuery && query !== currentQuery) {
      setCurrentQuery(undefined);
    }
  }, [query, currentQuery, setCurrentQuery]);

  const selectDropdownRef = useRef<any | null>(null); // FIXME: Any type
  useEffect(() => {
    if (autoFocus || currentQuery) {
      setTimeout(() => selectDropdownRef?.current?.focus(), 0); // delay to allow dropdown to populate before opening
    }
  }, [autoFocus, currentQuery]);

  const [defaultOptions, setDefaultOptions] = useState<
    | { label: string; value: google.maps.places.AutocompletePrediction }[]
    | undefined
  >();

  const { predictions: placePredictions, isPlacePredictionsLoading } =
    usePlacesQuery(query, { searchBias, types: placeTypes });

  useEffect(() => {
    if (!placePredictions.length) return;
    if (!query) {
      setLocations([]);
      return;
    }

    setLocations(
      placePredictions.map(p => ({
        label: p.description,
        value: p,
      })),
    );
  }, [JSON.stringify(placePredictions), query]);

  return (
    <>
      <SelectDropdown
        ref={selectDropdownRef}
        value={primaryValue?.addressText || currentQuery}
        options={(locations as SelectOption[]) || defaultOptions || []}
        filterOption={() => true} // options are already filtered from API
        onChange={option => {
          if (option === null) {
            setLocations([]);
            setDefaultOptions([]);
            updateValue(field, null);
            return;
          }
          const prediction =
            option.value as google.maps.places.AutocompletePrediction;

          updateValue(field, {
            ...(mode === LocationCollectionMode.STREET_NAME
              ? {
                  addressText: prediction.structured_formatting.main_text,
                }
              : {
                  businessGooglePlaceId: prediction.place_id,
                  addressText: prediction.description,
                }),
          });
        }}
        onInputChange={(val: string) => {
          if (val === null || val === undefined || val === '') {
            setQuery('');
            return;
          }
          setQuery(val);
        }}
        isLoading={isPlacePredictionsLoading && !!query}
        components={{
          ...(isPlacePredictionsLoading
            ? { NoOptionsMessage: LoadingMessage }
            : {
                NoOptionsMessage: props => (
                  <StartTypingMessage
                    {...props}
                    addressBookEntries={addressBookEntries}
                    onAddressBookSelect={async v => {
                      updateValue(field, v);
                      selectDropdownRef.current?.blur();
                    }}
                  />
                ),
              }),
        }}
        error={error}
        disabled={disabled}
        required={required}
        labelProps={{ labelStr: label }}
        placeholder={placeholder}
        openMenuOnFocus={
          // auto-reveal address book initially if there are entries
          (autoFocus && !primaryValue && !!addressBookEntries?.length) ||
          !!currentQuery
        }
        editableInput
        editableInputIncompleteErrorText="Please select a location to continue."
      />

      {noApartmentEntry === false && (
        <div className="pt-5">
          <div className="flex flex-col gap-1">
            <span className="mb-3 text-lg font-medium text-cool-gray-500">
              Is this an apartment?
            </span>

            <Toggle
              value={isApartment}
              onChange={v => typeof v === 'boolean' && setIsApartment(v)}
            />
          </div>
          {isApartment ? (
            <div className="mx-auto mt-3">
              <Text
                placeholder="Apartment number"
                value={apartmentNumber}
                onChange={val => {
                  setApartmentNumber(val);
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

type Suggestion = usAutocompletePro.Suggestion & LocationOption;

type LocationOption = {
  label: string;
  value: string;
  addressText: string;
  line1: string;
  line2: string;
  apartmentNumber?: string;
  city: string;
  state: string;
  postalCode: string;
  entries: number;
  parent?: LocationOption;
  children: LocationOption[];

  // Used for secondary expansion ("Apt")
  streetLine: string;
  secondary: string;
};

const autocompleteResults: Record<string, Suggestion[]> = {};
const entriesAutocompleteResults: Record<string, Suggestion[]> = {};

const parseAutocompleteResult = (
  result: usAutocompletePro.Suggestion,
  parent?: LocationOption,
): Suggestion => {
  const addressText = result.secondary
    ? `${result.streetLine}, ${result.secondary}, ${result.city}, ${result.state} ${result.zipcode}`
    : `${result.streetLine}, ${result.city}, ${result.state} ${result.zipcode}`;

  return {
    ...result,
    label: addressText,
    value: addressText,
    addressText,
    line1: result.streetLine,
    line2: result.secondary,
    apartmentNumber:
      result.secondary.split(' ').slice(1).join(' ').replace('#', '') ||
      undefined,
    postalCode: result.zipcode,
    parent,
    children: [],
  };
};

export const LocationSmarty = ({
  disabled,
  error,
  field,
  label,
  placeholder = 'Search for a location',
  required,
  updateValue,
  primaryValue,
  autoFocus,
  addressBookEntries,
  searchBias,
  setSwitchToGoogle,
  setCurrentQuery,
}: LocationProps & {
  setSwitchToGoogle: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setCurrentQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const [locations, setLocations] = useState<LocationOption[] | null>(null);
  const [query, setQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearchingChildren, setIsSearchingChildren] =
    useState<boolean>(false);
  const selectDropdownRef = useRef<any | null>(null); // FIXME: Any type
  const [defaultOptions, setDefaultOptions] = useState<
    LocationOption[] | undefined
  >();
  const apolloClient = useApolloClient();

  const getSuggestions = useMemo(() => {
    return debounce(async (prefix: string, selectedParent?: LocationOption) => {
      setIsSearchingChildren(!!selectedParent);

      if (selectedParent) {
        const children = entriesAutocompleteResults[selectedParent.addressText];
        if (children?.length) {
          setLocations(children);
          return;
        }

        try {
          setIsLoading(true);
          const results = await smartyGQLRequest(
            apolloClient,
            prefix,
            selectedParent,
            searchBias,
          );
          if (selectedParent) {
            entriesAutocompleteResults[selectedParent.addressText] = results;
          }
          setLocations(results);
        } catch (err) {
          console.error(err);
          setCurrentQuery(prefix);
          setSwitchToGoogle(true);
        }

        setIsLoading(false);
        return;
      }

      const cachedResults = autocompleteResults[prefix];
      setQuery(prefix);

      if (cachedResults) {
        setLocations(cachedResults);
        return;
      }

      try {
        setIsLoading(true);
        const results = await smartyGQLRequest(
          apolloClient,
          prefix,
          selectedParent,
          searchBias,
        );
        autocompleteResults[prefix] = results;
        if (prefix.length >= 5 && !results.length) {
          setCurrentQuery(prefix);
          setSwitchToGoogle(true);
        } else {
          setLocations(results);
        }
      } catch (err) {
        console.error(err);
        setCurrentQuery(prefix);
        setSwitchToGoogle(true);
      }

      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <SelectDropdown
      ref={selectDropdownRef}
      value={primaryValue?.addressText}
      options={locations || defaultOptions || []}
      filterOption={(o, inputValue) => {
        if (isSearchingChildren) {
          return o.label.indexOf(inputValue) > -1;
        }
        return true;
      }}
      onChange={async option => {
        if (option === null) {
          setLocations([]);
          setDefaultOptions([]);
          updateValue(field, null);
          return;
        }

        const o = option as LocationOption;

        if (o.entries && !o.parent) {
          // This is a parent address, so we don't select it directly
          await getSuggestions(query, o);
          selectDropdownRef.current?.onInputChange(
            `${o.streetLine}, ${o.secondary}`,
            { action: 'set-value' },
          );
          selectDropdownRef.current?.onMenuOpen();
          return;
        }
        const { parent, children, ...value } = o;
        setIsSearchingChildren(false);
        setLocations([]);
        setDefaultOptions([]);
        updateValue(field, locationFragmentTypeSchema.parse(value));
        selectDropdownRef.current?.blur();
      }}
      onInputChange={(val, action) => {
        if (val === null || val === undefined || val === '') {
          return;
        }

        if (action.action === 'set-value') {
          return;
        }

        if (!isSearchingChildren) {
          getSuggestions(val);
        }
      }}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        Option: ((props: OptionProps<LocationOption>) => {
          const { inputValue } = props?.selectProps || '';

          return (
            <>
              <components.Option
                {...props}
                selectOption={option => {
                  if (props.data.entries && !props.data.parent) {
                    getSuggestions(query);
                    return;
                  }
                  props.selectOption(option);
                }}
              >
                <div className="flex flex-row justify-between items-center cursor-pointer">
                  <HighlightedText
                    classNameHighlighted="font-bold text-indigo-bright-600"
                    input={props?.data?.label}
                    term={inputValue}
                  />
                  {props.data.entries && !props.data.parent ? (
                    <span className="text-sm font-bold text-indigo-bright-600">
                      + {props.data.entries} address
                      {props.data.entries > 1 ? 'es' : ''} &#8250;
                    </span>
                  ) : null}
                </div>
              </components.Option>
              <div className="bg-cool-gray-200 h-[1px] w-full my-1 last:hidden" />
            </>
          );
        }) as FC<OptionProps<unknown, boolean, GroupBase<unknown>>>,
        ...(isLoading
          ? { NoOptionsMessage: LoadingMessage }
          : {
              // eslint-disable-next-line react/no-unstable-nested-components
              NoOptionsMessage: props => (
                <StartTypingMessage
                  {...props}
                  addressBookEntries={addressBookEntries}
                  onAddressBookSelect={async v => {
                    updateValue(field, v);
                    selectDropdownRef.current?.blur();
                  }}
                />
              ),
            }),
      }}
      error={error}
      disabled={disabled}
      required={required}
      labelProps={{ labelStr: label }}
      placeholder={placeholder}
      openMenuOnFocus={
        // auto-reveal address book initially if there are entries
        autoFocus && !primaryValue && !!addressBookEntries?.length
      }
      editableInput
      editableInputIncompleteErrorText="Please select a location to continue."
    />
  );
};

const StartTypingMessage = (
  props: Parameters<typeof components.NoOptionsMessage>[0] & {
    addressBookEntries?: (LocationType & { label: string })[];
    onAddressBookSelect: (v: LocationType & { label: string }) => void;
  },
) => {
  const [focusedOption, setFocusedOption] = useState<number>(0);
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        setFocusedOption(prev => {
          if (prev >= (props.addressBookEntries?.length ?? 0) - 1) return 0;
          return prev + 1;
        });
      } else if (e.key === 'ArrowUp') {
        setFocusedOption(prev => {
          if (prev <= 0) return (props.addressBookEntries?.length ?? 0) - 1;
          return prev - 1;
        });
      } else if (e.key === 'Enter') {
        const selected = props.addressBookEntries?.[focusedOption];
        if (selected) {
          props.onAddressBookSelect(selected);
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [focusedOption]);

  return (
    <components.NoOptionsMessage {...props}>
      <div {...props.innerProps}>
        {props.addressBookEntries?.length ? (
          <div className="-mx-3 -mb-2">
            <div className="px-2 mt-1 mb-2 flex items-center">
              <IconFlatAddressBook className="mr-2" />
              <span className="text-left flex-1 uppercase text-sm font-medium text-cool-gray-400">
                Address book
              </span>
            </div>
            <div className="flex flex-col">
              {props.addressBookEntries.map((entry, i) => (
                <>
                  <button
                    type="button"
                    className={classNames(
                      'text-cool-gray-600 rounded-md px-2 py-2 text-left',
                      focusedOption === i && 'bg-cool-gray-100/90',
                    )}
                    onMouseEnter={() => setFocusedOption(i)}
                    onClick={() => {
                      props.onAddressBookSelect(entry);
                    }}
                  >
                    <div className="leading-5">{entry.addressText}</div>
                    <div className="text-sm text-cool-gray-400">
                      {entry.label}
                    </div>
                  </button>
                  <div className="bg-cool-gray-200 h-[1px] w-full my-1 last:hidden" />
                </>
              ))}
            </div>
          </div>
        ) : (
          <div>Start typing to search locations...</div>
        )}
      </div>
    </components.NoOptionsMessage>
  );
};

const LoadingMessage = (
  props: Parameters<typeof components.NoOptionsMessage>[0],
) => (
  <components.NoOptionsMessage {...props}>
    <div {...props.innerProps}>Searching locations...</div>
  </components.NoOptionsMessage>
);

const shouldUseSmarty = async (
  ldClient?: LDClient,
  mode?: LocationCollectionMode,
): Promise<boolean> => {
  if (!(mode === LocationCollectionMode.MAILING_ADDRESS)) {
    return false;
  }

  if (ldClient === undefined) {
    captureException(
      new Error('Unexpected undefined LD client in shouldUseSmarty'),
    );
    return false;
  }
  if (!ldClient.variation('enable-smarty-address-input-sk', false)) {
    return false;
  }

  return true;
};

export const Location = (props: LocationProps) => {
  const ldClient = useLDClient();
  const [useSmarty, setUseSmarty] = useState<boolean | undefined>(undefined);
  const [switchToGoogle, setSwitchToGoogle] = useState<boolean | undefined>();
  const [currentQuery, setCurrentQuery] = useState<string | undefined>();
  useEffect(() => {
    if (useSmarty === undefined) {
      const checkSmarty = async () => {
        const useSmartyBoolean = await shouldUseSmarty(ldClient, props.mode);
        setUseSmarty(useSmartyBoolean);
      };
      checkSmarty();
    }
  }, [useSmarty, ldClient, props.mode]);
  // Don't return a component while we're still unsure of the useSmarty value
  if (useSmarty === undefined) return null;
  // otherwise...
  return useSmarty && !switchToGoogle ? (
    <LocationSmarty
      {...props}
      setSwitchToGoogle={setSwitchToGoogle}
      setCurrentQuery={setCurrentQuery}
    />
  ) : (
    <LocationGoogleMaps
      {...props}
      currentQuery={currentQuery}
      setCurrentQuery={setCurrentQuery}
    />
  );
};

async function smartyGQLRequest(
  client: ReturnType<typeof useApolloClient>,
  prefix: string,
  selectedParent?: LocationOption,
  searchBias?: LocationFragment,
) {
  const { data: results } = await client.query({
    query: gql`
      query AutocompleteLocations(
        $prefix: String!
        $searchBias: LocationFragmentInput
        $selected: String
      ) {
        autocompleteLocation(
          prefix: $prefix
          searchBias: $searchBias
          selected: $selected
        ) {
          streetLine
          secondary
          city
          state
          zipcode
          entries
        }
      }
    `,
    variables: {
      prefix,
      searchBias,
      ...(selectedParent
        ? {
            // Using Smarty's secondary expansion feature to search for line2 options: https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#pro-secondary-expansion
            // Selected address text format: street_line secondary (entries) city state zipcode
            selected: `${selectedParent.line1} ${selectedParent.line2} (${selectedParent.entries}) ${selectedParent.city} ${selectedParent.state} ${selectedParent.postalCode}`,
          }
        : {}),
    },
  });

  const parsedLocations = (
    results.autocompleteLocation as usAutocompletePro.Suggestion[]
  ).map(r => parseAutocompleteResult(r, selectedParent));

  return parsedLocations;
}
