/* eslint-disable no-underscore-dangle */
import { FC, useEffect, useState } from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import useDebounce from 'react-use/lib/useDebounce';

import { HealthProviderType } from '@assured/shared-types/HealthProvider';

import { HighlightedText } from '../HighlightedText';
import { SelectDropdown } from '../SelectDropdown/SelectDropdown';
import { useHealthSearch } from './useHealthSearch/useHealthSearch';

interface HealthLocationProps {
  updateValue: (field: string, data: any) => void;
  label?: string;
  field?: string;
  filingLocation?: {
    latitude: number | null;
    longitude: number | null;
  };
  isRibbon?: boolean;
  mode: 'npi_individual' | 'npi_organization' | 'rxterm' | 'npi_hospital';
  existingResult?: any;
  autoFocus?: boolean;
  placeholder?: string;
}

const randomManualItemId = () => {
  return `MANUALLY_ENTERED_${Math.floor(Math.random() * 1000000)}`;
};

const locationLabel = (location: Partial<HealthProviderType>) => {
  return `${location['name.full']} - ${location['addr_practice.full']}, ${location.provider_type}`;
};

type HealthLocationOption = {
  label: string;
  value: string;
  option?: Partial<HealthProviderType>;
};

type QueryResultItem = Partial<HealthProviderType>;

export const HealthLocationCustomOption = ((
  props: OptionProps<HealthLocationOption>,
) => {
  const location = props?.data.option;
  const { inputValue } = props?.selectProps || {};
  if (location) {
    return (
      <components.Option {...props}>
        <>
          <div className="font-medium text-cool-gray-600 rounded-md text-left">
            <HighlightedText
              classNameHighlighted="font-bold text-indigo-bright-600"
              input={location['name.full'] || ''}
              term={inputValue}
            />
          </div>
          <div className="font-medium text-cool-gray-400 rounded-md text-left">
            {location['addr_practice.full'] &&
              `${location['addr_practice.full']}`}
            &nbsp;&middot;&nbsp;
            {location.provider_type}
          </div>
        </>
      </components.Option>
    );
  }
  return (
    <components.Option {...props}>
      <div className="font-medium text-cool-gray-600 rounded-md text-left">
        {props?.data.label}
      </div>
      <div className="font-medium text-cool-gray-400 rounded-md text-left">
        Manually entered
      </div>
    </components.Option>
  );
}) as FC<OptionProps<unknown, boolean, GroupBase<unknown>>>;

export const HealthLocation = ({
  updateValue,
  label,
  field,
  filingLocation,
  isRibbon,
  mode,
  existingResult,
  autoFocus,
  placeholder,
}: HealthLocationProps) => {
  const [healthLocations, setHealthLocations] = useState<
    HealthLocationOption[] | [] | null
  >(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  useDebounce(() => setDebouncedSearchTerm(searchTerm), 100, [searchTerm]);

  const [searchRes, setSearchRes] = useHealthSearch({
    isLoading,
    searchTerm: debouncedSearchTerm,
    setIsLoading,
    mode,
    is_ribbon: isRibbon,
    filing_location: filingLocation,
    showMoreResults: true,
  });

  const updateHealthLocationValue = async (item?: QueryResultItem) => {
    if (item && field) {
      const value = {
        name: item['name.full'],
        npi: item.NPI,
        npiData: item,
      };
      updateValue(field, value);
    } else if (field) {
      updateValue(field, null);
    }
  };

  const [selectedResult, setSelectedResult] = useState<QueryResultItem | null>(
    existingResult
      ? {
          _id: existingResult.npiData?._id,
          ...existingResult.npiData,
        }
      : undefined,
  );

  const selectItem = (item: QueryResultItem) => {
    if (selectedResult?._id !== item._id) {
      setSelectedResult(item);
      updateHealthLocationValue(item);
    }
    setSearchRes(null);
    setSearchTerm('');
    setDebouncedSearchTerm('');
  };

  const deselectItem = () => {
    setSelectedResult(null);
    updateHealthLocationValue();
  };

  useEffect(() => {
    if (!searchRes) return;
    if (!searchTerm) {
      setHealthLocations([]);
      return;
    }
    let locations: HealthLocationOption[] = [];
    const manualEntry = {
      label: searchTerm,
      value: 'MANUALLY_ENTERED',
    };
    if ((searchRes?.results?.length || 0) > 0) {
      const locationResults = searchRes.results.map(p => ({
        label: locationLabel(p),
        value: p._id,
        option: p,
      }));
      locations = locationResults;
    }
    locations.push(manualEntry);
    setHealthLocations(locations);
  }, [searchRes, searchTerm]);

  const existingValue = selectedResult
    ? {
        label:
          selectedResult.NPI === 'MANUALLY_ENTERED'
            ? selectedResult['name.full']
            : locationLabel(selectedResult),
        value: selectedResult._id,
        option: selectedResult,
      }
    : undefined;
  return (
    <SelectDropdown
      autoFocus={autoFocus}
      placeholder={placeholder}
      value={existingValue?.label}
      options={healthLocations || []}
      useDefaultFilter
      labelProps={{ labelStr: label }}
      components={{
        Option: HealthLocationCustomOption,
      }}
      onChange={option => {
        if (option === null) {
          setHealthLocations([]);
          deselectItem();
          return;
        }
        const o = option as HealthLocationOption;
        const { value } = o;
        if (value === 'MANUALLY_ENTERED') {
          const item = {
            _id: randomManualItemId(),
            NPI: 'MANUALLY_ENTERED',
            'name.full': searchTerm,
          };
          selectItem(item);
          return;
        }
        const res = searchRes?.results.find(p => p._id === value);
        if (res) {
          selectItem(res);
        }
      }}
      onInputChange={(val: string) => {
        if (val === null || val === undefined || val === '') {
          setSearchTerm('');
          return;
        }
        setSearchTerm(val);
      }}
    />
  );
};
