import classNames from 'classnames';

import { useKeyboardShortcut } from '@assured/ui/hooks';

import { Button } from '../Button';
import { ConcatenatedChips } from '../Chip';

export interface FormActionsProps {
  /** Text to display in the button */
  buttonText: string;

  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** If `true` button will be disabled and elments will display in a disabled state */
  isDisabled?: boolean;

  /** A click handler */
  onClick: React.MouseEventHandler<HTMLButtonElement>;

  /**
   * If `true` styling will use the primary button and primary flow forward, while
   * if `false` styling will use the secondary button and a secondary action
   */
  isPrimary?: boolean;

  /**
   * If true, will use the tertiary keyboard shortcut "shift+enter".
   */
  isTertiary?: boolean;

  /**
   * Hide skip button.
   */
  hideSkipButton?: boolean;
  /**
   * Hide shortcuts.
   */
  noShortcut?: boolean;
}

export const FormActions = ({
  buttonText,
  className,
  dataTestId = 'form-actions',
  isDisabled,
  isPrimary,
  isTertiary,
  hideSkipButton,
  noShortcut,
  onClick,
}: FormActionsProps & {
  onClick(): void;
}) => {
  const onShortcut = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  const { shortcut, disabled: shortcutDisabled } = useKeyboardShortcut(
    noShortcut
      ? []
      : [isTertiary ? 'shift+enter' : isPrimary ? 'enter' : 'meta+enter'],
    onShortcut,
  );

  return (
    <div
      className={classNames('flex items-center justify-start gap-6', className)}
      data-testid={dataTestId}
    >
      {hideSkipButton ? null : (
        <Button
          variant={isPrimary ? 'primary' : 'secondary'}
          disabled={isDisabled}
          onClick={onClick}
          dataTestId={
            isPrimary ? 'workflowPrimaryAction' : 'workflowSecondaryAction'
          }
        >
          {buttonText}
        </Button>
      )}

      {shortcut ? (
        <ConcatenatedChips
          chips={
            shortcut === 'enter'
              ? [{ children: 'Enter ↵' }]
              : shortcut === 'meta+enter'
              ? [{ children: 'Command ⌘' }, { children: 'Enter ↵' }]
              : shortcut === 'ctrl+enter'
              ? [{ children: 'Control ⌃' }, { children: 'Enter ↵' }]
              : shortcut === 'shift+enter'
              ? [{ children: 'Shift ⇧' }, { children: 'Enter ↵' }]
              : []
          }
          isFaded={shortcutDisabled || isDisabled}
          label="Press"
        />
      ) : null}
    </div>
  );
};
