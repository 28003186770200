{
  "v": "5.12.1",
  "fr": 30,
  "ip": 0,
  "op": 80,
  "w": 860,
  "h": 572,
  "nm": "Frame",
  "ddd": 0,
  "assets": [
    {
      "id": "comp_0",
      "nm": "mag",
      "fr": 30,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [119.179, 484.59, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0.083, 0.338],
                        [-0.077, 0.082],
                        [3.217, -2.439],
                        [0, 0],
                        [4.268, 5.077],
                        [-2.824, 2.141],
                        [0, 0],
                        [0.208, 2.27]
                      ],
                      "o": [
                        [0.095, -0.054],
                        [1.733, 3.297],
                        [0, 0],
                        [-1.31, 0.99],
                        [2.291, 2.622],
                        [0, 0],
                        [1.941, -1.471],
                        [-0.031, -0.343]
                      ],
                      "v": [
                        [11.533, -9.681],
                        [11.86, -9.902],
                        [10.345, -0.892],
                        [-2.625, 8.944],
                        [-13.004, 6.256],
                        [-3.945, 7.169],
                        [9.025, -2.663],
                        [11.691, -8.73]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.192156866193, 0.180392161012, 0.505882382393, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [259.764, 113.22, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-0.411, -0.298],
                        [-0.212, 0.021],
                        [0, 0],
                        [-0.16, 0.224],
                        [-1.964, 1.622],
                        [0.32, 0.396],
                        [0.394, -0.324],
                        [1.569, -2.19]
                      ],
                      "o": [
                        [0.19, 0.138],
                        [0, 0],
                        [0.252, -0.024],
                        [1.474, -2.061],
                        [0.394, -0.325],
                        [-0.32, -0.396],
                        [-2.087, 1.723],
                        [-0.298, 0.416]
                      ],
                      "v": [
                        [-3.213, 3.613],
                        [-2.589, 3.783],
                        [-2.585, 3.782],
                        [-1.927, 3.402],
                        [3.254, -2.146],
                        [3.385, -3.448],
                        [2.094, -3.576],
                        [-3.417, 2.32]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.933333337307, 0.949019610882, 1, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [305.181, 75.553, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-0.265, -0.435],
                        [-0.336, 0.033],
                        [0, 0],
                        [-0.122, 0.075],
                        [-0.672, 0.348],
                        [0.23, 0.454],
                        [0.451, -0.233],
                        [0.687, -0.427]
                      ],
                      "o": [
                        [0.191, 0.312],
                        [0, 0],
                        [0.135, -0.013],
                        [0.645, -0.401],
                        [0.451, -0.233],
                        [-0.23, -0.454],
                        [-0.714, 0.371],
                        [-0.433, 0.265]
                      ],
                      "v": [
                        [-1.811, 1.066],
                        [-0.941, 1.502],
                        [-0.937, 1.505],
                        [-0.544, 1.37],
                        [1.444, 0.244],
                        [1.845, -1.003],
                        [0.61, -1.404],
                        [-1.503, -0.206]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.933333337307, 0.949019610882, 1, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [444.332, 277.348, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [8.622, 3.338],
                        [-8.63, 6.541],
                        [8.888, 11.949],
                        [0.188, 0.235],
                        [11.618, -8.809]
                      ],
                      "o": [
                        [8.912, 4.779],
                        [11.881, -9.005],
                        [-0.181, -0.243],
                        [7.511, 12.175],
                        [-7.951, 6.025]
                      ],
                      "v": [
                        [-19.777, 18.897],
                        [9.003, 16.602],
                        [14.423, -21.341],
                        [13.87, -22.061],
                        [6.894, 15.118]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.192156866193, 0.180392161012, 0.505882382393, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          "nm": "Vector",
          "td": 1,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [398.705, 244.086, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-1.363, -14.855],
                        [14.733, -1.43],
                        [1.363, 14.855],
                        [-14.733, 1.43]
                      ],
                      "o": [
                        [1.363, 14.855],
                        [-14.733, 1.43],
                        [-1.363, -14.855],
                        [14.733, -1.43]
                      ],
                      "v": [
                        [26.677, -2.59],
                        [2.468, 26.897],
                        [-26.677, 2.59],
                        [-2.468, -26.897]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.976470589638, 0.980392158031, 0.984313726425, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          "nm": "Vector",
          "tt": 2,
          "tp": 5,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [400.013, 245.151, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-1.69, -18.416],
                        [18.266, -1.773],
                        [1.69, 18.416],
                        [-18.266, 1.773]
                      ],
                      "o": [
                        [1.689, 18.416],
                        [-18.266, 1.773],
                        [-1.69, -18.416],
                        [18.266, -1.773]
                      ],
                      "v": [
                        [33.073, -3.211],
                        [3.059, 33.346],
                        [-33.073, 3.211],
                        [-3.06, -33.346]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.505882382393, 0.549019634724, 0.972549021244, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [70.627, 467.961, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-0.501, -0.07],
                        [-0.07, 0.007],
                        [0, 0],
                        [-0.064, 0.434],
                        [-0.614, 0.465],
                        [0, 0],
                        [0.303, 0.409],
                        [0.407, -0.307],
                        [0, 0],
                        [0.18, -1.252]
                      ],
                      "o": [
                        [0.075, 0.011],
                        [0, 0],
                        [0.413, -0.04],
                        [0.11, -0.766],
                        [0, 0],
                        [0.403, -0.307],
                        [-0.303, -0.409],
                        [0, 0],
                        [-1.007, 0.76],
                        [-0.072, 0.505]
                      ],
                      "v": [
                        [-4.261, 4.741],
                        [-4.043, 4.746],
                        [-4.042, 4.753],
                        [-3.219, 3.96],
                        [-2.099, 2.055],
                        [4.682, -3.087],
                        [4.865, -4.384],
                        [3.58, -4.567],
                        [-3.201, 0.575],
                        [-5.039, 3.698]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.933333337307, 0.949019610882, 1, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [136.13, 416.022, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-0.303, -0.409],
                        [-0.307, 0.03],
                        [-0.141, 0.107],
                        [0, 0],
                        [-0.504, 0.049],
                        [0.047, 0.508],
                        [0.504, -0.049],
                        [0.696, -0.529],
                        [0, 0]
                      ],
                      "o": [
                        [0.198, 0.267],
                        [0.161, -0.016],
                        [0, 0],
                        [0.424, -0.32],
                        [0.504, -0.049],
                        [-0.047, -0.508],
                        [-0.859, 0.083],
                        [0, 0],
                        [-0.403, 0.307]
                      ],
                      "v": [
                        [-2.736, 1.729],
                        [-1.918, 2.092],
                        [-1.455, 1.909],
                        [0.641, 0.319],
                        [2.086, -0.249],
                        [2.915, -1.259],
                        [1.917, -2.092],
                        [-0.457, -1.158],
                        [-2.553, 0.432]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.933333337307, 0.949019610882, 1, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [109.693, 464.361, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-2.221, -2.987],
                        [2.971, -2.252],
                        [0, 0],
                        [2.221, 2.987],
                        [-2.971, 2.252],
                        [0, 0]
                      ],
                      "o": [
                        [2.221, 2.987],
                        [0, 0],
                        [-2.971, 2.252],
                        [-2.221, -2.987],
                        [0, 0],
                        [2.971, -2.252]
                      ],
                      "v": [
                        [11.863, -8.994],
                        [10.506, 0.492],
                        [-2.462, 10.324],
                        [-11.863, 8.994],
                        [-10.506, -0.492],
                        [2.462, -10.324]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.505882382393, 0.549019634724, 0.972549021244, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [228.616, 378.448, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.077, 0.312],
                        [0, 0],
                        [0.357, 1.564],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [-0.073, -0.313],
                        [0, 0],
                        [-0.383, -1.561],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-2.058, 4.236],
                        [-2.28, 3.302],
                        [-2.28, 3.306],
                        [-3.482, -1.362],
                        [0.307, -4.236],
                        [3.482, 0.036]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.192156866193, 0.180392161012, 0.505882382393, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 4,
          "nm": "Vector",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [201.651, 394.615, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [640.952, 640.952, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [4.512, -6.761],
                        [7.689, -2.489],
                        [-4.512, 6.761],
                        [-7.689, 2.489]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.505882382393, 0.549019634724, 0.972549021244, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Vector",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_1",
      "nm": "scan",
      "fr": 30,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Shape Layer 1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.833], "y": [0.833] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": -8,
                  "s": [0]
                },
                {
                  "i": { "x": [0.667], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": -3,
                  "s": [100]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.333], "y": [0] },
                  "t": 233,
                  "s": [100]
                },
                { "t": 242, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": -10,
                  "s": [538.031, 809.062, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0.134, "y": 1 },
                  "o": { "x": 0.633, "y": 0 },
                  "t": 0,
                  "s": [538.031, 848.062, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0, "y": 1 },
                  "o": { "x": 0.578, "y": 0 },
                  "t": 30,
                  "s": [538.031, 61.062, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0.134, "y": 1 },
                  "o": { "x": 0.633, "y": 0 },
                  "t": 60,
                  "s": [538.031, 848.062, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0, "y": 1 },
                  "o": { "x": 0.578, "y": 0 },
                  "t": 90,
                  "s": [538.031, 61.062, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0.134, "y": 1 },
                  "o": { "x": 0.633, "y": 0 },
                  "t": 120,
                  "s": [538.031, 848.062, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0, "y": 1 },
                  "o": { "x": 0.578, "y": 0 },
                  "t": 150,
                  "s": [538.031, 61.062, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0.134, "y": 1 },
                  "o": { "x": 0.633, "y": 0 },
                  "t": 180,
                  "s": [538.031, 848.062, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0, "y": 1 },
                  "o": { "x": 0.578, "y": 0 },
                  "t": 210,
                  "s": [538.031, 61.062, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                { "t": 240, "s": [538.031, 848.062, 0] }
              ],
              "ix": 2,
              "l": 2
            },
            "a": { "a": 0, "k": [0.031, 374.562, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-609, 374.562],
                        [609.062, 374.562]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.309803921569, 0.274509803922, 0.898039215686, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 4, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 61,
          "st": -10,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Rectangle 12602",
          "parent": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.333], "y": [0] },
                  "t": 43,
                  "s": [100]
                },
                { "t": 57, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-0.5, 373.5, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-0.5, 63.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [-100, 100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.995, "y": 0 },
                        "t": 33,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-610, 64.5],
                              [608, 63.5],
                              [609, 63.5],
                              [-609, 63.5]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": { "x": 0, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 40,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-610, 64.5],
                              [608, 63.5],
                              [608, -260.5],
                              [-610, -260.5]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 60,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-610, 64.5],
                              [608, 63.5],
                              [609, 63.5],
                              [-609, 63.5]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "gf",
                  "o": { "a": 0, "k": 100, "ix": 10 },
                  "r": 1,
                  "bm": 0,
                  "g": {
                    "p": 3,
                    "k": {
                      "a": 0,
                      "k": [
                        0, 0.31, 0.275, 0.898, 0.5, 0.31, 0.275, 0.898, 1, 0.31,
                        0.275, 0.898, 0, 0, 0.5, 0.25, 1, 0.5
                      ],
                      "ix": 9
                    }
                  },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 33,
                        "s": [0, 55.5],
                        "to": [0, -8.337],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 40,
                        "s": [0, -157.5],
                        "to": [0, 0],
                        "ti": [0, -90.667]
                      },
                      { "t": 60, "s": [0, -63.5], "h": 1 }
                    ],
                    "ix": 5
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 33,
                        "s": [0, 92.5],
                        "to": [0, 2.039],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 40,
                        "s": [0, 115.5],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      { "t": 60, "s": [0, 92.5], "h": 1 }
                    ],
                    "ix": 6
                  },
                  "t": 1,
                  "nm": "Gradient Fill 1",
                  "mn": "ADBE Vector Graphic - G-Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Rectangle 12601",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 30,
          "op": 60,
          "st": 20,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "Rectangle 12601",
          "parent": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.333], "y": [0] },
                  "t": 14,
                  "s": [100]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 23,
                  "s": [0]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.333], "y": [0] },
                  "t": 43,
                  "s": [100]
                },
                { "t": 57, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [0, 437, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [100, -100, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.995, "y": 0 },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-610, 64.5],
                              [608, 63.5],
                              [609, 63.5],
                              [-609, 63.5]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": { "x": 0, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 14,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-610, 64.5],
                              [608, 63.5],
                              [608, -260.5],
                              [-610, -260.5]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.833, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 30,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-610, 64.5],
                              [608, 63.5],
                              [609, 63.5],
                              [-609, 63.5]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.995, "y": 0 },
                        "t": 33,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-610, 64.5],
                              [608, 63.5],
                              [609, 63.5],
                              [-609, 63.5]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": { "x": 0, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 40,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-610, 64.5],
                              [608, 63.5],
                              [608, -260.5],
                              [-610, -260.5]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 60,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-610, 64.5],
                              [608, 63.5],
                              [609, 63.5],
                              [-609, 63.5]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "gf",
                  "o": { "a": 0, "k": 100, "ix": 10 },
                  "r": 1,
                  "bm": 0,
                  "g": {
                    "p": 3,
                    "k": {
                      "a": 0,
                      "k": [
                        0, 0.31, 0.275, 0.898, 0.5, 0.31, 0.275, 0.898, 1, 0.31,
                        0.275, 0.898, 0, 0, 0.5, 0.25, 1, 0.5
                      ],
                      "ix": 9
                    }
                  },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 0,
                        "s": [0, -63.5],
                        "to": [0, -15.667],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 14,
                        "s": [0, -157.5],
                        "to": [0, 0],
                        "ti": [0, -90.667]
                      },
                      { "t": 30, "s": [0, -63.5], "h": 1 },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 33,
                        "s": [0, 55.5],
                        "to": [0, -8.337],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 40,
                        "s": [0, -157.5],
                        "to": [0, 0],
                        "ti": [0, -90.667]
                      },
                      { "t": 60, "s": [0, -63.5], "h": 1 }
                    ],
                    "ix": 5
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 0,
                        "s": [0, 63.5],
                        "to": [0, 3.833],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 14,
                        "s": [0, 86.5],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      { "t": 30, "s": [0, 63.5], "h": 1 },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 33,
                        "s": [0, 92.5],
                        "to": [0, 2.039],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 40,
                        "s": [0, 115.5],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      { "t": 60, "s": [0, 92.5], "h": 1 }
                    ],
                    "ix": 6
                  },
                  "t": 1,
                  "nm": "Gradient Fill 1",
                  "mn": "ADBE Vector Graphic - G-Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Rectangle 12601",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 30,
          "st": -10,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_2",
      "nm": "card",
      "fr": 30,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Shape Layer 7",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-185.572, -39.871, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-116.5, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [45.5, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.717647058824, 0.741176470588, 0.972549019608, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Shape Layer 6",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-194.471, -39.871, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-65, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [-100, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.717647058824, 0.741176470588, 0.972549019608, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "Shape Layer 5",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-194.471, -93.27, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-65, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [104, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.717647058824, 0.741176470588, 0.972549019608, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "Shape Layer 4",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-194.471, -137.084, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-65, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [3, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 28,
                        "s": [0.717647058824, 0.741176470588, 0.972549019608, 1]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 34,
                        "s": [0.267589390278, 0.784313738346, 0.458154380322, 1]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.167], "y": [0] },
                        "t": 38,
                        "s": [0.717647058824, 0.741176470588, 0.972549019608, 1]
                      },
                      {
                        "t": 42,
                        "s": [0.267589390278, 0.784313738346, 0.458154380322, 1]
                      }
                    ],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          "nm": "Shape Layer 3",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-194.471, -230.19, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-65, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [3, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.717647058824, 0.741176470588, 0.972549019608, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          "nm": "Shape Layer 1",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 0,
                  "s": [973, 417, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 1, "y": 0 },
                  "t": 40,
                  "s": [577.236, 417, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                { "t": 80, "s": [158, 417, 0] }
              ],
              "ix": 2,
              "l": 2,
              "x": "var $bm_rt;\n$bm_rt = loopOutDuration('cycle', 0);"
            },
            "a": { "a": 0, "k": [-54, -127.5, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0, 0, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 0,
                  "s": [0, 0, 100]
                },
                {
                  "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 30.667,
                  "s": [74, 74, 100]
                },
                {
                  "i": { "x": [0.629, 0.629, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [1, 1, 0.333], "y": [0, 0, 0] },
                  "t": 53.333,
                  "s": [74, 74, 100]
                },
                { "t": 80, "s": [0, 0, 100] }
              ],
              "ix": 6,
              "l": 2,
              "x": "var $bm_rt;\n$bm_rt = loopOutDuration('cycle', 0);"
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": { "a": 0, "k": [568, 322], "ix": 2 },
                  "p": { "a": 0, "k": [0, 0], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 4 },
                  "nm": "Rectangle Path 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "rd",
                  "nm": "Round Corners 1",
                  "r": { "a": 0, "k": 26, "ix": 1 },
                  "ix": 2,
                  "mn": "ADBE Vector Filter - RC",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.901960784314, 0.921568627451, 1, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [-54, -127.5], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Rectangle 1",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_3",
      "nm": "card",
      "fr": 30,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Shape Layer 7",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-185.572, -39.871, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-116.5, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [45.5, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.717647058824, 0.741176470588, 0.972549019608, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Shape Layer 6",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-194.471, -39.871, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-65, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [-100, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.717647058824, 0.741176470588, 0.972549019608, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "Shape Layer 5",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-194.471, -93.27, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-65, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [104, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.717647058824, 0.741176470588, 0.972549019608, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "Shape Layer 4",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-194.471, -137.084, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-65, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [3, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.717647058824, 0.741176470588, 0.972549019608, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          "nm": "Shape Layer 3",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-194.471, -230.19, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-65, -122.5, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [136.92, 136.92, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-133, -122.5],
                        [3, -122.5]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.717647058824, 0.741176470588, 0.972549019608, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 15, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          "nm": "Shape Layer 1",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 0,
                  "s": [973, 417, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 1, "y": 0 },
                  "t": 40,
                  "s": [577.236, 417, 0],
                  "to": [0, 0, 0],
                  "ti": [0, 0, 0]
                },
                { "t": 80, "s": [158, 417, 0] }
              ],
              "ix": 2,
              "l": 2,
              "x": "var $bm_rt;\n$bm_rt = loopOutDuration('cycle', 0);"
            },
            "a": { "a": 0, "k": [-54, -127.5, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0, 0, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 0,
                  "s": [0, 0, 100]
                },
                {
                  "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 30.667,
                  "s": [74, 74, 100]
                },
                {
                  "i": { "x": [0.629, 0.629, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [1, 1, 0.333], "y": [0, 0, 0] },
                  "t": 53.333,
                  "s": [74, 74, 100]
                },
                { "t": 80, "s": [0, 0, 100] }
              ],
              "ix": 6,
              "l": 2,
              "x": "var $bm_rt;\n$bm_rt = loopOutDuration('cycle', 0);"
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": { "a": 0, "k": [568, 322], "ix": 2 },
                  "p": { "a": 0, "k": [0, 0], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 4 },
                  "nm": "Rectangle Path 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "rd",
                  "nm": "Round Corners 1",
                  "r": { "a": 0, "k": 26, "ix": 1 },
                  "ix": 2,
                  "mn": "ADBE Vector Filter - RC",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.901960784314, 0.921568627451, 1, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [-54, -127.5], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Rectangle 1",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 3600,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "1",
      "parent": 3,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 41, "ix": 11 },
        "r": { "a": 0, "k": -3, "ix": 10 },
        "p": { "a": 0, "k": [251.554, 153.316, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [683.12, 683.12, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-13.751, 0],
                    [0, 14.145],
                    [13.751, 0],
                    [0, -14.145]
                  ],
                  "o": [
                    [13.751, 0],
                    [0, -14.145],
                    [-13.751, 0],
                    [0, 14.145]
                  ],
                  "v": [
                    [0, 25.612],
                    [24.899, 0],
                    [0, -25.612],
                    [-24.899, 0]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "fl1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "gf",
              "o": { "a": 0, "k": 100, "ix": 10 },
              "r": 1,
              "bm": 0,
              "g": {
                "p": 3,
                "k": {
                  "a": 0,
                  "k": [
                    0, 1, 1, 1, 0.5, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0.5, 0.5, 1, 0
                  ],
                  "ix": 9
                }
              },
              "s": { "a": 0, "k": [0, 0], "ix": 5 },
              "e": { "a": 0, "k": [21.679, 0.312], "ix": 6 },
              "t": 2,
              "h": { "a": 0, "k": 0, "ix": 7 },
              "a": { "a": 0, "k": 0, "ix": 8 },
              "nm": "Gradient Fill 1",
              "mn": "ADBE Vector Graphic - G-Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "flare1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": -64,
      "op": 3536,
      "st": -64,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "2",
      "parent": 3,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 41, "ix": 11 },
        "r": { "a": 0, "k": -3, "ix": 10 },
        "p": { "a": 0, "k": [124.318, 457.06, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [683.12, 683.12, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-9.965, 0],
                    [0, 10.25],
                    [9.965, 0],
                    [0, -10.25]
                  ],
                  "o": [
                    [9.965, 0],
                    [0, -10.25],
                    [-9.965, 0],
                    [0, 10.25]
                  ],
                  "v": [
                    [0, 18.559],
                    [18.043, 0],
                    [0, -18.559],
                    [-18.043, 0]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "2",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "gf",
              "o": { "a": 0, "k": 100, "ix": 10 },
              "r": 1,
              "bm": 0,
              "g": {
                "p": 3,
                "k": {
                  "a": 0,
                  "k": [
                    0, 1, 1, 1, 0.5, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0.5, 0.5, 1, 0
                  ],
                  "ix": 9
                }
              },
              "s": { "a": 0, "k": [0, 0], "ix": 5 },
              "e": { "a": 0, "k": [16.686, 1.716], "ix": 6 },
              "t": 2,
              "h": { "a": 0, "k": 0, "ix": 7 },
              "a": { "a": 0, "k": 0, "ix": 8 },
              "nm": "Gradient Fill 1",
              "mn": "ADBE Vector Graphic - G-Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "f2",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": -64,
      "op": 3536,
      "st": -64,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 0,
      "nm": "mag",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.167], "y": [0] },
              "t": 0,
              "s": [3]
            },
            {
              "i": { "x": [0.833], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 20,
              "s": [0]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.167], "y": [0] },
              "t": 40,
              "s": [3]
            },
            {
              "i": { "x": [0.833], "y": [1] },
              "o": { "x": [0.167], "y": [0] },
              "t": 60,
              "s": [0]
            },
            { "t": 80, "s": [3] }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": -7,
              "s": [450, 249.5, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": 13,
              "s": [450, 265.5, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": 33,
              "s": [450, 249.5, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": 53,
              "s": [450, 265.5, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": 73,
              "s": [450, 249.5, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": 93,
              "s": [450, 265.5, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            { "t": 113, "s": [450, 249.5, 0] }
          ],
          "ix": 2,
          "l": 2
        },
        "a": { "a": 0, "k": [397.743, 241.035, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [93.827, 93.827, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "w": 614,
      "h": 560,
      "ip": 0,
      "op": 3600,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "Vector 5",
      "parent": 3,
      "td": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": -3, "ix": 10 },
        "p": { "a": 0, "k": [400.367, 236.934, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [683.12, 683.12, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-1.363, -14.855],
                    [14.733, -1.43],
                    [1.363, 14.855],
                    [-14.733, 1.43]
                  ],
                  "o": [
                    [1.363, 14.855],
                    [-14.733, 1.43],
                    [-1.363, -14.855],
                    [14.733, -1.43]
                  ],
                  "v": [
                    [26.677, -2.59],
                    [2.468, 26.897],
                    [-26.677, 2.59],
                    [-2.468, -26.897]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.976470589638, 0.980392158031, 0.984313726425, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Vector",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 3600,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 0,
      "nm": "scan",
      "tt": 1,
      "tp": 4,
      "refId": "comp_1",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [454, 252.5, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [552, 462.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [57.609, 57.609, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "w": 1104,
      "h": 925,
      "ip": 10,
      "op": 3610,
      "st": 10,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "Vector 4",
      "parent": 3,
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.833], "y": [1] },
              "o": { "x": [0.333], "y": [0] },
              "t": 18,
              "s": [0]
            },
            {
              "i": { "x": [0.833], "y": [1] },
              "o": { "x": [0.167], "y": [0] },
              "t": 28,
              "s": [100]
            },
            {
              "i": { "x": [0.667], "y": [1] },
              "o": { "x": [0.167], "y": [0] },
              "t": 65,
              "s": [100]
            },
            { "t": 75, "s": [0] }
          ],
          "ix": 11
        },
        "r": { "a": 0, "k": -3, "ix": 10 },
        "p": { "a": 0, "k": [400.367, 236.934, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [697.12, 697.12, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-1.363, -14.855],
                    [14.733, -1.43],
                    [1.363, 14.855],
                    [-14.733, 1.43]
                  ],
                  "o": [
                    [1.363, 14.855],
                    [-14.733, 1.43],
                    [-1.363, -14.855],
                    [14.733, -1.43]
                  ],
                  "v": [
                    [26.677, -2.59],
                    [2.468, 26.897],
                    [-26.677, 2.59],
                    [-2.468, -26.897]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "3",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "gf",
              "o": { "a": 0, "k": 100, "ix": 10 },
              "r": 1,
              "bm": 0,
              "g": {
                "p": 3,
                "k": {
                  "a": 0,
                  "k": [
                    0, 0.667, 0.694, 0.98, 0.49, 0.641, 0.673, 0.98, 1, 0.616,
                    0.651, 0.98, 0, 0, 0.5, 0.5, 1, 1
                  ],
                  "ix": 9
                }
              },
              "s": { "a": 0, "k": [0.141, 0.606], "ix": 5 },
              "e": { "a": 0, "k": [35.479, 12.738], "ix": 6 },
              "t": 2,
              "h": { "a": 0, "k": 0, "ix": 7 },
              "a": { "a": 0, "k": 0, "ix": 8 },
              "nm": "Gradient Fill 1",
              "mn": "ADBE Vector Graphic - G-Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "f3",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 3600,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "Vector 2",
      "parent": 3,
      "td": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": -3, "ix": 10 },
        "p": { "a": 0, "k": [400.367, 236.934, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [683.12, 683.12, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-1.363, -14.855],
                    [14.733, -1.43],
                    [1.363, 14.855],
                    [-14.733, 1.43]
                  ],
                  "o": [
                    [1.363, 14.855],
                    [-14.733, 1.43],
                    [-1.363, -14.855],
                    [14.733, -1.43]
                  ],
                  "v": [
                    [26.677, -2.59],
                    [2.468, 26.897],
                    [-26.677, 2.59],
                    [-2.468, -26.897]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.976470589638, 0.980392158031, 0.984313726425, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Vector",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 3600,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 0,
      "nm": "card",
      "tt": 1,
      "tp": 7,
      "refId": "comp_2",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [586, 354.5, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [552, 462.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [170.29, 170.29, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "w": 1104,
      "h": 925,
      "ip": 0,
      "op": 3600,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "nm": "Vector 3",
      "parent": 3,
      "td": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": -3, "ix": 10 },
        "p": { "a": 0, "k": [400.367, 236.934, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [683.12, 683.12, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-1.363, -14.855],
                    [14.733, -1.43],
                    [1.363, 14.855],
                    [-14.733, 1.43]
                  ],
                  "o": [
                    [1.363, 14.855],
                    [-14.733, 1.43],
                    [-1.363, -14.855],
                    [14.733, -1.43]
                  ],
                  "v": [
                    [26.677, -2.59],
                    [2.468, 26.897],
                    [-26.677, 2.59],
                    [-2.468, -26.897]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.976470589638, 0.980392158031, 0.984313726425, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Vector",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 3600,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 0,
      "nm": "card",
      "tt": 2,
      "tp": 9,
      "refId": "comp_3",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [424, 304.5, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [552, 462.5, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "w": 1104,
      "h": 925,
      "ip": 0,
      "op": 3600,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": [],
  "props": {}
}
