import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SelectInput } from '@assured/shared-types/Services';
import {
  CallerVerificationOutput,
  VerifyCallerArguments,
} from '@assured/shared-types/Services/PolicySearch';

type CallerState = {
  policyNumber: string;
  contact: SelectInput;
  contactIndex: number;
};

export function useCallerVerification({
  initialReporter,
  setInitialReporter,
  originalInitialReporter,
  verifyCaller,
  itemsVerified,
}: {
  initialReporter?: SelectInput;
  setInitialReporter: (reporter: SelectInput) => void;
  originalInitialReporter?: SelectInput;
  verifyCaller: (payload: VerifyCallerArguments) => CallerVerificationOutput;
  itemsVerified: Record<string, string>;
}) {
  const [callerState, setCallerState] = useState<CallerState>();
  const callerCache = useRef<Record<string, CallerState>>({});

  useEffect(() => {
    if (callerState) {
      setInitialReporter(callerState.contact);
    } else if (
      originalInitialReporter &&
      initialReporter?.id !== originalInitialReporter.id
    ) {
      setInitialReporter(originalInitialReporter);
    }
  }, [
    initialReporter,
    callerState,
    setInitialReporter,
    originalInitialReporter,
  ]);

  const [callerVerification, setCallerVerification] = useState(
    verifyCaller({
      contactIndex: callerState?.contactIndex,
      itemsVerified: Object.values(itemsVerified),
    }),
  );

  useEffect(() => {
    setCallerVerification(
      verifyCaller({
        contactIndex: callerState?.contactIndex,
        itemsVerified: Object.values(itemsVerified),
      }),
    );
  }, [callerState?.contactIndex, itemsVerified, verifyCaller]);

  const onCallerChange = useCallback(
    (payload?: CallerState) => {
      const originalInitialReporterString = JSON.stringify(
        originalInitialReporter,
      );
      const originalInitialReporterId = originalInitialReporter?.id;

      if (payload) {
        setCallerState(payload);
        if (payload.contact.id) {
          callerCache.current[payload.contact.id] = payload;
        } else {
          callerCache.current[JSON.stringify(payload.contact)] = payload;
        }
      } else if (
        originalInitialReporterId &&
        callerCache.current[originalInitialReporterId]
      ) {
        setCallerState(callerCache.current[originalInitialReporterId]);
      } else if (
        originalInitialReporter &&
        callerCache.current[originalInitialReporterString]
      ) {
        setCallerState(callerCache.current[originalInitialReporterString]);
      } else {
        setCallerState(undefined);
      }
    },
    [originalInitialReporter],
  );

  const resetCallerVerification = useCallback(selectedPolicyNumber => {
    setCallerState(prevCallerState => {
      if (
        selectedPolicyNumber &&
        prevCallerState?.policyNumber !== selectedPolicyNumber
      ) {
        return undefined;
      }
      return prevCallerState;
    });
  }, []);

  const forceVerifiedItemSelectors = useMemo(() => {
    return typeof callerState?.contactIndex === 'number'
      ? [
          (id: string) =>
            id.startsWith('contact_name') &&
            id.endsWith(callerState?.contactIndex.toString()),
        ]
      : [];
  }, [callerState?.contactIndex]);

  return {
    callerPolicyNumber: callerState?.policyNumber,
    callerVerification,
    onCallerChange,
    resetCallerVerification,
    forceVerifiedItemSelectors,
  };
}
