import classNames from 'classnames';

import { InfoDetailsItem } from './InfoDetailsItem';

import type { InfoDetailsItemProps } from './InfoDetailsItem';

export interface InfoDetailsSectionProps {
  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** Set to `true` for error treatment */
  hasError?: boolean;

  /** An array of list items represented by their `props` */
  listItems: (InfoDetailsItemProps & { id?: string })[];

  /** Subtitle of the section */
  subtitle?: string | string[];

  /** Subtitle widget to be rendered */
  subtitleWidget?: React.ReactNode;

  /** Title of the section */
  title?: string;

  /** Title widget to be rendered */
  titleWidget?: React.ReactNode;
}

export const InfoDetailsSection = ({
  className,
  dataTestId = 'info-details-section',
  hasError,
  listItems,
  subtitle,
  subtitleWidget,
  title,
  titleWidget,
}: InfoDetailsSectionProps) => {
  return (
    <div
      className={classNames('flex flex-col gap-2 py-3 px-[13px]', className)}
      data-testid={dataTestId}
    >
      {title && (
        <InfoDetailsSectionTitle title={title} titleWidget={titleWidget} />
      )}
      {subtitle && (
        <InfoDetailsSectionSubtitle
          subtitle={subtitle}
          subtitleWidget={subtitleWidget}
          hasError={hasError}
        />
      )}
      {listItems.map((listItemProps, index) => (
        <InfoDetailsItem
          {...listItemProps}
          /* eslint-disable-next-line react/no-array-index-key */
          key={`info-details-item-${listItemProps.textPrimary}-${listItemProps.textSecondary}-${listItemProps.id}-${index}`}
        />
      ))}
    </div>
  );
};

const InfoDetailsSectionTitle = ({
  title,
  titleWidget,
}: Pick<InfoDetailsSectionProps, 'title' | 'titleWidget'>) => {
  return titleWidget ? (
    <div className="flex justify-between items-center">
      <h2 className="text-base mt-1 ml-1 mb-1 font-medium">{title}</h2>
      {titleWidget}
    </div>
  ) : (
    <h2 className="text-base mt-1 ml-1 mb-1 font-medium">{title}</h2>
  );
};

const InfoDetailsSectionSubtitle = ({
  subtitle,
  subtitleWidget,
  hasError,
}: Pick<
  InfoDetailsSectionProps,
  'subtitle' | 'subtitleWidget' | 'hasError'
>) => {
  const subtitleBlock = (
    <div>
      {(Array.isArray(subtitle) ? subtitle : [subtitle]).map(s => (
        <h3
          key={s}
          className={classNames(
            'uppercase text-xs leading-[15px] font-medium mb-2 px-[5px]',
            {
              'text-cool-gray-700': !hasError,
              'text-red-warm-600': hasError,
              'last:mb-0': !!subtitleWidget,
            },
          )}
        >
          {s}
        </h3>
      ))}
    </div>
  );

  return subtitleWidget ? (
    <div className="flex justify-between items-center">
      {subtitleBlock}
      {subtitleWidget}
    </div>
  ) : (
    subtitleBlock
  );
};
