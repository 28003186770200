import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { Phase } from './constants';
import { SubEvent } from './types';

/**
 * Custom hook to get active subEvents based on the currentTime.
 */
export const useActiveSubEvents = (
  currentTime: number,
  subEvents: SubEvent[] | undefined,
  earliestStartTime: string | null,
  selectedPhase: Phase,
): SubEvent[] => {
  const [activeSubEvents, setActiveSubEvents] = useState<SubEvent[]>([]);

  useEffect(() => {
    const earliestStartDateTime = earliestStartTime
      ? DateTime.fromISO(earliestStartTime)
      : null;

    // Check if subEvents and earliestStartTime are available
    if (!subEvents || !earliestStartTime) {
      setActiveSubEvents([]); // Clear active subEvents if dependencies are missing
      return;
    }

    // Calculate active subEvents based on currentTime
    const newActiveSubEvents = subEvents.filter(subEvent => {
      // earliestDateTime cannot be null here.
      const relativeStart = DateTime.fromISO(subEvent.start)
        .diff(earliestStartDateTime as DateTime)
        .toMillis();

      // earliestDateTime cannot be null here.
      const relativeEnd = DateTime.fromISO(subEvent.end)
        .plus({ days: 1 })
        .diff(earliestStartDateTime as DateTime)
        .toMillis();

      const isActive =
        currentTime >= relativeStart && currentTime <= relativeEnd;
      const isPhaseMatch =
        selectedPhase === 'ALL' || subEvent.phase === selectedPhase;

      // Include only subEvents where currentTime is on or after the start time and before or on endTime
      return isActive && !subEvent.draft && isPhaseMatch;
    });

    // Update state with the currently active subEvents
    setActiveSubEvents(newActiveSubEvents);
  }, [currentTime, subEvents, earliestStartTime, selectedPhase]);

  return activeSubEvents;
};

/**
 * Additive way to track ongoing events, they remain in the array as long as the current playTime
 * is after the events start time
 */
export const useSeenSubEvents = (
  currentTime: number,
  subEvents: SubEvent[] | undefined,
  selectedPhases: Phase[],
  earliestStartTime?: string,
  disabledDraftCheck = false,
): SubEvent[] => {
  const [activeSubEvents, setActiveSubEvents] = useState<SubEvent[]>([]);

  useEffect(() => {
    const earliestStartDateTime = earliestStartTime
      ? DateTime.fromISO(earliestStartTime)
      : null;

    // Check if subEvents and earliestStartDateTime are available
    if (!subEvents || !earliestStartDateTime) {
      setActiveSubEvents([]);
      return;
    }

    // Filter subEvents based on currentTime
    const updatedActiveSubEvents = subEvents.filter(subEvent => {
      const relativeStart = DateTime.fromISO(subEvent.start)
        .diff(earliestStartDateTime)
        .toMillis();

      const isActive = currentTime >= relativeStart;
      const isPhaseMatch =
        selectedPhases.includes('ALL') ||
        selectedPhases.includes(subEvent.phase);

      // Include only subEvents where currentTime is on or after the start time
      return (
        isActive && (disabledDraftCheck || !subEvent.draft) && isPhaseMatch
      );
    });

    // Update state with the active subEvents based on currentTime
    setActiveSubEvents(updatedActiveSubEvents);
  }, [
    currentTime,
    subEvents,
    earliestStartTime,
    selectedPhases,
    disabledDraftCheck,
  ]);

  return activeSubEvents;
};
