import classNames from 'classnames';

import { AccordionItem } from './AccordionItem';

import type { AccordionItemProps } from './AccordionItem';

export interface AccordionProps {
  /** Custom className */
  className?: string;

  /** Custom className for the header text */
  classNameHeaderText?: string;

  /** Custom className for the header padding */
  classNameHeaderPadding?: string;

  /** Custom className from the horizontal rule border color */
  classNameHorizontalRuleBorderColor?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** Items to populate the accordion */
  items: AccordionItemProps[];
}

export const Accordion = ({
  className,
  classNameHeaderText,
  classNameHeaderPadding,
  classNameHorizontalRuleBorderColor,
  dataTestId = 'accordion',
  items,
}: AccordionProps) => {
  return (
    <div className={classNames(className)} data-testid={dataTestId}>
      {items.map(props => (
        <AccordionItem
          key={`accordion-item-${props.title}`}
          classNameHeaderText={classNameHeaderText}
          classNameHeaderPadding={classNameHeaderPadding}
          classNameHorizontalRuleBorderColor={
            classNameHorizontalRuleBorderColor
          }
          {...props}
        />
      ))}
    </div>
  );
};
