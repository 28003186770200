import { useEffect, useState } from 'react';

import { Button } from '../Button';
import { IconFlatRenderer } from '../Icon/IconFlatRenderer';
import { InfoDetailsSection } from '../InfoDetails/InfoDetailsSection';
import { Modal } from '../Modal';
import { Spinner } from '../Spinner';
import { PolicySearchModalActionType } from './state/policySearchModalReducer';

import type {
  PolicySearchModalAction,
  PolicySearchModalState,
} from './state/policySearchModalReducer';

import type {
  PolicySearchAlertType,
  PolicySearchSubmitPayload,
  PolicySearchData,
} from '@assured/shared-types/Services/PolicySearch';

export interface ModalStaticContent {
  butonPrimaryText?: string;
  butonSecondaryText: string;
  subtitle?: string | string[];
  title: string;
}

export const modalStaticConentCollection: Record<
  PolicySearchAlertType,
  ModalStaticContent
> = {
  DATE_OF_LOSS_OUT_OF_RANGE: {
    butonPrimaryText: 'Attach policy anyway',
    butonSecondaryText: 'Keep searching',
    title: 'Date of loss is not in effective range of this policy',
  },
  DUPLICATE_CLAIM_WARNING: {
    butonPrimaryText: 'Attach policy anyway',
    butonSecondaryText: 'Cancel',
    subtitle:
      'If this is the same claim, transfer caller to the appropriate Claim Owner.',
    title: 'Another claim exists within 10 days of DOL',
  },
  MANUAL_LOSS: {
    butonPrimaryText: 'Submit as manual loss',
    butonSecondaryText: 'Keep searching',
    title: `Are you sure you'd like to submit this claim as a manual loss?`,
  },
  MANUAL_LOSS_NOT_ALLOWED: {
    title: 'Manual loss not allowed',
    subtitle: [
      'We were unable to locate an active policy based on the information you provided.',
      'Call us back with other additional information that can be used to search for a policy.',
      'We are willing to open a claim once you have enough information.',
    ],
    butonPrimaryText: 'Continue anyway',
    butonSecondaryText: 'Keep searching',
  },
  UNSUPPORTED_POLICY_TYPE: {
    title: 'Unsupported policy type',
    subtitle: [
      'At this time, only personal auto policies are supported in Sidekick.',
      'Please return to MyCars to file this claim.',
    ],
    butonSecondaryText: 'Keep searching',
  },
};

interface PolicySearchModalProps {
  /** A dispatch to update modal states */
  modalDispatch: React.Dispatch<PolicySearchModalAction>;

  /** The state in which to derive data */
  modalState: PolicySearchModalState;

  /**
   * The modal type to determine content. If we find we have more than a few types
   * eventually, we should take a different approach
   */
  modalType?: PolicySearchAlertType;

  /** A click handler of attach policy button */
  onClickAttachPolicy: (payload?: PolicySearchSubmitPayload) => void;

  /** A function that is called when the modal is mounted */
  onModalMount?: (
    modalType: PolicySearchAlertType,
    isOpen: boolean,
    policy?: PolicySearchData,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
}

export const PolicySearchModal = ({
  modalDispatch,
  modalState,
  modalType,
  onClickAttachPolicy,
  onModalMount,
}: PolicySearchModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, selectedModal, modals } = modalState;
  const activeModal =
    !modals || !selectedModal ? undefined : modals[selectedModal];
  const staticContent = !selectedModal
    ? undefined
    : modalStaticConentCollection[selectedModal];
  const { details, selectedPolicy } = activeModal || {};
  const { butonPrimaryText, butonSecondaryText, title, subtitle } =
    staticContent || {};
  const policyNumber = activeModal?.selectedPolicy?.info.policyNumber;

  useEffect(() => {
    if (onModalMount && modalType) {
      onModalMount(modalType, isOpen, selectedPolicy, setIsLoading);
    }
    // I do not want to pass in selectedPolicy but instead
    // selectedPolicy.info.policyNumber since that is unique
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, policyNumber, onModalMount]);

  return (
    <Modal
      className="max-h-[100vh] min-h-[300px] flex flex-col justify-center"
      isOpen={!!(selectedModal && isOpen) ?? false}
      onClose={() => {
        modalDispatch({
          type: PolicySearchModalActionType.CLOSE,
        });
      }}
      ariaLabel={title || 'Policy Search'}
      hasCloseIcon
    >
      <div className="w-full overflow-y-auto h-full">
        <div className="flex flex-col justify-center gap-8 w-full">
          <div className="flex justify-center">
            <IconFlatRenderer
              iconKey="ICON_FLAT_CAUTION"
              width={40}
              height={40}
              aria-label="Caution"
            />
          </div>

          {(title || subtitle) && (
            <div>
              {title && (
                <h1 className="text-cool-gray-800 font-medium leading-[30px] text-center text-xl mx-auto">
                  {title}
                </h1>
              )}
              {subtitle &&
                (Array.isArray(subtitle) ? subtitle : [subtitle]).map(s => (
                  <p
                    key={s}
                    className="text-cool-gray-500 font-medium leading-[24px] text-center text-[16px] max-w-[386px] mx-auto mt-4"
                  >
                    {s}
                  </p>
                ))}
            </div>
          )}
          {isLoading && (
            <div className="flex justify-center">
              <Spinner className="text-indigo-600" />
            </div>
          )}
          {details && (
            <div className="border border-cool-gray-200 rounded-md">
              <InfoDetailsSection
                listItems={details.items}
                subtitle={details.subtitle}
                title={details.title}
                hasError={details.hasError}
              />
            </div>
          )}
          <div className="flex justify-between gap-3">
            <Button
              className="basis-1/2 whitespace-nowrap w-[220px] only:mx-auto"
              onClick={() => {
                modalDispatch({
                  type: PolicySearchModalActionType.CLOSE,
                });
              }}
              variant="secondary"
            >
              {butonSecondaryText}
            </Button>
            {butonPrimaryText && (
              <Button
                className="basis-1/2 whitespace-nowrap w-[220px]"
                onClick={() => {
                  modalDispatch({
                    type: PolicySearchModalActionType.ACKNOWLEDGE,
                  });
                  if (
                    modalType === 'MANUAL_LOSS' ||
                    modalType === 'MANUAL_LOSS_NOT_ALLOWED'
                  ) {
                    onClickAttachPolicy({
                      metaData: { isManualLoss: true },
                    });
                  } else {
                    onClickAttachPolicy();
                  }
                }}
              >
                {butonPrimaryText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
