import classNames from 'classnames';
import { useMemo } from 'react';

import { SelectDropdown } from '../SelectDropdown';

const optionColors = [
  {
    background: '#EEF2FF',
    borderActive: '#6366F1',
    border: '#C7D2FE',
  },
  {
    background: '#F0FDFA',
    borderActive: '#14B8A6',
    border: '#99F6E4',
  },
  {
    background: '#FAF5FF',
    borderActive: '#A855F7',
    border: '#E9D5FF',
  },
  {
    background: '#FDF2F8',
    borderActive: '#FBCFE8',
    border: '#FBCFE8',
  },
];

export interface TagDropdownProps {
  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** The type of the token, e.g. "policy_number" */
  type: string;

  /** Show the dropdown in an "inactive" UI mode */
  inactive?: boolean;

  /** Dropdown options */
  options: {
    /** The type of the token */
    type: string;

    /** Full label for the token, e.g. "Policy Number" */
    label: string;

    /** 2-character label for the token, e.g. "PN" for "Policy Number" */
    shortLabel: string;
  }[];
  onTypeChange: (type: string) => void;
  input: React.ReactNode;
}

export const TagDropdown = ({
  className,
  dataTestId = 'tag-dropdown',
  input,
  options,
  onTypeChange,
  type,
  inactive,
}: TagDropdownProps) => {
  const selectOptions = useMemo(
    () =>
      options.map((option, index) => ({
        backgroundColor: optionColors[index % optionColors.length].background,
        borderColor: optionColors[index % optionColors.length].border,
        borderColorActive:
          optionColors[index % optionColors.length].borderActive,
        label: option.label,
        shortLabel: option.shortLabel,
        value: option.type,
      })),
    [],
  );
  const selectedOption = useMemo(
    () => selectOptions.find(option => option.value === type),
    [type],
  );
  return (
    <span
      className={classNames(
        'inline-flex border items-center gap-1 rounded-lg p-1 pr-2',
        inactive
          ? 'border-gray-300 bg-gray-50'
          : 'border-indigo-bright-200 bg-indigo-bright-50',
        className,
      )}
      style={
        inactive
          ? {}
          : {
              backgroundColor: selectedOption?.backgroundColor,
              borderColor: selectedOption?.borderColor,
            }
      }
      data-testid={dataTestId}
    >
      <SelectDropdown
        value={type}
        formatOptionLabel={(data, meta) => {
          const shouldFormat =
            meta.selectValue.length && meta.context === 'value';
          if (!shouldFormat || !selectedOption?.shortLabel) {
            return data.label;
          }
          const matchedOption = meta.selectValue.find(
            selected => selected.value === data.value,
          );
          if (!matchedOption) {
            return data.label;
          }
          return selectedOption.shortLabel;
        }}
        inline
        isClearable={false}
        isSearchable={false}
        variant="select"
        onChange={v => {
          if (v) {
            onTypeChange(v.value as string);
          }
        }}
        options={selectOptions}
        styles={{
          // due to React Select styles being embedded in a `style` tag
          // certain `classNames` won't truly override, hence - need to
          // rely on inline styles
          control: (provided, state) => {
            return {
              ...provided,
              ...(inactive
                ? { borderColor: `#94A3B8 !important` }
                : !selectedOption?.borderColor ||
                  !selectedOption?.borderColorActive
                ? {}
                : !state.menuIsOpen
                ? {
                    borderColor: `${selectedOption.borderColor} !important`,
                  }
                : {
                    borderColor: `${selectedOption?.borderColorActive} !important`,
                  }),
              borderWidth: '1px',
              borderStyle: 'solid',
              borderRadius: '5px',
              boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.05)',
              height: '26px',
              minHeight: '26px',
              padding: '0 8px !important',
            };
          },
          dropdownIndicator: () => ({
            alignItems: 'center',
            color: '#94A3B8',
            padding: 0,
            height: '15px',
            fontSize: '14px !important',
            width: '15px',
          }),
          option: provided => ({
            ...provided,
            fontSize: '14px !important',
          }),
          menu: provided => ({
            ...provided,
            fontSize: '14px !important',
            width: 'auto',
          }),
          singleValue: provided => ({
            ...provided,
            color: '#475569',
            fontSize: '14px !important',
            fontWeight: 500,
            lineHeight: '16.8px',
          }),
          valueContainer: provided => ({
            ...provided,
            fontSize: '14px !important',
            padding: 0,

            // prevent shifting when text content changes via fixed width
            width: '25px',
          }),
        }}
      />
      {input}
    </span>
  );
};
