import { RecoilRoot } from 'recoil';

export interface InboxProps extends React.HTMLAttributes<HTMLElement> {}

/**
 * A React component to render an inbox container.
 */
export const Inbox = ({ children, className }: InboxProps) => {
  return (
    <RecoilRoot>
      <aside className={className}>{children}</aside>
    </RecoilRoot>
  );
};
