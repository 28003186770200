import classNames from 'classnames';
import { Suspense, useEffect, useRef, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { twMerge } from 'tailwind-merge';

import { useKeyboardShortcut } from '@assured/ui/hooks';

import { downloadFile } from '../../lib/downloadFile';
import { AccessibleHiddenText } from '../Accessibility/AccessibleHiddenText';
import { IconFlatRendererLazy } from '../Icon/IconFlatRendererLazy';
import { useModal } from '../Modal';
import { Spinner } from '../Spinner';
import { NextPreviousButton } from './NextPreviousButton';

export interface MetaDataType {
  label: string;
  value: string;
}

export interface LightboxItem {
  /** A filename to be used for the downloaded file */
  downloadFilename?: string;

  /** A function to return a signed URL */
  getSignedUrl?: (source: string) => Promise<string>;

  /** Set to `true` if the item is a video */
  isVideo?: boolean;

  /** Metadata of the corresponding media */
  metadata?: MetaDataType[];

  /** The source of media */
  source: string;

  /** Title of the item */
  title: string;
}

export interface LightboxProps {
  /** The active item index */
  activeItemIndex: number;

  /** Custom className */
  className?: string;

  /** Custom media container className. This className will override via
   * tailwind-merge. This one could be useful to override aspect-ratio
   */
  classNameMediaContainer?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** Milliseconds to debounce next / previous button clicks to load and render media */
  debouncedNextPreviousMilliseconds?: number;

  /** Is image contained within container via `object-contain` */
  isImageContained?: boolean;

  /** Is active item loading */
  isLoading?: boolean;

  /** Is modal open */
  isOpen?: boolean;

  /** A list of media items */
  items: LightboxItem[];

  /** A handler for when the modal close icon is clicked or escape key */
  onClose?: (event?: MouseEvent | React.MouseEvent | null) => void;

  /** Setter of the active item index */
  setActiveItemIndex: (index: number) => void;

  /** Setter of the active item loading */
  setIsLoading?: (isLoading: boolean) => void;
}

// make sure these have the same value
export const ANIMATION_DURATION_MS = 150;
const ANIMATION_DURATION_CLASS_NAME = 'duration-150';

/**
 * A React component to render a slideshow modal, referred to internally
 * and in designs as "Lightbox".
 */
export const Lightbox = ({
  activeItemIndex,
  className,
  classNameMediaContainer,
  dataTestId = 'lightbox',
  debouncedNextPreviousMilliseconds = 300,
  isImageContained,
  isLoading,
  isOpen: shouldOpen,
  items,
  onClose,
  setActiveItemIndex,
  setIsLoading,
}: LightboxProps) => {
  const activeItem = items[activeItemIndex];
  const modalRef = useRef<HTMLDivElement>(null);
  const modalDownloadButtonRef = useRef<HTMLButtonElement>(null);
  const modalNextButtonRef = useRef<HTMLElement>(null);
  const modalPreviousButtonRef = useRef<HTMLElement>(null);
  const [hasLoadError, setHasLoadError] = useState(false);
  const { isDisplayNone, isOpen } = useModal({
    animationDurationMs: ANIMATION_DURATION_MS,
    isOpen: shouldOpen,
    modalControlRefs: [
      modalDownloadButtonRef,
      modalNextButtonRef,
      modalPreviousButtonRef,
    ],
    modalRef,
    onClose,
  });

  // a cache of signed URLs. this assumes short-lived client-side sessions,
  // without a need to re-sign
  const [signedUrls, setSignedUrls] = useState<Record<string, string>>({});
  const shouldHaveSignedUrl = !!activeItem.getSignedUrl;
  const sourceUrl = !shouldHaveSignedUrl
    ? activeItem.source
    : signedUrls[activeItem.source];

  // if no source URL we can assume signing request is in progress
  const isLoadingOrHasError = isLoading || hasLoadError || !sourceUrl;

  // navigate to previous
  const isPreviousDisabled = activeItemIndex === 0;
  const navigateToPrevious = () => {
    if (isPreviousDisabled) {
      return;
    }
    setActiveItemIndex(activeItemIndex - 1);
  };
  useKeyboardShortcut(['ArrowLeft'], () => {
    navigateToPrevious();
  });

  // navigate to next
  const isNextDisabled = activeItemIndex === items.length - 1;
  const navigateToNext = () => {
    if (isNextDisabled) {
      return;
    }
    setActiveItemIndex(activeItemIndex + 1);
  };
  useKeyboardShortcut(['ArrowRight'], () => {
    navigateToNext();
  });

  // debounce the active index used to load and render media
  const [debouncedActiveItemIndex, setDebouncedActiveItemIndex] =
    useState(activeItemIndex);
  useDebounce(
    () => setDebouncedActiveItemIndex(activeItemIndex),
    debouncedNextPreviousMilliseconds,
    [activeItemIndex],
  );
  const debouncedActiveItem = items[debouncedActiveItemIndex];

  // trigger effects like signing URLs when active item changes
  useEffect(() => {
    const getSignedUrl = async () => {
      try {
        if (!debouncedActiveItem.getSignedUrl) {
          return;
        }
        const signedUrl = await debouncedActiveItem.getSignedUrl(
          debouncedActiveItem.source,
        );
        setSignedUrls(state => ({
          ...state,
          [debouncedActiveItem.source]: signedUrl,
        }));
      } catch (error) {
        console.error(error);
        setHasLoadError(true);
      }
    };

    // if we need to get a signed URL
    if (
      debouncedActiveItem.getSignedUrl &&
      !signedUrls[debouncedActiveItem.source] &&
      isOpen
    ) {
      // on new active item reset load error state
      setHasLoadError(false);
      getSignedUrl();
    }
  }, [debouncedActiveItem, isOpen]);

  return (
    <div
      className={classNames(
        `fixed z-[2000] flex justify-center items-center w-full h-full top-0 right-0 bg-cool-gray-800/75 transition-opacity ease-in-out`,
        ANIMATION_DURATION_CLASS_NAME,
        {
          'opacity-0': !isOpen,
          'opacity-100': isOpen,
          hidden: isDisplayNone,
        },
      )}
    >
      <div className="w-[100px] flex items-center justify-center">
        <span ref={modalPreviousButtonRef}>
          <NextPreviousButton
            isDisabled={isPreviousDisabled}
            onClick={navigateToPrevious}
          />
        </span>
      </div>
      <div
        className={twMerge(
          classNames(
            `bg-cool-gray-800 rounded-[18px] p-3 transition-transform transform ease-in-out w-[80vw] max-w-7xl`,
            ANIMATION_DURATION_CLASS_NAME,
            {
              'scale-90': !isOpen,
              'scale-100': isOpen,
            },
          ),
          className,
        )}
        data-testid={dataTestId}
        ref={modalRef}
      >
        <div
          className={twMerge(
            classNames(
              'bg-cool-gray-300 rounded-xl relative aspect-video overflow-hidden',
              {
                'mb-3': !!activeItem.metadata?.length,
              },
            ),
            classNameMediaContainer,
          )}
        >
          {isLoadingOrHasError && (
            <div className="w-full h-full absolute flex justify-center items-center">
              {isLoading || !sourceUrl ? (
                <Spinner
                  className="text-cool-gray-400"
                  width={36}
                  height={36}
                />
              ) : (
                <Suspense fallback="">
                  <IconFlatRendererLazy
                    className="[&_*]:fill-cool-gray-400"
                    width={40}
                    height={40}
                    iconKey="ICON_FLAT_WARNING"
                  />
                </Suspense>
              )}
            </div>
          )}
          {activeItem.isVideo ? (
            <video
              autoPlay
              className={classNames('w-full h-full absolute inset-0', {
                'object-contain': isImageContained,
                'object-cover': !isImageContained,
                'opacity-0': isLoadingOrHasError,
                'opacity-100': !isLoadingOrHasError,
              })}
              controls
              onLoadedData={() => {
                if (setIsLoading) {
                  setIsLoading(false);
                }
              }}
              loop
              muted
              playsInline
              src={sourceUrl}
            />
          ) : (
            <img
              alt={activeItem.title}
              className={classNames('w-full h-full absolute inset-0', {
                'object-contain': isImageContained,
                'object-cover': !isImageContained,
                'opacity-0': isLoadingOrHasError,
                'opacity-100': !isLoadingOrHasError,
              })}
              onLoad={() => {
                if (setIsLoading) {
                  setIsLoading(false);
                }
              }}
              onError={() => setHasLoadError(true)}
              src={sourceUrl}
            />
          )}
          <div className="font-medium text-white text-xl leading-6 p-3 bg-cool-gray-900/75 absolute top-3 left-3 rounded-lg">
            {activeItemIndex + 1}/{items.length} {activeItem.title}
          </div>
        </div>
        {!!activeItem.metadata?.length && (
          <div className="flex">
            {activeItem.metadata.map(metadataItem => (
              <div
                className="flex flex-col gap-1 font-medium text-sm leading-[17px]"
                key={`${metadataItem.label}_${metadataItem.value}`}
                style={{
                  width: `${
                    (1 / (activeItem.metadata?.length as number)) * 100
                  }%`,
                }}
              >
                <div className="text-cool-gray-400">{metadataItem.label}</div>
                <div className="text-cool-gray-50 text-ellipsis overflow-hidden whitespace-nowrap">
                  {metadataItem.value}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="w-[100px] flex items-center justify-center">
        <span ref={modalNextButtonRef}>
          <NextPreviousButton
            isDisabled={isNextDisabled}
            isNext
            onClick={navigateToNext}
          />
        </span>
      </div>
      <div className="fixed top-8 right-6 z-[2001] flex gap-6">
        <button
          onClick={() => {
            downloadFile({
              filename: activeItem.downloadFilename || 'download',
              url: sourceUrl,
            });
          }}
          ref={modalDownloadButtonRef}
          type="button"
        >
          <AccessibleHiddenText>download</AccessibleHiddenText>
          <Suspense fallback="">
            <IconFlatRendererLazy
              className="[&_*]:fill-white"
              iconKey="ICON_FLAT_ARROW_DOWN_TO_LINE"
              width={27}
              height={36}
            />
          </Suspense>
        </button>
        <button
          onClick={e => {
            if (onClose) {
              onClose(e);
            }
          }}
          type="button"
        >
          <AccessibleHiddenText>close</AccessibleHiddenText>
          <Suspense fallback="">
            <IconFlatRendererLazy
              className="[&_*]:stroke-white [&_*]:stroke-[2.5px]"
              iconKey="ICON_FLAT_CLOSE"
              width={36}
              height={36}
            />
          </Suspense>
        </button>
      </div>
    </div>
  );
};
