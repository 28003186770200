import classNames from 'classnames';

interface ChartYAxisProps {
  /**
   * The textual representation of the unit (example: `%`)
   */
  chartYAxisUnit: string;

  /** Height of each y-axis range in pixels */
  chartYAxisUnitHeight: number;

  /**
   * The y-axis values representing the possible range of data points
   */
  chartYAxisValues: number[];

  /**
   * A className for the main containing element
   */
  className?: string;

  /**
   * Padding in pixels used in both top and bottom when the chart is in a
   * scrollable state.
   */
  scrollPaddingY: number;

  /**
   * If the chart area is scrollable, this should be `true`
   */
  isScrollable?: boolean;
}

/**
 * A component to render a y-axis column
 */
export const ChartYAxis = ({
  chartYAxisUnit,
  chartYAxisUnitHeight,
  chartYAxisValues,
  className = 'w-10',
  isScrollable,
  scrollPaddingY,
}: ChartYAxisProps) => {
  return (
    <div
      className={classNames(
        'flex flex-col text-cool-gray-400 font-medium text-xs leading-[15px]',
        className,
      )}
      style={{
        ...(!isScrollable
          ? {}
          : {
              paddingTop: `${scrollPaddingY}px`,
              paddingBottom: `${scrollPaddingY}px`,
            }),
      }}
    >
      {chartYAxisValues.map((value, index) => (
        <div
          key={`y-axis-value-${value}`}
          style={{
            ...(index === chartYAxisValues.length - 1
              ? {}
              : {
                  height: `${chartYAxisUnitHeight}px`,
                }),
          }}
        >
          {value}
          {chartYAxisUnit}
        </div>
      ))}
    </div>
  );
};
