import { useRouteMatch } from 'react-router-dom';

import useConversationTimeline from './useConversationTimeline';
import { useSelectedMessageCursor } from './useSelectedMessageCursor';

// Designed to prefetch a timeline if the conversation is available in the url
// and there is no search hit query param
const useTimelinePrefetch = () => {
  const match = useRouteMatch<{ id: string; conversationId: string }>(
    '/cases/:id/messaging/conversations/:conversationId',
  );
  const selectedMessageCursor = useSelectedMessageCursor();

  const conversationId = selectedMessageCursor
    ? ''
    : match?.params.conversationId ?? '';
  useConversationTimeline(conversationId);
};

export default useTimelinePrefetch;
