import classNames from 'classnames';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';

export interface CheckboxLargeProps {
  /** Custom className */
  className?: string;

  /** If `true` will be checked */
  isActive?: boolean;

  // @TODO - add `isDisabled` when we need it
}

export const CheckboxLarge = ({ className, isActive }: CheckboxLargeProps) => {
  if (!isActive) {
    return (
      <span
        className={classNames(
          'w-[33px] h-[33px] bg-cool-gray-50 block rounded-full border-[3px] border-gray-100',
          className,
        )}
      />
    );
  }
  return (
    <IconFlatRenderer className={className} iconKey="ICON_FLAT_CHECK_CIRCLE" />
  );
};
