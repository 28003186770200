import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { IconFlatPlus } from '../Icon/IconFlatPlus';
import { IconFlatX } from '../Icon/IconFlatX';

const classNameIconButton =
  'flex w-8 h-8 justify-center items-center rounded-md hover:bg-cool-gray-50';

/**
 * A React component to render a timeslot row to include the selection
 * interface or prompt to add new
 */
export const AvailabilitySelectionTimeslot = ({
  children,
  id,
  onClickAdd,
  onClickRemove,
  shouldAnimate,
}: {
  /** The React Node to render as content */
  children: ReactNode;

  /** A unique DOM identifier used for the `motion` `key`  */
  id: string;

  /** Handler called on click of the add button */
  onClickAdd?: () => void;

  /** Handler called on click of the remove button */
  onClickRemove?: () => void;

  /** Set to `true` if the timeslot should animate on DOM enter and exit */
  shouldAnimate?: boolean;
}) => {
  return (
    <motion.div
      className="flex justify-between items-center gap-1 h-10 overflow-hidden"
      key={id}
      {...(!shouldAnimate
        ? undefined
        : {
            // subtle height animation from 30px -> 40px on enter, on exit -> 30px
            animate: {
              opacity: 1,
              height: '40px',
            },
            exit: {
              opacity: 0,
              height: '30px',
            },
            initial: {
              opacity: 0,
              height: '30px',
            },
            transition: {
              ease: 'easeInOut',
              duration: 0.1,
            },
          })}
    >
      <div className="flex items-center justify-between grow gap-1">
        {children}
      </div>
      <div
        className={classNames('flex items-center gap-1 w-[68px]', {
          'justify-end': onClickAdd,
          'justify-start': !onClickAdd,
        })}
      >
        {onClickRemove && (
          <button
            className={classNameIconButton}
            onClick={onClickRemove}
            type="button"
          >
            <IconFlatX
              className="[&_*]:fill-cool-gray-800"
              width={12}
              height={12}
            />
          </button>
        )}
        {onClickAdd && (
          <button
            className={classNameIconButton}
            onClick={onClickAdd}
            type="button"
          >
            <IconFlatPlus
              className="[&_*]:fill-cool-gray-500"
              width={14}
              height={14}
            />
          </button>
        )}
      </div>
    </motion.div>
  );
};
