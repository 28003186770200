import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface NotificationsProps {
  /** React node to render as content */
  children: ReactNode;

  /** `className` that will override via `tailwind-merge` */
  className?: string;
}

/**
 * A React component to render a notifications sidebar
 */
export const Notifications = ({ children, className }: NotificationsProps) => {
  return (
    <aside
      className={twMerge(
        'flex flex-col h-full overflow-hidden w-[440px] border-l border-cool-gray-200 bg-white',
        className,
      )}
    >
      {children}
    </aside>
  );
};
