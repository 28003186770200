import classnames from 'classnames';

import { ProgressBar } from '@tremor/react';

import { SparkChartContainer } from './SparkChartContainer';
import { SparkChartBaseType } from './sparkChartTypes';

import type { ChartContainerProps } from './ChartContainer';

import type { ProgressBarProps } from '@tremor/react';

type DataType = Record<string, string | number | undefined>;

interface ProgressBarListProps extends SparkChartBaseType {
  data?: DataType[];
  twoCols?: boolean;
}

type ProgressBarPropsWithoutValue = Omit<ProgressBarProps, 'value'>;

const ProgressBarItem = ({ datum }: { datum: DataType }) => {
  if (!datum) {
    return null;
  }

  return (
    <div className="pb-4">
      <p className="flex justify-between items-center pb-[10px] text-gray-500 text-sm">
        <span>{datum.location}</span>
        <span>{datum.label?.toLocaleString()}</span>
      </p>
      {/**
       * eslint-disable-next-line @typescript-eslint/ban-ts-comment
       * @ts-ignore */}
      <ProgressBar value={Number(datum.value) || 0} color="indigo-600" />
    </div>
  );
};

/**
 * A component to render a bar chart and its container layout.
 */
export const ProgressBarList = ({
  chartTitle,
  children,
  isLoading,
  chartSubTitle,
  maxWidth,
  twoCols = false,
  ...props
}: ProgressBarPropsWithoutValue &
  ChartContainerProps &
  ProgressBarListProps): JSX.Element => {
  const isEmpty = !isLoading && (!props.data || props.data.length === 0);

  return (
    <SparkChartContainer
      isEmpty={isEmpty}
      isLoading={isLoading}
      chartTitle={chartTitle}
      chartSubTitle={chartSubTitle}
      maxWidth={maxWidth}
    >
      <div
        className={classnames('w-full', {
          'grid grid-cols-2 gap-x-6': twoCols,
        })}
      >
        {props.data &&
          props.data.map(datum => (
            <ProgressBarItem
              key={`${datum.location}${datum.label}`}
              datum={datum}
            />
          ))}
      </div>
    </SparkChartContainer>
  );
};
