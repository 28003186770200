import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { SelectInput } from '@assured/shared-types/Services';

import { Accordion } from '../Accordion';
import { InfoDetailsSection } from '../InfoDetails';
import { InfoListItem } from '../InfoListItem';
import { Spinner } from '../Spinner';
import { PolicySearchCallerToggle } from './PolicySearchCallerToggle';
import { PolicySearchVersion } from './types';

import type {
  PolicySearchData,
  PolicySection,
  PolicySearchFilterPayload,
  PolicySearchFilterType,
} from '@assured/shared-types/Services/PolicySearch';
import { DriverOption, DriversOnPolicySearch } from './DriversOnPolicySearch';

interface AccordionSectionProps {
  /** The current selected policy that shows up in the aside */
  selectedPolicy: PolicySearchData;

  /** The section being rendered */
  section: PolicySection;

  /** Whether the section is collapsed or open */
  isCollapsed: boolean;

  /** A function that is called if you toggle an accordion item */
  onAccordionItemToggle?: (
    isOpen: boolean,
    policy: PolicySearchData,
    section: PolicySection,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;

  /** Verified items */
  verifiedItems: string[];

  /** Items disabled from being verified */
  blockedVerificationItems: string[];

  /** Callback of field item select or deselect */
  onItemSelect: (payload: { id: string; isSelected: boolean }) => void;

  /** Initial reporter selection */
  initialReporter?: SelectInput;

  /** Callback for when caller toggle changes */
  onCallerChange: (payload?: {
    policyNumber: string;
    contact: SelectInput;
    contactIndex: number;
  }) => void;

  /** PolicySearch version */
  version?: PolicySearchVersion;
}

// a simple transformation so the DriversOnPolicySearch component
// can conceptually deal with "drivers' names" rather than "subsection titles"
const subsectionsToDrivers = (
  subsections: {
    title: string;
  }[],
): DriverOption[] => {
  return subsections.map(subsection => ({
    name: subsection.title,
  }));
};

const AccordionSection = ({
  selectedPolicy,
  section,
  onAccordionItemToggle,
  isCollapsed,
  blockedVerificationItems,
  verifiedItems,
  onItemSelect,
  initialReporter,
  onCallerChange,
  version,
}: AccordionSectionProps) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      if (onAccordionItemToggle) {
        onAccordionItemToggle(
          !isCollapsed,
          selectedPolicy,
          section,
          setIsLoading,
        );
      }
    },
    // I do not want to pass in selectedPolicy and section but instead
    // selectedPolicy.info.policyNumber and section.title since those are unique
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isCollapsed,
      selectedPolicy.info.policyNumber,
      section.title,
      onAccordionItemToggle,
      setIsLoading,
    ],
  );

  const [subsectionTitlesFilter, setSubsectionTitlesFilter] = useState<
    string[] | undefined
  >(undefined);

  const ldClient = useLDClient();

  const isDriverSearchEnabled = ldClient?.variation(
    'eng-10387-search-by-driver',
    false,
  );

  const isDriversOnPolicySection = section.title === 'Drivers on Policy';

  return (
    <Fragment
      key={`accordion-section-frag-${selectedPolicy.info?.policyNumber}-${section.title}`}
    >
      {isLoading && (
        <div className="flex justify-center">
          <Spinner className="text-indigo-600" />
        </div>
      )}
      {isDriverSearchEnabled && isDriversOnPolicySection && (
        <DriversOnPolicySearch
          drivers={subsectionsToDrivers(section.sections)}
          onChange={drivers => {
            setSubsectionTitlesFilter(
              drivers.map(driver => {
                return driver.name;
              }),
            );
          }}
        />
      )}
      {isDriverSearchEnabled &&
        subsectionTitlesFilter &&
        !subsectionTitlesFilter.length && (
          <InfoDetailsSection subtitle="No search results." listItems={[]} />
        )}
      {section.sections.map((subsection, subsectionIndex) => {
        if (
          isDriverSearchEnabled &&
          subsectionTitlesFilter &&
          !subsectionTitlesFilter.includes(subsection.title)
        ) {
          return;
        }

        return (
          <InfoDetailsSection
            key={`accordion-section-${selectedPolicy.info?.policyNumber}-${section.title}-${subsection.title}`}
            listItems={subsection.items.map(item => ({
              id: `${selectedPolicy.info?.policyNumber}-${item.id}`,
              indentSize: item.indentSize,
              isActive: verifiedItems.includes(item.id),
              isDisabled: blockedVerificationItems.includes(item.id),
              onClick: !item.isVerifiable
                ? undefined
                : isSelected =>
                    onItemSelect({
                      id: item.id,
                      isSelected,
                    }),
              textPrimary: item.label,
              textSecondary: item.value,
              listItems: item.items?.map(subItem => ({
                id: `${selectedPolicy.info?.policyNumber}-${subItem.id}`,
                indentSize: subItem.indentSize,
                isActive: verifiedItems.includes(subItem.id),
                isDisabled: blockedVerificationItems.includes(subItem.id),
                onClick: !subItem.isVerifiable
                  ? undefined
                  : isSelected =>
                      onItemSelect({
                        id: subItem.id,
                        isSelected,
                      }),
                textPrimary: subItem.label,
                textSecondary: subItem.value,
                textSecondaryHasDanger: subItem.isInvalid,
              })),
              textSecondaryHasDanger: item.isInvalid,
            }))}
            subtitle={subsection.title}
            subtitleWidget={
              version === 2 && section.controls === 'CONTACT' ? (
                <PolicySearchCallerToggle
                  policyNumber={selectedPolicy.info.policyNumber}
                  contact={subsection}
                  contactIndex={subsectionIndex}
                  onCallerChange={onCallerChange}
                  callerSelection={initialReporter}
                  onItemSelect={onItemSelect}
                />
              ) : null
            }
            hasError={subsection.isInvalid}
          />
        );
      })}
    </Fragment>
  );
};

export const PolicySearchMain = ({
  onItemSelect,
  policySearchData,
  searchFilterType,
  searchFilter,
  selectedPolicyIndex,
  setSelectedPolicyIndex,
  verifiedItems,
  blockedVerificationItems,
  onPolicySelect,
  onAccordionItemToggle,
  version = 1,
  initialReporter,
  onCallerChange,
}: {
  /** Callback of field item select or deselect */
  onItemSelect: (payload: { id: string; isSelected: boolean }) => void;

  /**
   * A set of policy result data, fetched from a remote resource and
   * transformed
   */
  policySearchData: PolicySearchData[];

  /** Filter search results by one of these options */
  searchFilterType?: PolicySearchFilterType;

  /** Search payload sent to the API */
  searchFilter?: PolicySearchFilterPayload;

  /** A function that is called if you select a policy */
  onPolicySelect?: (policy: PolicySearchData) => void;

  /** A function that is called if you toggle an accordion item */
  onAccordionItemToggle?: (
    isOpen: boolean,
    policy: PolicySearchData,
    section: PolicySection,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;

  /** Selected policy index */
  selectedPolicyIndex: number;

  /** Selected policy index setter */
  setSelectedPolicyIndex: (selectedPolicyIndex: number) => void;

  /** Verified items */
  verifiedItems: string[];

  /** Items disabled from being verified */
  blockedVerificationItems: string[];

  /** Version of the Policy Search component */
  version?: PolicySearchVersion;

  /** Initial reporter selection */
  initialReporter?: SelectInput;

  /** Callback for when caller toggle changes */
  onCallerChange: (payload?: {
    policyNumber: string;
    contact: SelectInput;
    contactIndex: number;
  }) => void;
}) => {
  const isSearchPersonalDetails = searchFilterType === 'NAME';

  const isolateSections = version === 2;

  const selectedPolicy = policySearchData[selectedPolicyIndex];
  const policySections = selectedPolicy?.sections || [];

  const separatedSections = isolateSections
    ? policySections.reduce((acc, section) => {
        if (section.isolate) {
          return [...(acc.length ? acc : [[]]), [section]];
        }
        if (acc.length === 0) {
          acc.push([section]);
        } else {
          acc[0].push(section);
        }
        return acc;
      }, [] as PolicySection[][])
    : [policySections];

  const sectionCount = separatedSections.length;

  return (
    <main className="flex flex-nowrap w-full">
      <div
        className={classNames(
          'basis-[calc(100%-297px)] min-w-[180px] overflow-y-auto flex flex-col',
          {
            'basis-[calc(100%-594px)]': sectionCount > 1,
          },
        )}
      >
        {policySearchData.map((policySearchDatum, index) => {
          const { info } = policySearchDatum;
          return (
            <InfoListItem
              chipContentPrimary={info.productCode}
              chipContentSecondary={info.state}
              hasAlternateChipPosition={isSearchPersonalDetails}
              highlightedText={
                // eslint-disable-next-line no-nested-ternary
                searchFilterType === 'NAME'
                  ? searchFilter?.name
                  : searchFilterType === 'DRIVER_LICENSE'
                  ? searchFilter?.licenseNumber
                  : searchFilter?.policyNumber
              }
              highlightedTextMode={
                // eslint-disable-next-line no-nested-ternary
                searchFilterType === 'NAME'
                  ? 'SUBTITLE_PRIMARY'
                  : searchFilterType === 'DRIVER_LICENSE'
                  ? 'TITLE_SECONDARY'
                  : 'TITLE_PRIMARY'
              }
              isSelected={selectedPolicyIndex === index}
              onClick={() => {
                if (onPolicySelect) {
                  onPolicySelect(policySearchDatum);
                }
                setSelectedPolicyIndex(index);
              }}
              onFocus={() => {
                setSelectedPolicyIndex(index);
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={`info-list-item-policy-${info.policyNumber}-${info.nameMatched}-${index}`}
              subtitlePrimary={info.nameMatched}
              subtitleSecondary={
                // eslint-disable-next-line no-nested-ternary
                isSearchPersonalDetails
                  ? info.vehicleLabel
                  : searchFilterType === 'DRIVER_LICENSE'
                  ? `Driver's license`
                  : info.address
              }
              titlePrimary={info.policyNumber}
              titleSecondary={
                // eslint-disable-next-line no-nested-ternary
                isSearchPersonalDetails
                  ? info.address
                  : searchFilterType === 'DRIVER_LICENSE'
                  ? info.driverLicense
                  : info.vehicleLabel
              }
            />
          );
        })}
      </div>

      {separatedSections.map((sections, i) =>
        sections.length ? (
          <aside
            key={sections[0].title}
            className={classNames(
              'basis-[297px] border-l border-l-cool-gray-200 overflow-y-auto pb-4',
              {
                'bg-cool-gray-50': i % 2 === 0,
              },
            )}
          >
            <Accordion
              items={sections.map((section, index) => ({
                // eslint does not want me to use a children function
                // i investigated the alternative (react.cloneElement) and the code is much harder to follow
                // so just ignoring this eslint rule
                // eslint-disable-next-line react/no-unstable-nested-components
                children: isCollapsed => (
                  <AccordionSection
                    // eslint-disable-next-line react/no-array-index-key
                    key={`accordion-section-${selectedPolicy?.info?.policyNumber}-${section.title}-${index}`}
                    selectedPolicy={policySearchData[selectedPolicyIndex]}
                    section={section}
                    isCollapsed={isCollapsed}
                    onAccordionItemToggle={onAccordionItemToggle}
                    blockedVerificationItems={blockedVerificationItems}
                    verifiedItems={verifiedItems}
                    onItemSelect={onItemSelect}
                    initialReporter={initialReporter}
                    onCallerChange={onCallerChange}
                    version={version}
                  />
                ),
                hasDanger: section.isInvalid,
                isOpenByDefault: version === 2 ? index < 2 : index === 0, // pre-open details & vehicles in v2, details in v1
                title: section.title,
                forceOpen: sections.length === 1,
              }))}
            />
          </aside>
        ) : null,
      )}
    </main>
  );
};
