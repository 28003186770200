import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface AvatarIconProps {
  /** React node intended to be the rendered icon component */
  children: ReactNode;

  /** `className` that will override via `tailwind-merge` */
  className?: string;
}

/**
 * A React component to render an avatar containing icon
 */
export const AvatarIcon = ({ children, className }: AvatarIconProps) => {
  return (
    <div
      className={twMerge(
        'flex w-6 h-6 justify-center items-center bg-cool-gray-100 border border-cool-gray-200 rounded-full',
        className,
      )}
    >
      {children}
    </div>
  );
};
