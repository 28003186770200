import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SelectInput } from '@assured/shared-types/Services';
import { CallerVerificationOutput } from '@assured/shared-types/Services/PolicySearch';
import { useKeyboardShortcut } from '@assured/ui/hooks';

import { Banner } from '../Banner';
import { IconEmojiRenderer } from '../Icon/IconEmojiRenderer';
import { TokenExtractorValueForConfig } from '../Input/TextTokenExtractor';
import { ProgressivePolicySearchDefaultTokenExtractorConfig } from '../Input/TextTokenExtractorDefaults';
import { ListActionContainer } from '../ListActionContainer';
import { SvgMagnifyingGlassWithPlus } from '../Svg/SvgMagnifyingGlassWithPlus';
import { useCallerVerification } from './hooks/useCallerVerification';
import { usePolicySearchModal } from './hooks/usePolicySearchModal';
import { PolicySearchFooter } from './PolicySearchFooter';
import { PolicySearchInput } from './PolicySearchInput';
import { PolicySearchMain } from './PolicySearchMain';
import { PolicySearchModal } from './PolicySearchModal';
import { SearchLoading } from './SearchLoading';
import { PolicySearchModalActionType } from './state/policySearchModalReducer';
import { PolicySearchVersion } from './types';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { BannerProps } from '../Banner';
import type {
  PolicySearchAlertType,
  PolicySearchData,
  PolicySection,
  PolicySearchFilterPayload,
  PolicySearchFilterType,
  PolicySearchSubmitPayload,
  VerifyCallerArguments,
} from '@assured/shared-types/Services/PolicySearch';

export type { PolicySearchVersion };

export type PolicySearchControlComponent = 'subsectionWidget' | 'banner';

export interface PolicySearchProps {
  /** Custom className */
  className?: string;

  /** Custom style */
  style?: React.CSSProperties;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** Set to `true` if the initial data per search is loading */
  isLoading?: boolean;

  /**
   * A set of policy result data, fetched from a remote resource and
   * transformed
   */
  policySearchData: PolicySearchData[];

  /** Filter search results by one of these options */
  searchFilterType?: PolicySearchFilterType;

  /** Search filter payload sent to the API */
  searchFilter?: PolicySearchFilterPayload;

  /**
   * A function to trigger search functionality. It should be safe to assume
   * it's debounced.
   */
  setSearchFilter: (payload: PolicySearchFilterPayload) => void;

  /** A function to submit policy search result */
  submit: (payload: PolicySearchSubmitPayload) => void;

  /** A function that is called if you select a policy */
  onPolicySelect?: (policy: PolicySearchData) => void;

  /** A function that is called if you toggle an accordion item */
  onAccordionItemToggle?: (
    isOpen: boolean,
    policy: PolicySearchData,
    section: PolicySection,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;

  /** A function that is called when the modal is mounted */
  onModalMount?: (
    modalType: PolicySearchAlertType,
    isOpen: boolean,
    policy?: PolicySearchData,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;

  /** A function provided by consumer to verify the policy based on selected fields */
  verifyPolicy: (payload: {
    itemsVerified: string[];
    allPossibleItems: string[];
    policyNumber?: string;
    hasMultiplePoliciesWithSameName?: boolean;
    searchFilterType?: PolicySearchFilterType;
  }) => Promise<{
    verified: boolean;
    blockedItems: string[];
    forceVerifiedItems: string[];
  }>;

  /** A function provided by consumer to verify the caller based on selected fields */
  verifyCaller: (payload: VerifyCallerArguments) => CallerVerificationOutput;

  /** A select input of the initialReporter on the claim */
  initialReporter?: SelectInput;

  /** A function to set the initial reporter */
  setInitialReporter: (payload: SelectInput) => void;

  /** The original initial reporter */
  originalInitialReporter?: SelectInput;

  /** Items to preset as verified for the first policy */
  initialItemsVerified?: string[];

  /** Show an error when the user selects "manual loss" */
  disallowManualLoss?: boolean;

  /** Consider this phone number as 'pre-verified' */
  preverifiedPhoneNumber?: string;

  /** Version of the policy search component */
  version?: PolicySearchVersion;
}

const prefilledBannerProps: BannerProps = {
  children: 'Policy number has been pre-filled',
  variant: 'success',
};

export const PolicySearch = ({
  className,
  style,
  dataTestId,
  isLoading,
  policySearchData,
  searchFilterType = 'POLICY_NUMBER',
  searchFilter,
  setSearchFilter,
  onPolicySelect,
  onAccordionItemToggle,
  onModalMount,
  submit,
  verifyPolicy,
  verifyCaller,
  initialReporter,
  setInitialReporter,
  originalInitialReporter,
  initialItemsVerified,
  disallowManualLoss,
  preverifiedPhoneNumber,
  version = 1,
}: PolicySearchProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSearchFilter = useMemo(() => searchFilter, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSearchFilterType = useMemo(() => searchFilterType, []);
  const setSearchFilterDebounced = debounce(setSearchFilter, 200);
  const [hasNoResults, setHasNoResults] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [blockedVerificationItems, setBlockedVerificationItems] = useState<
    string[]
  >([]);
  const [bannerProps, setBannerProps] = useState<BannerProps | undefined>(
    !initialSearchFilter?.policyNumber || initialItemsVerified?.length
      ? undefined
      : prefilledBannerProps,
  );
  const [selectedPolicyIndex, setSelectedPolicyIndex] = useState<number>(0);

  const [itemsVerified, setItemsVerified] = useState<Record<string, string>>(
    (initialItemsVerified || []).reduce((v, id) => {
      // eslint-disable-next-line no-param-reassign
      v[id] = id;
      return v;
    }, {} as { [key: string]: string }),
  );
  const ldClient = useLDClient();
  const enableCommercialAutoPolicySearchRemoval = ldClient?.variation(
    'eng-8171_pgr_sk_commercial_policy_attached_to_manual_loss',
  );
  const {
    callerVerification,
    onCallerChange,
    resetCallerVerification,
    forceVerifiedItemSelectors,
  } = useCallerVerification({
    initialReporter,
    setInitialReporter,
    originalInitialReporter,
    verifyCaller,
    itemsVerified,
  });

  const handleItemSelect = useCallback(
    item => {
      // if item was deselected
      if (
        !item.isSelected &&
        !forceVerifiedItemSelectors.some(f => f(item.id))
      ) {
        setItemsVerified(
          ({ [item.id]: _ommitted, ...updatedItemsVerified }) =>
            updatedItemsVerified,
        );
      } else if (item.isSelected) {
        // if item was selected
        setItemsVerified(prevItemsVerified => ({
          ...prevItemsVerified,
          [item.id]: item.id,
        }));
      }
    },
    [setItemsVerified, forceVerifiedItemSelectors],
  );

  const selectedPolicyData = !policySearchData.length
    ? undefined
    : policySearchData[selectedPolicyIndex];
  const [modalState, modalDispatch] = usePolicySearchModal(selectedPolicyData);

  // handler for arrow up and down keys
  const handleArrow = (direction: 'up' | 'down') => {
    if (!policySearchData.length) {
      return;
    }
    const isUp = direction === 'up';
    const updatedSelectedPolicyIndex = isUp
      ? selectedPolicyIndex - 1
      : selectedPolicyIndex + 1;
    const shouldRepeat = isUp
      ? updatedSelectedPolicyIndex < 0
      : updatedSelectedPolicyIndex > policySearchData.length - 1;

    // repeat over list
    if (shouldRepeat) {
      setSelectedPolicyIndex(isUp ? policySearchData.length - 1 : 0);
      return;
    }
    setSelectedPolicyIndex(updatedSelectedPolicyIndex);
  };

  // handle "attach policy" trigger
  const onClickAttachPolicy = (payload?: PolicySearchSubmitPayload) => {
    // submit a payload directly if provided
    if (payload) {
      if (payload.metaData?.isManualLoss && searchFilter?.policyNumber) {
        // insert currently entered policy number for manual losses
        // unless the policy is for commercial auto
        if (
          !(
            enableCommercialAutoPolicySearchRemoval &&
            policySearchData.some(
              data => data.info.productCode.toLowerCase() === 'ca',
            )
          )
        ) {
          // eslint-disable-next-line no-param-reassign
          payload.policyNumber = searchFilter.policyNumber;
        }
      }
      submit(payload);
    } else if (isVerified && selectedPolicyData?.info?.policyNumber) {
      // determine payload based on state.
      // first check if we have any "alerts" that are unacknowledged
      const modalTypeUnacknowledged = !modalState?.modals
        ? undefined
        : (Object.keys(modalState.modals) as PolicySearchAlertType[]).find(
            modalKey =>
              // if the modal is open, we can assume we've acknowledged it
              (!modalState.isOpen || modalKey !== modalState.selectedModal) &&
              // if the modal is not yet acknowledged
              !modalState?.modals?.[modalKey].isAcknowledged &&
              // if the modal requires acknowledgment
              modalState?.modals?.[modalKey].shouldRequireAcknowledgement,
          );

      // if we have an unacknowledged modal, let's open it
      if (modalTypeUnacknowledged) {
        modalDispatch({
          type: PolicySearchModalActionType.OPEN,
          payload: {
            modalType: modalTypeUnacknowledged,
          },
        });
      } else {
        // otherwise, if we've reach this point, we gucci
        submit({
          policyNumber: selectedPolicyData.info.policyNumber,
          metaData: {
            isVerified,
            itemsVerified: Object.values(itemsVerified),
            callerIsVerified: callerVerification?.callerVerified,
          },
        });
      }
    }
  };

  // keyboard shortcuts
  useKeyboardShortcut(['arrowup'], () => handleArrow('up'));
  useKeyboardShortcut(['arrowdown'], () => handleArrow('down'));
  const { disabled: enterShortcutDisabled } = useKeyboardShortcut(
    ['enter'],
    onClickAttachPolicy,
  );

  // handle what is shown in the search input box
  const [tokenExtractorValue, setTokenExtractorValue] = useState<
    TokenExtractorValueForConfig<
      typeof ProgressivePolicySearchDefaultTokenExtractorConfig
    >
  >({
    text: initialSearchFilter?.policyNumber
      ? `${initialSearchFilter?.policyNumber},` // include comma to force parsing
      : '',
    matches: [],
  });

  // show hint to the user based on their currently extracted fields
  let hintText;
  if (
    tokenExtractorValue.matches.some(
      m =>
        m.type === 'address' || m.type === 'state' || m.type === 'postal_code',
    ) &&
    !tokenExtractorValue.matches.some(
      m => m.type === 'name' || m.type === 'driver_license',
    )
  ) {
    hintText = 'Hint: add a Name to your search';
  } else if (
    tokenExtractorValue.matches.some(m => m.type === 'driver_license') &&
    !tokenExtractorValue.matches.some(m => m.type === 'state')
  ) {
    hintText = 'Hint: add a State to your search';
  }

  // when results from the search input change, fire new search
  useEffect(() => {
    const getMatch = (
      type: (typeof tokenExtractorValue)['matches'][number]['type'],
    ) => tokenExtractorValue.matches.find(m => m.type === type)?.value;
    const policyNumber = getMatch('policy_number');
    const licenseNumber = getMatch('driver_license');
    const stateCode = getMatch('state');
    const name = getMatch('name');
    const postalCode = getMatch('postal_code');
    const address = getMatch('address');
    setSearchFilterDebounced({
      policyNumber,
      licenseNumber,
      stateCode,
      name,
      postalCode,
      address,
      // productCode, // not currently available to filter
    });
    setSelectedPolicyIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenExtractorValue]);

  // if we show the prefilled banner and search filter has changed
  useEffect(() => {
    if (
      bannerProps &&
      initialSearchFilter?.policyNumber &&
      tokenExtractorValue.text !== `${initialSearchFilter?.policyNumber},` // default value
    ) {
      setBannerProps(undefined);
    }
  }, [bannerProps, initialSearchFilter, tokenExtractorValue.text]);

  // when search filter type changes from initial
  useEffect(() => {
    // remove banner
    if (searchFilterType !== initialSearchFilterType) {
      setBannerProps(undefined);
    }
  }, [initialSearchFilterType, searchFilterType]);

  // when selected policy changes - reset things
  useEffect(() => {
    if (
      initialSearchFilter?.policyNumber &&
      (initialSearchFilter?.policyNumber ===
        selectedPolicyData?.info.policyNumber ||
        !selectedPolicyData?.info.policyNumber)
    ) {
      // Special case: Don't reset verified items if we're loading a policy that was pre-filled
      return;
    }

    setItemsVerified({});
    setIsVerified(false);
    resetCallerVerification(selectedPolicyData?.info.policyNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialSearchFilter?.policyNumber,
    selectedPolicyData?.info.policyNumber,
  ]);

  // when verified items change check if they qualify the policy as "verified"
  useEffect(() => {
    const getData = async () => {
      const allPossibleItems =
        selectedPolicyData?.sections
          .map(s => s.sections.map(ss => ss.items.map(i => i)))
          .flat(2)
          .filter(Boolean) ?? [];

      const { verified, blockedItems, forceVerifiedItems } = await verifyPolicy(
        {
          itemsVerified: Object.values(itemsVerified),
          allPossibleItems: allPossibleItems.map(i => i.id),
          policyNumber: selectedPolicyData?.info?.policyNumber,
          hasMultiplePoliciesWithSameName:
            selectedPolicyData &&
            policySearchData.some(
              (policy, index) =>
                index !== selectedPolicyIndex &&
                policy.info.nameMatched ===
                  selectedPolicyData?.info.nameMatched,
            ),
          searchFilterType,
        },
      );
      setIsVerified(verified);
      setBlockedVerificationItems(blockedItems);

      const newItemsVerified = { ...itemsVerified };
      let hasChangedItemsVerified = false;

      // Force verify phone number if it was prefilled and matches.
      if (preverifiedPhoneNumber) {
        const phoneNumberItem = allPossibleItems.find(
          i =>
            i.id === 'phone_number' &&
            `+1${i.value?.replace(/[^0-9]/g, '')}` === preverifiedPhoneNumber,
        );
        if (phoneNumberItem && !itemsVerified[phoneNumberItem.id]) {
          newItemsVerified[phoneNumberItem.id] = phoneNumberItem.id;
          hasChangedItemsVerified = true;
        }
      }

      // If the force verification logic has added an item as verified that wasn't verified
      // before, add it to the verified items.
      if (forceVerifiedItems.some(id => !itemsVerified[id])) {
        forceVerifiedItems.forEach(id => {
          newItemsVerified[id] = id;
        });
        hasChangedItemsVerified = true;
      }

      // If the blocking logic has blocked a key the user previously verified, unset it
      // so that we don't consider it a verified factor.
      const nowBlockedVerifiedKeys = Object.keys(itemsVerified).filter(key =>
        blockedItems.includes(key),
      );
      if (nowBlockedVerifiedKeys.length) {
        nowBlockedVerifiedKeys.forEach(key => {
          delete newItemsVerified[key];
        });
        hasChangedItemsVerified = true;
      }

      if (hasChangedItemsVerified) {
        setItemsVerified(newItemsVerified);
      }
    };
    if (selectedPolicyData) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicyData, policySearchData, searchFilterType, itemsVerified]);

  // when search data has changed
  const isInitialPolicySearchData = useRef(true);
  useEffect(() => {
    if (isInitialPolicySearchData.current) {
      isInitialPolicySearchData.current = false;
      return;
    }
    if (isLoading) {
      return;
    }
    if (!policySearchData.length) {
      setHasNoResults(true);
    } else {
      setHasNoResults(false);
    }
  }, [policySearchData, isLoading]);

  const shouldShowImagery = !policySearchData.length || hasNoResults;

  /**
   * Returns `true` if there are more search input groups than matching
   * groups, signifying the user is entering text.
   */
  const hasUnmatchedInput = useMemo(() => {
    let searchTerm = tokenExtractorValue.text.replace(/\s/g, '');

    // removes trailing commas if existent
    if (searchTerm.slice(-1) === ',') {
      searchTerm = searchTerm.slice(0, -1);
    }

    // an array of search input groups
    const searchTerms = searchTerm.split(',');

    // are there more search input groups than matching groups
    return searchTerms.length > tokenExtractorValue.matches.length;
  }, [tokenExtractorValue]);

  return (
    <>
      <div
        className={classNames('w-full flex flex-col gap-3', className)}
        style={style}
        data-testid="policy-search"
      >
        {bannerProps && (
          <Banner variant={bannerProps.variant}>{bannerProps.children}</Banner>
        )}
        <PolicySearchInput
          dataTestId={dataTestId}
          data-enable-shortcuts="arrowup,arrowdown"
          type="token_extractor"
          tokenExtractorConfig={
            ProgressivePolicySearchDefaultTokenExtractorConfig
          }
          value={JSON.stringify(tokenExtractorValue)}
          onChange={t => {
            setTokenExtractorValue(JSON.parse(t));
          }}
          hintText={hintText}
          isLoading={!!policySearchData.length && isLoading}
          shouldPromptToSearch={
            !tokenExtractorValue.matches.length || hasUnmatchedInput
          }
          shouldShowTrailer={!!tokenExtractorValue.text && !hasNoResults}
        />
        <ListActionContainer
          className={classNames({
            'justify-center items-center': shouldShowImagery,
            'justify-start': !shouldShowImagery,
          })}
          footer={
            <PolicySearchFooter
              isVerified={isVerified}
              verifiedItemsCount={Object.values(itemsVerified).length}
              modalDispatch={action => {
                if (
                  disallowManualLoss &&
                  action.payload?.modalType === 'MANUAL_LOSS'
                ) {
                  // eslint-disable-next-line no-param-reassign
                  action.payload.modalType = 'MANUAL_LOSS_NOT_ALLOWED';
                }
                return modalDispatch(action);
              }}
              onClickAttachPolicy={onClickAttachPolicy}
              shortcutDisabled={enterShortcutDisabled}
              callerVerification={callerVerification}
              version={version}
            />
          }
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {hasNoResults ? (
            <div className="flex flex-col gap-2 justify-center items-center">
              <IconEmojiRenderer
                iconKey="ICON_EMOJI_BUMMER"
                width={80}
                height={80}
              />
              <span className="text-sm text-cool-gray-500 font-medium">
                No results found. Please try another search.
              </span>
            </div>
          ) : !policySearchData.length ? (
            <div className="flex flex-col gap-2 justify-center items-center">
              <SearchLoading
                className={classNames('w-[140px] translate-x-3', {
                  hidden: !isLoading,
                })}
                shouldLoop
              />
              <SvgMagnifyingGlassWithPlus
                className={classNames({
                  hidden: isLoading,
                })}
              />
              <span className="text-sm text-cool-gray-500 font-medium">
                {isLoading
                  ? `Searching for policies...`
                  : `Start typing to search for policies`}
              </span>
            </div>
          ) : (
            <PolicySearchMain
              onItemSelect={handleItemSelect}
              onPolicySelect={onPolicySelect}
              onAccordionItemToggle={onAccordionItemToggle}
              policySearchData={policySearchData}
              searchFilterType={searchFilterType}
              searchFilter={searchFilter}
              selectedPolicyIndex={selectedPolicyIndex}
              setSelectedPolicyIndex={setSelectedPolicyIndex}
              verifiedItems={Object.values(itemsVerified)}
              blockedVerificationItems={blockedVerificationItems}
              version={version}
              onCallerChange={onCallerChange}
              initialReporter={initialReporter}
            />
          )}
        </ListActionContainer>
      </div>
      <PolicySearchModal
        modalDispatch={modalDispatch}
        modalState={modalState}
        modalType={modalState.selectedModal}
        onClickAttachPolicy={onClickAttachPolicy}
        onModalMount={onModalMount}
      />
    </>
  );
};
