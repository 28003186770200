import { AnimatePresence, motion } from 'framer-motion';
import {
  createContext,
  forwardRef,
  HTMLAttributes,
  HTMLProps,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';

interface ModalOptions {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  closeOnOutsideClick?: boolean;
  closeOnEscape?: boolean;
}

export const useModal = ({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  closeOnEscape = true,
  closeOnOutsideClick = true,
}: ModalOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  });

  const { context } = data;

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context, {
    outsidePress: closeOnOutsideClick,
    escapeKey: closeOnEscape,
  });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId],
  );
};

type ContextType =
  | (ReturnType<typeof useModal> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<
        React.SetStateAction<string | undefined>
      >;
    })
  | null;

const ModalContext = createContext<ContextType>(null);

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context == null) {
    throw new Error('Modal components must be wrapped in <Modal />');
  }

  return context;
};

export const Modal = ({
  children,
  ...options
}: {
  children: ReactNode;
} & ModalOptions) => {
  const dialog = useModal(options);

  return (
    <ModalContext.Provider value={dialog}>{children}</ModalContext.Provider>
  );
};

const modalVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

export const ModalContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(({ className, children, ...rest }, propRef) => {
  const { context: floatingContext, ...context } = useModalContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  const classes = twMerge(
    'bg-white relative mx-auto max-w-[500px] rounded z-20 shadow-xl overflow-hidden my-4',
    className,
  );

  return (
    <AnimatePresence>
      {floatingContext.open && (
        <FloatingPortal>
          <FloatingOverlay
            style={{
              zIndex: 1001,
            }}
            lockScroll
          >
            <FloatingFocusManager context={floatingContext}>
              <motion.div
                className="fixed overflow-auto z-10 w-full h-full top-0 left-0 justify-center bg-cool-gray-900/50 py-4"
                variants={modalVariants}
                style={{
                  zIndex: 1000,
                }}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <div
                  ref={ref}
                  aria-labelledby={context.labelId}
                  aria-describedby={context.descriptionId}
                  {...context.getFloatingProps(rest)}
                  className={classes}
                  style={{
                    zIndex: 1002,
                  }}
                >
                  {children}
                </div>
              </motion.div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </AnimatePresence>
  );
});

export const ModalHeader = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, ...rest }, ref) => {
  return (
    <header
      className="flex items-center justify-center p-4"
      {...rest}
      ref={ref}
    >
      {children}
    </header>
  );
});

export const ModalBody = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, ...rest }, ref) => {
  return (
    <main className="overflow-hidden px-5" {...rest} ref={ref}>
      {children}
    </main>
  );
});

export const ModalTitle = forwardRef<
  HTMLHeadingElement,
  HTMLProps<HTMLHeadingElement>
>(({ children, ...props }, ref) => {
  const { setLabelId } = useModalContext();
  const id = useId();

  // Only sets `aria-labelledby` on the Modal root element
  // if this component is mounted inside it.
  useLayoutEffect(() => {
    setLabelId(id);
    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return (
    <h2
      className="text-lg text-center leading-6 font-medium text-gray-900"
      {...props}
      ref={ref}
      id={id}
    >
      {children}
    </h2>
  );
});
