import { lazy, Suspense } from 'react';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

import useLocalSettings from '../../hooks/useLocalSettings';
import logo from '../../images/icons/logo.svg';
import ClaimLookup from '../shared/ClaimLookup';
import { ProfileMenu } from './ProfileMenu';

const Inbox = lazy(() => import('../Inbox'));

export const ReducedHeader = () => {
  const { settings, updateSettings } = useLocalSettings();
  const isClaimLookupOn = useFeatureFlag<boolean>(
    'eng-10121-reduced-header-claim-lookup',
    false,
  );

  return (
    <header className="w-full border-b border-gray-200 shadow-sm bg-white">
      <div className="relative z-[60] flex-shrink-0 h-16 flex">
        <div className="flex-1 flex justify-between px-4 sm:px-6">
          <div className="flex-1 flex py-4">
            <img className="h-full w-auto invert" src={logo} alt="Assured" />
          </div>
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            <div className="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
              {isClaimLookupOn && <ClaimLookup />}
              <div className="flex items-center space-x-8">
                <Suspense fallback={<div className="w-8 h-8" />}>
                  <Inbox />
                </Suspense>
                <ProfileMenu
                  settings={settings}
                  updateSettings={updateSettings}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
