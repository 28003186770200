import classNames from 'classnames';

import { Button } from '../Button/Button';
import { ShortcutKeyIcon } from '../ShortcutKeyIcon';

import type { ButtonSelectSingleVariantProps } from './ButtonSelectSingleProps';

export const ButtonSelectSingleLong = ({
  children,
  className,
  innerClassName,
  dataTestId = 'button-select-single-long',
  hasLargeBorderRadius,
  isActive,
  isHovered,
  isMulti,
  leadingIcon,
  onSelection,
  setIsActive,
  setIsHovered,
  shortcutKey,
  shortcutDisabled,
  subtext,
  trailingContent,
  secondRow,
  minWidthMode,
}: ButtonSelectSingleVariantProps) => {
  return (
    <Button
      className={classNames(
        {
          // using an "inset border" to prevent layout shift on
          // toggling of active and inactive states
          'min-w-[200px]': minWidthMode,
          'shadow-[inset_0_0_0_2px_#4F46E5]': isActive,
        },
        className,
      )}
      innerClassName={innerClassName}
      classNameText={classNames({
        'inline-flex items-center basis-full': isMulti,
      })}
      colorBackground={!isHovered ? 'white' : 'indigo-bright-50'}
      colorBorder={!isActive ? 'cool-gray-300' : 'transparent'}
      colorText="cool-gray-600"
      data-testid={dataTestId}
      hasEqualPadding
      hasLargeBorderRadius={hasLargeBorderRadius}
      hasLargePadding={isMulti}
      hasLighterFont
      leadingIcon={
        shortcutKey || leadingIcon ? (
          <span
            className={classNames('flex items-center', {
              'gap-2': !isMulti,
              'gap-5': isMulti,
            })}
          >
            {shortcutKey ? (
              <ShortcutKeyIcon
                isDisabled={shortcutDisabled}
                isActive={isActive}
              >
                {shortcutKey}
              </ShortcutKeyIcon>
            ) : null}
            {leadingIcon}
          </span>
        ) : null
      }
      onClick={() => {
        if (onSelection) {
          onSelection(!isActive);
        }
        setIsActive(!isActive);
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      trailingContent={trailingContent}
      secondRow={secondRow}
      variant="default"
    >
      <span
        className={classNames({
          'flex flex-col basis-full gap-2 items-start': isMulti,
        })}
      >
        {children}
        {!!subtext && (
          <span
            className={classNames('text-cool-gray-400', {
              'ml-2': !isMulti,
              'text-sm leading-5': isMulti,
            })}
          >
            {subtext}
          </span>
        )}
      </span>
    </Button>
  );
};
