import cloneDeep from 'lodash.clonedeep';
import { useEffect, useReducer } from 'react';

import {
  initialState,
  PolicySearchModalActionType,
  policySearchModalReducer,
} from '../state/policySearchModalReducer';

import type { PolicySearchData } from '@assured/shared-types/Services/PolicySearch';

import type {
  PolicySearchModalAction,
  PolicySearchModalCollection,
  PolicySearchModalState,
} from '../state/policySearchModalReducer';

/**
 * This function extracts "alert" data from policy data and populates details
 * in modal states.
 */
const getUpdatedModalCollection = (
  selectedPolicyData?: PolicySearchData,
): PolicySearchModalCollection | undefined => {
  if (!selectedPolicyData || !initialState.modals) {
    return initialState.modals;
  }

  const modals = cloneDeep(initialState.modals);

  for (const section of selectedPolicyData?.sections || []) {
    for (const alert of section.alerts || []) {
      modals[alert.alertType] = {
        ...modals[alert.alertType],
        // duplicative data by adding selectedPolicy... makes code much easier to follow though
        selectedPolicy: selectedPolicyData,
        shouldRequireAcknowledgement: true,
        details: {
          items:
            alert.details?.items?.map(item => ({
              textPrimary: item.label,
              textSecondary: item.value,
              textSecondaryHasDanger: item.isInvalid,
              listItems: item.items?.map(subItem => ({
                textPrimary: subItem.label,
                textSecondary: subItem.value,
                textSecondaryHasDanger: subItem.isInvalid,
              })),
            })) || [],
          subtitle: alert.details?.subtitle,
          title: alert.details?.title,
          hasError: alert.details?.isInvalid,
        },
      };
    }
  }
  return modals;
};

export const usePolicySearchModal = (
  policyData: PolicySearchData | undefined,
): [PolicySearchModalState, React.Dispatch<PolicySearchModalAction>] => {
  const [state, dispatch] = useReducer(
    policySearchModalReducer,
    cloneDeep(initialState),
  );

  useEffect(() => {
    const updatedModalCollection = getUpdatedModalCollection(policyData);
    if (updatedModalCollection) {
      dispatch({
        type: PolicySearchModalActionType.UPDATE_MODALS,
        payload: {
          modals: updatedModalCollection,
        },
      });
    }
  }, [policyData]);

  return [state, dispatch];
};
