import classNames from 'classnames';
import { ReactNode } from 'react';

export interface ListActionContainerProps {
  /** Child node */
  children: ReactNode;

  /** Custom className */
  className?: string;

  /** Custom className for the padding bottom styles */
  classNamePaddingBottom?: string;

  /** Footer child node */
  footer: ReactNode;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;
}

export const ListActionContainer = ({
  children,
  className,
  classNamePaddingBottom,
  dataTestId = 'list-action-container',
  footer,
}: ListActionContainerProps) => {
  return (
    <div
      className={classNames(
        'relative min-h-[250px] grow border border-cool-gray-200 flex rounded-lg shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_3px_0_rgba(0,0,0,0.10)] overflow-y-hidden',
        {
          'pb-[72px]': !classNamePaddingBottom,
        },
        className,
        classNamePaddingBottom,
      )}
      data-testid={dataTestId}
    >
      {children}
      <footer className="p-4 absolute bottom-0 left-0 z-10 w-full bg-white">
        <div className="absolute w-full h-[1px] bg-cool-gray-200 top-0 left-0 shadow-[0_-4px_10px_0_rgba(0,0,0,0.25)]" />
        {footer}
      </footer>
    </div>
  );
};
