import classNames from 'classnames';

import { CallerVerificationOutput } from '@assured/shared-types/Services/PolicySearch';

import { StatusIcon, StatusIconProps } from '../StatusIcon/StatusIcon';

interface PolicySearchCallerVerificationBarProps
  extends CallerVerificationOutput {
  callerSelected: boolean;
}

export const PolicySearchCallerVerificationBar = ({
  callerSelected,
  callerVerified,
  primaryCount,
  secondaryCount,
  primaryRequired,
  secondaryRequired,
}: PolicySearchCallerVerificationBarProps) => {
  const primaryVerified = primaryCount >= primaryRequired ? 1 : 0;
  const secondaryVerified = secondaryCount >= secondaryRequired ? 1 : 0;
  const completedChecks = primaryVerified + secondaryVerified;

  const isSuccess = callerSelected && callerVerified;

  let status: StatusIconProps['status'] = 'invalid';
  if (isSuccess) {
    status = 'done';
  } else if (callerSelected && completedChecks === 0) {
    status = '0/4';
  } else if (callerSelected) {
    status = '2/4';
  }

  let message = 'Caller not on policy';
  if (isSuccess) {
    message = 'Caller verified';
  } else if (callerSelected) {
    message = 'Caller not verified';
  }

  return (
    <div
      role="alert"
      className={classNames(
        `flex gap-2 items-center w-fit text-sm font-medium leading-snug tracking-tight px-4 py-3 h-10 rounded-lg`,
        {
          'bg-green-50 text-green-700 [&_path]:fill-green-700':
            status === 'done',
          'bg-yellow-50 text-yellow-700 [&_path]:fill-yellow-700 [&_circle]:stroke-yellow-700':
            ['0/4', '2/4'].includes(status),
          'bg-gray-100 text-gray-600 first:[&_path]:fill-gray-600':
            status === 'invalid',
        },
      )}
    >
      {message}
      <StatusIcon status={status} />
    </div>
  );
};
