import classNames from 'classnames';
import { ReactNode } from 'react';

import { Spinner } from '../Spinner';
import { ChartLabel, ChartLabelTheme } from './ChartLabel';

export interface ChartContainerBaseProps {
  /**
   * The height of the chart in pixels. Most charting libraries require this
   * to determine the `canvas`.
   */
  chartHeight?: number;

  /** A theme option for a label, if applicable */
  chartLabelTheme?: ChartLabelTheme;

  /** A chart title to display prominently */
  chartTitle?: string;

  /**
   * If `true` the chart has minimal content and therefore different
   * styling treatment (like smaller header bottom margin)
   */
  isSimple?: boolean;

  /** Labels to display on the chart (currently only the 1st) */
  labels?: string[];
}

export interface ChartContainerProps extends ChartContainerBaseProps {
  /** The chart element */
  children?: ReactNode;

  /** If `true` header and content are centered */
  isCentered?: boolean;

  /** If `true` data is empty */
  isEmpty?: boolean;

  /** Text to show when data is empty */
  emptyText?: string;

  /** If `true` a loading UI state will be presented */
  isLoading?: boolean;
}

/**
 * A component to render a chart and its container layout.
 */
export const ChartContainer = ({
  chartHeight = 224,
  chartLabelTheme,
  chartTitle,
  children,
  isCentered,
  isSimple,
  isEmpty,
  emptyText = 'No data',
  isLoading,
  labels,
}: ChartContainerProps) => (
  <div
    className={classNames(
      'rounded-2xl p-6 border border-cool-gray-200 bg-white shadow-lifted',
      {
        'text-center': isCentered,
      },
    )}
  >
    <header
      className={classNames('flex', {
        'justify-between': !isCentered,
        'justify-center': isCentered,
        'mb-6': !isSimple,
      })}
    >
      <h3 className="text-cool-gray-700 font-normal text-lg">
        {chartTitle || <>&nbsp;</>}
      </h3>
      {labels?.[0] && (
        <ChartLabel theme={chartLabelTheme}>{labels[0]}</ChartLabel>
      )}
    </header>
    <div
      className={classNames(
        'flex flex-col justify-center items-center w-full',
        {
          'rounded-md bg-cool-gray-50': isEmpty || isLoading,
          'my-3': isSimple,
        },
      )}
      style={!chartHeight ? undefined : { height: `${chartHeight}px` }}
    >
      {isEmpty ? (
        <span className="text-cool-gray-600">{emptyText}</span>
      ) : isLoading ? (
        <Spinner className="text-cool-gray-700" />
      ) : (
        children
      )}
    </div>
  </div>
);
