import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { SubEvent } from './types';

/**
 * Returns the latest endTime for duringEvents
 */
export const useLatestDuringEndTime = (subEvents?: SubEvent[]) => {
  return useMemo(() => {
    if (!subEvents || subEvents.length === 0) {
      return null;
    }

    const duringEvents = subEvents.filter(
      subEvent => subEvent.phase === 'DURING',
    );

    return duringEvents.reduce((latest, subEvent) => {
      const latestDate = DateTime.fromISO(latest);
      const subEventEndDate = DateTime.fromISO(subEvent.end);
      return subEventEndDate > latestDate ? subEvent.end : latest;
    }, subEvents[0].end);
  }, [subEvents]);
};
