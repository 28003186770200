import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface MenuHeaderProps {
  /** A `ReactNode` to render as main content */
  children?: ReactNode;

  /** `className` that will override via `tailwind-merge` */
  className?: string;
}

/**
 * A React component to render a menu header
 */
export const MenuHeader = ({ children, className }: MenuHeaderProps) => {
  return (
    <header
      className={twMerge(
        'p-[10px] text-cool-gray-500 text-xs font-medium leading-4 uppercase',
        className,
      )}
    >
      {children}
    </header>
  );
};
