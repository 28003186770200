import { SvgTreadmillTraining } from '@assured/design-system';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';

export function StickyFooterBanner() {
  const bannerEnabled = useFeatureFlag('eng-10355-pii-warning-banner', false);

  if (!bannerEnabled) {
    return null;
  }

  return (
    <div className="fixed bottom-0 right-0 bg-yellow-100 text-black p-2 flex items-center justify-center z-[99]">
      <span className="pr-1 pl-[10px]">
        <SvgTreadmillTraining />
      </span>
      <span className="pr-1 text-sm">
        This is a training environment; do not input customer data or other PII.
      </span>
    </div>
  );
}
