import classNames from 'classnames';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  KeyboardShortcutProvider,
  useKeyboardShortcut,
} from '@assured/ui/hooks';

import { SimpleTabMenuItem } from './SimpleTabMenuItem';

interface SimpleTabMenuItemData {
  /** React node to render as panel content */
  panel: ReactNode;

  /** Count to represent number of items within the panel, if applicable */
  panelItemCount?: number;

  /** React node to render as tab content */
  tab: ReactNode;

  /** A unique `id` of the DOM node (one use case is to populate `key`) */
  id: string;
}

export interface SimpleTabMenuProps {
  /** `className` for the container that will override via `tailwind-merge` */
  className?: string;

  /** `className` for the panel that will override via `tailwind-merge` */
  classNamePanel?: string;

  /** The menu items to render */
  items: SimpleTabMenuItemData[];

  /** Selected tab index setter */
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;

  /** Selected tab index */
  selectedTabIndex: number;

  /** `true` to enable opacity based show / hide animation */
  shouldAnimateShowHideOpacity?: boolean;

  /** `true` to enable tab change animation effect (sliding underline) */
  shouldAnimateTabs?: boolean;
}

const tabPanelIdPrefix = `tab-menu__panel`;

/**
 * A React component to render a tab menu
 */
export const SimpleTabMenuBase = ({
  className,
  classNamePanel,
  items,
  setSelectedTabIndex,
  selectedTabIndex,
  shouldAnimateShowHideOpacity,
  shouldAnimateTabs,
}: SimpleTabMenuProps) => {
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();
  useKeyboardShortcut(['ArrowLeft'], () => {
    setFocusedIndex(previous => {
      if (!items.length) {
        return;
      }
      if (!previous) {
        return items.length - 1;
      }
      return previous - 1;
    });
  });
  useKeyboardShortcut(['ArrowRight'], () => {
    setFocusedIndex(previous => {
      if (typeof previous === 'undefined' || previous >= items.length - 1) {
        return 0;
      }
      return previous + 1;
    });
  });
  return (
    <AnimateSharedLayout>
      <div className={twMerge('flex flex-col overflow-hidden', className)}>
        <nav className="px-4 border-b border-b-cool-gray-200 flex justify-center gap-4">
          {items.map((item, index) => (
            <SimpleTabMenuItem
              controls={`${tabPanelIdPrefix}-${index}`}
              isFocused={index === focusedIndex}
              isSelected={index === selectedTabIndex}
              key={item.id}
              onClick={() => setSelectedTabIndex(index)}
              onFocus={() => setFocusedIndex(index)}
              panelItemCount={item.panelItemCount}
              shouldAnimate={shouldAnimateTabs}
            >
              {item.tab}
            </SimpleTabMenuItem>
          ))}
        </nav>
        <div className={twMerge('overflow-y-auto w-full', classNamePanel)}>
          {items.map((item, index) => (
            <motion.div
              className={classNames('px-4 py-6 flex-col gap-2', {
                hidden: index !== selectedTabIndex,
                flex: index === selectedTabIndex,
              })}
              animate={{
                opacity:
                  shouldAnimateShowHideOpacity && index !== selectedTabIndex
                    ? 0
                    : 1,
              }}
              id={`${tabPanelIdPrefix}-${item.id}`}
              initial={{
                opacity: !shouldAnimateShowHideOpacity ? 1 : 0,
              }}
              key={`${tabPanelIdPrefix}-${item.id}`}
              transition={{ duration: 0.4 }}
            >
              {item.panel}
            </motion.div>
          ))}
        </div>
      </div>
    </AnimateSharedLayout>
  );
};

/**
 * A React component to render a tab menu (with arrow key focus)
 */
export const SimpleTabMenu = (props: SimpleTabMenuProps) => {
  return (
    <KeyboardShortcutProvider>
      <SimpleTabMenuBase {...props} />
    </KeyboardShortcutProvider>
  );
};
