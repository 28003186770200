import classNames from 'classnames';
import { MaskedInputProps } from 'react-text-mask';

import { InputProps, Text } from './Text';

export type NumberInputProps = Omit<
  InputProps<MaskedInputProps>,
  'onChange' | 'mask'
> & {
  onChange?(v: number): void;
};

const CounterButton = ({
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => (
  <button
    type="button"
    {...props}
    className={classNames(
      'm-0 rounded-md bg-cool-gray-100 p-5 leading-[0.6] text-[1.5rem] flex-none text-cool-gray-600 disabled:text-cool-gray-300',
      props.className,
    )}
  />
);

/**
 * This component wraps [`Text`](./Text.tsx) and parses values as numbers. It
 * also ensures that currency values are defined in cents.
 */
export const Number = ({
  hasError,
  hint,
  header,
  trailer,
  onSubmit,
  ...props
}: NumberInputProps) => {
  const numValue =
    typeof props.value === 'string'
      ? parseInt(props.value, 10)
      : typeof props.value === 'number'
      ? props.value
      : undefined;
  switch (props.type) {
    case 'currency':
      return (
        <Text
          {...props}
          hasError={hasError}
          hint={hint}
          value={numValue ? `$${Math.round((numValue || 0) / 100)}` : undefined}
          onChange={(v: string) => props.onChange?.(parseInt(v, 10) * 100)}
          className={classNames(props.className, 'max-w-[150px]')}
        />
      );
    case 'small_number':
      return (
        <Text
          {...props}
          hasError={hasError}
          hint={hint}
          value={numValue?.toString()}
          onChange={(v: string) =>
            props.onChange?.(Math.max(Math.min(parseInt(v, 10), 99), 0))
          }
          header={
            <CounterButton
              onClick={() => props.onChange?.((numValue || 1) - 1)}
              disabled={!numValue}
              className="self-stretch"
            >
              -
            </CounterButton>
          }
          trailer={
            <CounterButton
              onClick={() => props.onChange?.((numValue || 0) + 1)}
              className="self-stretch"
            >
              +
            </CounterButton>
          }
          className={classNames(
            'w-[11.5625rem] pt-[5px] pb-[5px] pl-[5px] pr-[5px] text-cool-gray-600',
            props.className,
          )}
        />
      );
    default:
      return (
        <Text
          {...props}
          hasError={hasError}
          hint={hint}
          value={numValue?.toString()}
          onChange={(v: string) => props.onChange?.(parseInt(v, 10))}
          className={props.className}
        />
      );
  }
};
