import classNames from 'classnames';

import { Checkbox } from '../Checkbox';

export interface InfoDetails {
  /** If set, will have an indentation looking style */
  indentSize?: 'sm' | 'lg';

  /** Set to `true` if disabled */
  isDisabled?: boolean;

  /** Text to be rendered in the primary slot */
  textPrimary: string;

  /** Text to be rendered in the secondary slot */
  textSecondary?: string;

  /** Set to `true` if secondary text needs to be treated as error text */
  textSecondaryHasDanger?: boolean;

  /** An array of list items represented by their `props` */
  listItems?: (InfoDetailsItemProps & { id?: string })[];
}

export interface InfoDetailsItemProps extends InfoDetails {
  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** A click handler */
  onClick?: (isActive: boolean) => void;

  /** If the item is active */
  isActive?: boolean;
}

export const InfoDetailsItem = ({
  className,
  dataTestId = 'info-details-item',
  indentSize,
  isActive,
  isDisabled,
  onClick,
  textPrimary,
  textSecondary,
  textSecondaryHasDanger,
  listItems,
}: InfoDetailsItemProps) => {
  const clickHandler = () => {
    if (isDisabled) {
      return;
    }
    const updatedIsActive = !isActive;
    if (onClick) {
      onClick(updatedIsActive);
    }
  };
  return (
    <div
      className={classNames(
        'flex items-start gap-2 px-[5px] py-[3px] rounded',
        {
          'cursor-pointer hover:bg-indigo-bright-50 [&:hover_span]:text-indigo-bright-600':
            !!onClick && !isDisabled,
          'cursor-not-allowed hover:bg-cool-gray-100': !!onClick && isDisabled,
          'cursor-default': !onClick,
          'pl-7': indentSize === 'sm',
          'pl-10': indentSize === 'lg',
        },
        className,
      )}
      role="button"
      tabIndex={0}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          clickHandler();
        }
      }}
      title={!!onClick && isDisabled ? `Not eligible` : undefined}
      data-testid={dataTestId}
      onClick={clickHandler}
    >
      {!!onClick && (
        <Checkbox
          isActive={isActive ?? false}
          isDisabled={isDisabled}
          size="sm"
        />
      )}
      <div
        className={classNames('flex items-start grow justify-between text-xs', {
          'flex-col': listItems,
          'gap-2': listItems,
          'gap-3': !listItems,
        })}
      >
        <span className="text-cool-gray-500 font-normal text-left leading-[15px] select-none whitespace-nowrap">
          {textPrimary}
        </span>
        {textSecondary && (
          <span
            className={classNames(
              'font-medium grow text-right leading-[15px] select-none',
              {
                'text-cool-gray-700': !textSecondaryHasDanger,
                'text-red-warm-600': textSecondaryHasDanger,
              },
            )}
          >
            {textSecondary}
          </span>
        )}
        {listItems && (
          <div className="pl-[5px] w-full">
            {listItems.map(listItemProps => (
              <InfoDetailsItem
                {...listItemProps}
                key={`info-details-item-${listItemProps.textPrimary}-${listItemProps.textSecondary}-${listItemProps.id}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
