import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

import { AccordionHeader } from './AccordionHeader';

export interface AccordionItemProps {
  /** The React Node to render as the title content */
  children: ReactNode | ((isCollapsed: boolean) => ReactNode);

  /** Custom className */
  className?: string;

  /** Custom className for the header text */
  classNameHeaderText?: string;

  /** Custom className for the header padding */
  classNameHeaderPadding?: string;

  /** Custom className from the horizontal rule border color */
  classNameHorizontalRuleBorderColor?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** Set to `true` if text needs to be treated as error text */
  hasDanger?: boolean;

  /** If `true` will be open by default */
  isOpenByDefault?: boolean;

  /** An "on open" handler for item */
  onOpenToggle?: (isOpen: boolean) => void;

  /**
   * Making height animation optional as it requires a set max height that
   * has been arbitrarily chosen
   * */
  shouldAnimateHeight?: boolean;

  /** Title text of the accordion item */
  title: string;

  /** For cases where you don't want a line running from your title to the chevron */
  hideHeaderHR?: boolean;

  /** If you want to disable the ability to collapse, functionally and visually */
  forceOpen?: boolean;
}

const ANIMATION_DURATION_MS = 300;

export const AccordionItem = ({
  children,
  className,
  classNameHeaderText,
  classNameHeaderPadding,
  classNameHorizontalRuleBorderColor,
  dataTestId = 'accordion-item',
  hasDanger,
  isOpenByDefault,
  onOpenToggle,
  title,
  hideHeaderHR,
  forceOpen,
}: AccordionItemProps) => {
  const [isCollapsed, setIsCollapsed] = useState(
    !isOpenByDefault && !forceOpen,
  );
  const [isHidden, setIsHidden] = useState(true);

  const renderedChildren =
    typeof children === 'function' ? children(isCollapsed) : children;

  // once animation is complete and accordion is collapsed, we
  // remove the contents so that tabbing through invisible content
  // doesn't occur
  useEffect(() => {
    if (!isCollapsed) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }, [isCollapsed]);

  return (
    <div className={classNames(className)} data-testid={dataTestId}>
      <AccordionHeader
        hasDanger={hasDanger}
        isCollapsed={isCollapsed}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
          if (onOpenToggle) {
            onOpenToggle(isCollapsed);
          }
        }}
        className={className}
        classNameHeaderText={classNameHeaderText}
        classNameHeaderPadding={classNameHeaderPadding}
        classNameHorizontalRuleBorderColor={classNameHorizontalRuleBorderColor}
        hideHeaderHR={hideHeaderHR || forceOpen}
        disabled={forceOpen}
      >
        {title}
      </AccordionHeader>
      <div
        className={classNames({
          hidden: isHidden,
          'max-h-0': isCollapsed,
          'max-h-full': !isCollapsed,
          'overflow-y-hidden': isCollapsed,
        })}
      >
        {renderedChildren}
      </div>
    </div>
  );
};
