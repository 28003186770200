import { DateTime } from 'luxon';

import { SubEvent } from './types';

export type PhasedEvents = {
  ALL: SubEvent[];
  BEFORE: SubEvent[];
  DURING: SubEvent[];
  AFTER: SubEvent[];
} | null;

/**
 * Calculates the total duration of an event from an array of sub-events.
 */
export const calculateEventDuration = (subEvents: SubEvent[]) => {
  if (!subEvents || subEvents.length === 0) {
    return {
      duration: undefined,
      startTime: undefined,
      endTime: undefined,
    };
  }

  const startTimes = subEvents.map(event => {
    const start = DateTime.fromISO(event.start);

    return start.toMillis();
  });

  const endTimes = subEvents.map(event => {
    const end = DateTime.fromISO(event.end);
    return end.toMillis();
  });

  const earliestStart = DateTime.fromMillis(Math.min(...startTimes));
  const latestEnd = DateTime.fromMillis(Math.max(...endTimes));

  return {
    duration: latestEnd.diff(earliestStart).toMillis(),
    startTime: earliestStart,
    endTime: latestEnd,
  };
};

/**
 * Generates evenly spaced timestamp labels across the duration of an event.
 * Ensures only the first label and the first label of a new day include the day abbreviation.
 */
export const generateLabels = (
  startTime: DateTime | undefined,
  endTime: DateTime | undefined,
  count = 12,
) => {
  if (!endTime || !startTime) {
    return undefined;
  }

  // If startTime and endTime are the same, only return the first tick
  if (startTime.toMillis() === endTime.toMillis()) {
    // Always show the day abbreviation
    const label = startTime.toFormat('EEE hh:mm a');
    return [{ label, timeStamp: startTime }];
  }

  const totalDuration = endTime.diff(startTime).toMillis();
  // Calculate interval for evenly spaced ticks
  const interval = totalDuration / (count - 1);
  let lastDay = startTime.toFormat('EEE');

  // To ensure no duplicate timestamps
  const seenTimestamps = new Set<number>();

  return Array.from({ length: count }, (_, i) => {
    let currentTime: DateTime;

    if (i === 0) {
      // Ensure the first tick is exactly at startTime
      currentTime = startTime;
    } else if (i === count - 1) {
      // Ensure the last tick is exactly at endTime
      currentTime = endTime;
    } else {
      // For intermediate ticks, calculate evenly spaced time
      currentTime = startTime.plus({ milliseconds: i * interval });
    }

    // Skip duplicate timestamps
    if (seenTimestamps.has(currentTime.toMillis())) {
      return null;
    }
    seenTimestamps.add(currentTime.toMillis());

    const currentDay = currentTime.toFormat('EEE');

    let formattedLabel = currentTime.toFormat('hh:mm a');

    if (i === 0) {
      // First label always includes the day abbreviation
      formattedLabel = currentTime.toFormat('EEE hh:mm a');
    } else if (currentDay !== lastDay) {
      // First tick on a new day includes the day abbreviation
      formattedLabel = currentTime.toFormat('EEE hh:mm a');
    }

    lastDay = currentDay;

    return { label: formattedLabel, timeStamp: currentTime };
  }).filter(Boolean); // Remove null values
};

/**
 * Organizes an array of `subEvents` into phases based on each sub-event’s `phase` property.
 */
export const preparePhasedEvents = (
  subEventsToPrepare: SubEvent[],
): PhasedEvents => {
  if (!subEventsToPrepare) {
    return null;
  }

  return {
    ALL: subEventsToPrepare,
    BEFORE: subEventsToPrepare.filter(subEvent => subEvent.phase === 'BEFORE'),
    DURING: subEventsToPrepare.filter(subEvent => subEvent.phase === 'DURING'),
    AFTER: subEventsToPrepare.filter(subEvent => subEvent.phase === 'AFTER'),
  };
};

/**
 * Returns a datetime string for the current time given a start time string. (earliestStartTime)
 */
export const getRelativeDateTimeString = (
  currentTime: number,
  startTime: string,
): string => {
  const startDateTime = DateTime.fromISO(startTime);

  // Fallback to now if startDateTime is invalid
  const validStartTime = startDateTime.isValid ? startDateTime : DateTime.now();

  // Add currentTime (in milliseconds) to the startDateTime
  const relativeDateTime = validStartTime.plus({ milliseconds: currentTime });

  return relativeDateTime.toISO();
};
