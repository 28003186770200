import moment from 'moment';

import type { TimeslotGroup, TimeslotState } from './types';

const MINUTES_IN_A_DAY = 1440;

/** A map of select options by number of minutes since midnight */
export type TimeslotSelectOptionMap = Map<number, TimeslotSelectOption>;

/**
 * Payload used by select component options
 */
export interface TimeslotSelectOption {
  label: string;
  value: string;
}

/**
 * A function to return timeslot options for selection
 */
export const getTimeslotSelectOptions = ({
  timeslotDurationMinutes,
}: {
  /** Duration of a "timeslot" in minutes */
  timeslotDurationMinutes: number;
}): {
  /** A map of select options by number of minutes since midnight */
  timeslotSelectOptionMap: TimeslotSelectOptionMap;

  /** An array of select options */
  timeslotSelectOptions: TimeslotSelectOption[];
} => {
  const timeslotSelectOptionMap: TimeslotSelectOptionMap = new Map();
  const timeslotSelectOptions: TimeslotSelectOption[] = [];

  let minute = 0;

  // builds list of options by starting from a midnight `Moment` date,
  // adding minutes, storing select option, and incrementing stored
  // minute on each iteration
  while (minute < MINUTES_IN_A_DAY) {
    const now = moment();
    const midnight = now.startOf('day');
    const midnightDatePlusMinutes = midnight.add(minute, 'minutes');
    const option = {
      label: midnightDatePlusMinutes.format('h:mm A'),
      value: `${minute}`,
    };

    timeslotSelectOptions.push(option);
    timeslotSelectOptionMap.set(minute, option);

    minute += timeslotDurationMinutes;
  }

  return {
    timeslotSelectOptionMap,
    timeslotSelectOptions,
  };
};

/**
 * A function to validate if a string input is a valid number when
 * parsed.
 */
export const isNumericStringValid = (value: string) => {
  if (typeof value !== 'string') {
    return false;
  }
  return !Number.isNaN(parseFloat(value)) && Number.isFinite(Number(value));
};

/**
 * A function to transform select option data based on the select counterpart.
 * For example - if this is the `startMinute` select, we'll want to ensure each
 * minute is less than its counterpart select `endMinute`.
 */
export const transformTimeslotSelectOptions = ({
  options,
  maxValue,
  minValue,
  value,
}: {
  /** The full set of select options, unfiltered */
  options: TimeslotSelectOption[];

  /** A max value in minutes (applicable only for start minute) */
  maxValue?: number;

  /** A min value in minutes (applicable only for end minute) */
  minValue?: number;

  /** Value of the currently selected option */
  value: string;
}) => {
  const isStartMinute = typeof maxValue === 'number';
  const isEndMinute = typeof minValue === 'number';

  const transformedOptions = options
    .filter(option => {
      // if we're transforming select options for the start minute
      // and max value is referencing midnight (of the end minute),
      // all options are valid
      if (isStartMinute && maxValue === 0) {
        return true;
      }

      // ensure start minute options are each less than end minute
      // we allow it if the option is the currently selected
      if (
        isStartMinute &&
        value !== option.value &&
        Number(option.value) > maxValue
      ) {
        return false;
      }

      // ensure end minute options are each more than start minute
      // we allow it if the option is the currently selected
      if (
        isEndMinute &&
        value !== option.value &&
        Number(option.value) < minValue
      ) {
        return false;
      }
      return true;
    })
    .sort((a, b) => Number(a.value) - Number(b.value));

  // if we're transforming select options for the end minute, we ensure
  // midnight exists at the end
  if (isEndMinute) {
    transformedOptions.push(options[0]);
    if (transformedOptions[0].value === '0') {
      transformedOptions.shift();
    }
  }

  return transformedOptions;
};

/** A function to get default timeslot state  */
export const getDefaultTimeslotState = (timeslotGroups: TimeslotGroup[]) =>
  timeslotGroups.reduce<TimeslotState>(
    (accumulator, current) => ({
      ...accumulator,
      [current]: [],
    }),
    {},
  );

/**
 * The IANA timezone
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions | Intl.DateTimeFormat.prototype.resolvedOptions()}
 */
export const getIanaTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;
