import type {
  PolicySearchAlertType,
  PolicySearchData,
} from '@assured/shared-types/Services/PolicySearch';

import type { InfoDetails } from '../../InfoDetails/InfoDetailsItem';

export enum PolicySearchModalActionType {
  ACKNOWLEDGE = 'ACKNOWLEDGE',
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
  UPDATE_MODALS = 'UPDATE_MODALS',
}

export interface PolicySearchModalDetails {
  items: InfoDetails[];
  subtitle?: string | string[];
  title?: string;
  hasError?: boolean;
}

export interface PolicySearchModal {
  details?: PolicySearchModalDetails;
  isAcknowledged?: boolean;
  shouldRequireAcknowledgement?: boolean;
  selectedPolicy?: PolicySearchData;
}

export interface PolicySearchModalPayload {
  details?: PolicySearchModalDetails;
  modals?: PolicySearchModalCollection;
  modalType?: PolicySearchAlertType;
}

export type PolicySearchModalCollection = Record<
  PolicySearchAlertType,
  PolicySearchModal
>;

export interface PolicySearchModalAction {
  payload?: PolicySearchModalPayload;
  type: PolicySearchModalActionType;
}

export interface PolicySearchModalState {
  /** If `true` modal should be open. */
  isOpen: boolean;

  /** A collection of modals render based on state as managed here */
  modals?: PolicySearchModalCollection;

  /** Defines which modal is selected. */
  selectedModal?: PolicySearchAlertType;
}

export const initialState: PolicySearchModalState = {
  selectedModal: undefined,
  isOpen: false,
  modals: {
    DATE_OF_LOSS_OUT_OF_RANGE: {
      details: undefined,
      isAcknowledged: undefined,
      shouldRequireAcknowledgement: false,
      selectedPolicy: undefined,
    },
    DUPLICATE_CLAIM_WARNING: {
      details: undefined,
      isAcknowledged: undefined,
      shouldRequireAcknowledgement: false,
      selectedPolicy: undefined,
    },
    MANUAL_LOSS: {
      details: undefined,
      isAcknowledged: undefined,
      shouldRequireAcknowledgement: undefined,
      selectedPolicy: undefined,
    },
    MANUAL_LOSS_NOT_ALLOWED: {
      details: undefined,
      isAcknowledged: undefined,
      shouldRequireAcknowledgement: undefined,
      selectedPolicy: undefined,
    },
    UNSUPPORTED_POLICY_TYPE: {
      details: undefined,
      isAcknowledged: undefined,
      shouldRequireAcknowledgement: false,
      selectedPolicy: undefined,
    },
  },
};

export function policySearchModalReducer(
  state: PolicySearchModalState,
  action: PolicySearchModalAction,
) {
  const { type, payload } = action;
  switch (type) {
    case PolicySearchModalActionType.ACKNOWLEDGE:
      return {
        ...state,
        ...(!state.modals ||
        !state.selectedModal ||
        !state.modals[state.selectedModal]
          ? {}
          : {
              modals: {
                ...state.modals,
                [state.selectedModal]: {
                  ...state.modals[state.selectedModal],
                  isAcknowledged: true,
                  shouldRequireAcknowledgement: false,
                },
              },
            }),
        isOpen: false,
      };
    case PolicySearchModalActionType.CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    case PolicySearchModalActionType.OPEN:
      return {
        ...state,
        isOpen: true,
        selectedModal: payload?.modalType,
      };
    case PolicySearchModalActionType.UPDATE_MODALS:
      return {
        ...state,
        modals: payload?.modals,
      };
    default:
      return state;
  }
}
