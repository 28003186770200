import classNames from 'classnames';
import { useFormik } from 'formik';
import { Location } from 'history';
import moment from 'moment-timezone';
import { useRef, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { twMerge } from 'tailwind-merge';
import { z, ZodError } from 'zod';

import { DatePicker, Toggle } from '@assured/design-system';

import {
  OutOfOfficeSettingsByRepIdDocument,
  OutOfOfficeSettingsModalDocument,
  useUpsertAdjusterOutOfOfficeSettingsByRepIdMutation,
  useUpsertAdjusterOutOfOfficeSettingsMutation,
  useValidateRepIdLazyQuery,
} from '../../../../generatedX/graphql';
import { zodToFormikErrors } from '../../zod-utils';
import { EMPTY_VALUES } from './constants';
import DiscardConfirmationDialog from './DiscardConfirmationDialog';
import { getDateString } from './getDateString';
import { useOutOfOfficeSettingsModalContext } from './OutOfOfficeSettingsContext';
import OutOfOfficeSettingsFooter from './OutOfOfficeSettingsFooter';

// Note: this is necessary because we NEED to send `null` as the value to the
// server in order to unset the value, however, the codegen for the graph
// maps optional values to `undefined` (not allowing `null`), and in graphQL,
// optional and nullable are the same thing....
// see adjuster/codegen.yaml:generates.config.maybeValue for def
const UNSAFE_UNSET = null as unknown as undefined;

const BASE_MESSAGE =
  'Thank you for your message. I will return to the office on';

const localTimezone = moment().tz(moment.tz.guess()).format('z');
const now = new Date();

const emptyValidationSchema = z.object({
  startAt: z.null(),
  endAt: z.null(),
  message: z.null(),
  forwardToAdjusterRepCode: z.null(),
  forwardToAdjusterRepCodeValidated: z.null(),
});

const validationSchema = z
  .object({
    startAt: z.date().refine(val => val !== undefined, {
      message: 'Start is required',
    }),
    endAt: z.date().refine(val => val !== undefined, {
      message: 'To is required',
    }),
    message: z.string().min(1, 'Automated reply is required'),
    forwardToAdjusterRepCode: z.string().nullable(),
    forwardToAdjusterRepCodeValidated: z.boolean().nullable(),
  })
  .superRefine((values, ctx) => {
    if (values.startAt && values.endAt && values.startAt >= values.endAt) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'End date must be after start date',
        path: ['endAt'],
      });
    }

    if (
      values.forwardToAdjusterRepCode !== null &&
      !values.forwardToAdjusterRepCodeValidated
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid rep ID',
        path: ['forwardToAdjusterRepCode'],
      });
    }
  });

const OutOfOfficeSettingsForm = ({
  targetUser,
  initialValues,
}: {
  targetUser: string | null;
  initialValues: {
    startAt: Date | null;
    endAt: Date | null;
    message: string | null;
    forwardToAdjusterRepCode: string | null;
    forwardToAdjusterRepCodeValidated: boolean | null;
  };
}) => {
  const { setIsOpen } = useOutOfOfficeSettingsModalContext();

  const [discardConfirmationDialogOpen, setDiscardConfirmationDialogOpen] =
    useState(false);
  const blockNavigationAway = useRef<boolean>(true);
  const [nextLocation, setNextLocation] = useState<Location<unknown> | null>(
    null,
  );
  const history = useHistory();
  const [validateRepId] = useValidateRepIdLazyQuery();

  const [updateAdjusterOutOfOfficeSettings, { loading: loadingForUser }] =
    useUpsertAdjusterOutOfOfficeSettingsMutation();
  const [updateAdjusterOutOfOfficeSettingsByRepId, { loading: loadingForRep }] =
    useUpsertAdjusterOutOfOfficeSettingsByRepIdMutation();

  const loading = targetUser ? loadingForRep : loadingForUser;

  const form = useFormik({
    initialValues,
    onSubmit: (values, helpers) => {
      helpers.setStatus('submitted');

      const transformedValues = {
        startAt: values.startAt ? values.startAt.toISOString() : UNSAFE_UNSET,
        endAt: values.endAt ? values.endAt.toISOString() : UNSAFE_UNSET,
        message: values.message || UNSAFE_UNSET,
        forwardToAdjusterRepCode:
          values.forwardToAdjusterRepCode || UNSAFE_UNSET,
      };

      if (targetUser) {
        updateAdjusterOutOfOfficeSettingsByRepId({
          variables: {
            ...transformedValues,
            repId: targetUser,
          },
          update: (cache, { data }) => {
            if (!data) return;
            if (!data.upsertAdjusterOutOfOfficeSettingsByRepId) return;

            cache.writeQuery({
              query: OutOfOfficeSettingsByRepIdDocument,
              data: {
                adjusterOutOfOfficeSettings: {
                  ...data.upsertAdjusterOutOfOfficeSettingsByRepId,
                },
              },
            });
          },
        }).then(() => {
          setIsOpen(false);
        });
      } else {
        updateAdjusterOutOfOfficeSettings({
          variables: transformedValues,
          update: (cache, { data }) => {
            if (!data) return;
            if (!data.upsertAdjusterOutOfOfficeSettings) return;

            cache.writeQuery({
              query: OutOfOfficeSettingsModalDocument,
              data: {
                adjusterOutOfOfficeSettings: {
                  ...data.upsertAdjusterOutOfOfficeSettings,
                },
              },
            });
          },
        }).then(() => {
          setIsOpen(false);
        });
      }
    },
    validate: values => {
      const forwardToAdjusterRepCodeError =
        values.forwardToAdjusterRepCode &&
        !values.forwardToAdjusterRepCodeValidated
          ? { forwardToAdjusterRepCode: 'Invalid rep ID' }
          : {};

      try {
        (values.startAt ? validationSchema : emptyValidationSchema).parse(
          values,
        );

        return forwardToAdjusterRepCodeError;
      } catch (error) {
        if (error instanceof ZodError) {
          const formikErrors = zodToFormikErrors<typeof values>(error);

          return { ...formikErrors, ...forwardToAdjusterRepCodeError };
        }

        return forwardToAdjusterRepCodeError;
      }
    },
    validateOnMount: true,
  });

  const enabled = Boolean(form.values.startAt);
  const placeholderDateString = `MM/DD/YYYY at 12:00 AM ${localTimezone}`;

  const endDateString = form.values.endAt
    ? getDateString(form.values.endAt)
    : placeholderDateString;

  const defaultMessage = `${BASE_MESSAGE} ${endDateString}.`;
  const messageNonDefault =
    form.values.message && form.values.message !== defaultMessage;

  const showMessageError = 'message' in form.touched && form.errors.message;

  const showRepIdError =
    'forwardToAdjusterRepCode' in form.touched &&
    form.errors.forwardToAdjusterRepCode;

  useDebounce(
    () => {
      if (form.values.forwardToAdjusterRepCode) {
        validateRepId({
          variables: {
            repId: form.values.forwardToAdjusterRepCode,
          },
          onCompleted: result => {
            const valid = result?.validateRepId?.valid;
            if (!valid) {
              form.setErrors({ forwardToAdjusterRepCode: 'Invalid rep ID' });
            }
            form.setFieldValue('forwardToAdjusterRepCodeValidated', valid);
          },
        });
      }
    },
    250,
    [form.values.forwardToAdjusterRepCode],
  );

  return (
    <>
      <Prompt
        when={form.dirty}
        message={newLocation => {
          setNextLocation(newLocation);

          if (
            form.dirty &&
            blockNavigationAway.current &&
            form.status !== 'submitted'
          ) {
            setDiscardConfirmationDialogOpen(true);
            return false;
          }

          return true;
        }}
      />
      <DiscardConfirmationDialog
        isOpen={discardConfirmationDialogOpen}
        setIsOpen={setDiscardConfirmationDialogOpen}
        onConfirm={() => {
          blockNavigationAway.current = false;
          setDiscardConfirmationDialogOpen(false);
          if (nextLocation) {
            history.push(nextLocation);
          }
        }}
        onCancel={() => {
          setDiscardConfirmationDialogOpen(false);
          setNextLocation(null);
        }}
      />
      <form
        onSubmit={form.handleSubmit}
        className="flex-grow flex flex-col overflow-hidden"
      >
        <div
          className={classNames(
            'flex-grow overflow-y-auto overflow-x-hidden px-6',
            {
              'pb-4': !enabled,
            },
          )}
        >
          <fieldset className="block">
            <legend className="text-gray-700 text-lg font-medium">
              Automatic Replies
            </legend>
            <p className="text-gray-500 text-sm pb-4">
              Use out of office to automatically send replies informing contacts
              of your unavailability to respond.
            </p>
            <Toggle
              labelRight={
                <span className="inline-flex items-center flex-grow">
                  <span className="flex-grow text-sm text-gray-700 pr-2">
                    Automatic replies
                  </span>
                </span>
              }
              onChange={() => {
                if (!enabled) {
                  form.setFieldValue('startAt', new Date());
                  form.setFieldValue(
                    'message',
                    `${BASE_MESSAGE} ${placeholderDateString}.`,
                  );
                } else {
                  form.setValues(EMPTY_VALUES, true);
                }
              }}
              value={enabled}
            />
            {enabled && (
              <>
                <div className="flex gap-4 py-4">
                  <div
                    className={twMerge(
                      'flex-grow [&_.react-datepicker-wrapper]:!block [&_input]:!text-sm [&_input::placeholder]:!text-sm [&_input]:!rounded [&_input]:!h-[48px] [&_input]:!border [&_input]:!min-w-[0px] [&_input]:!w-full [&_input]:!py-0 [&_input]:!px-4',
                      form.errors.startAt
                        ? '[&_input]:!border-red-700 [&_input:focus]:!shadow-outline-red'
                        : '[&_input]:!border-gray-300 [&_input:focus]:!border-indigo-500',
                    )}
                  >
                    <label htmlFor="oooFrom">
                      <span className="block text-gray-700 font-medium text-xs pb-2">
                        From
                      </span>
                      <DatePicker
                        autoComplete="off"
                        id="oooFrom"
                        showTimeSelect
                        minDate={now}
                        onChange={v => {
                          form.setFieldValue('startAt', v);
                        }}
                        value={{ date: form.values.startAt }}
                        format="MM/dd/yyyy h:mm aa"
                      />
                    </label>
                  </div>
                  <div
                    className={twMerge(
                      'flex-grow [&_.react-datepicker-wrapper]:!block [&_input]:!text-sm [&_input::placeholder]:!text-sm [&_input]:!rounded [&_input]:!h-[48px] [&_input]:!border [&_input]:!min-w-[0px] [&_input]:!w-full [&_input]:!py-0 [&_input]:!px-4',
                      form.values.endAt && form.errors.endAt
                        ? '[&_input]:!border-red-700 [&_input:focus]:!shadow-outline-red'
                        : '[&_input]:!border-gray-300 [&_input:focus]:!border-indigo-500',
                    )}
                  >
                    <label htmlFor="oooTo">
                      <span className="block text-gray-700 font-medium text-xs pb-2">
                        To
                      </span>
                      <DatePicker
                        autoComplete="off"
                        id="oooTo"
                        minDate={form.values.startAt || now}
                        showTimeSelect
                        onChange={d => {
                          if (!d) {
                            return;
                          }

                          if (!form.values.endAt) {
                            form.setFieldValue(
                              'message',
                              `${BASE_MESSAGE} ${moment(d).format(
                                'MM/DD/YYYY',
                              )} at ${moment(d).format(
                                'hh:mm A',
                              )} ${localTimezone}.`,
                            );
                          } else {
                            form.setFieldValue(
                              'message',
                              form.values.message?.replace(
                                endDateString,
                                getDateString(d),
                              ),
                            );
                          }

                          form.setFieldValue('endAt', d);
                        }}
                        value={{ date: form.values.endAt }}
                        placeholderText={placeholderDateString}
                        format="MM/dd/yyyy h:mm aa"
                      />
                      {form.values.endAt && form.errors.endAt && (
                        <div
                          id="endAt:error"
                          className="text-xs text-red-700 font-medium p-1"
                        >
                          {form.errors.endAt}
                        </div>
                      )}
                    </label>
                  </div>
                </div>
                <div className="pb-4">
                  <span className="flex items-center min-h-[24px] pb-2">
                    <label
                      className="flex-grow block text-gray-700 font-medium text-xs"
                      htmlFor="message"
                    >
                      Automated reply
                    </label>
                    {messageNonDefault && (
                      <button
                        type="button"
                        className="text-sm font-medium text-indigo-600"
                        onClick={() => {
                          form.setFieldValue('message', defaultMessage);
                        }}
                      >
                        Reset to default
                      </button>
                    )}
                  </span>
                  <textarea
                    id="message"
                    name="message"
                    className={twMerge(
                      'rounded border text-sm focus:border-indigo-600 outline-none w-full p-4 disabled:text-gray-500 disabled:bg-gray-50 min-h-[96px]',
                      showMessageError
                        ? 'border-red-700 focus:border-red-700 focus:shadow-outline-red'
                        : 'focus:border-indigo-500 border-gray-300 focus:shadow-outline-indigo',
                    )}
                    disabled={!form.values.endAt}
                    value={form.values.message || ''}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    aria-describedby={
                      showMessageError ? 'message:error' : undefined
                    }
                  />
                  {showMessageError && (
                    <div
                      id="message:error"
                      className="text-xs text-red-700 font-medium p-1"
                    >
                      {form.errors.message}
                    </div>
                  )}
                </div>
              </>
            )}
          </fieldset>
          {enabled && (
            <fieldset className="pt-2">
              <legend className="text-gray-700 text-lg font-medium">
                Message Forwarding
              </legend>
              <p className="text-gray-500 text-sm pb-4">
                Use forwarding to send message notifications to a designated
                person while you&rsquo;re out of office and automatically
                subscribe them to associated claims.
              </p>
              <div className="pb-4">
                <Toggle
                  labelRight={
                    <span className="inline-flex items-center flex-grow">
                      <span className="flex-grow text-sm text-gray-700 pr-2">
                        Message forwarding
                      </span>
                    </span>
                  }
                  onChange={checked => {
                    form.setFieldValue(
                      'forwardToAdjusterRepCode',
                      checked ? '' : null,
                    );
                    if (!checked) {
                      form.setValues(
                        {
                          ...form.values,
                          forwardToAdjusterRepCode: null,
                          forwardToAdjusterRepCodeValidated: null,
                        },
                        true,
                      );
                    }
                  }}
                  value={form.values.forwardToAdjusterRepCode !== null}
                />
              </div>
              {form.values.forwardToAdjusterRepCode !== null && (
                <div className="pb-6">
                  <label className="flex flex-col">
                    <span className="block font-medium text-gray-700 pb-2 text-xs">
                      Forward my messages to:
                    </span>
                    <input
                      type="text"
                      name="forwardToAdjusterRepCode"
                      placeholder="Enter valid rep ID"
                      className={twMerge(
                        'w-full border shadow-sm rounded focus:outline-none text-cool-gray-700 text-sm px-3 h-[48px] flex-shrink-0 min-w-[144px]',
                        showRepIdError
                          ? 'border-red-700 focus:border-red-700 focus:shadow-outline-red'
                          : 'focus:border-indigo-500 border-gray-300 focus:shadow-outline-indigo',
                      )}
                      value={form.values.forwardToAdjusterRepCode}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      aria-describedby={
                        showRepIdError
                          ? 'forwardToAdjusterRepCode:error'
                          : undefined
                      }
                    />
                  </label>
                  {showRepIdError && (
                    <div
                      id="forwardToAdjusterRepCode:error"
                      className="text-xs text-red-700 font-medium p-1"
                    >
                      {form.errors.forwardToAdjusterRepCode}
                    </div>
                  )}
                </div>
              )}
            </fieldset>
          )}
        </div>
        <OutOfOfficeSettingsFooter
          onCancel={() => {
            setIsOpen(false);
          }}
          submitDisabled={!form.isValid || loading || !form.dirty}
          submitLoading={loading}
        />
      </form>
    </>
  );
};

export default OutOfOfficeSettingsForm;
