import { CheckboxInput } from './CheckboxInput';

import type { CheckboxOrRadioInputProps } from './CheckboxInput';

/**
 * A fairly bare bones radio input (type="radio"), ideal when dealing with native
 * HTML forms. `className` will override via `twMerge`.
 */
export function RadioInput(props: CheckboxOrRadioInputProps) {
  return <CheckboxInput type="radio" {...props} />;
}
