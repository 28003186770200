import { TokenExtractorConfig } from './TextTokenExtractor';

type ProgressivePolicySearchTokenType =
  | 'policy_number'
  | 'driver_license'
  | 'state'
  | 'postal_code'
  | 'name'
  | 'address';

export const ProgressivePolicySearchDefaultTokenExtractorConfig: TokenExtractorConfig<ProgressivePolicySearchTokenType> =
  {
    tokens: [
      {
        type: 'policy_number',
        shortLabel: 'PN',
        label: 'Policy Number',
        matchers: [/\b([0-9]{9}\s*(?=[,;]?)|[0-9]{8}\s*(?=[,;]))\b/],
      },
      {
        type: 'driver_license',
        shortLabel: 'DL',
        label: 'Driver License',
        matchers: [/\b(?=.*[0-9])[A-Za-z0-9]{6,}\s*(?=[,;])/],
        inactive_if: ['policy_number'],
      },
      {
        type: 'state',
        shortLabel: 'ST',
        label: 'State',
        matchers: [
          /\b(AL|Alabama|AK|Alaska|AZ|Arizona|AR|Arkansas|CA|California|CO|Colorado|CT|Connecticut|DE|Delaware|FL|Florida|GA|Georgia|HI|Hawaii|ID|Idaho|IL|Illinois|IN|Indiana|IA|Iowa|KS|Kansas|KY|Kentucky|LA|Louisiana|ME|Maine|MD|Maryland|MA|Massachusetts|MI|Michigan|MN|Minnesota|MS|Mississippi|MO|Missouri|MT|Montana|NE|Nebraska|NV|Nevada|NH|New Hampshire|NJ|New Jersey|NM|New Mexico|NY|New York|NC|North Carolina|ND|North Dakota|OH|Ohio|OK|Oklahoma|OR|Oregon|PA|Pennsylvania|RI|Rhode Island|SC|South Carolina|SD|South Dakota|TN|Tennessee|TX|Texas|UT|Utah|VT|Vermont|VA|Virginia|WA|Washington|WV|West Virginia|WI|Wisconsin|WY|Wyoming)\s*(?=[,;])/i,
        ],
        inactive_if: ['policy_number'],
      },
      {
        type: 'postal_code',
        shortLabel: 'PC',
        label: 'Postal Code',
        matchers: [/\b[0-9]{5}\s*(?=[,;])\b/],
        inactive_if: ['policy_number', 'driver_license'],
      },
      {
        type: 'address',
        shortLabel: 'AD',
        label: 'Address',
        matchers: [/\b\d+ [A-Za-z\- ]{2,}[A-Za-z]\s*(?=[,;])/],
        inactive_if: ['policy_number', 'driver_license'],
      },
      {
        type: 'name',
        shortLabel: 'NA',
        label: 'Name',
        matchers: [/\b[A-Za-z][A-Za-z\- ]*[A-Za-z]\s*(?=[,;])/],
        inactive_if: ['policy_number', 'driver_license'],
      },
    ],
  };
