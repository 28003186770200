import { atom } from 'recoil';

/**
 * Multiple types of menus exist in the inbox component, so this
 * state will help us ensure only one is open at a time. This string
 * value should represent an identifier of a menu instance that is
 * open. If `undefined` then no menus are open.
 */
type InboxOpenMenuState = string | undefined;

export const inboxOpenMenuState = atom<InboxOpenMenuState>({
  key: 'inboxOpenMenus',
  default: undefined,
});
