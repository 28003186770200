import { useLDClient } from 'launchdarkly-react-client-sdk';

import { Location as LocationV1 } from './LocationV1';
import { Location as LocationV2 } from './LocationV2';
import { LocationProps } from './types';

export const Location = (props: LocationProps) => {
  const ldClient = useLDClient();
  const useNewVersion = ldClient?.variation(
    'eng-6128_refactored_location_testing_6_24_24',
  );
  return useNewVersion ? <LocationV2 {...props} /> : <LocationV1 {...props} />;
};
