import { useMemo } from 'react';

import { SelectDropdown } from '../SelectDropdown';
import { transformTimeslotSelectOptions } from './helpers';

import type { SelectDropdownProps } from '../SelectDropdown';
import type { TimeslotSelectOption } from './helpers';

/**
 * A React component to render an availability select dropdown
 */
export const AvailabilitySelectDropdown = ({
  defaultOptions,
  maxValue,
  minValue,
  onChange,
  value,
}: {
  defaultOptions: TimeslotSelectOption[];
  onChange?: SelectDropdownProps['onChange'];
  maxValue?: number;
  minValue?: number;
  value: string;
}) => {
  const options = useMemo(() => {
    return transformTimeslotSelectOptions({
      options: defaultOptions,
      maxValue,
      minValue,
      value,
    });
  }, [defaultOptions, maxValue, minValue]);

  return (
    <SelectDropdown
      className="grow max-w-[calc(50%-8px)]"
      options={options}
      variant="select"
      isClearable={false}
      isSearchable={false}
      labelProps={{
        className: 'hidden',
        labelStr: 'timeslot',
      }}
      onChange={onChange}
      size="md"
      styles={{
        menuPortal: baseStyles => ({
          ...baseStyles,
          zIndex: 10000,
        }),
      }}
      value={value}
    />
  );
};
