import { useCallback, useEffect, useRef, useState } from 'react';

interface UsePlayheadOptions {
  duration?: number;
  playbackSpeed?: number;
}

/**
 * Manages the playhead's position and playback state.
 * Ensures the playback always completes in 30 seconds by default at 60fps.
 */
export const usePlayhead = ({
  duration,
  playbackSpeed = 1,
}: UsePlayheadOptions) => {
  const [currentTime, setCurrentTimeState] = useState(0); // Default to 0
  const [isPlaying, setIsPlaying] = useState(false);

  const currentTimeRef = useRef(currentTime);
  const accumulatedTimeRef = useRef(0); // Default to 0
  const lastTimestampRef = useRef<number | null>(null);
  const animationFrameIdRef = useRef<number | null>(null);

  const playbackDuration = 30 * 1000; // 30s playback in real time

  // Reset playhead when duration changes
  useEffect(() => {
    if (duration !== undefined) {
      setCurrentTimeState(duration);
      accumulatedTimeRef.current = duration;
    } else {
      setCurrentTimeState(0);
      accumulatedTimeRef.current = 0;
    }
  }, [duration]);

  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime]);

  const updatePlayhead = useCallback(
    (timestamp: number) => {
      if (!isPlaying || duration === undefined) return;

      if (lastTimestampRef.current === null) {
        lastTimestampRef.current = timestamp;
      }

      const deltaTime = (timestamp - lastTimestampRef.current) * playbackSpeed;
      lastTimestampRef.current = timestamp;

      accumulatedTimeRef.current += deltaTime;

      const nextTime = Math.min(
        (accumulatedTimeRef.current / playbackDuration) * duration,
        duration,
      );

      if (Math.abs(nextTime - currentTimeRef.current) > 0.1) {
        setCurrentTimeState(nextTime);
      }

      if (nextTime >= duration) {
        setCurrentTimeState(duration);
        setIsPlaying(false);
        animationFrameIdRef.current = null;
      } else {
        animationFrameIdRef.current = requestAnimationFrame(updatePlayhead);
      }
    },
    [isPlaying, playbackSpeed, duration, playbackDuration],
  );

  useEffect(() => {
    if (isPlaying && duration !== undefined) {
      animationFrameIdRef.current = requestAnimationFrame(updatePlayhead);
    }
    return () => {
      if (animationFrameIdRef.current !== null) {
        cancelAnimationFrame(animationFrameIdRef.current);
        animationFrameIdRef.current = null;
      }
    };
  }, [isPlaying, duration, updatePlayhead]);

  const play = useCallback(() => {
    if (duration === undefined) return;

    if (currentTimeRef.current >= duration) {
      setCurrentTimeState(0);
      accumulatedTimeRef.current = 0;
    }
    setIsPlaying(true);
    lastTimestampRef.current = null;
  }, [duration]);

  const pause = useCallback(() => {
    setIsPlaying(false);
    if (animationFrameIdRef.current !== null) {
      cancelAnimationFrame(animationFrameIdRef.current);
      animationFrameIdRef.current = null;
    }
  }, []);

  const moveForward = useCallback(() => {
    if (duration === undefined) return;

    setCurrentTimeState(prev => {
      const incrementAmount = duration / 10;
      const nextTime = Math.min(prev + incrementAmount, duration);
      accumulatedTimeRef.current = (nextTime / duration) * playbackDuration;
      return nextTime;
    });
  }, [duration, playbackDuration]);

  const moveBackward = useCallback(() => {
    if (duration === undefined) return;

    setCurrentTimeState(prev => {
      const incrementAmount = duration / 10;
      const nextTime = Math.max(prev - incrementAmount, 0);
      accumulatedTimeRef.current = (nextTime / duration) * playbackDuration;
      return nextTime;
    });
  }, [duration, playbackDuration]);

  const setCurrentTime = useCallback(
    (time: number) => {
      if (duration === undefined) return;

      const clampedTime = Math.max(0, Math.min(time, duration));
      setCurrentTimeState(clampedTime);
      accumulatedTimeRef.current = (clampedTime / duration) * playbackDuration;
    },
    [duration, playbackDuration],
  );

  const moveToStart = useCallback(() => {
    setCurrentTimeState(0);
    accumulatedTimeRef.current = 0;
    pause();
  }, [pause]);

  const moveToEnd = useCallback(() => {
    if (duration === undefined) return;

    setCurrentTimeState(duration);
    accumulatedTimeRef.current = playbackDuration;
    pause();
  }, [duration, playbackDuration, pause]);

  return {
    currentTime,
    isPlaying,
    play,
    pause,
    moveForward,
    moveBackward,
    setCurrentTime,
    moveToStart,
    moveToEnd,
  };
};
