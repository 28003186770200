import classNames from 'classnames';
import { ReactNode } from 'react';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';

export interface BannerProps {
  /** Inner content */
  children?: ReactNode;

  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** A variant to determine appearance */
  variant: 'error' | 'success' | 'warning' | 'callout';

  /** Size of banner */
  size?: 'small' | 'large';
}

export const Banner = ({
  children,
  className,
  dataTestId = 'banner',
  variant,
  size,
}: BannerProps) => {
  const isError = variant === 'error';
  const isSuccess = variant === 'success';
  const isWarning = variant === 'warning';
  const isCallout = variant === 'callout';

  // seems that the error svg specifically has more empty space around it
  // @TODO - reach out to design and request that SVG content meets the edges
  // of its container
  const iconSize = !isError ? 22 : 25;

  return (
    <div
      className={classNames(
        `flex justify-start items-center rounded-lg`,
        {
          'gap-2': size === 'small',
          'gap-2.5': size !== 'small',
          'px-2': size === 'small',
          'px-[18px]': size !== 'small',
          'py-2': size === 'small',
          'py-4': size !== 'small',
          'bg-red-warm-50 border border-red-warm-700 shadow-lightgray': isError,
          'bg-green-warm-50 border-2 border-green-warm-100': isSuccess,
          'bg-yellow-50 border border-yellow-300': isWarning,
          'bg-orange-warm-50 border border-orange-300': isCallout,
        },
        className,
      )}
      data-testid={dataTestId}
    >
      <IconFlatRenderer
        className={classNames('flex-shrink-0', {
          '[&_*]:fill-[#EA580C]': isCallout,
          '[&_*]:fill-[#B51E1E]': isError,
          '[&_*]:fill-green-warm-600': isSuccess,
        })}
        iconKey={
          isWarning
            ? 'ICON_FLAT_CAUTION'
            : !isSuccess
            ? 'ICON_FLAT_EXCLAMATION_CIRCLE'
            : 'ICON_FLAT_CHECK_CIRCLE_SUBTRACT'
        }
        width={iconSize}
        height={iconSize}
      />
      <span
        className={classNames(`font-medium leading-4`, {
          'text-xs': size === 'small',
          'text-lg': size !== 'small',
          'text-cool-gray-800': isError,
          'text-cool-gray-700': isSuccess,
          'text-yellow-warm-700': isWarning,
          'text-orange-900': isCallout,
        })}
      >
        {children}
      </span>
    </div>
  );
};
