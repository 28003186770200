import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

const underlineClassName =
  'block bg-indigo-bright-600 w-full h-0.5 absolute bottom-0';

interface SimpleTabMenuItemProps {
  /** React node intended to be the rendered icon component */
  children: ReactNode;

  /** `className` that will override via `tailwind-merge` */
  className?: string;

  /**
   * `id` of a corresponding "tabpanel" used for accessibility
   * attribute `aria-controls`
   */
  controls?: string;

  /** `true` if this item should be focused */
  isFocused?: boolean;

  /** `true` if the current tab item is selected */
  isSelected?: boolean;

  /** Click handler of the tab item button */
  onClick?: () => void;

  /** On focus callback function */
  onFocus?: () => void;

  /** Count to represent number of items within the panel, if applicable */
  panelItemCount?: number;

  /** `true` to enable animation effect (sliding underline) */
  shouldAnimate?: boolean;
}

/**
 * A React component to render a tab menu item
 */
export const SimpleTabMenuItem = ({
  children,
  className,
  controls,
  panelItemCount,
  isFocused,
  isSelected,
  onClick,
  onFocus,
  shouldAnimate,
}: SimpleTabMenuItemProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (!isFocused) {
      return;
    }
    ref.current?.focus();
  }, [isFocused]);
  return (
    <button
      aria-controls={controls}
      aria-selected={isSelected}
      className={twMerge(
        'relative grow rounded-tl-md rounded-tr-md focus-visible:outline-[3px] focus-visible:outline-indigo-bright-200',
        className,
      )}
      onClick={onClick}
      onFocus={onFocus}
      ref={ref}
      role="tab"
      type="button"
    >
      <span className="p-3 flex justify-center items-center gap-[6px]">
        <span
          className={classNames(
            'flex justify-start items-center gap-[5px] leading-5 font-medium text-sm hover:text-indigo-bright-600',
            {
              'text-cool-gray-700': !isSelected,
              'text-indigo-bright-600': isSelected,
            },
          )}
        >
          <span>{children}</span>
          {typeof panelItemCount !== 'number' ? null : (
            <span
              className={classNames(
                'flex justify-center items-center h-5 w-5 rounded-full text-xxs font-medium',
                {
                  'text-cool-gray-800 bg-cool-gray-100': !isSelected,
                  'text-indigo-bright-800 bg-indigo-bright-100': isSelected,
                },
              )}
            >
              {panelItemCount}
            </span>
          )}
        </span>
      </span>
      {isSelected &&
        (!shouldAnimate ? (
          <span className={underlineClassName} />
        ) : (
          <motion.span
            className={underlineClassName}
            layoutId="underline"
            transition={{ duration: 0.2 }}
          />
        ))}
    </button>
  );
};
