import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { usePrevious } from '@assured/ui/hooks/usePrevious';

import { IconFlatFilterList } from '../Icon/IconFlatFilterList';
import { MenuItems } from '../Menu';
import { InboxMenu } from './InboxMenu';
import { inboxOpenMenuState } from './state/inboxOpenMenuState';

export enum InboxFilterTypeEnum {
  ARCHIVED_ONLY = 'ARCHIVED_ONLY',
  MESSAGES_ONLY = 'MESSAGES_ONLY',
  UNREAD = 'UNREAD',
  UNREAD_AND_READ = 'UNREAD_AND_READ',
}

const id = 'inbox__menu-filter';

/**
 * A React component to render the inbox filter menu
 */
export const InboxFilterMenu = ({
  className,
  isLoadingFilteredItems,
  onFilter,
}: {
  className?: string;
  isLoadingFilteredItems?: boolean;
  onFilter: (filterType: InboxFilterTypeEnum) => void;
}) => {
  const [inboxOpenMenu, setInboxOpenMenu] = useRecoilState(inboxOpenMenuState);
  const [isWaitingToClose, setIsWaitingToClose] = useState(false);
  const previousIsLoadingFilteredItems = usePrevious(isLoadingFilteredItems);

  useEffect(() => {
    if (
      !isLoadingFilteredItems &&
      previousIsLoadingFilteredItems &&
      isWaitingToClose
    ) {
      setIsWaitingToClose(false);
      setInboxOpenMenu(undefined);
    }
  }, [isLoadingFilteredItems, previousIsLoadingFilteredItems]);

  return (
    <InboxMenu
      className={className}
      icon={<IconFlatFilterList className="text-cool-gray-500" />}
      id={id}
    >
      <MenuItems
        defaultSelectedIndex={0}
        items={[
          {
            content: 'Unread and read',
            iconKey: 'ICON_FLAT_INBOX',
            value: InboxFilterTypeEnum.UNREAD_AND_READ,
          },
          {
            content: 'Unread',
            iconKey: 'ICON_FLAT_ENVELOPE_WITH_DOT',
            value: InboxFilterTypeEnum.UNREAD,
          },
          {
            content: 'Messages only',
            iconKey: 'ICON_FLAT_SPEECH_BALLOONS_OUTLINE',
            value: InboxFilterTypeEnum.MESSAGES_ONLY,
          },
          {
            content: 'Archived only',
            iconKey: 'ICON_FLAT_ARCHIVE_LARGE',
            isLoading: isLoadingFilteredItems,
            value: InboxFilterTypeEnum.ARCHIVED_ONLY,
          },
        ]}
        onSelect={value => {
          if (value) {
            onFilter(value as InboxFilterTypeEnum);
            if (inboxOpenMenu === id) {
              // for archived messages we refetch and wait for the
              // response to close menu
              if (value === InboxFilterTypeEnum.ARCHIVED_ONLY) {
                setIsWaitingToClose(true);
              } else {
                setInboxOpenMenu(undefined);
              }
            }
          }
        }}
        shouldRequireSelectedIndex
        shouldShowChecks
        title="Filter by"
      />
    </InboxMenu>
  );
};
