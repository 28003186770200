export {
  calculateEventDuration,
  generateLabels,
  preparePhasedEvents,
  getRelativeDateTimeString,
} from './utils';

export { CATPlayerProvider, usePlayerContext } from './CATPlayerContext';
export { CATPlayBar } from './CATPlayBar';

export { useEarliestStartTime } from './useEarliestStartTime';
export { useLatestDuringEndTime } from './useLatestDuringEndTime';
export { useActiveSubEvents, useSeenSubEvents } from './useActiveSubEvents';
export { useActiveLogEvents } from './useActiveLogEvents';
export { useLatestEndTime } from './useLatestEndTime';

export type { CatPlayBarProps } from './CATPlayBar';
export type { PlayerContextProps } from './CATPlayerContext';
export type {
  SubEvent,
  MainEvent,
  PhasedEvents,
  ActiveLogEventItem,
  BaseEvent,
} from './types';
