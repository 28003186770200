import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button } from '../Button';
import { Modal } from './Modal';

import type { ModalProps } from './Modal';

export interface ModalWithControlsProps extends ModalProps {
  /** A `ReactNode` for rendering a footer message (error message for example) */
  footerMessage?: ReactNode;

  /** Set to `true` is save button should have `disabled` prop set */
  isSaveButtonDisabled?: boolean;

  /** A `ReactNode` to render a leading icon within the action button */
  leadingIconAction?: ReactNode;

  /** Function called on save button click */
  onSave?: () => void;

  /** Text of the action button if applicable */
  textButtonAction?: string;

  /** Text of the cancel button if applicable */
  textButtonCancel?: string;

  /** The header title of the modal */
  title?: string;
}

export const ModalWithControls = ({
  children,
  className,
  classNameIconClose,
  footerMessage,
  hasCloseIcon,
  isSaveButtonDisabled,
  leadingIconAction,
  onSave,
  textButtonAction = 'Save',
  textButtonCancel = 'Cancel',
  title,
  ...props
}: ModalWithControlsProps) => {
  return (
    <Modal
      hasCloseIcon
      iconKeyClose="ICON_FLAT_X"
      {...props}
      className={twMerge(
        'w-full max-w-[600px] max-h-[90vh] py-0 px-0 flex flex-col',
        className,
      )}
      classNameIconClose={twMerge(
        'rounded-md p-1 hover:bg-cool-gray-50',
        classNameIconClose,
      )}
      ariaLabel={props.ariaLabel}
    >
      {title && (
        <h3 className="px-6 py-4 font-medium leading-[48px] text-xl text-cool-gray-700 shadow-lifted-sm border-b border-b-cool-gray-200">
          {title}
        </h3>
      )}
      <div className="p-6 flex flex-col gap-4 overflow-y-scroll">
        {children}
      </div>
      <footer className="p-6 shadow-lifted-top-sm border-t border-t-cool-gray-200 flex justify-end gap-4">
        {footerMessage && (
          <div className="flex items-center grow">{footerMessage}</div>
        )}
        <Button onClick={props.onClose} size="sm" variant="secondary">
          {textButtonCancel}
        </Button>
        {onSave && (
          <Button
            className="relative"
            disabled={!!isSaveButtonDisabled}
            onClick={onSave}
            size="sm"
            leadingIconFloating={leadingIconAction}
          >
            {textButtonAction}
          </Button>
        )}
      </footer>
    </Modal>
  );
};
