import { ComponentProps, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';

import type { IconKey } from '../Icon/IconFlatRenderer';

export type TaskCardHeaderStatusProps = ComponentProps<'div'> & {
  /** A `className` that will override via `tailwind-merge` */
  classNameStatusText?: string;

  /** A `className` that will override via `tailwind-merge` */
  classNameStatusIcon?: string;

  /** An `IconKey` to represent the task status */
  statusIconKey: IconKey;

  /** Text to represent the task status (name) */
  textStatus: ReactNode;

  /** Text to represent the task status change in detail, such as when it changed, etc */
  textStatusLong: ReactNode;
};

/**
 * A React component to render task status
 */
export const TaskCardHeaderStatus = ({
  className,
  classNameStatusIcon,
  classNameStatusText,
  statusIconKey,
  textStatus,
  textStatusLong,
}: TaskCardHeaderStatusProps) => {
  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      <div>
        <span
          className={twMerge(
            'inline-flex items-center justify-start gap-[6px] pr-2 pl-1 py-0.5 border font-medium text-xs leading-4 rounded-full',
            classNameStatusText,
          )}
        >
          <IconFlatRenderer
            className={classNameStatusIcon}
            iconKey={statusIconKey}
            width={12}
            height={12}
          />
          <span>{textStatus}</span>
        </span>
      </div>
      <span className="text-xs leading-4 font-normal">{textStatusLong}</span>
    </div>
  );
};
