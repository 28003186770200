import classNames from 'classnames';
import { DetailedHTMLProps } from 'react';

import { IconFlatCheckCircle } from '../Icon/IconFlatCheckCircle';
import { IconFlatCircleRemove } from '../Icon/IconFlatCircleRemove';
import { IconFlatDottedCircle } from '../Icon/IconFlatDottedCircle';
import { IconFlatExclamationCircle } from '../Icon/IconFlatExclamationCircle';

type DivProps = DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export type ToastProps = DivProps & {
  variant?: 'default' | 'info' | 'success' | 'warning' | 'danger';
  size?: 'md';
};

export const Toast = ({
  children,
  variant = 'default',
  size = 'md',
  ...props
}: ToastProps) => {
  return (
    <div
      {...props}
      className={classNames(
        `flex gap-1 items-center w-fit border text-xs leading-snug tracking-tight`,
        {
          'px-4 py-3': size === 'md',
          'bg-green-50 border-green-200 text-green-600': variant === 'success',
          'bg-yellow-50 border-yellow-200 text-yellow-600':
            variant === 'warning',
          'bg-blue-50 border-blue-200 text-blue-600': variant === 'info',
          'bg-red-50 border-red-200 text-red-800': variant === 'danger',
          'bg-gray-50 border-gray-200 text-gray-700': variant === 'default',
        },
        props.className,
      )}
    >
      {variant === 'default' ? (
        <IconFlatCircleRemove className="w-[14px] h-[14px] m-[2px] first:[&_rect]:fill-cool-gray-600" />
      ) : null}
      {variant === 'success' ? (
        <IconFlatCheckCircle className="w-[18px] h-[18px] [&_path]:fill-green-600" />
      ) : null}
      {variant === 'warning' ? (
        <IconFlatExclamationCircle className="w-[18px] h-[18px] [&_path]:fill-yellow-400" />
      ) : null}
      {variant === 'info' ? (
        <IconFlatDottedCircle className="w-[18px] h-[18px] [&_path]:fill-blue-600" />
      ) : null}
      {variant === 'danger' ? (
        <IconFlatExclamationCircle className="w-[18px] h-[18px] [&_path]:fill-red-600" />
      ) : null}
      {children}
    </div>
  );
};
