import classNames from 'classnames';
import { ComponentProps, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type TaskCardStatusProps = ComponentProps<'div'> & {
  /** Set to `true` if content is compact and applies different styling */
  isCompact?: boolean;

  /** Text to render describing the type of task change */
  textTypeOfTask?: ReactNode;
};

/**
 * A React component to render details about status changes
 */
export const TaskCardStatus = ({
  children,
  className,
  isCompact,
  textTypeOfTask = 'Update',
}: TaskCardStatusProps) => {
  return (
    <div
      className={twMerge(
        classNames(
          'text-sm leading-5 border-[0.5px] border-cool-gray-300 bg-cool-gray-100 rounded-lg',
          !isCompact ? 'p-3' : 'p-2',
        ),
        className,
      )}
    >
      {!isCompact && (
        <strong className="font-medium block">{textTypeOfTask}</strong>
      )}
      <p className="font-normal">{children}</p>
    </div>
  );
};
