declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

// A
export { AccessibleHiddenText } from './components/Accessibility/AccessibleHiddenText';

export { Accordion } from './components/Accordion';
export type { AccordionProps } from './components/Accordion';

export { AnimatedExpandCollapse } from './components/AnimatedExpandCollapse';
export type { AnimatedExpandCollapseProps } from './components/AnimatedExpandCollapse';

// B
export { Banner } from './components/Banner';
export type { BannerProps } from './components/Banner';

export { Button } from './components/Button';
export type { ButtonProps } from './components/Button';

export { ButtonSelectSingle } from './components/ButtonSelectSingle';
export type { ButtonSelectSingleProps } from './components/ButtonSelectSingle';

// C
export * from './components/CATPlayBar';

export { CheckboxLarge } from './components/CheckboxLarge';
export type { CheckboxLargeProps } from './components/CheckboxLarge';

export { Chip } from './components/Chip';
export type { ChipProps } from './components/Chip';

export { ConcatenatedChips } from './components/Chip';
export type { ConcatenatedChipsProps } from './components/Chip';

export * from './components/Chart';

export * from './components/Avatar';

export * from './lib/conicGradient';

export { CopyText } from './components/CopyText';
export type { CopyTextProps } from './components/CopyText';

// D
export { DatePicker, months } from './components/DatePicker';
export type { DatePickerProps } from './components/DatePicker';

export {
  DismissibleToast,
  DismissibleToastContent,
  DismissibleToastContentHeader,
} from './components/DismissibleToast';
export type { DismissibleToastProps } from './components/DismissibleToast';

export { downloadFile } from './lib/downloadFile';

// F
export { FormActions } from './components/FormActions';
export type { FormActionsProps } from './components/FormActions';

export {
  FlyoutMenu,
  Menu,
  MenuHeader,
  MenuItem,
  MenuItems,
} from './components/Menu';
export type {
  FlyoutMenuProps,
  MenuProps,
  MenuHeaderProps,
  MenuItemProps,
  MenuItemsProps,
} from './components/Menu';

// G
export { getDisplayDate } from './lib/getDisplayDate';

// H
export { HighlightedText } from './components/HighlightedText';
export type { HighlightedTextProps } from './components/HighlightedText';

export { HighwayExitType } from './components/LocationIndicator/LocationIndicator';

// I
export type { IconEmojiRendererProps } from './components/Icon/IconEmojiRenderer';

export * from './components/Icon';

export type { IconKey } from './components/Icon/IconFlatRenderer';

export { IconFlatRendererLazy } from './components/Icon/IconFlatRendererLazy';

export { IconEmojiRendererLazy } from './components/Icon/IconEmojiRendererLazy';

export {
  Inbox,
  InboxContent,
  InboxHeader,
  InboxItem,
  InboxIconButton,
  InboxSection,
} from './components/Inbox';

export { InboxItemButton } from './components/Inbox/InboxItemButton';
export {
  InboxFilterMenu,
  InboxFilterTypeEnum,
} from './components/Inbox/InboxFilterMenu';

export { InfoDetailsItem, InfoDetailsSection } from './components/InfoDetails';
export type {
  InfoDetailsItemProps,
  InfoDetailsSectionProps,
} from './components/InfoDetails';

export { InfoListItem } from './components/InfoListItem';
export type { InfoListItemProps } from './components/InfoListItem';

export {
  Text,
  Number,
  LicensePlate,
  HealthLocationCustomOption,
  HealthLocation,
  UnitInput,
  Location,
  LocationGoogleMaps,
  RadioInput,
  CheckboxInput,
} from './components/Input';

export type { LicensePlateInputProps } from './components/Input';

export type { LocationValue } from './components/Input/Location/types';

// L
export { Lightbox, ANIMATION_DURATION_MS } from './components/Lightbox';
export type { LightboxProps } from './components/Lightbox';

export { ListActionContainer } from './components/ListActionContainer';
export type { ListActionContainerProps } from './components/ListActionContainer';

// M
export { Modal } from './components/Modal';
export type { ModalProps } from './components/Modal';

export { ModalWithControls } from './components/Modal/ModalWithControls';
export type { ModalWithControlsProps } from './components/Modal/ModalWithControls';

export {
  useModal as useModalV2,
  useModalContext as useModalV2Context,
  Modal as ModalV2,
  ModalContent as ModalV2Content,
  ModalHeader as ModalV2Header,
  ModalTitle as ModalV2Title,
  ModalBody as ModalV2Body,
} from './components/Modal/Modal2';

// N
export {
  Notifications,
  NotificationItem,
  NotificationsHeader,
  NotificationsSection,
  NotificationsTask,
} from './components/Notifications';
export type { NotificationsProps } from './components/Notifications';

// P
export { PolicySearch, PolicySearchInput } from './components/PolicySearch';
export type { PolicySearchProps } from './components/PolicySearch';

export { Pill } from './components/Pill';

// R

// S
export {
  SegmentedControlNav,
  SegmentedControlNavLink,
} from './components/SegmentedControl';
export type {
  SegmentedControlNavProps,
  SegmentedControlNavLinkProps,
} from './components/SegmentedControl';

export { SimpleTabMenu } from './components/SimpleTabMenu';
export type { SimpleTabMenuProps } from './components/SimpleTabMenu';

export { Spinner } from './components/Spinner';
export type { SpinnerProps } from './components/Spinner';

export { STATES_OPTIONS } from './components/SelectStateDropdown/options';

export * from './components/SelectDropdown';

export * from './components/Svg';

// T
export { TabLink } from './components/Tabs/Tabs';

export * from './components/TaskCard';

export { TagDropdown } from './components/TagDropdown';
export type { TagDropdownProps } from './components/TagDropdown';

export { toast } from './components/DismissibleToast';

export type { Timeslot } from './components/AvailabilitySelection/types';

export type { TimeslotState } from './components/AvailabilitySelection';

export * from './components/Toast';

export { Toggle } from './components/Toggle';
export type { ToggleProps } from './components/Toggle';

// U
export { useAbTestingVariant } from './hooks/useAbTestingVariant';

export { useOutsideClickRef } from './hooks/useOutsideClickRef';

export { useVehicleSearch } from './hooks/useVehicleSearch';

export {
  AvailabilitySelection,
  useTimeslotSelectOptions,
} from './components/AvailabilitySelection';

export { useTimeslotState } from './components/AvailabilitySelection/hooks';

export {
  getIanaTimezone,
  getDefaultTimeslotState,
} from './components/AvailabilitySelection/helpers';

// V
export { VehicleSelection } from './components/VehicleSelection';
export type { VehicleSelectionProps } from './components/VehicleSelection';
