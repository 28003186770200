import classNames from 'classnames';

import { Spinner } from '../Spinner';
import { SparkChartBaseType } from './sparkChartTypes';

export interface SparkChartContainerBaseProps extends SparkChartBaseType {
  /** A chart title to display prominently */
  chartTitle?: string;

  /**
   * If `true` the chart has minimal content and therefore different
   * styling treatment (like smaller header bottom margin)
   */
  isSimple?: boolean;
}

export interface SparkChartContainerProps extends SparkChartContainerBaseProps {
  /** If `true` header and content are centered */
  isCentered?: boolean;

  /** If `true` data is empty */
  isEmpty?: boolean;

  /** Text to show when data is empty */
  emptyText?: string;

  /** If `true` a loading UI state will be presented */
  isLoading?: boolean;
}

/**
 * A component to render a chart and its container layout.
 */
export const SparkChartContainer = ({
  chartTitle,
  children,
  isCentered,
  isSimple,
  isEmpty,
  emptyText = 'No data',
  isLoading,
  chartSubTitle,
  maxWidth = '100%',
}: SparkChartContainerProps): JSX.Element => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-between rounded-2xl p-6 border border-cool-gray-300 bg-white h-full',
        {
          'text-center': isCentered,
        },
        maxWidth,
      )}
    >
      <header className="mb-6">
        <h3 className="text-cool-gray-500 font-normal text-sm mb-auto">
          {chartTitle || <>&nbsp;</>}
        </h3>
        {chartSubTitle}
      </header>
      <div
        className={classNames('mt-auto w-full mh-[69px] items-end', {
          'rounded-md ': isEmpty || isLoading,
          'my-3': isSimple,
        })}
      >
        {isEmpty && <span className="text-cool-gray-600">{emptyText}</span>}
        {!isEmpty && isLoading && (
          <div className="w-full flex items-center justify-center">
            <Spinner className="text-cool-gray-700" />
          </div>
        )}
        {!isEmpty && !isLoading && (
          <div className="h-full w-full">{children}</div>
        )}
      </div>
    </div>
  );
};
