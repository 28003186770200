import { useState } from 'react';

import { useKeyboardShortcut } from '@assured/ui/hooks';

import { IconFlatCheckWide } from '../Icon/IconFlatCheckWide';
import { Spinner } from '../Spinner';
import { MenuHeader } from './MenuHeader';
import { MenuItem } from './MenuItem';

import type { IconKey } from '../Icon/IconFlatRenderer';

export interface MenuItemsProps {
  /** The default selected index on mount applicable if using checks */
  defaultSelectedIndex?: number;

  /** A representation of menu items */
  items: {
    content: string;
    iconKey: IconKey;
    isLoading?: boolean;
    value?: string;
  }[];

  /** The title of the menu */
  title?: string;

  /** A selection callback */
  onSelect?: (value?: string) => void;

  /**
   * If selection is required and at least one item should always be
   * checked
   */
  shouldRequireSelectedIndex?: boolean;

  /** Set to `true` to show check icons within the menu items */
  shouldShowChecks?: boolean;
}

/**
 * A React component to render menu items
 */
export const MenuItems = ({
  defaultSelectedIndex,
  items,
  title,
  onSelect,
  shouldRequireSelectedIndex,
  shouldShowChecks,
}: MenuItemsProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    defaultSelectedIndex,
  );
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();
  useKeyboardShortcut(['ArrowUp'], () => {
    setFocusedIndex(previous => {
      if (!items.length) {
        return;
      }
      if (!previous) {
        return items.length - 1;
      }
      return previous - 1;
    });
  });
  useKeyboardShortcut(['ArrowDown'], () => {
    setFocusedIndex(previous => {
      if (typeof previous === 'undefined' || previous >= items.length - 1) {
        return 0;
      }
      return previous + 1;
    });
  });
  return (
    <>
      {title && <MenuHeader>{title}</MenuHeader>}
      {items.map((menuItem, index) => (
        <MenuItem
          contentStartIconKey={menuItem.iconKey}
          contentEnd={
            menuItem.isLoading ? (
              <Spinner width={12} height={12} />
            ) : selectedIndex !== index || !shouldShowChecks ? undefined : (
              <IconFlatCheckWide />
            )
          }
          key={menuItem.iconKey}
          onClick={() => {
            setSelectedIndex(previous => {
              if (previous === index && !shouldRequireSelectedIndex) {
                return undefined;
              }
              if (onSelect) {
                onSelect(menuItem?.value);
              }
              return index;
            });
          }}
          isFocused={focusedIndex === index}
          onFocus={() => setFocusedIndex(index)}
        >
          {menuItem.content}
        </MenuItem>
      ))}
    </>
  );
};
