import classNames from 'classnames';
import { FC } from 'react';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';
import { Text as TextInput } from '../Input';
import { TagDropdown } from '../TagDropdown';
import { PolicySearchTrailingContent } from './PolicySearchTrailingContent';

import type { HTMLProps, InputProps } from '../Input';

export const PolicySearchInput: FC<
  InputProps<HTMLProps> & {
    /** Custom hint */
    hintText?: string;

    /** Set to `true` when the input is loading */
    isLoading?: boolean;

    /** Set to `true` when should show trailing text */
    shouldShowTrailer?: boolean;

    /**
     * Set to `true` if the user should be prompted to search, when it's not
     * clear. For example before any search term has been captured, the user
     * may not know they need to press enter, hence prompt
     */
    shouldPromptToSearch?: boolean;
  }
> = ({
  className,
  hintText,
  isLoading,
  shouldPromptToSearch,
  shouldShowTrailer,
  ...props
}) => {
  // FIXME: Implement support for initial value in token_extractor
  // if we have an initial value handle it (as it won't trigger the onChange)
  // useEffect(() => {
  //   if (typeof initialValue === 'string' && initialValue && props.onChange) {
  //     props.onChange(initialValue);
  //   }
  // }, []);

  return (
    <TextInput
      {...props}
      className={classNames(
        '[&_.header-icon_*]:stroke-gray-400 [&:has(:focus)_.header-icon_*]:stroke-gray-600 [&[data-error]_.header-icon_*]:stroke-gray-600',
        className,
      )}
      header={
        <div>
          <IconFlatRenderer
            className="pl-1 header-icon"
            width={24}
            height={24}
            iconKey="ICON_FLAT_MAGNIFYING_GLASS"
          />
        </div>
      }
      onChange={value => {
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      placeholder="Search by name, policy number, or driver's license"
      trailer={
        !shouldShowTrailer ? undefined : (
          <PolicySearchTrailingContent
            className="flex items-center gap-2 flex-shrink-0"
            hintText={hintText}
            isLoading={isLoading}
            shouldPromptToSearch={shouldPromptToSearch}
          />
        )
      }
      TokenExtractorCustomTokenComponent={CustomTagDropdown}
    />
  );
};

const CustomTagDropdown: typeof TagDropdown = props => (
  <TagDropdown {...props} className="-my-1.5" />
);
