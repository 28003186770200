import classNames from 'classnames';
import { Fragment } from 'react';

import { Chip } from './Chip';

import type { ChipProps } from './Chip';

export interface ConcatenatedChipsProps {
  /** Child chip data to render */
  chips: ChipProps[];

  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** If `true` will display in a faded / opaque-like style */
  isFaded?: boolean;

  /** A label to prefix */
  label?: string;
}

export const ConcatenatedChips = ({
  chips,
  className,
  dataTestId = 'concatenated-chips',
  isFaded,
  label,
}: ConcatenatedChipsProps) => {
  return (
    <div
      className={classNames('flex items-center gap-2', className)}
      data-testid={dataTestId}
    >
      {label && (
        <span
          className={classNames(
            'text-base font-medium leading-7 transition-colors',
            {
              'text-cool-gray-500': !isFaded,
              'text-cool-gray-400': isFaded,
            },
          )}
        >
          {label}
        </span>
      )}
      {chips.map((chipProps, index) => (
        <Fragment key={chipProps.children?.toString()}>
          <Chip isFaded={isFaded} {...chipProps} />
          {index !== chips.length - 1 && (
            <span className="text-lg text-cool-gray-500 font-medium">+</span>
          )}
        </Fragment>
      ))}
    </div>
  );
};
