import classNames from 'classnames';
import { DetailedHTMLProps } from 'react';

import type {
  ButtonSelectSingleSize,
  ButtonSelectSingleVariantProps,
} from './ButtonSelectSingleProps';

export interface ButtonSelectSingleSquareBaseProps
  extends ButtonSelectSingleVariantProps {
  /** The size variant to render */
  size?: ButtonSelectSingleSize;
}

type HtmlButtonProps = DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type ButtonSelectSingleSquareProps = HtmlButtonProps &
  ButtonSelectSingleSquareBaseProps;

export const ButtonSelectSingleSquare = ({
  children,
  className,
  innerClassName,
  dataTestId = 'button-select-single-square',
  isActive,
  isHovered,
  leadingIcon,
  onSelection,
  setIsActive,
  setIsHovered,
  shortcutKey,
  shortcutDisabled,
  size,
  ...rest
}: ButtonSelectSingleSquareProps) => {
  return (
    <button
      className={classNames(
        'py-[22px] px-[16.5px] border text-cool-gray-600 font-normal text-lg relative rounded-lg',
        {
          // using an "inset border" to prevent layout shift on
          // toggling of active and inactive states
          'shadow-[inset_0_0_0_2px_#4F46E5]': isActive,

          // background
          'bg-white': !isHovered,
          'bg-indigo-bright-50': isHovered,

          // border
          'border-cool-gray-300': !isActive,
          'border-transparent': isActive,

          // width
          'w-[130px]': size === 'square',
          'w-[160px]': size === 'square-large',
        },
        className,
      )}
      data-testid={dataTestId}
      onClick={() => {
        if (onSelection) {
          onSelection(!isActive);
        }
        setIsActive(!isActive);
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      {...rest}
    >
      <span
        className={classNames(
          'rounded-bl-lg rounded-tr-lg text-sm font-medium px-2.5 leading-7 absolute top-0 right-0 transition-opacity',
          {
            'bg-indigo-bright-600 text-white': isActive,
            'bg-cool-gray-200 text-cool-gray-800': !isActive,
            'opacity-50': shortcutDisabled,
          },
        )}
      >
        {shortcutKey}
      </span>
      <span className="flex flex-col items-start gap-3 h-full">
        {leadingIcon && <span>{leadingIcon}</span>}
        <span className="text-left leading-[22px]">{children}</span>
      </span>
    </button>
  );
};
