import Fuse from 'fuse.js';
import { useEffect, useMemo } from 'react';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';
import { Text } from '../Input/Text';

// const inputSmallClasses = "!py-0 px-3 !min-h-[32px] text-sm";
const inputMediumClasses =
  '!py-[1px] !px-0 !min-h-[40px] text-sm relative -left-[4px]';

const TextContainer: React.FC = ({ children }) => {
  // this is designed to match the spacing in InfoSectionDetails and its contents
  return (
    <div className="flex flex-col gap-2 py-3 pl-[16px] pr-[15px]">
      {children}
    </div>
  );
};

interface DriversOnPolicySearchProps {
  drivers: DriverOption[];
  onChange: (value: DriverOption[]) => void;
}

export const DriversOnPolicySearch: React.FC<DriversOnPolicySearchProps> = ({
  drivers,
  onChange,
}: DriversOnPolicySearchProps) => {
  const fuse = useMemo(() => {
    return new Fuse<DriverOption>([], {
      keys: ['name'],
      includeScore: true,
      minMatchCharLength: 1,
      threshold: 0.3,
      shouldSort: true,
    });
  }, []);

  useEffect(() => {
    fuse.setCollection(drivers);
  }, [fuse, drivers]);

  const onChangeHandler = (value: string) => {
    if (value) {
      const searchResults = fuse.search(value);
      onChange(searchResults.map(result => result.item));
    } else {
      onChange(drivers);
    }
  };

  return (
    <TextContainer>
      <Text
        onChange={onChangeHandler}
        placeholder="Search drivers"
        classNameInput={inputMediumClasses}
        header={
          <div className="relative -left-[4px]">
            <IconFlatRenderer
              className="pl-1 header-icon"
              width={24}
              height={24}
              iconKey="ICON_FLAT_MAGNIFYING_GLASS"
            />
          </div>
        }
      />
    </TextContainer>
  );
};

export interface DriverOption {
  name: string;
}
