import { ReactNode } from 'react';

import { AvatarIcon } from '../AvatarIcon';
import { IconFlatX } from '../Icon/IconFlatX';

/**
 * A React component to render a dismissible "toast" content header component.
 */
export const DismissibleToastContentHeader = ({
  children,
  onCloseClick,
  secondary,
}: {
  children: ReactNode;
  onCloseClick: () => void;
  secondary: ReactNode;
}) => {
  return (
    <>
      <span>{children}</span>
      <span className="flex justify-between gap-2">
        <span>{secondary}</span>
        <button
          className="bg-cool-gray-50 h-5 w-5 rounded-full flex items-center justify-center"
          onClick={event => {
            event.stopPropagation();
            onCloseClick();
          }}
          type="button"
        >
          <IconFlatX
            className="[&_*]:fill-cool-gray-500"
            width={12}
            height={12}
          />
        </button>
      </span>
    </>
  );
};

/**
 * A React component to render a dismissible "toast" content component.
 */
export const DismissibleToastContent = ({
  avatarIconClassName,
  children,
  header,
  icon,
}: {
  avatarIconClassName?: string;
  children: ReactNode;
  header: ReactNode;
  icon: ReactNode;
}) => {
  return (
    <div className="flex gap-4 items-start w-full">
      <div>
        <AvatarIcon className={avatarIconClassName}>{icon}</AvatarIcon>
      </div>
      <div className="flex flex-col flex-grow gap-1 font-medium leading-5">
        <header className="text-cool-gray-500 text-xs flex justify-between gap-2">
          {header}
        </header>
        <div className="text-cool-gray-700 text-sm line-clamp-5">
          {children}
        </div>
      </div>
    </div>
  );
};
