import { DateTime } from 'luxon';
import React from 'react';

export interface TitleSectionProps {
  catStartTime: DateTime;
  currentTime: number;
}

/**
 * Displays the title and start time of the CAT event.
 */
function Base(props: TitleSectionProps): JSX.Element {
  const { catStartTime, currentTime } = props;

  const formattedDate = catStartTime
    .toLocal()
    .plus({ milliseconds: currentTime })
    .toFormat('ccc MM/dd/yyyy');

  const formattedTime = catStartTime
    .toLocal()
    .plus({ milliseconds: currentTime })
    .toFormat('hh:mma ZZZZ');

  return (
    <div className="justify-self-start">
      <div className="text-gray-700 text-left text-base font-medium leading-4">
        {formattedDate} at <span className="font-mono">{formattedTime}</span>
      </div>
    </div>
  );
}

export default React.memo(Base);
