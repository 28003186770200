import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';

import { AnimatedExpandCollapse } from '../AnimatedExpandCollapse';
import { IconFlatChevron } from '../Icon/IconFlatChevron';
import { InboxIconButton } from './InboxIconButton';
import { InboxSectionMenu } from './InboxSectionMenu';

export const idPrefixInboxSectionMenu = 'inbox__menu-section';

/**
 * A React component to render an inbox section
 */
export const InboxSection = ({
  children,
  externalId,
  id,
  isUnsubscribed,
  isUnsubscribing,
  onArchived,
  onMarkedAsRead,
  onUnsubscribed,
  shouldHideOnAnimationEnd,
  title,
}: {
  children?: ReactNode;
  externalId: string;
  id: string;
  isUnsubscribed?: boolean;
  isUnsubscribing?: boolean;
  onArchived: () => void;
  onMarkedAsRead: () => void;
  onUnsubscribed: () => Promise<void>;
  shouldHideOnAnimationEnd?: boolean;
  title: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const accessibilityText = !isExpanded
    ? 'Show notifications'
    : 'Hide notifications';

  useEffect(() => {
    if (shouldHideOnAnimationEnd) {
      setTimeout(() => {
        setIsHidden(true);
      }, 300);
    } else {
      setIsHidden(false);
    }
  }, [shouldHideOnAnimationEnd]);

  if (isHidden) {
    return null;
  }

  return (
    <section className="flex flex-col py-4 border-b border-b-cool-gray-200">
      <header className="px-2 flex justify-between gap-2">
        <span className="flex flex-col gap-0.5 font-normal">
          <span className="text-cool-gray-500 text-xs leading-4">
            {externalId}
          </span>
          <span className="text-cool-gray-900 text-sm leading-[20px]">
            {title}
          </span>
        </span>
        <span className="flex justify-between gap-2 relative">
          <InboxSectionMenu
            className="top-10 right-12 w-[175px]"
            id={`${idPrefixInboxSectionMenu}-${id}`}
            isUnsubscribed={isUnsubscribed}
            isUnsubscribing={isUnsubscribing}
            onArchived={onArchived}
            onMarkedAsRead={onMarkedAsRead}
            onUnsubscribed={onUnsubscribed}
          />
          <InboxIconButton
            accessibilityText={accessibilityText}
            icon={
              <IconFlatChevron
                className={classNames('text-cool-gray-500', {
                  'rotate-180': !isExpanded,
                })}
              />
            }
            className="active:outline focus:outline outline-[3px] outline-indigo-bright-200"
            onClick={() => setIsExpanded(previous => !previous)}
            title={accessibilityText}
          />
        </span>
      </header>
      <AnimatedExpandCollapse isExpanded={isExpanded}>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              className="flex flex-col pt-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </AnimatedExpandCollapse>
    </section>
  );
};
