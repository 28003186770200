import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface NotificationsProps {
  /** React node to render as content */
  children: ReactNode;

  /** `className` that will override via `tailwind-merge` */
  className?: string;
}

/**
 * A React component to render a notifications sidebar header
 */
export const NotificationsHeader = ({
  children,
  className,
}: NotificationsProps) => {
  return (
    <header
      className={twMerge(
        'font-medium text-xl text-cool-gray-700 leading-6',
        className,
      )}
    >
      {children}
    </header>
  );
};
