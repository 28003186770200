import Lottie from 'lottie-react';

import lottieSearchLoadingJson from './search-loading-lottie.json';

/** A component to render a search loading animation */
export const SearchLoading = ({
  className,
  shouldLoop,
}: {
  /** Lottie component `className` */
  className?: string;

  /** Should play animation in loop */
  shouldLoop?: boolean;
}) => {
  return (
    <Lottie
      animationData={lottieSearchLoadingJson}
      className={className}
      data-testid="lottie-policy-search-loading"
      loop={shouldLoop}
    />
  );
};
