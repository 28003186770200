/**
 * A function to trigger a file download from a URL. Still not a great standardized way
 * of doing this - yay! The ideal way would be to simply use an anchor element with
 * `download` attribute, however this is limited to the same domain, hence the need
 * for the below (since a lot of media we reference is hosted by a 3rd party)
 * @see {@link https://itnext.io/how-to-download-files-with-javascript-d5a69b749896 | where the below was inspired by}
 */
export const downloadFile = async ({
  filename,
  url,
}: {
  filename: string;
  url: string;
}) => {
  // i'm not sure what `dl=1` does, but somehow it seems to fix issues i had in
  // which downloads from S3 were getting CORS blocked intermittently. i found
  // this snippet in
  // `apps/frontend/src/components/ClaimView/components/Media/Image.tsx`
  const image = await fetch(
    url +
      (url.indexOf('imgix') !== -1
        ? ''
        : url.indexOf('?') !== -1
        ? '&dl=1'
        : '?dl=1'),
    {
      mode: 'cors',
    },
  );
  const imageBlog = await image.blob();
  const imageUrl = URL.createObjectURL(imageBlog);
  const anchor = document.createElement('a');
  anchor.href = imageUrl;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(imageUrl);
};
