import classNames from 'classnames';
import { FC } from 'react';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';
import { Text as TextInput } from '../Input';

import type { HTMLProps, InputProps } from '../Input';

export const SearchInput: FC<
  InputProps<HTMLProps> & {
    placeholder?: string;
    isLoading?: boolean;
  }
> = ({
  className,
  placeholder,
  isLoading,
  shouldPromptToSearch,
  shouldShowTrailer,
  ...props
}) => {
  return (
    <TextInput
      {...props}
      className={classNames(
        '[&_.header-icon_*]:stroke-gray-400 [&:has(:focus)_.header-icon_*]:stroke-gray-600 [&[data-error]_.header-icon_*]:stroke-gray-600',
        className,
      )}
      header={
        <div>
          <IconFlatRenderer
            className="pl-1 header-icon"
            width={24}
            height={24}
            iconKey="ICON_FLAT_MAGNIFYING_GLASS"
          />
        </div>
      }
      onChange={value => {
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      placeholder={
        placeholder ?? "Search by name, policy number, or driver's license"
      }
    />
  );
};
