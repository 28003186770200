import { ReactNode, Suspense, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconFlatRendererLazy } from '../Icon/IconFlatRendererLazy';

import type { IconKey } from '../Icon/IconFlatRenderer';

export interface MenuItemProps {
  /** A `ReactNode` to render as main content */
  children?: ReactNode;

  /** `className` that will override via `tailwind-merge` */
  className?: string;

  /** A `ReactNode` to render as ending content */
  contentEnd?: ReactNode;

  /** An icon key to match an icon with to render as starting content */
  contentStartIconKey?: IconKey;

  /** `true` if this item should be focused */
  isFocused?: boolean;

  /** On click callback function */
  onClick?: () => void;

  /** On focus callback function */
  onFocus?: () => void;
}

/**
 * A React component to render a menu item
 */
export const MenuItem = ({
  children,
  className,
  contentEnd,
  contentStartIconKey,
  isFocused,
  onClick,
  onFocus,
}: MenuItemProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (!isFocused) {
      return;
    }
    ref.current?.focus();
  }, [isFocused]);
  return (
    <button
      className={twMerge(
        'p-[10px] flex items-center justify-between gap-2 text-cool-gray-600 text-sm font-medium leading-[22px] text-left rounded-lg active:bg-cool-gray-100 hover:bg-cool-gray-50 focus-visible:outline-0 focus-visible:bg-cool-gray-50',
        className,
      )}
      onClick={onClick}
      onFocus={onFocus}
      ref={ref}
      type="button"
    >
      <span className="flex items-center justify-start gap-2">
        {!!contentStartIconKey && (
          <Suspense fallback="">
            <IconFlatRendererLazy iconKey={contentStartIconKey} />
          </Suspense>
        )}
        <span>{children}</span>
      </span>
      {contentEnd}
    </button>
  );
};
