import { z } from 'zod';

import { Country } from './Country';

export { Country };

export const addressFragmentTypeSchema = z.object({
  addressText: z.string().optional().nullable(),
  apartmentNumber: z.string().optional().nullable(),
  businessGooglePlaceId: z.string().optional().nullable(),
  businessName: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  county: z.string().optional().nullable(),
  country: z.custom<Country | `${Country}`>().optional().nullable(),
  id: z.string().optional().nullable(),
  line1: z.string().optional().nullable(),
  line2: z.string().optional().nullable(),
  postalCode: z.string().optional().nullable(),
  state: z.string().optional().nullable(),
});

/**
 * A partial Address object
 */
export type AddressFragmentType = z.infer<typeof addressFragmentTypeSchema>;

/**
 * Legacy alias of AddressFragmentType
 */
export type AddressType = AddressFragmentType;

export const locationFragmentTypeSchema = addressFragmentTypeSchema.extend({
  collectionMode: z.string().optional().nullable(),
  // For enrichedAt, `coerce` is used to support JSON ISO string parsing, as is used in
  // `./apps/backend/src/carrierApis/common/policyService.ts`.
  enrichedAt: z.coerce.date().optional().nullable(),
  latitude: z.number().optional().nullable(),
  longitude: z.number().optional().nullable(),
  type: z.string().optional().nullable(),
  typeOther: z.string().optional().nullable(),
});

export const locationTypeSchema = locationFragmentTypeSchema;

/**
 * A partial Location object — hopefully has at least one of `addressText` or `latitude` and `longitude`,
 * but may not have any of them.
 */
export type LocationFragment = z.infer<typeof locationFragmentTypeSchema>;

/**
 * Legacy alias of LocationFragment
 */
export type LocationType = LocationFragment;

export const addressSchema = z.object({
  addressText: z.string(),
  apartmentNumber: z.string().optional(),
  businessGooglePlaceId: z.string().optional(),
  businessName: z.string().optional(),
  city: z.string(),
  county: z.string().optional(),
  country: z.custom<Country | `${Country}`>(),
  id: z.string().optional(),
  line1: z.string(),
  line2: z.string().optional(),
  postalCode: z.string(),
  state: z.string(),
});

/**
 * A complete address object
 */
export type Address = z.infer<typeof addressSchema>;

export const richLocationSchema = addressSchema.extend({
  collectionMode: z.string().optional(),
  // For enrichedAt, `coerce` is used to support JSON ISO string parsing, as is used in
  // `./apps/backend/src/carrierApis/common/policyService.ts`.
  enrichedAt: z.coerce.date().optional(),
  latitude: z.number(),
  longitude: z.number(),
  businessName: z.string().optional(),
  type: z.string().optional(),
  typeOther: z.string().optional(),
});

/**
 * A RichLocation represents a location that has been enriched with coordinates and address information
 */
export type RichLocation = z.infer<typeof richLocationSchema>;

/**
 * Legacy alias of RichLocation
 */
export const locationSchema = richLocationSchema;
