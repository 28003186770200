import classNames from 'classnames';

import { Spinner } from '../Spinner';
import { SparkChartBaseType } from './sparkChartTypes';

export interface SparkChartFlexContainerBaseProps extends SparkChartBaseType {
  /**
   * The height of the chart in pixels. Most charting libraries require this
   * to determine the `canvas`.
   */
  chartHeight?: number;

  /** A chart title to display prominently */
  chartTitle?: string;

  /**
   * If `true` the chart has minimal content and therefore different
   * styling treatment (like smaller header bottom margin)
   */
  isSimple?: boolean;
}

export interface SparkChartFlexContainerProps
  extends SparkChartFlexContainerBaseProps {
  /** If `true` header and content are centered */
  isCentered?: boolean;

  /** If `true` data is empty */
  isEmpty?: boolean;

  /** Text to show when data is empty */
  emptyText?: string;

  /** If `true` a loading UI state will be presented */
  isLoading?: boolean;

  /** Class overrides */
  className?: string;
}

/**
 * A component to render a chart and its container layout.
 */
export const SparkChartFlexContainer = ({
  chartTitle,
  children,
  isCentered,
  isSimple,
  isEmpty,
  emptyText = 'No data',
  isLoading,
  chartSubTitle,
  className,
  maxWidth = '100%',
  chartHeight,
}: SparkChartFlexContainerProps): JSX.Element => (
  <div
    className={classNames(
      'px-6 py-4 bg-white h-full',
      {
        'text-center': isCentered,
      },
      maxWidth,
      className,
    )}
  >
    <header className="w-full">
      <h3 className="text-cool-gray-500 font-normal text-sm">
        {chartTitle || <>&nbsp;</>}
      </h3>
    </header>
    <div className="flex justify-between items-center">
      <span className="text-cool-gray-500 font-normal">{chartSubTitle}</span>

      <div
        className={classNames(
          'flex flex-col justify-center items-center w-full h-full',
          {
            'rounded-md': isEmpty || isLoading,
            'my-3': isSimple,
          },
        )}
        style={
          !chartHeight ? { height: '100%' } : { height: `${chartHeight}px` }
        }
      >
        {isEmpty && (
          <span className="text-cool-gray-600 w-full h-full flex justify-center">
            {emptyText}
          </span>
        )}
        {!isEmpty && isLoading && (
          <span className="">
            <Spinner className="text-cool-gray-700" />
          </span>
        )}

        {!isEmpty && !isLoading && children}
      </div>
    </div>
  </div>
);
