import classNames from 'classnames';
import moment from 'moment-timezone';
import { Fragment } from 'react';

export type ChartTooltipProps = {
  /** If tooltip is visible, this will be `true` */
  active?: boolean;

  /**
   * The payload passed to the chart component.
   * @see {@link https://www.tremor.so/docs/visualizations/line-chart#usage-example-with-custom-tooltip | tremor docs with example}
   */
  payload?: {
    dataKey?: string | number;
    name?: string | number;
    payload?: Record<string, number | string>;
  }[];
};

/**
 * A function to create and return a custom tooltip component
 */
export const createChartTooltip =
  ({
    secondaryText,
    valueFormatter = (value: number) => `${value}`,
    size = 'sm',
  }: {
    /** Secondary text displayed within the tooltip */
    secondaryText?: string;

    /** A size for the tootip popover width */
    size?: 'sm' | 'md' | 'lg';

    /** A function to format a number into a display string */
    valueFormatter?: (value: number) => string;
  }) =>
  ({ active, payload }: ChartTooltipProps) => {
    if (!active || !payload?.length) {
      return null;
    }

    const tooltipHeaderText =
      payload[0]?.payload?.tooltipHeaderText || payload[0].name;
    const tooltipSecondaryText =
      payload[0]?.payload?.tooltipSecondaryText || secondaryText;

    return (
      <div
        className={classNames(
          'rounded-md bg-white p-2 shadow-lifted border border-cool-gray-200 gap-2 flex flex-col text-left',
          {
            'w-24': size === 'sm',
            'w-28': size === 'md',
          },
        )}
      >
        {/**
         * @TODO Support multi-series chart. With this, a multi-series chart would
         * display an awkward looking tooltip. In its current iteration, there isn't
         * a design for a custom tooltip with multiple series'. We can cross this
         * bridge when we get to it.
         */}
        <Fragment key={`category-${payload[0].name}`}>
          <div>
            {tooltipHeaderText && (
              <div className="font-medium text-xs leading-[14.5px] text-cool-gray-800 mb-1">
                {tooltipHeaderText}
              </div>
            )}
            {payload[0].payload?.dateRaw && (
              <div className="font-medium text-cool-gray-400 text-[10px] leading-[12px]">
                {moment(payload[0].payload.dateRaw).format('MMM D, YYYY')}
              </div>
            )}
          </div>
          <div>
            {typeof payload[0].dataKey === 'string' &&
              typeof payload[0].payload?.[payload[0].dataKey] === 'number' && (
                <div
                  className={classNames(
                    'font-medium text-xl leading-[24px] text-cool-gray-900',
                    {
                      'mb-1': !!tooltipSecondaryText,
                    },
                  )}
                >
                  {valueFormatter(
                    payload[0].payload[payload[0].dataKey] as number,
                  )}
                </div>
              )}
            {tooltipSecondaryText && (
              <div className="font-normal text-cool-gray-700 text-base leading-[19px]">
                {tooltipSecondaryText}
              </div>
            )}
          </div>
        </Fragment>
      </div>
    );
  };
