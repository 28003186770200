import classNames from 'classnames';
import { ComponentType, ReactNode } from 'react';

import { ChartBackground } from './ChartBackground';
import { ChartContainer } from './ChartContainer';
import { ChartYAxis } from './ChartYAxis';

import type { ChartContainerProps } from './ChartContainer';
import type { ChartTooltipProps } from './ChartTooltip';

export type BarFunnelChartData = {
  /**
   * The label of a chart data point. This text is displayed in
   * the tooltip and as a label in the x-axis. For example,
   * "Qualified users".
   */
  label: string;

  /**
   * The changed percent from the previous data point. For example,
   * in a conversion funnel, if a data point signified "users", step 1
   * should reflect 100(%), while step 2 will reflect what percent
   * of those users continued, like 90(%)... in which case `change`
   * would be `10` to denote 10% change.
   */
  change: number;

  /**
   * The percent value of a chart data point, for example, in a
   * conversion funnel, `value` would be `100` in let's say
   * "step 1", while in "step 2", value is `90`.
   */
  value: number;
};

interface BarFunnelChartCommonProps {
  /**
   * A className for the main bar element (the one at the bottom
   * of the stack)
   */
  classNameBar?: string;

  /**
   * A className for the secondary bar element (the one at the top
   * of the stack)
   */
  classNameBarSecondary?: string;

  /**
   * A tooltip component to render the popover like elements. This
   * pattern is a convention of chart components in this package.
   */
  customTooltip: ComponentType<ChartTooltipProps>;

  /**
   * The property name representing the key within chart data holding
   * values
   */
  dataKey: string;
}

export interface BarColumnProps extends BarFunnelChartCommonProps {
  /**
   * Height of the chart content in pixels (excluding x-axis labels, scroll
   * gutters)
   */
  chartContentHeight: number;

  /**
   * A className for the main containing element
   */
  className?: string;

  /**
   * A data item to render the stacked bars and info elements
   */
  data: BarFunnelChartData;
}

/**
 * A component to render a column element consisting of 2 stacked bars and
 * info elements.
 */
const BarColumn = ({
  chartContentHeight,
  className,
  classNameBar,
  classNameBarSecondary,
  customTooltip: CustomTooltip,
  data,
  dataKey,
}: BarColumnProps) => {
  return (
    <div className={classNames('flex flex-col gap-[10px]', className)}>
      <div
        className="flex flex-col justify-end w-full"
        style={{ height: `${chartContentHeight}px` }}
      >
        {/**
         * If `change` is 0 or another falsey value, we won't render
         * the top stacked bar. This typically will only be on the
         * first data point, when the value is `100` (%).
         */}
        {!!data.change && (
          <div
            className={classNames('rounded-sm', classNameBarSecondary)}
            style={{ height: `${data.change}%` }}
          />
        )}
        <div
          className={classNames(
            'rounded-sm relative flex justify-center',
            classNameBar,
          )}
          style={{ height: `${data.value}%` }}
        >
          <div
            className={classNames('absolute', {
              // the below conditional `classNames` are being determined
              // from the percentage in height of the bar. it is
              // specifically accounting for bars that are low in which
              // the tooltip should be positioned relative to the bottom,
              // not the top.
              'top-0': data.value >= 30,
              'bottom-2': data.value < 30,
            })}
          >
            <div
              className={classNames({
                // the below conditional `classNames` are being determined
                // from the percentage in height of the bar similar to the
                // previous comment.
                'mt-[-13%]': data.value >= 90,
                'mt-[-30%]': data.value < 90 && data.value >= 30,
              })}
            >
              <CustomTooltip
                active
                payload={[
                  {
                    dataKey,
                    name: data.label,
                    payload: data,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center text-cool-gray-800 font-medium text-xs leading-[14px]">
        {data.label}
      </div>
    </div>
  );
};

/**
 * Padding in pixels used in both top and bottom when the chart is in a
 * scrollable state. We need this real estate to account for the scrollbar
 * on the bottom and overflow-y elements that extend above the top of the
 * container.
 */
const SCROLL_PADDING_Y = 16;

/** A component to render chart data */
const ChartDataContainer = ({
  children,
  classNameDataContainer,
  data,
  isScrollable,
}: {
  /** Child elements to render */
  children?: ReactNode;

  /** A className for the container of the actual data (bars) */
  classNameDataContainer?: string;

  /** Data to render the charts */
  data?: BarFunnelChartData[];

  /** If the chart area is scrollable, this should be `true` */
  isScrollable?: boolean;
}) => {
  return (
    <div
      className={classNames('flex w-full relative', {
        'overflow-x-clip': !isScrollable,
        'overflow-x-scroll': isScrollable,
      })}
      style={{
        ...(!isScrollable
          ? {}
          : {
              paddingTop: `${SCROLL_PADDING_Y}px`,
              paddingBottom: `${SCROLL_PADDING_Y}px`,
            }),
      }}
    >
      {!!data?.length && (
        <div
          className={classNames(
            'absolute z-10 flex justify-between min-w-full',
            classNameDataContainer,
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

interface BarFunnelChartProps extends BarFunnelChartCommonProps {
  /**
   * The textual representation of the unit (example: `%`)
   */
  chartYAxisUnit?: string;

  /**
   * The y-axis values representing the possible range of data points
   */
  chartYAxisValues?: number[];

  /**
   * A className for the main containing element
   */
  className?: string;

  /**
   * A className for the container of the stacked bars
   */
  classNameBarContainer?: string;

  /**
   * A className for the container of the actual data (bars)
   */
  classNameDataContainer?: string;

  /**
   * Data to render the charts
   */
  data?: BarFunnelChartData[];

  /**
   * If the chart area is scrollable, this should be `true`
   */
  isScrollable?: boolean;
}

/**
 * Height in pixels of the x-axis label elements per design. We need to use
 * this value in calculations.
 */
const X_AXIS_LABEL_HEIGHT = 24;

/**
 * A component to render a funnel-based, bar chart and its container
 * layout.
 */
export const BarFunnelChart = ({
  chartHeight = 224,
  chartTitle,
  chartYAxisUnit = '%',
  chartYAxisValues = [100, 75, 50, 25, 0],
  className,
  classNameBar = 'bg-indigo-bright-600',
  classNameBarContainer = 'w-[calc(50%-12px)]',
  classNameBarSecondary = 'bg-gradient-180-degrees-indigo',
  classNameDataContainer = 'gap-3',
  customTooltip: CustomTooltip,
  data,
  dataKey,
  isLoading,
  isScrollable,
  labels,
  emptyText,
}: BarFunnelChartProps & ChartContainerProps) => {
  /**
   * Height of the chart content in pixels (excluding x-axis labels, scroll
   * gutters)
   * */
  const chartContentHeight =
    chartHeight -
    X_AXIS_LABEL_HEIGHT -
    (!isScrollable ? 0 : SCROLL_PADDING_Y * 2);

  /** Height of each y-axis range in pixels */
  const chartYAxisUnitHeight =
    chartContentHeight / (chartYAxisValues.length - 1);

  return (
    <ChartContainer
      chartHeight={chartHeight}
      chartTitle={chartTitle}
      isEmpty={!isLoading && (!data || data.length <= 0)}
      isLoading={isLoading}
      labels={labels}
      emptyText={emptyText}
    >
      <div
        className={classNames('w-full flex justify-start', className)}
        style={!chartHeight ? undefined : { height: `${chartHeight}px` }}
      >
        <ChartYAxis
          chartYAxisUnit={chartYAxisUnit}
          chartYAxisUnitHeight={chartYAxisUnitHeight}
          chartYAxisValues={chartYAxisValues}
          isScrollable={isScrollable}
          scrollPaddingY={SCROLL_PADDING_Y}
        />
        <ChartDataContainer
          classNameDataContainer={classNameDataContainer}
          data={data}
          isScrollable={isScrollable}
        >
          {data?.map(item => (
            <BarColumn
              chartContentHeight={chartContentHeight}
              className={classNameBarContainer}
              classNameBar={classNameBar}
              classNameBarSecondary={classNameBarSecondary}
              customTooltip={CustomTooltip}
              data={item}
              dataKey={dataKey}
              key={`bar-${item.label}`}
            />
          ))}
          <ChartBackground
            chartYAxisUnitHeight={chartYAxisUnitHeight}
            chartYAxisValues={chartYAxisValues}
            className="absolute -z-10 flex flex-col w-full"
          />
        </ChartDataContainer>
      </div>
    </ChartContainer>
  );
};
