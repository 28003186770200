import './App.css';
import 'react-image-lightbox/style.css';

import { H } from 'highlight.run';
import { History } from 'history';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { StickyFooterBanner } from './components/Layout/EnvBanner';
import { AssuredAuthOProvider } from './components/Providers/AssuredAuthOProvider';
import { RouteProvider } from './components/Providers/RouteProvider';
import { TenantConfigProvider } from './components/Providers/TenantConfigProvider';
import { Placeholder } from './components/shared/Placeholder';
import config from './config';
import { useAuth } from './hooks/useAuth';
import { EmmaProvider } from './hooks/useEmma';
import { MessagingProvider } from './hooks/useMessaging';
import { AuthenticatedApolloProvider } from './services/apollo';

/**
 * Root component for the application. Note that <AppInner/> is broken out into its own
 * component so it can be wrapped in the <AssuredAuthOProvider/> component. This is done
 * so that we can listen for authentication changes and fire analytics events based on them.
 */
const App = ({ history }: { history: History }) => {
  return (
    <AssuredAuthOProvider history={history}>
      <AppInner history={history} />
    </AssuredAuthOProvider>
  );
};

export const AppInner = ({ history }: { history: History }) => {
  const { error, logout, user } = useAuth();

  useEffect(() => {
    const Intercom = (window as any).Intercom;
    if (config.enableIntercom && user?.sub) {
      Intercom('boot', {
        app_id: 's49bfo9k',
        user_id: user.sub,
        name: user.name,
        email: user.email,
        company: {
          id: config.tenant,
          name: config.tenant,
        },
      });

      const unregister = history.listen(() => {
        Intercom('update');
      });

      return () => unregister();
    }
  }, [user]);

  useEffect(() => {
    if (user?.sub) {
      analytics.identify(user.sub || '', {
        email: user.email,
        name: user.name,
        company: config.tenant,
      });
      H.identify(user.sub || '', {
        highlightDisplayName: user.name || user.email || '',
        email: user.email || '',
        name: user.name || '',
        company: config.tenant,
      });
    } else if (window.navigator.userAgent.includes('HeadlessChrome')) {
      // This is our headless PDF rendering, set it to the same user for consistency so we
      // don't end up with a bajillion anonymous users in the analytics.
      analytics.identify('headless', {
        email: 'headless@assured.claims',
        name: 'Headless PDF Renderer',
      });
    }
  }, [user]);

  if (error) {
    return (
      <Placeholder
        message={
          <div>
            <div>
              {(error as any)?.error_description ||
                'An unknown authentication error occurred. Please try again.'}
            </div>
            <div className="mt-1">
              <button
                className="cursor-pointer text-blue-700 hover:text-blue-600 text-xs font-bold"
                onClick={() => logout()}
                type="button"
              >
                Log out
              </button>
            </div>
          </div>
        }
      />
    );
  }

  return (
    <AuthenticatedApolloProvider>
      <MessagingProvider>
        <EmmaProvider>
          <TenantConfigProvider>
            <Helmet>
              <title>Assured ClaimView</title>
            </Helmet>
            <div className="App bg-gray-100">
              <RouteProvider history={history} />
              <StickyFooterBanner />
            </div>
          </TenantConfigProvider>
        </EmmaProvider>
      </MessagingProvider>
    </AuthenticatedApolloProvider>
  );
};

export default App;
