/// <reference types="@types/google.maps" />

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { components } from 'react-select';

import { LocationType } from '@assured/shared-types/Claim/LocationType';

import { IconFlatAddressBook } from '../../Icon/IconFlatAddressBook';

export const LoadingMessage = (
  props: Parameters<typeof components.NoOptionsMessage>[0],
) => (
  <components.NoOptionsMessage {...props}>
    <div {...props.innerProps}>Searching locations...</div>
  </components.NoOptionsMessage>
);

export const StartTypingMessage = (
  props: Parameters<typeof components.NoOptionsMessage>[0] & {
    addressBookEntries?: (LocationType & { label: string })[];
    onAddressBookSelect: (v: LocationType & { label: string }) => void;
  },
) => {
  const [focusedOption, setFocusedOption] = useState<number>(0);
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        setFocusedOption(prev => {
          if (prev >= (props.addressBookEntries?.length ?? 0) - 1) return 0;
          return prev + 1;
        });
      } else if (e.key === 'ArrowUp') {
        setFocusedOption(prev => {
          if (prev <= 0) return (props.addressBookEntries?.length ?? 0) - 1;
          return prev - 1;
        });
      } else if (e.key === 'Enter') {
        const selected = props.addressBookEntries?.[focusedOption];
        if (selected) {
          props.onAddressBookSelect(selected);
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [focusedOption]);

  return (
    <components.NoOptionsMessage {...props}>
      <div {...props.innerProps}>
        {props.addressBookEntries?.length ? (
          <div className="-mx-3 -mb-2">
            <div className="px-2 mt-1 mb-2 flex items-center">
              <IconFlatAddressBook className="mr-2" />
              <span className="text-left flex-1 uppercase text-sm font-medium text-cool-gray-400">
                Address book
              </span>
            </div>
            <div className="flex flex-col">
              {props.addressBookEntries.map((entry, i) => (
                <React.Fragment key={entry.id || i}>
                  <button
                    type="button"
                    className={classNames(
                      'text-cool-gray-600 rounded-md px-2 py-2 text-left',
                      focusedOption === i && 'bg-cool-gray-100/90',
                    )}
                    onMouseEnter={() => setFocusedOption(i)}
                    onClick={() => {
                      props.onAddressBookSelect(entry);
                    }}
                  >
                    <div className="leading-5">{entry.addressText}</div>
                    <div className="text-sm text-cool-gray-400">
                      {entry.label}
                    </div>
                  </button>
                  <div className="bg-cool-gray-200 h-[1px] w-full my-1 last:hidden" />
                </React.Fragment>
              ))}
            </div>
          </div>
        ) : (
          <div>Start typing to search locations...</div>
        )}
      </div>
    </components.NoOptionsMessage>
  );
};
