import { DateTime } from 'luxon';
import { useRef } from 'react';

import ActionButtons from './ActionButtons';
import { ActionWrapper } from './ActionWrapper';
import { usePlayerContext } from './CATPlayerContext';
import { Container } from './Container';
import PlaybackSpeed from './PlaybackSpeed';
import TitleSection from './TitleSection';
import { Track } from './Track';
import { MainEvent } from './types';

export type CatPlayBarProps = {
  event: MainEvent;
  catStartTime: DateTime;
};

/**
 * A high-level component representing the playback bar for a "CAT" event (e.g., weather warnings, alerts).
 * Displays controls for playback and phase navigation, with visual indicators for current playback status and phases.
 */
export function CATPlayBar(props: CatPlayBarProps): JSX.Element {
  const { catStartTime, event } = props;

  const trackRef = useRef<HTMLDivElement>(null);

  const {
    currentTime,
    isPlaying,
    play,
    pause,
    selectedPhases,
    setCurrentTime,
    phasedEvents,
    earliestStartTime,
    playbackSpeed,
    setPlaybackSpeed,
    moveForward,
    moveBackward,
    moveToStart,
    moveToEnd,
  } = usePlayerContext();

  const currentFilteredEvents = selectedPhases
    .flatMap(selectedPhase => phasedEvents[selectedPhase] || [])
    .filter(phaseEventFilter => phaseEventFilter);

  const playheadPosition = (currentTime / event.duration) * 100;
  const renderedEvent = {
    duration: event.duration,
    labels: event.labels,
    subEvents: currentFilteredEvents,
  };

  return (
    <Container>
      <ActionWrapper>
        <TitleSection catStartTime={catStartTime} currentTime={currentTime} />
        <ActionButtons
          isPlaying={isPlaying}
          play={play}
          pause={pause}
          next={moveForward}
          previous={moveBackward}
          moveToStart={moveToStart}
          moveToEnd={moveToEnd}
        />
        <PlaybackSpeed
          playbackSpeed={playbackSpeed}
          handlePlaybackSpeedChange={setPlaybackSpeed}
        />
      </ActionWrapper>
      <Track
        event={renderedEvent}
        playheadPosition={playheadPosition}
        setCurrentTime={setCurrentTime}
        trackRef={trackRef}
        currentTime={currentTime}
        earliestStartTime={earliestStartTime}
      />
    </Container>
  );
}
