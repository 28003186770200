import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { useKeyboardShortcut } from '@assured/ui/hooks';

import { useVehicleSearch } from '../../hooks/useVehicleSearch';
import { ListActionContainer } from '../ListActionContainer';
import { SearchInput } from '../SearchInput';
import { SvgVehicleQuestionMark } from '../Svg/SvgVehicleQuestionMark';
import { VehicleSelectionFooter } from './VehicleSelectionFooter';
import { VehicleSelectionItem } from './VehicleSelectionItem';

import type { VehicleSelectionItemPropsBase } from './VehicleSelectionItem';

export interface VehicleSelectionProps {
  /** Custom className */
  className?: string;

  /** Custom styles */
  style?: React.CSSProperties;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** A callback to submit selected vehicle data */
  onSubmit: (data: {
    vehicleIds?: (string | number)[];
    vehicleId?: string | number;
  }) => void;

  /** A callback called when "Unlisted Vehicle" is selected */
  onUnlisted?: () => void;

  /** Acceptable characters to be used as the shortcut key */
  shortcutKeys?: string[];

  /** List of vehicle data */
  vehicles: VehicleSelectionItemPropsBase[];

  /** Vehicle to select by default */
  initialSelectedVehicleIds?: (string | number)[];

  /** Don't offer the trailer button */
  disallowTrailer?: boolean;

  /** Don't offer the unlisted button */
  disallowUnlisted?: boolean;

  /** Allow multiple vehicle selection */
  multiSelect?: boolean;

  withSearch?: boolean;
}

type VehicleId = string | number;

const toggleSingleSelection = (
  currentSelection: VehicleId[],
  selectedId: VehicleId,
): VehicleId[] => {
  const isCurrentlySelected = currentSelection[0] === selectedId;
  return isCurrentlySelected ? [] : [selectedId];
};

const toggleMultiSelection = (
  currentSelection: VehicleId[],
  selectedId: VehicleId,
): VehicleId[] => {
  const isCurrentlySelected = currentSelection.includes(selectedId);
  return isCurrentlySelected
    ? currentSelection.filter(id => id !== selectedId)
    : [...currentSelection, selectedId];
};

export const VehicleSelection: React.FC<VehicleSelectionProps> = ({
  className,
  style,
  dataTestId = 'vehicle-selection',
  onSubmit,
  onUnlisted,
  shortcutKeys,
  vehicles,
  initialSelectedVehicleIds,
  disallowTrailer,
  disallowUnlisted,
  multiSelect = false,
  withSearch = false,
}: VehicleSelectionProps) => {
  const [selectedVehicleIds, setSelectedVehicleIds] = useState<
    (string | number)[]
  >(initialSelectedVehicleIds ? [...initialSelectedVehicleIds] : []);

  useEffect(() => {
    setSelectedVehicleIds(
      initialSelectedVehicleIds ? [...initialSelectedVehicleIds] : [],
    );
  }, [initialSelectedVehicleIds]);

  const onItemClicked = (vehicleId: VehicleId) => {
    setSelectedVehicleIds(currentSelection =>
      multiSelect
        ? toggleMultiSelection(currentSelection, vehicleId)
        : toggleSingleSelection(currentSelection, vehicleId),
    );
  };

  const { disabled: enterShortcutDisabled } = useKeyboardShortcut(
    ['enter'],
    () => {
      if (selectedVehicleIds.length > 0) {
        onSubmit(
          multiSelect
            ? { vehicleIds: selectedVehicleIds }
            : { vehicleId: selectedVehicleIds[0] },
        );
      }
    },
  );

  const [searchTerm, setSearchTerm] = useState<string>('');

  const onSearchInputChange = (value: string) => {
    setSearchTerm(value);
  };

  // avoid the expense of searching if searching is disabled,
  // but of course the hook must be called unconditionally
  const searchResults = useVehicleSearch({
    searchTerm: withSearch ? searchTerm : '',
    options: withSearch ? vehicles : [],
  });

  const vehicleOptions = withSearch ? searchResults : vehicles;

  return (
    <div
      className={classNames('w-full flex flex-col gap-3', className)}
      data-testid={dataTestId}
      style={style}
    >
      {withSearch && (
        <SearchInput
          placeholder="Search by make, model, license plate, or VIN"
          onChange={onSearchInputChange}
        />
      )}
      <ListActionContainer
        className="justify-start"
        footer={
          <VehicleSelectionFooter
            onSubmit={onSubmit}
            selectedVehicleIds={selectedVehicleIds}
            multiSelect={multiSelect}
            disallowTrailer={disallowTrailer}
            disallowUnlisted={disallowUnlisted}
            shortcutDisabled={enterShortcutDisabled}
            onUnlisted={onUnlisted}
          />
        }
      >
        {vehicleOptions.length === 0 ? (
          <div className="flex-1 flex flex-col items-center justify-center gap-4">
            <SvgVehicleQuestionMark className="-ml-1" />
            <div className="text-cool-gray-500 font-medium text-base">
              No vehicles available
            </div>
          </div>
        ) : (
          <div className="overflow-x-auto w-full">
            {vehicleOptions.map((vehicleSelectionItemProps, index) => (
              <VehicleSelectionItem
                {...vehicleSelectionItemProps}
                hasRoundedTop={index === 0}
                isActive={selectedVehicleIds.includes(
                  vehicleSelectionItemProps.vehicleId,
                )}
                key={`vehicle-selection-item-${vehicleSelectionItemProps.vehicleId}`}
                onClick={() =>
                  onItemClicked(vehicleSelectionItemProps.vehicleId)
                }
                shortcutKeys={shortcutKeys}
              />
            ))}
          </div>
        )}
      </ListActionContainer>
    </div>
  );
};
