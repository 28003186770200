import { SparkAreaChart } from '@tremor/react';

import { SparkChartContainer } from './SparkChartContainer';
import { SparkChartBaseType } from './sparkChartTypes';

import type { ChartContainerProps } from './ChartContainer';

import type { SparkAreaChartProps } from '@tremor/react';

interface SimpleAreaChartProps extends SparkChartBaseType {
  /**
   * SparkChart Bar/Area charts can be stacked. This is controlled by a boolean toggle and additional elements in the categories array.
   */
  stack?: boolean;

  data: Record<string, number>[] | undefined;
}

/**
 * A component to render a bar chart and its container layout.
 */
export const SimpleAreaChart = ({
  chartHeight,
  chartTitle,
  children,
  isLoading,
  chartSubTitle,
  maxWidth,
  stack = false,
  ...props
}: SparkAreaChartProps & ChartContainerProps & SimpleAreaChartProps) => {
  return (
    <SparkChartContainer
      isEmpty={!isLoading && (!props?.data || props?.data.length <= 0)}
      isLoading={isLoading}
      chartTitle={chartTitle}
      chartSubTitle={chartSubTitle}
      maxWidth={maxWidth}
    >
      {children}
      <SparkAreaChart {...props} style={{ width: '100%' }} stack={stack} />
    </SparkChartContainer>
  );
};
