import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface NotificationsSectionProps {
  /** React node to render as content */
  children: ReactNode;

  /** `className` that will override via `tailwind-merge` */
  className?: string;
}

/**
 * A React component to render a notifications sidebar section
 */
export const NotificationsSection = ({
  children,
  className,
}: NotificationsSectionProps) => {
  return (
    <section
      className={twMerge(
        'flex flex-col flex-grow border-b border-b-cool-gray-200 h-1/2',
        className,
      )}
    >
      {children}
    </section>
  );
};
