import classNames from 'classnames';

import { ChartContainer } from './ChartContainer';

import type { ChartContainerProps } from './ChartContainer';

/**
 * A component to render a big number chart and its container layout.
 */
export const BigNumberChart = ({
  chartHeight = 224,
  chartTitle,
  className,
  isLoading,
  label,
  value,
  valueFormatter,
  unit,
  size = 'lg',
}: {
  className?: string;
  label?: string;
  value?: number;
  valueFormatter?: (value: number) => string;
  unit?: string;
  size?: 'md' | 'lg';
} & ChartContainerProps) => (
  <ChartContainer
    chartHeight={chartHeight}
    chartTitle={chartTitle}
    isCentered
    isEmpty={!isLoading && typeof value !== 'number'}
    isLoading={isLoading}
    isSimple
  >
    <div className={classNames('flex flex-col items-center', className)}>
      {typeof value !== 'number' ? null : (
        <>
          <div
            className={classNames(
              'font-bold text-cool-gray-900 leading-[121px]',
              size === 'lg' ? 'text-[100px]' : 'text-[80px]',
            )}
          >
            {!valueFormatter ? value : valueFormatter(value)}
            {unit && <span className="text-[40px] leading-[48px]">{unit}</span>}
          </div>
          {label && (
            <div className="font-normal text-lg leading-[22px] text-cool-gray-500">
              {label}
            </div>
          )}
        </>
      )}
    </div>
  </ChartContainer>
);
