import classNames from 'classnames';
import { ReactNode } from 'react';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';

export interface AccordionHeaderProps {
  /** The React Node to render as the title content */
  children: ReactNode;

  /** Custom className for the whole component */
  className?: string;

  /** Custom className for the header text */
  classNameHeaderText?: string;

  /** Custom className for the header padding */
  classNameHeaderPadding?: string;

  /** Custom className from the horizontal rule border color */
  classNameHorizontalRuleBorderColor?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** Set to `true` if text needs to be treated as error text */
  hasDanger?: boolean;

  /** Set to `true` if accordion item is collapsed */
  isCollapsed?: boolean;

  /** Click handler (used to control the component collapsed state) */
  onClick: () => void;

  /** For cases where you don't want a line running from your title to the chevron */
  hideHeaderHR?: boolean;

  /** Disable toggling */
  disabled?: boolean;
}

export const AccordionHeader = ({
  children,
  className,
  classNameHeaderPadding,
  classNameHeaderText,
  classNameHorizontalRuleBorderColor,
  dataTestId = 'accordion-header',
  hasDanger,
  isCollapsed,
  onClick,
  hideHeaderHR,
  disabled,
}: AccordionHeaderProps) => {
  return (
    <button
      className={classNames(
        'flex focus:outline-none focus-visible:outline-none w-full gap-2 items-center',
        {
          'p-[18px] pb-[6px]': !classNameHeaderPadding,
          'cursor-pointer': !disabled,
          'cursor-default': disabled,
        },
        className,
        classNameHeaderPadding,
      )}
      data-testid={dataTestId}
      onClick={event => {
        // prevent event from bubbling up and triggering clicks of
        // parent components
        event.stopPropagation();

        if (!disabled) {
          onClick();
        }
      }}
      type="button"
    >
      <div
        className={classNames(
          'text-sm leading-[17px] font-medium',
          { 'text-cool-gray-900': !hasDanger && !classNameHeaderText },
          { 'text-red-warm-600': hasDanger && !classNameHeaderText },
          classNameHeaderText,
        )}
      >
        {children}
      </div>
      {!hideHeaderHR ? (
        <div className="grow flex items-end">
          <hr
            className={classNames(
              'h-1/2 border-t w-full',
              {
                'border-t-cool-gray-200': !classNameHorizontalRuleBorderColor,
              },
              classNameHorizontalRuleBorderColor,
            )}
          />
        </div>
      ) : null}

      {!disabled ? (
        <div className="flex items-center">
          <IconFlatRenderer
            className={classNames('icon-flat-renderer', {
              'rotate-180 [&_path]:stroke-cool-gray-400': !isCollapsed,
              '[&_path]:stroke-cool-gray-300': isCollapsed,
            })}
            iconKey="ICON_FLAT_CHEVRON_OUTLINE_DOWN"
            width={14}
            height={14}
          />
        </div>
      ) : null}
    </button>
  );
};
