import React from 'react';

import { SelectDropdown } from '../SelectDropdown/SelectDropdown';

export interface PlaybackSpeedProps {
  playbackSpeed: number;
  handlePlaybackSpeedChange: (speed: number) => void;
}

/**
 * A dropdown for selecting the current phase of the CAT event.
 */
function BasePlaybackSpeedDropdown(props: PlaybackSpeedProps): JSX.Element {
  const { playbackSpeed, handlePlaybackSpeedChange } = props;

  const handleChange = (value: number) => {
    handlePlaybackSpeedChange(value);
  };

  const options = [
    {
      label: '0.05x',
      value: 0.05,
    },
    {
      label: '0.5x',
      value: 0.5,
    },
    {
      label: '1x',
      value: 1,
    },
    {
      label: '2x',
      value: 2,
    },
    {
      label: '5x',
      value: 5,
    },
    {
      label: '10x',
      value: 10,
    },
  ];

  return (
    <div className="justify-self-end w-[82px] min-w-[90px]">
      <SelectDropdown
        className="min-w-[90px] z-[9999999]"
        size="sm"
        value={playbackSpeed}
        onChange={option => handleChange(option?.value)}
        options={options}
        isClearable={false}
        menuPortalZIndex={999999}
      />
    </div>
  );
}

/**
 * Memoized ActionButton that only updates when currentPhase changes.
 */
export default React.memo(
  BasePlaybackSpeedDropdown,
  (prevProps, nextProps) => prevProps.playbackSpeed === nextProps.playbackSpeed,
);
