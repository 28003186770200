import classNames from 'classnames';
import { ReactNode, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { KeyboardShortcutProvider } from '@assured/ui/hooks';

import { useModal } from '../Modal';
import { Menu } from './Menu';

// make sure these have the same value
const ANIMATION_DURATION_MS = 150;
const ANIMATION_DURATION_CLASS_NAME = 'duration-150';

export interface FlyoutMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  /** A `ReactNode` to render as the button that triggers the menu open */
  button?: ReactNode;

  /** A handler for when the menu is closed via escape key */
  onEscapeClose?: () => void;

  /** Should open setter */
  setShouldOpen?: (shouldOpen: boolean) => void;

  /** Set to `true` if the menu should open */
  shouldOpen?: boolean;
}

/**
 * A React component to render a flyout menu
 */
export const FlyoutMenuBase = ({
  button,
  className,
  onEscapeClose,
  setShouldOpen,
  shouldOpen,
  ...rest
}: FlyoutMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  // this is not a modal component, however we can use this functionality
  const { isDisplayNone, isOpen } = useModal({
    animationDurationMs: ANIMATION_DURATION_MS,
    isOpen: shouldOpen,
    onClose: () => {
      if (!setShouldOpen) {
        return;
      }
      setShouldOpen(false);
    },
    onEscapeClose: () => {
      if (!onEscapeClose) {
        return;
      }
      onEscapeClose();
    },
    modalControlRefs: [],
    modalRef: menuRef,
  });

  return (
    <>
      {button}
      <Menu
        className={twMerge(
          classNames(
            'absolute transition-[opacity,transform,translate-y] transform ease-in-out z-10',
            ANIMATION_DURATION_CLASS_NAME,
            {
              'opacity-0': !isOpen,
              'opacity-100': isOpen,
              hidden: isDisplayNone,
              'scale-90': !isOpen,
              'scale-100': isOpen,
              'translate-y-2': isOpen,
              'translate-y-0': !isOpen,
            },
          ),
          className,
        )}
        ref={menuRef}
        {...rest}
      />
    </>
  );
};

/**
 * A React component to render a flyout menu (with escape to close)
 */
export const FlyoutMenu = (props: FlyoutMenuProps) => {
  const containerRef = useRef(null);
  return (
    <div ref={containerRef}>
      <KeyboardShortcutProvider rootElement={containerRef?.current}>
        <FlyoutMenuBase {...props} />
      </KeyboardShortcutProvider>
    </div>
  );
};
