import classNames from 'classnames';
import { DetailedHTMLProps } from 'react';

interface SpinnerPropsBase {
  /** Custom className */
  className?: string;

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** Color of the spinner, to populate `stroke` attribute */
  strokeColor?: string;
}

type HtmlSvgProps = DetailedHTMLProps<
  React.SVGAttributes<SVGSVGElement>,
  SVGSVGElement
>;

export type SpinnerProps = HtmlSvgProps & SpinnerPropsBase;

export const Spinner = ({
  children,
  className,
  dataTestId = 'spinner',
  height = 20,
  strokeColor,
  width = 21,
  ...rest
}: SpinnerProps) => {
  return (
    <svg
      tabIndex={-1}
      className={classNames('animate-spin', className)}
      data-testid={dataTestId}
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.99201 18.334C6.50114 17.8496 5.17327 16.9623 4.1552 15.7703C3.13713 14.5783 2.46853 13.128 2.2233 11.5797C1.97808 10.0314 2.16579 8.44545 2.76568 6.99719C3.36557 5.54893 4.35427 4.29476 5.62248 3.37336C6.89069 2.45195 8.38899 1.89919 9.95175 1.7762C11.5145 1.65321 13.0808 1.96477 14.4776 2.67644C15.8743 3.38812 17.047 4.47217 17.8661 5.80876C18.6852 7.14535 19.1187 8.68241 19.1187 10.25"
        stroke={strokeColor || 'currentColor'}
        strokeWidth="3"
      />
    </svg>
  );
};
