import { AnimatePresence } from 'framer-motion';
import { Fragment, useMemo } from 'react';

import { AvailabilitySelectDropdown } from './AvailabilitySelectDropdown';
import { AvailabilitySelectionTimeslot } from './AvailabilitySelectionTimeslot';
import {
  DEFAULT_TIMESLOT_MINUTE_END,
  DEFAULT_TIMESLOT_MINUTE_START,
} from './constants';
import { isNumericStringValid } from './helpers';
import { useTimeslotSelectOptions } from './hooks';

import type { TimeslotState, TimeslotGroup, Timeslot } from './types';

/** React `props` for `AvailabilitySelection` component */
export interface AvailabilitySelectionProps {
  /** A controlled state setter inherited from parent */
  setTimeslotState: React.Dispatch<React.SetStateAction<TimeslotState>>;

  /** A controlled state inherited from parent */
  timeslotState: TimeslotState;
}

/**
 * A React component controlled, to render a UI to select and transmit availability
 * time data
 */
export const AvailabilitySelection = ({
  setTimeslotState,
  timeslotState,
}: AvailabilitySelectionProps) => {
  // all timeslot select options as a source of transormed options
  const {
    timeslotSelectOptionMap,
    timeslotSelectOptions: defaultTimeslotSelectOptions,
  } = useTimeslotSelectOptions();

  /** Groups of timeslots, typically days of the week (ie: 'Monday', 'Tuesday', etc) */
  const timeslotGroups: TimeslotGroup[] = useMemo(
    () => Object.keys(timeslotState),
    [timeslotState],
  );

  /**
   * A function to add a timeslot to state
   */
  const addTimeslot = (timeslotGroup: TimeslotGroup) => {
    setTimeslotState(state => {
      return {
        ...state,
        [timeslotGroup]: [
          ...(!state[timeslotGroup] ? [] : state[timeslotGroup]),
          {
            id: `${Date.now()}`,
            startMinute: DEFAULT_TIMESLOT_MINUTE_START,
            endMinute: DEFAULT_TIMESLOT_MINUTE_END,
          },
        ],
      };
    });
  };

  /**
   * A function to patch a timeslot state entry by ID within a group
   */
  const patchTimeslotById = ({
    timeslotId,
    timeslotGroup,
    payload,
  }: {
    timeslotId: Timeslot['id'];
    timeslotGroup: TimeslotGroup;
    payload: Partial<Timeslot>;
  }) => {
    setTimeslotState(state => {
      if (!state[timeslotGroup]?.length) {
        return state;
      }
      return {
        ...state,
        [timeslotGroup]: state[timeslotGroup].map(current => ({
          ...current,
          ...(current.id === timeslotId && payload),
        })),
      };
    });
  };

  /**
   * A function to remove a timeslot from state by ID within a group
   */
  const removeTimeslotById = ({
    timeslotId,
    timeslotGroup,
  }: {
    timeslotId: Timeslot['id'];
    timeslotGroup: TimeslotGroup;
  }) => {
    setTimeslotState(state => {
      if (!state[timeslotGroup]?.length) {
        return state;
      }
      return {
        ...state,
        [timeslotGroup]: state[timeslotGroup].filter(
          current => current.id !== timeslotId,
        ),
      };
    });
  };

  return (
    <div className="grid grid-cols-4 gap-x-1 gap-y-4 leading-5 font-medium text-sm text-cool-gray-700">
      {timeslotGroups.map(timeslotGroup => (
        <Fragment key={`timeslot-group-${timeslotGroup}`}>
          <div className="col-span-1 py-2.5">{timeslotGroup}</div>
          <div className="col-span-3 text-cool-gray-500 font-normal flex flex-col gap-4">
            {!timeslotState[timeslotGroup]?.length ? (
              <AvailabilitySelectionTimeslot
                id="timeslot__item-unavailable"
                onClickAdd={() => addTimeslot(timeslotGroup)}
              >
                <span className="py-2.5">Unavailable</span>
              </AvailabilitySelectionTimeslot>
            ) : (
              <AnimatePresence>
                {timeslotState[timeslotGroup].map((timeslot, index) => {
                  const startMinuteOption =
                    timeslotSelectOptionMap.get(timeslot.startMinute)?.value ||
                    defaultTimeslotSelectOptions[0].value;
                  const endMinuteOption =
                    timeslotSelectOptionMap.get(timeslot.endMinute)?.value ||
                    defaultTimeslotSelectOptions[0].value;
                  return (
                    <AvailabilitySelectionTimeslot
                      onClickAdd={
                        index !== 0
                          ? undefined
                          : () => addTimeslot(timeslotGroup)
                      }
                      onClickRemove={() => {
                        removeTimeslotById({
                          timeslotId: timeslot.id,
                          timeslotGroup,
                        });
                      }}
                      id={`timeslot__item-${timeslot.id}`}
                      key={`timeslot-${timeslot.id}`}
                      shouldAnimate
                    >
                      <AvailabilitySelectDropdown
                        defaultOptions={defaultTimeslotSelectOptions}
                        maxValue={Number(endMinuteOption)}
                        onChange={input => {
                          const value = input?.value as string;
                          if (!value || !isNumericStringValid(value)) {
                            return;
                          }
                          patchTimeslotById({
                            timeslotId: timeslot.id,
                            timeslotGroup,
                            payload: {
                              startMinute: Number(value),
                            },
                          });
                        }}
                        value={startMinuteOption}
                      />
                      <span>-</span>
                      <AvailabilitySelectDropdown
                        defaultOptions={defaultTimeslotSelectOptions}
                        minValue={Number(startMinuteOption)}
                        onChange={input => {
                          const value = input?.value as string;
                          if (!value || !isNumericStringValid(value)) {
                            return;
                          }
                          patchTimeslotById({
                            timeslotId: timeslot.id,
                            timeslotGroup,
                            payload: {
                              endMinute: Number(value),
                            },
                          });
                        }}
                        value={endMinuteOption}
                      />
                    </AvailabilitySelectionTimeslot>
                  );
                })}
              </AnimatePresence>
            )}
          </div>
        </Fragment>
      ))}
    </div>
  );
};
