import React from 'react';

export interface ActionWrapperProps {
  children: React.ReactNode;
}

export function ActionWrapper(props: ActionWrapperProps): JSX.Element {
  const { children } = props;

  return (
    <div className="grid grid-cols-[minmax(90px,260px)_auto_minmax(90px,260px)]  items-center w-full gap-4">
      {children}
    </div>
  );
}
