import { useRecoilState } from 'recoil';

import { IconFlatMore } from '../Icon/IconFlatMore';
import { MenuItems } from '../Menu';
import { InboxMenu } from './InboxMenu';
import { inboxOpenMenuState } from './state/inboxOpenMenuState';

import type { IconKey } from '../Icon/IconFlatRenderer';

enum InboxSectionMenuEnum {
  ARCHIVE = 'ARCHIVE',
  MARK_AS_READ = 'MARK_AS_READ',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

/**
 * A React component to render the inbox section menu
 */
export const InboxSectionMenu = ({
  className,
  id,
  isUnsubscribed,
  isUnsubscribing,
  onArchived,
  onMarkedAsRead,
  onUnsubscribed,
}: {
  className?: string;
  id: string;
  isUnsubscribed?: boolean;
  isUnsubscribing?: boolean;
  onArchived: () => void;
  onMarkedAsRead: () => void;
  onUnsubscribed: () => Promise<void>;
}) => {
  const [inboxOpenMenu, setInboxOpenMenu] = useRecoilState(inboxOpenMenuState);
  return (
    <InboxMenu
      accessibilityText="More actions"
      className={className}
      icon={<IconFlatMore className="text-cool-gray-500" />}
      id={id}
    >
      <MenuItems
        items={[
          ...(isUnsubscribed
            ? []
            : [
                {
                  content: 'Unsubscribe',
                  iconKey: 'ICON_FLAT_BELL_WITH_SLASH' as IconKey,
                  isLoading: isUnsubscribing,
                  value: InboxSectionMenuEnum.UNSUBSCRIBE,
                },
              ]),
          {
            content: 'Archive all',
            iconKey: 'ICON_FLAT_ARCHIVE_LARGE',
            value: InboxSectionMenuEnum.ARCHIVE,
          },
          {
            content: 'Mark all as read',
            iconKey: 'ICON_FLAT_ENVELOPE_OPEN',
            value: InboxSectionMenuEnum.MARK_AS_READ,
          },
        ]}
        onSelect={async value => {
          if (value === InboxSectionMenuEnum.ARCHIVE) {
            onArchived();
          }
          if (value === InboxSectionMenuEnum.MARK_AS_READ) {
            onMarkedAsRead();
          }
          if (value === InboxSectionMenuEnum.UNSUBSCRIBE) {
            // wait for loading to complete before closing the menu
            await onUnsubscribed();
          }

          // close menu
          if (inboxOpenMenu === id) {
            setInboxOpenMenu(undefined);
          }
        }}
      />
    </InboxMenu>
  );
};
