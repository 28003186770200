import classNames from 'classnames';
import { useState } from 'react';

import { CommercialAutoVehicleThumbnailType } from '@assured/shared-types/CommercialAutoVehicle';
import { CommercialAutoVehicleThumbnail } from '@assured/ui/components/Vehicles/CommercialAutoVehicleThumbnail';
import { useKeyboardShortcut } from '@assured/ui/hooks';

import { Accordion } from '../Accordion';
import { CheckboxLarge } from '../CheckboxLarge';
import { IconVehicleRenderer } from '../Icon/IconVehicleRenderer';
import { InfoDetailsSection } from '../InfoDetails/InfoDetailsSection';
import { Pill } from '../Pill';
import { ShortcutKeyIcon } from '../ShortcutKeyIcon';

import type { VehicleCoverage } from '@assured/shared-types/Claim/CarrierInsurancePolicy';

import type { IconKey } from '../Icon/IconVehicleRenderer';

export interface VehicleSelectionItemPropsBase {
  /** Set to `true` if item is disabled and cannot be selected */
  isDisabled?: boolean;

  /** The vehicle label, e.g. "'23 Ford Fusion" */
  label?: string;

  /** The vehicle type, e.g. "hatchback" */
  type?: string;

  /** An "icon key" for the corresponding vehicle icon */
  vehicleIconKey?: IconKey;

  /** The commercial vehicle image thumbnail type */
  commercialAutoVehicleThumbnailType?: CommercialAutoVehicleThumbnailType;

  /** A list of vehicle coverages */
  vehicleCoverages?: VehicleCoverage[];

  /** The vehicle VIN */
  vin?: string;
}

export interface VehicleSelectionItemProps
  extends VehicleSelectionItemPropsBase {
  /** Set to `true` if item is active */
  isActive?: boolean;

  /** Selection handler */
  onClick: () => void;

  /** Set to `true` if top corners should be rounded (made for the first) */
  hasRoundedTop?: boolean;

  /** Acceptable characters to be used as the shortcut key */
  shortcutKeys?: string[];
}

export const VehicleSelectionItem: React.FC<VehicleSelectionItemProps> = ({
  hasRoundedTop,
  isActive,
  isDisabled,
  label,
  type,
  onClick,
  shortcutKeys,
  vehicleCoverages,
  vehicleIconKey,
  commercialAutoVehicleThumbnailType,
  vin,
}: VehicleSelectionItemProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const onClickIfEnabled = () => {
    if (isDisabled) {
      return;
    }
    onClick();
  };
  const { shortcut } = useKeyboardShortcut(
    shortcutKeys ?? [],
    onClickIfEnabled,
  );

  return (
    <div className="w-full overflow-hidden border-b border-bottom-cool-gray">
      <div
        className={classNames('w-full gap-2 flex p-5 border cursor-pointer', {
          'border-transparent': !isActive,
          'border-indigo-bright-600': isActive,
          'rounded-tl-lg rounded-tr-lg': hasRoundedTop,
          'hover:bg-indigo-bright-50': !isDisabled,
        })}
        onClick={onClickIfEnabled}
      >
        <div className="w-[110px] flex flex-col gap-3">
          <div className="flex justify-start">
            <ShortcutKeyIcon
              isActive={isActive}
              variant={!isDisabled ? undefined : 'light'}
            >
              {shortcut}
            </ShortcutKeyIcon>
          </div>
          <div className="h-[85px] -mx-[22px] flex justify-center items-center">
            {
              // eslint-disable-next-line no-nested-ternary
              commercialAutoVehicleThumbnailType ? (
                <CommercialAutoVehicleThumbnail
                  type={commercialAutoVehicleThumbnailType}
                />
              ) : vehicleIconKey ? (
                <IconVehicleRenderer
                  iconKey={vehicleIconKey}
                  className={classNames({
                    'opacity-50': isDisabled,
                  })}
                />
              ) : null
            }
          </div>
        </div>
        <div className="flex-grow flex flex-col gap-3">
          <div className="flex justify-between">
            <div className="flex flex-col items-start gap-2">
              <span className="flex justify-start items-center gap-2">
                {label && (
                  <span
                    className={classNames('text-lg font-medium', {
                      'text-cool-gray-600': !isDisabled,
                      'text-cool-gray-400': isDisabled,
                    })}
                  >
                    {label}
                  </span>
                )}
                {type && (
                  <Pill variant={!isDisabled ? 'neutral' : 'disabled'}>
                    {type}
                  </Pill>
                )}
              </span>
              {vin && (
                <span
                  className={classNames('font-normal text-[16px] font-mono', {
                    'text-cool-gray-600': !isDisabled,
                    'text-cool-gray-400': isDisabled,
                  })}
                >
                  {vin}
                </span>
              )}
            </div>
            <div className="flex flex-col justify-start">
              <CheckboxLarge isActive={isActive} />
            </div>
          </div>
          {!!vehicleCoverages?.length && (
            <Accordion
              className="bg-cool-gray-50 rounded-[5px]"
              classNameHeaderText={classNames({
                'text-cool-gray-400': isDisabled && !isAccordionOpen,
              })}
              classNameHeaderPadding="p-[16px]"
              items={[
                {
                  children: (
                    <InfoDetailsSection
                      className="-mt-[16px]"
                      listItems={vehicleCoverages.map(vehicleCoverage => ({
                        textPrimary: vehicleCoverage.description || '',
                        textSecondary:
                          vehicleCoverage?.coverageTerm?.displayValue || '',
                      }))}
                    />
                  ),
                  isOpenByDefault: false,
                  onOpenToggle: (isOpen: boolean) => {
                    setIsAccordionOpen(isOpen);
                    onClickIfEnabled();
                  },
                  title: 'Coverages',
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};
