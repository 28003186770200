import { DateTime } from 'luxon';
import React from 'react';

import { Phase, typeColors } from './constants';

interface TickProps {
  id: string;
  phase: Phase;
  start: string;
  eventDuration: number;
  earliestStartTime: string;
}

export function EventTick(props: TickProps) {
  const { id, phase, start, eventDuration, earliestStartTime } = props;
  const relativeStart = DateTime.fromISO(start).toMillis() - DateTime.fromISO(earliestStartTime).toMillis();
  const position = (relativeStart / eventDuration) * 100;

  return (
    <div
      key={id}
      className={`absolute w-[2px] h-[6px] flex-shrink-0 -top-[0.05rem] ${typeColors[phase]}`}
      style={{ left: `${position}%` }}
    />
  );
}

export function PhaseTick(props: TickProps) {
  const { id, phase, start, eventDuration, earliestStartTime } = props;
  const relativeStart = DateTime.fromISO(start).toMillis() - DateTime.fromISO(earliestStartTime).toMillis();
  const position = (relativeStart / eventDuration) * 100;

  return (
    <div
      key={id}
      className={`absolute h-2 w-2 rounded-full -top-0.5 ${typeColors[phase]}`}
      style={{ left: `${position}%` }}
    />
  );
}

export const MemoizedPhaseTick = React.memo(PhaseTick);
export const MemoizedEventTick = React.memo(EventTick);