import { CallerVerificationOutput } from '@assured/shared-types/Services/PolicySearch';

import { Button } from '../Button';
import { Chip } from '../Chip';
import { StatusIcon } from '../StatusIcon/StatusIcon';
import { PolicySearchCallerVerificationBar } from './PolicySearchCallerVerificationBar';
import { PolicySearchModalActionType } from './state/policySearchModalReducer';
import { PolicySearchVersion } from './types';

import type { PolicySearchModalAction } from './state/policySearchModalReducer';
export const PolicySearchFooter = ({
  isVerified,
  verifiedItemsCount,
  modalDispatch,
  onClickAttachPolicy,
  shortcutDisabled,
  callerVerification,
  version,
}: {
  /** The policy has been verified if `true` */
  isVerified?: boolean;

  /** Count of verified items */
  verifiedItemsCount?: number;

  /** A dispatch to update modal states */
  modalDispatch: React.Dispatch<PolicySearchModalAction>;

  /** A click handler of attach policy button */
  onClickAttachPolicy: () => void;

  /** Whether the shortcut is enabled */
  shortcutDisabled: boolean;

  /** The caller verification state */
  callerVerification?: CallerVerificationOutput;

  /** The version of PolicySearch being used */
  version?: PolicySearchVersion;
}) => {
  return (
    <div className="flex justify-between">
      <Button
        onClick={() => {
          modalDispatch({
            type: PolicySearchModalActionType.OPEN,
            payload: {
              modalType: 'MANUAL_LOSS',
            },
          });
        }}
        size="sm"
        variant="secondary"
      >
        Manual loss
      </Button>

      <div className="flex gap-3 items-center">
        {version === 2 && callerVerification ? (
          <PolicySearchCallerVerificationBar
            callerVerified={callerVerification.callerVerified}
            primaryCount={callerVerification.primaryCount}
            secondaryCount={callerVerification.secondaryCount}
            primaryRequired={callerVerification.primaryRequired}
            secondaryRequired={callerVerification.secondaryRequired}
            callerSelected={callerVerification.callerSelected}
          />
        ) : null}
        <Button
          disabled={!isVerified}
          size="sm"
          onClick={() => {
            onClickAttachPolicy();
          }}
          trailingContent={
            <StatusIcon
              // eslint-disable-next-line no-nested-ternary
              status={isVerified ? 'done' : verifiedItemsCount ? '2/4' : '0/4'}
              color="#ffffff"
            />
          }
          title={!isVerified ? 'Verify more items to attach policy' : undefined}
        >
          Attach policy
        </Button>
        <Chip isFaded={!isVerified || shortcutDisabled}>Enter &crarr;</Chip>
      </div>
    </div>
  );
};
