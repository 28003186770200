import classNames from 'classnames';
import { useEffect, useLayoutEffect, useRef } from 'react';

interface TokenExtractorTokenConfig<T> {
  /**
   * The type of the token, e.g. "policy_number"
   */
  type: T;

  /**
   * 2-character label for the token, e.g. "PN" for "Policy Number"
   */
  shortLabel: string;

  /**
   * Full label for the token, e.g. "Policy Number"
   */
  label: string;

  /**
   * One or more regular expression matchers to detect the token. We will
   * apply the matchers by "tier", i.e. matchers[0] will be tried first for
   * all tokens, then matchers[1], etc.
   */
  matchers: RegExp[];

  /**
   * Only allow matching this token when at least one of the other specified
   * tokens has been matched as well.
   */
  requires?: T[];

  /**
   * Show a token in an "inactive" state if one of the specified tokens has
   * been matched.
   */
  inactive_if?: T[];
}

export type TokenExtractorConfig<T> = {
  tokens: TokenExtractorTokenConfig<T>[];
};

interface TokenExtractorMatch<T> {
  type: T;
  offset: number;
  length: number;
  value: string;
  userConfirmed?: boolean;
  inactive?: boolean;
}

export interface TokenExtractorValue<T> {
  text: string;
  matches: TokenExtractorMatch<T>[];
}

export type TokenExtractorValueForConfig<C> = TokenExtractorValue<
  C extends TokenExtractorConfig<infer T> ? T : never
>;

export type CustomTokenComponent = React.ComponentType<{
  type: string;
  inactive?: boolean;
  options: { type: string; label: string; shortLabel: string }[];
  onTypeChange: (type: string) => void;
  input: React.ReactNode;
}>;

export const TextTokenExtractor = <T extends string>({
  config,
  value,
  onChange,
  components,
  className,
  placeholder,
  ...inputProps
}: {
  config: TokenExtractorConfig<T>;
  value: TokenExtractorValue<T>;
  onChange: (value: TokenExtractorValue<T>) => void;
  components?: {
    CustomTokenComponent: CustomTokenComponent;
  };
  className?: string;
  placeholder?: string;
  'data-enable-shortcuts'?: string;
}) => {
  const { text, matches } = value;

  // Track the caret position relative to the text string so we can restore it on
  // render events.
  const globalCaretPosition = useRef(0);
  const inputToCaretPosition = useRef<HTMLInputElement | null>(null);
  const inputCaretPosition = useRef(0);
  const justTyped = useRef(false);
  useLayoutEffect(() => {
    if (!inputToCaretPosition.current) {
      return;
    }

    inputToCaretPosition.current?.focus();
    setTimeout(() => {
      if (!justTyped.current) {
        inputToCaretPosition.current = null;
        return;
      }

      inputToCaretPosition.current?.focus();
      inputToCaretPosition.current?.setSelectionRange(
        inputCaretPosition.current,
        inputCaretPosition.current,
      );
      inputToCaretPosition.current = null;
      justTyped.current = false;
    }, 0);
  }, [value]);

  // We need to split the text into chunks, each of which is either a token
  // or not a token. We'll use the matches to do this.
  const chunks: { text: string; match?: TokenExtractorMatch<T> }[] = [];
  let lastOffset = 0;
  matches.forEach(match => {
    if (match.offset > lastOffset) {
      chunks.push({
        text: text.slice(lastOffset, match.offset),
      });
    }
    chunks.push({
      text: match.value,
      match,
    });
    lastOffset = match.offset + match.length;
  });
  chunks.push({
    text: text.slice(lastOffset), // even if empty string, still include it so user can keep typing
  });

  /**
   * Handler for when the user changes the text in one of the input fields.
   */
  const handleTextChange = (
    chunkIndex: number,
    e: { currentTarget: HTMLInputElement },
  ) => {
    const text = e.currentTarget.value;

    // Update the caret position that we should re-render at. This allows us to restore accurately e.g.
    // in the case of a new token being inserted in the middle of the text.
    const localCaretPosition = e.currentTarget.selectionStart ?? 0;
    const newCaretPosition =
      chunks
        .slice(0, chunkIndex)
        .reduce((acc, chunk) => acc + chunk.text.length, 0) +
      localCaretPosition;
    globalCaretPosition.current = newCaretPosition;
    justTyped.current = true;

    const newText = chunks
      .map((chunk, i) => (i === chunkIndex ? text : chunk.text))
      .join('');

    updateMatchesForText(newText);
  };

  /**
   * Update matches using a given text string and fire a change event
   */
  const updateMatchesForText = (newText: string) => {
    // We need to re-run the token extractor on the new text to get the new matches. We need to run
    // the first matcher for every token, then the second matcher for every token, etc. Once text has
    // been matched by one token, no other token is allowed to match that text, so we replace the
    // matched text with spaces.
    let remainingText = newText;
    const newMatches: TokenExtractorMatch<T>[] = [];

    const maxMatchers = Math.max(...config.tokens.map(t => t.matchers.length));
    let matcherIndex = 0;
    let tokenIndex = 0;
    let hasFoundAMatchThisCycle = false;
    while (remainingText.length > 0) {
      let token = config.tokens[tokenIndex];
      if (
        (!token.requires ||
          token.requires.some(t => newMatches.some(m => m.type === t))) &&
        !newMatches.some(m => m.type === token.type) // Only one match per token type
      ) {
        const matcher = token.matchers[matcherIndex];
        if (matcher) {
          const match = remainingText.match(matcher);
          if (match) {
            hasFoundAMatchThisCycle = true;
            const offset = match.index ?? 0;
            const length = match[0].length;
            newMatches.push({
              type: token.type,
              offset,
              length,
              value: match[0],
            });
            remainingText =
              remainingText.substring(0, offset) +
              '�'.repeat(length) +
              remainingText.substring(offset + match[0].length);
          }
        }
      }
      if (tokenIndex === config.tokens.length - 1) {
        tokenIndex = 0;
        if (matcherIndex === maxMatchers - 1) {
          if (!hasFoundAMatchThisCycle) {
            break;
          } else {
            hasFoundAMatchThisCycle = false;
            matcherIndex = 0;
          }
        } else {
          matcherIndex++;
        }
      } else {
        tokenIndex++;
      }
    }

    // Sort the matches by offset so they're in the same order as the text.
    newMatches.sort((a, b) => a.offset - b.offset);

    // If a user has manually overridden the type of a token in the prior stack of
    // matches, we want to preserve that.
    matches
      .filter(m => m.userConfirmed)
      .forEach(match => {
        const newMatch = newMatches.find(
          candidate =>
            candidate.offset === match.offset &&
            candidate.length === match.length,
        );
        if (newMatch) {
          newMatch.type = match.type;
          newMatch.userConfirmed = true;
        }
      });

    // Apply "inactive if" rules to mark if a match should be inactive.
    newMatches.forEach(match => {
      const token = config.tokens.find(t => t.type === match.type);
      if (token?.inactive_if) {
        if (token.inactive_if.some(t => newMatches.some(m => m.type === t))) {
          match.inactive = true;
        }
      }
    });

    onChange({
      text: newText,
      matches: newMatches,
    });
  };

  /**
   * Handler for when the user edits the type of a token.
   */
  const handleMatchTypeChange = (chunkIndex: number, type: T) => {
    onChange({
      text,
      matches: chunks
        .map((chunk, i) =>
          i === chunkIndex && chunk.match
            ? { ...chunk, match: { ...chunk.match, type, userConfirmed: true } }
            : chunk,
        )
        .map(chunk => chunk.match)
        .filter(<X,>(x: X | undefined): x is X => !!x),
    });
  };

  // On initial mount, compute matches for initial input text
  useEffect(() => {
    if (value.text) {
      updateMatchesForText(value.text);
    }
  }, []);

  let currentTextOffset = 0;
  return (
    <div className={classNames('flex flex-wrap', className)}>
      {chunks.map((chunk, i) => {
        const localCaretPosition =
          globalCaretPosition.current - currentTextOffset;
        const shouldDisplayCaret =
          localCaretPosition >= 0 && localCaretPosition <= chunk.text.length;
        currentTextOffset += chunk.text.length;
        if (shouldDisplayCaret) {
          inputCaretPosition.current = localCaretPosition;
          currentTextOffset = Number.MAX_VALUE; // no more carets
        }

        const input = (
          <>
            <span
              key={`${i}-${chunk.text}`}
              className="whitespace-pre"
              ref={r => {
                // This is used to get the input to be the same width as the text
                // it's replacing. We use this span to compute the width of the text
                // and then adjust the input style to match that width.
                if (r) {
                  r.innerText = chunk.text;
                  const width = r.clientWidth;
                  const input = r.nextElementSibling as HTMLInputElement;
                  input.style.width = `${width}px`;
                  input.style.display = 'inline-block';
                  r.innerText = '';
                }
              }}
            />
            <input
              className="flex-1 hidden focus:outline-none bg-transparent"
              placeholder={i === 0 ? placeholder : undefined}
              {...inputProps}
              type="text"
              value={chunk.text}
              ref={shouldDisplayCaret ? inputToCaretPosition : undefined}
              onChange={e => handleTextChange(i, e)}
              onKeyDown={e => {
                const target = e.currentTarget;

                // Consider the "Enter" key to be equivalent to a space, in order to help trigger
                // matches that depend on the end of a word. But don't allow entering multiple times.
                if (
                  e.key === 'Enter' &&
                  target.value.substring(target.value.length - 2) !== ', ' &&
                  target.value.substring(target.value.length - 1) !== ','
                ) {
                  e.preventDefault();
                  e.stopPropagation();
                  target.value += ', ';
                  handleTextChange(i, e);
                  return;
                }

                // Special case for "Delete" at the beginning of an input. We want to delete the
                // text in the previous input and then move the caret to the end of that input.
                if (
                  e.key === 'Backspace' &&
                  i > 0 &&
                  target.selectionStart === 0 &&
                  target.selectionEnd === 0
                ) {
                  e.preventDefault();
                  e.stopPropagation();
                  const previousInput = target.parentElement?.previousSibling
                    ?.lastChild as HTMLInputElement;
                  previousInput.value = previousInput.value.slice(
                    0,
                    previousInput.value.length - 1,
                  );
                  previousInput.focus();
                  previousInput.setSelectionRange(
                    previousInput.value.length,
                    previousInput.value.length,
                  );
                  handleTextChange(i - 1, { currentTarget: previousInput });
                  return;
                }

                // Allow the user to use the arrow keys to move between inputs so they can
                // edit their response text.
                const { parentElement } = target;
                if (
                  e.key === 'ArrowLeft' &&
                  i > 0 &&
                  target.selectionStart === 0
                ) {
                  const previousInput = parentElement?.previousSibling
                    ?.lastChild as HTMLInputElement;
                  previousInput.focus();
                  previousInput.setSelectionRange(
                    previousInput.value.length,
                    previousInput.value.length,
                  );
                } else if (
                  e.key === 'ArrowRight' &&
                  i < chunks.length - 1 &&
                  target.selectionStart === target.value.length
                ) {
                  const nextInput = parentElement?.nextSibling
                    ?.lastChild as HTMLInputElement;
                  nextInput.focus();
                  nextInput.setSelectionRange(0, 0);
                }
              }}
            />
          </>
        );

        const tokenConfig = config.tokens.find(
          token => token.type === chunk.match?.type,
        );

        if (!chunk.match || !tokenConfig) {
          return (
            <span
              key={`${i}-${chunk.text}-nomatch`}
              className={classNames('inline-flex', {
                'flex-1': i === chunks.length - 1,
              })}
            >
              {input}
            </span>
          );
        }

        if (components?.CustomTokenComponent) {
          return (
            <components.CustomTokenComponent
              key={`${i}-${chunk.text}-${chunk.match.type}`}
              type={chunk.match.type}
              inactive={chunk.match.inactive}
              options={config.tokens.map(t => ({
                type: t.type,
                label: t.label,
                shortLabel: t.shortLabel,
              }))}
              input={input}
              onTypeChange={type => handleMatchTypeChange(i, type as T)}
            />
          );
        }

        return (
          <span
            key={`${i}-${chunk.text}-${chunk.match.type}`}
            className={classNames(
              'inline-flex border items-center gap-2',
              chunk.match.inactive
                ? 'border-gray-500 bg-gray-200'
                : 'border-blue-500 bg-blue-200',
            )}
          >
            <span className="text-gray-400">{tokenConfig.shortLabel}</span>
            <select
              value={chunk.match.type}
              onChange={e => handleMatchTypeChange(i, e.target.value as T)}
            >
              {config.tokens.map(token => (
                <option key={token.type} value={token.type}>
                  {token.label}
                </option>
              ))}
            </select>
            {input}
          </span>
        );
      })}
    </div>
  );
};
