import { History } from 'history';
import { useMemo } from 'react';

import { Auth0Context, Auth0ContextInterface } from '@auth0/auth0-react';

import { AppInner } from './App';

const MockedJWT =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';

const MockAuth0Provider = ({ children }: { children: React.ReactNode }) => {
  const contextValue: Auth0ContextInterface = useMemo(
    () => ({
      getAccessTokenSilently: (async () =>
        MockedJWT) as Auth0ContextInterface['getAccessTokenSilently'],
      getAccessTokenWithPopup: async () => MockedJWT,
      getIdTokenClaims: async () => ({
        name: 'mocked-name',
        __raw: 'mocked-raw',
      }),
      loginWithRedirect: async () => {},
      loginWithPopup: async () => {},
      logout: async () => {},
      buildAuthorizeUrl: async () => 'mocked-url',
      buildLogoutUrl: () => 'mocked-url',
      handleRedirectCallback: async () => ({}),
      isAuthenticated: true,
      isLoading: false,
    }),
    [],
  );
  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  );
};

const UIFunctionalShell = ({ history }: { history: History }) => {
  return (
    <MockAuth0Provider>
      <AppInner history={history} />
    </MockAuth0Provider>
  );
};

export default UIFunctionalShell;
