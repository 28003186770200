import { toast, Toaster } from 'sonner';

export type DismissibleToastProps = Parameters<typeof Toaster>;

export { toast };

/**
 * A React component to render a dismissible "toast" component.
 * @see {@link https://sonner.emilkowal.ski/getting-started | Sonner docs }
 */
export const DismissibleToast = (props: DismissibleToastProps[0]) => {
  return <Toaster {...props} />;
};
