import React from 'react';
import { useSessionStorage } from 'react-use';
import * as uuid from 'uuid';

import { useAuth } from '../../hooks/useAuth';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import useWindowFocusHandlers from '../../hooks/useWindowFocusHandlers';
import { ActivityPayload, PayloadFn } from './types';
import useActiveDomainObject from './useActiveDomainObject';
import useActivityTrackingConfig from './useActivityTrackingConfig';
import useUserIdForCarrier from './useUserIdForCarrier';

function submitActivityToCarrier(
  payload: ActivityPayload,
  config: { endpoint: string; header: string; key: string },
) {
  if (Object.keys(payload).length === 0) return;

  fetch(config.endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      [config.header]: config.key,
    },
    body: JSON.stringify(payload),
  });
}

async function getPayloadFn(tenant: string) {
  switch (tenant) {
    case 'ProgressiveQA':
    case 'Progressive':
      return import('./activity/getProgressivePayload').then(
        module => module.default,
      );
    default: {
      // eslint-disable-next-line no-console
      console.log('Did not find a payload function for', tenant);
      return Promise.resolve(() => ({}));
    }
  }
}

function validConfig(
  config?: {
    endpoint?: string;
    header?: string;
    key?: string;
  } | null,
): config is { endpoint: string; header: string; key: string } {
  if (!config) return false;
  if (!config.endpoint) return false;
  if (!config.header) return false;
  if (!config.key) return false;
  return true;
}

const useActivityTracking = (tenant: string) => {
  const lastEventTs = React.useRef(Date.now());
  const isActivityTrackingOn = useFeatureFlag<boolean>(
    'eng-8115-adjuster-activity-tracking',
    false,
  );
  const { config } = useActivityTrackingConfig(isActivityTrackingOn);

  const { user } = useAuth();
  const { userIdForCarrier } = useUserIdForCarrier();
  const [sessionId] = useSessionStorage(
    'assured.activity.sessionId',
    uuid.v4(),
  );

  const activeDomainObject = useActiveDomainObject();
  const domainObjectId = activeDomainObject?.number ?? '';

  const [payloadFn, setPayloadFn] = React.useState<PayloadFn | null>(null);

  React.useEffect(() => {
    getPayloadFn(tenant).then(fn => {
      // need the extra function so the setState actually set to the fn,
      // but passing a function directly will be treated as an update state function,
      // not the piece of state
      setPayloadFn(() => fn);
    });
  }, [tenant]);

  const onFocus = React.useCallback(() => {
    if (!payloadFn) return;
    if (!validConfig(config)) return;

    const payload = payloadFn({
      event: 'focus',
      lastEventTs: lastEventTs.current,
      user: { name: user.name, userIdForCarrier },
      domainObjectId,
      sessionId,
    });
    submitActivityToCarrier(payload, config);

    lastEventTs.current = Date.now();
  }, [
    payloadFn,
    config,
    user.name,
    userIdForCarrier,
    domainObjectId,
    sessionId,
  ]);

  const onBlur = React.useCallback(() => {
    if (!payloadFn) return;
    if (!validConfig(config)) return;

    const payload = payloadFn({
      event: 'blur',
      lastEventTs: lastEventTs.current,
      user: { name: user.name, userIdForCarrier },
      domainObjectId,
      sessionId,
    });
    submitActivityToCarrier(payload, config);

    lastEventTs.current = Date.now();
  }, [
    payloadFn,
    config,
    user.name,
    userIdForCarrier,
    domainObjectId,
    sessionId,
  ]);

  const skip = !isActivityTrackingOn || !user || !validConfig(config);
  useWindowFocusHandlers(skip, onFocus, onBlur);
};

export default useActivityTracking;
