import { RefObject, useEffect, useState } from 'react';

import { addBodyClass, removeBodyClass } from '../../lib/bodyClass';

/**
 * A React hook to manage open, close, and animation transitions
 */
export const useModal = ({
  animationDurationMs,
  isOpen: shouldOpen,
  modalRef,
  modalControlRefs,
  onClose,
}: {
  /** Animation duration milliseconds */
  animationDurationMs: number;

  /**
   * A list of control elements that correspond to the modal. These are
   * only needed if you want to ensure they don't trigger a modal close.
   */
  modalControlRefs?: RefObject<HTMLElement>[];

  /** The main modal element ref */
  modalRef: RefObject<HTMLElement>;

  /** If `true` modal will be open */
  isOpen?: boolean;

  /** A handler for when the modal close icon is clicked or escape key */
  onClose?: (event?: MouseEvent | React.MouseEvent | null) => void;

  /** A handler for when the modal is closed via escape key */
  onEscapeClose?: () => void;
}) => {
  // we manage a local open state to manage the fade in / fade out
  // effect that relies on the timing of `display` and `opacity`
  const [isOpen, setIsOpen] = useState(!shouldOpen);
  const [isDisplayNone, setIsDisplayNone] = useState(!shouldOpen);

  /**
   * Elements with transforms act as a containing block for fixed position descendants,
   * so position:fixed under something with a transform no longer has fixed behavior.
   * Workaround this by disabling transform when animation is complete
   * @see {@link https://www.w3.org/TR/css-transforms-1/ | W3C CSS Transforms Module Level 1}
   * @see {@link https://stackoverflow.com/a/37953806 | Related Stack Overflow}
   */
  const [isTransformEnabled, setIsTransformEnabled] = useState(true);
  useEffect(() => {
    setIsTransformEnabled(true);
    setTimeout(() => {
      setIsTransformEnabled(false);
    }, animationDurationMs);
  }, [animationDurationMs, isOpen]);

  // close modal on click outside of the element, and outside of any
  // corresponding control elements
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!isOpen || !onClose || !(event.target instanceof HTMLElement)) {
        return;
      }
      const modalElements = [
        ...(!modalRef.current ? [] : [modalRef.current]),
        ...(!modalControlRefs?.length
          ? []
          : modalControlRefs.map(ref => ref.current)),
      ];

      // if event target is or contained by any modal element, return
      // (don't close modal)
      for (const element of modalElements) {
        if (
          element &&
          (element === event.target || element.contains(event.target))
        ) {
          return;
        }
      }
      onClose(event);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, modalRef, onClose]);

  // we need to manage the setting of `display: none` so it doesn't
  // prelude opacity (or set at the same time)
  useEffect(() => {
    // if we are closing
    if (!shouldOpen) {
      // re-enable scrolling of page while modal is closed
      removeBodyClass('overflow-hidden');

      // 1) animate opacity with a duration
      setIsOpen(false);

      // 2) upon animation complete, set to hidden to prevent blocking
      // the z layer
      setTimeout(() => {
        setIsDisplayNone(true);
      }, animationDurationMs);
    } else {
      // prevent scrolling of page while modal is open
      addBodyClass('overflow-hidden');

      // otherwise we need to open, but first remove `display: none`
      setIsDisplayNone(false);

      // ensure we add opacity after `display: none` is removed, so
      // the fadein effect works correctly
      setTimeout(() => {
        setIsOpen(true);
      }, 10);
    }
    return () => {
      removeBodyClass('overflow-hidden');
    };
  }, [shouldOpen]);

  return {
    isDisplayNone,
    isOpen,
    isTransformEnabled,
    setIsDisplayNone,
    setIsOpen,
  };
};
