import classNames from 'classnames';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { AvatarIcon } from '../AvatarIcon';

/**
 * A React component to render a notification item
 */
export const NotificationItem = ({
  avatarIconClassName,
  children,
  className,
  icon,
  isImportant,
  isLast,
  timestamp,
  title,
  trailingContent,
}: {
  avatarIconClassName?: string;
  children: ReactNode;
  className?: string;
  icon: ReactNode;
  isImportant?: boolean;
  isLast?: boolean;
  timestamp: string;
  title?: ReactNode;
  trailingContent?: ReactNode;
}) => {
  return (
    <span className={twMerge('p-2 flex justify-start gap-2 w-full', className)}>
      <span className="flex flex-col gap-1">
        <AvatarIcon className={avatarIconClassName}>{icon}</AvatarIcon>
        <span className="flex-grow flex flex-col justify-end items-center">
          {!isLast && <span className="flex-grow w-[1px] bg-cool-gray-300" />}
        </span>
      </span>
      <span className="flex-grow flex flex-col gap-1 text-cool-gray-500 text-xs leading-4">
        {title && <span>{title}</span>}
        <span className="text-cool-gray-800 text-sm leading-5 line-clamp-5">
          {children}
        </span>
        <span
          className={classNames(
            'font-normal flex justify-start items-center gap-1',
            {
              'text-blue-600': isImportant,
            },
          )}
        >
          {isImportant && (
            <span className="rounded-full h-[6px] w-[6px] bg-blue-600" />
          )}
          <span className="first-letter:uppercase">{timestamp}</span>
        </span>
        {trailingContent}
      </span>
    </span>
  );
};
