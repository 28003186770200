import { twMerge } from 'tailwind-merge';

import type { ComponentProps } from 'react';

export type CheckboxOrRadioInputProps = ComponentProps<'input'> & {
  disabled?: boolean;
};

/**
 * A fairly bare bones checkbox input (type="checkbox"), ideal when dealing with native
 * HTML forms. `className` will override via `twMerge`.
 */
export function CheckboxInput({
  className,
  ...props
}: CheckboxOrRadioInputProps) {
  return (
    <input
      className={twMerge(
        'inline-block cursor-pointer w-5 h-5 accent-white border rounded-sm checked:accent-indigo-bright-600 checked:border-none disabled:cursor-default',
        className,
      )}
      type="checkbox"
      {...props}
    />
  );
}
