import { useEffect, useRef, useState } from 'react';

import { LocationCollectionMode } from '@assured/step-renderer/types/step-components/Location';
import { useLoadScript } from '@react-google-maps/api';

import { SelectDropdown, SelectOption } from '../../SelectDropdown';
import { Spinner } from '../../Spinner';
import { Toggle } from '../../Toggle';
import { Text } from '../Text';
import { usePlacesQuery } from '../usePlacesQuery';
import { LoadingMessage, StartTypingMessage } from './SupportingComponents';
import { LocationOption, LocationProps } from './types';

const libraries = ['places' as const];

export const LocationGoogleMaps = (
  props: LocationProps & {
    setQuery: (query?: string) => void;
    setLocations: (locations: LocationOption[]) => void;
    setSwitchToGoogle?: (s: false) => void;
    query?: string;
    locations: LocationOption[];
  },
) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
  });

  if (!isLoaded) {
    return <Spinner />;
  }

  if (loadError) {
    console.error(
      'Error loading Google Maps API (will render anyway 🤞)',
      loadError,
    );
  }

  return <LocationGoogleMapsInner {...props} />;
};

export const LocationGoogleMapsInner = ({
  disabled,
  error,
  field,
  label,
  placeholder = 'Search for a location',
  required,
  updateValue,
  primaryValue,
  noApartmentEntry = true,
  autoFocus,
  addressBookEntries,
  searchBias,
  placeTypes,
  mode,
  setQuery,
  query,
  locations,
  setLocations,
  setSwitchToGoogle,
}: LocationProps & {
  setQuery: (query?: string) => void;
  setLocations: (locations: LocationOption[]) => void;
  setSwitchToGoogle?: (s: false) => void;
  query?: string;
  locations: LocationOption[];
}) => {
  const [isApartment, setIsApartment] = useState<boolean>(false);
  const [apartmentNumber, setApartmentNumber] = useState('');

  const selectDropdownRef = useRef<any | null>(null); // FIXME: Any type
  useEffect(() => {
    if (autoFocus || query) {
      setTimeout(() => selectDropdownRef?.current?.focus(), 0); // delay to allow dropdown to populate before opening
    }
  }, [autoFocus, query]);

  const [defaultOptions, setDefaultOptions] = useState<
    | { label: string; value: google.maps.places.AutocompletePrediction }[]
    | undefined
  >();

  const { predictions: placePredictions, isPlacePredictionsLoading } =
    usePlacesQuery(query, { searchBias, types: placeTypes });

  useEffect(() => {
    if (!placePredictions.length) return;
    if (!query) {
      setLocations([]);
      return;
    }

    setLocations(
      placePredictions.map(p => ({
        label: p.description,
        value: p,
      })),
    );
  }, [JSON.stringify(placePredictions), query]);

  return (
    <>
      <SelectDropdown
        ref={selectDropdownRef}
        value={primaryValue?.addressText || query}
        options={(locations as SelectOption[]) || defaultOptions || []}
        filterOption={() => true} // options are already filtered from API
        ClearIndicatorCallback={() => {
          setQuery(undefined);
          if (setSwitchToGoogle) {
            setSwitchToGoogle(false);
          }
        }}
        onChange={option => {
          if (option === null) {
            setLocations([]);
            setDefaultOptions([]);
            updateValue(field, null);
            return;
          }
          const prediction =
            option.value as google.maps.places.AutocompletePrediction;

          updateValue(field, {
            ...(mode === LocationCollectionMode.STREET_NAME
              ? {
                  addressText: prediction.structured_formatting.main_text,
                }
              : {
                  businessGooglePlaceId: prediction.place_id,
                  addressText: prediction.description,
                }),
          });
        }}
        onInputChange={(val: string) => {
          if (val.length === 0 && setSwitchToGoogle) {
            setSwitchToGoogle(false);
          }
          setQuery(val);
        }}
        isLoading={isPlacePredictionsLoading && !!query}
        components={{
          ...(isPlacePredictionsLoading
            ? { NoOptionsMessage: LoadingMessage }
            : {
                NoOptionsMessage: props => (
                  <StartTypingMessage
                    {...props}
                    addressBookEntries={addressBookEntries}
                    onAddressBookSelect={async v => {
                      updateValue(field, v);
                      selectDropdownRef.current?.blur();
                    }}
                  />
                ),
              }),
        }}
        error={error}
        disabled={disabled}
        required={required}
        labelProps={{ labelStr: label }}
        placeholder={placeholder}
        openMenuOnFocus={
          // auto-reveal address book initially if there are entries
          (autoFocus && !primaryValue && !!addressBookEntries?.length) ||
          !!query
        }
        editableInput
        editableInputIncompleteErrorText="Please select a location to continue."
      />

      {noApartmentEntry === false && (
        <div className="pt-5">
          <div className="flex flex-col gap-1">
            <span className="mb-3 text-lg font-medium text-cool-gray-500">
              Is this an apartment?
            </span>

            <Toggle
              value={isApartment}
              onChange={v => typeof v === 'boolean' && setIsApartment(v)}
            />
          </div>
          {isApartment ? (
            <div className="mx-auto mt-3">
              <Text
                placeholder="Apartment number"
                value={apartmentNumber}
                onChange={val => {
                  setApartmentNumber(val);
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
