import { useKeyboardShortcut } from '@assured/ui/hooks';

import { Button } from '../Button';
import { Chip } from '../Chip';

export interface VehicleSelectionSubmitPayload {
  /** An ID of the covered vehicle */
  vehicleId?: string | number;

  /** A list of covered vehicle IDs used in multi select */
  vehicleIds?: (string | number)[];

  /** Set to `true` if selection is unlisted */
  isUnlisted?: boolean;

  /** Set to `true` if selection is a trailer */
  isTrailer?: boolean;
}

export type VehicleSelectionOnSubmit = (
  payload: VehicleSelectionSubmitPayload,
) => void;

interface VehicleSelectionFooterProps {
  /** A callback to submit selected vehicle data */
  onSubmit: VehicleSelectionOnSubmit;

  /** A callback called when "Unlisted Vehicle" is selected */
  onUnlisted?: () => void;

  /** A list of selected vehicle IDs */
  selectedVehicleIds: (string | number)[];

  /** Set to `true` if multiple vehicle selection is allowed */
  multiSelect?: boolean;

  /** Don't offer the trailer button */
  disallowTrailer?: boolean;

  /** Don't offer the unlisted button */
  disallowUnlisted?: boolean;

  /** If `true` shortcut chip will display in a faded style */
  shortcutDisabled?: boolean;
}

export const VehicleSelectionFooter: React.FC<VehicleSelectionFooterProps> = ({
  onSubmit,
  selectedVehicleIds,
  multiSelect,
  disallowTrailer,
  disallowUnlisted,
  shortcutDisabled,
  onUnlisted,
}: VehicleSelectionFooterProps) => {
  const handleSubmit = () => {
    onSubmit(
      multiSelect
        ? { vehicleIds: selectedVehicleIds }
        : { vehicleId: selectedVehicleIds[0] },
    );
  };

  const { disabled: unlistedShortcutDisabled } = useKeyboardShortcut(
    ['u'],
    () => {
      if (!disallowUnlisted) {
        onUnlisted?.();
      }
    },
  );

  return (
    <div className="flex justify-between">
      <div className="flex gap-3 items-center">
        {disallowTrailer ? null : (
          <Button
            onClick={() =>
              onSubmit({
                isTrailer: true,
              })
            }
            size="sm"
            variant="secondary"
          >
            Trailer
          </Button>
        )}
        {disallowUnlisted ? null : (
          <>
            <Button
              onClick={() => onUnlisted?.()}
              size="sm"
              variant="secondary"
            >
              Unlisted vehicle
            </Button>
            <Chip
              isFaded={unlistedShortcutDisabled}
              className="hidden md:block"
            >
              U
            </Chip>
          </>
        )}
      </div>
      <div className="flex gap-3 items-center">
        <Button
          onClick={handleSubmit}
          size="sm"
          disabled={selectedVehicleIds.length === 0}
        >
          Select vehicle
        </Button>
        <Chip isFaded={shortcutDisabled || selectedVehicleIds.length === 0}>
          Enter &crarr;
        </Chip>
      </div>
    </div>
  );
};
