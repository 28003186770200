import React from 'react';

export type ContainerProps = {
  children: React.ReactNode;
};

export function Container(props: ContainerProps): JSX.Element {
  const { children } = props;
  return (
    <div className="p-4 self-stretch rounded-lg border border-gray-300 bg-white shadow-md [min-h-[116px]] w-full">
      {children}
    </div>
  );
}
