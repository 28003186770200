import { useRecoilState } from 'recoil';

import { IconFlatMore } from '../Icon/IconFlatMore';
import { MenuItems } from '../Menu';
import { InboxMenu } from './InboxMenu';
import { inboxOpenMenuState } from './state/inboxOpenMenuState';

enum InboxMainMenuEnum {
  MARK_ALL_AS_READ = 'MARK_ALL_AS_READ',
  ARCHIVE_ALL = 'ARCHIVE_ALL',
}

const id = 'inbox__menu-main';

/**
 * A React component to render the main inbox menu
 */
export const InboxMainMenu = ({
  archiveAll,
  className,
  markAllAsRead,
}: {
  archiveAll: () => void;
  className?: string;
  markAllAsRead: () => void;
}) => {
  const [inboxOpenMenu, setInboxOpenMenu] = useRecoilState(inboxOpenMenuState);
  return (
    <InboxMenu
      className={className}
      icon={<IconFlatMore className="text-cool-gray-500" />}
      id={id}
    >
      <MenuItems
        items={[
          {
            content: 'Mark all notifications as read',
            iconKey: 'ICON_FLAT_ENVELOPE_OPEN',
            value: InboxMainMenuEnum.MARK_ALL_AS_READ,
          },
          {
            content: 'Archive all notifications',
            iconKey: 'ICON_FLAT_ARCHIVE_LARGE',
            value: InboxMainMenuEnum.ARCHIVE_ALL,
          },
        ]}
        onSelect={value => {
          if (value === InboxMainMenuEnum.ARCHIVE_ALL) {
            archiveAll();
          }
          if (value === InboxMainMenuEnum.MARK_ALL_AS_READ) {
            markAllAsRead();
          }
          if (inboxOpenMenu === id) {
            setInboxOpenMenu(undefined);
          }
        }}
      />
    </InboxMenu>
  );
};
