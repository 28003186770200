import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

export type TaskCardDetailsListProps<Type> = ComponentProps<'div'> & {
  /** An optional id to help derive unique keys */
  id?: string;

  /** Items of the list */
  items?: Type[];

  /** Title text */
  title?: string;

  /** A function to return the formatted item if necessary */
  formatItem?: <Item>(item: Item) => Item;
};

/**
 * A React component to render detail lists about the task
 */
export const TaskCardDetailsList = <Type,>({
  className,
  formatItem,
  id = 'task-card-details-list',
  items,
  title,
}: TaskCardDetailsListProps<Type>) => {
  return (
    <div className={twMerge('flex flex-col gap-1', className)}>
      <span className="font-medium text-xs leading-4 text-cool-gray-500">
        {title}
      </span>
      {!!items?.length && (
        <ul>
          {items.map(item => (
            <li
              key={`${id}__item-${title}-${item}`}
              className="text-ellipsis overflow-hidden"
            >
              {!formatItem ? item : formatItem(item)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
