import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import { LocationCollectionMode } from '@assured/step-renderer/types/step-components/Location';
import { captureException } from '@sentry/browser';

import { LocationGoogleMaps } from './LocationGoogleMaps';
import { LocationSmarty } from './LocationSmarty';
import { LocationOption, LocationProps } from './types';

import type { LDClient } from 'launchdarkly-react-client-sdk';

const shouldUseSmarty = async (
  ldClient?: LDClient,
  mode?: LocationCollectionMode,
): Promise<boolean> => {
  if (!(mode === LocationCollectionMode.MAILING_ADDRESS)) {
    return false;
  }

  if (ldClient === undefined) {
    captureException(
      new Error('Unexpected undefined LD client in shouldUseSmarty'),
    );
    return false;
  }
  if (!ldClient.variation('enable-smarty-address-input-sk', false)) {
    return false;
  }

  return true;
};

export const Location = (props: LocationProps) => {
  const ldClient = useLDClient();
  const [useSmarty, setUseSmarty] = useState<boolean | undefined>(undefined);
  const [switchToGoogle, setSwitchToGoogle] = useState<boolean | undefined>();
  const [query, setQuery] = useState<string | undefined>();
  const [locations, setLocations] = useState<LocationOption[]>([]);
  useEffect(() => {
    if (useSmarty === undefined) {
      const checkSmarty = async () => {
        const useSmartyBoolean = await shouldUseSmarty(ldClient, props.mode);
        setUseSmarty(useSmartyBoolean);
      };
      checkSmarty();
    }
  }, [useSmarty, ldClient, props.mode]);

  // Don't return a component while we're still unsure of the useSmarty value
  if (useSmarty === undefined) return null;
  // otherwise...
  return useSmarty && !switchToGoogle ? (
    <LocationSmarty
      {...props}
      setQuery={setQuery}
      setLocations={setLocations}
      setSwitchToGoogle={setSwitchToGoogle}
      query={query}
      locations={locations}
    />
  ) : (
    <LocationGoogleMaps
      {...props}
      setQuery={setQuery}
      setLocations={setLocations}
      setSwitchToGoogle={setSwitchToGoogle}
      query={query}
      locations={locations}
    />
  );
};
