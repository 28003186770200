import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { usePrevious } from '@assured/ui/hooks/usePrevious';

export interface AnimatedExpandCollapseProps {
  /** React node intended to be the rendered icon component */
  children: ReactNode;

  /** `className` that will override via `tailwind-merge` */
  className?: string;

  /** `true` if should be expanded */
  isExpanded?: boolean;

  /** If `true` will expand upon being added to the DOM */
  shouldExpandOnEnter?: boolean;
}

/**
 * A React component to provide expand and collapse animation
 */
export const AnimatedExpandCollapse = ({
  children,
  className,
  isExpanded: isExpandedProp,
  shouldExpandOnEnter,
}: AnimatedExpandCollapseProps) => {
  const [isExpandedState, setIsExpandedState] = useState(false);
  const isExpandedPrevious = usePrevious(isExpandedProp);
  const shouldExpand = !shouldExpandOnEnter ? isExpandedProp : isExpandedState;

  // expand upon DOM add if applicable
  useEffect(() => {
    // expand after mount so browser will honor the CSS change
    // to animate. `setTimeout` to ensure callback is enqueued
    // later in the callstack to accommodate browser rendering
    // quirks
    if (isExpandedProp && !isExpandedPrevious && shouldExpandOnEnter) {
      setTimeout(() => {
        setIsExpandedState(true);
      }, 10);
    }

    // mirror state with prop
    if (!isExpandedProp && isExpandedPrevious && shouldExpandOnEnter) {
      setIsExpandedState(false);
    }
  }, [isExpandedProp, isExpandedPrevious, shouldExpandOnEnter]);

  return (
    <div
      className={twMerge(
        classNames(
          'grid overflow-hidden transition-all duration-300 ease-in-out',
          {
            'grid-rows-[0fr] opacity-0': !shouldExpand,
            'grid-rows-[1fr] opacity-100': shouldExpand,
          },
        ),
        className,
      )}
      role="region"
    >
      <div className="overflow-hidden">{children}</div>
    </div>
  );
};
